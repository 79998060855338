.w-8{
  width:  8px;
  height: 8px;
  display: inline-block;
}
.w-16{
  width:  16px;
  height: 16px;
  display: inline-block;
}
.w-20{
  width:  20px;
  height: 20px;
  display: inline-block;
}
.w-24{
  width:  24px;
  height: 24px;
  display: inline-block;
  text-align: center;
}
.w-30{
  width:  30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
}
.w-32{
  width:  32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
}
.w-40{
  width:  40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}
.w-42{
  width:  42px;
  height: 42px;
  line-height: 42px;
  display: inline-block;
  text-align: center;
}

.w-44{
  width:  44px;
  height: 44px;
  line-height: 44px;
  display: inline-block;
  text-align: center;
}
.w-48{
  width:  48px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  text-align: center;
}
.w-56{
  width:  56px;
  height: 56px;
  line-height: 56px;
  display: inline-block;
  text-align: center;
}
.w-90 {
  width: 90%;
}

.w-64{
  width:  64px;
}
.w-96{
  width:  96px;
}
.w-128{
  width:  128px;
}
.w-xxxs{
  width: 40px;
}
.w-xxs{
  width: 60px;
}
.w-xs{
  width: 90px;
}
.w-sm{
  width: 120px;
}
.w-m{
  width: 140px;
}
.w{
  width: 180px;
}
.w-md{
  width: 200px;
}
.w-lg{
  width: 230px !important;
}
.w-xl{
  width: 280px;
}
.w-xxl{
  width: 320px;
}

.w-full{
  width: 100%;
}
.w-auto{
  width: auto;
}
.h-auto{
  height: auto;
}
.h-full{
  height: 100%;
}
.h-v{
  height: 100vh;
}
.h-v-5{
  height: 50vh;
}
.h-2x {
  height: 2rem;
}
.min-w-0{
  min-width: 0;
}
.min-w-5{
  min-width: 5%;
}
.min-w-10{
  min-width: 10%;
}
.min-w-15{
  min-width: 15%;
}
.min-w-20{
  min-width: 20%;
}
.min-w-25{
  min-width: 25%;
}
.min-w-50{
  min-width: 50%;
}
.min-w-75{
  min-width: 75%;
}
.min-w-90{
  min-width: 90%;
}
.min-w-100{
  min-width: 100%;
}

.max-w-5{
  max-width: 5%;
}
.max-w-10{
  max-width: 10%;
}
.max-w-15{
  max-width: 15%;
}
.max-w-20{
  max-width: 20%;
}
.max-w-25{
  max-width: 25%;
}


@include media-breakpoint-down(sm) {
  .w-auto-sm{
    width: auto;
  }
  .w-full-sm{
    width: 100%;
  }
}

@include media-breakpoint-down(xs) {
  .w-auto-xs{
    width: auto;
  }
  .w-full-xs{
    width: 100%;
  }
}
