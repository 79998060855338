@mixin btn-outline($color, $text-color) {
  &:hover {
    color: $text-color !important;
    background-color: darken($color, 4%);
  }

  &:focus {
    color: $text-color !important;
    background-color: darken($color, 5%);
  }
}

.btn {
  font-weight: 600;
  outline: 0 !important;
  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]).active {
    box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
  }
  > i {
    &.pull-left,
    &.pull-right {
      line-height: 1.5;
    }
  }
  &.rounded {
    padding-left: 1.2em;
    padding-right: 1.2em;
  }
}

.btn-lg {
  font-size: 1rem;
}

.btn-md {
  padding: .4695rem .75rem;
  font-size: 0.95rem;
}

.btn-sm {
  font-size: 0.9rem;
}

.btn-xs {
  padding: .2195rem .5rem;
  font-size: 0.85rem;
}

.btn-outline {
  border-width: 1px;
  background-color: transparent;
  box-shadow: none !important;
}

.btn {
  &.b-primary,
  &.primary {
    @include btn-outline($primary, $primary-color);
  }
  &.b-accent,
  &.accent {
    @include btn-outline($accent, $accent-color);
  }
  &.b-warn,
  &.warn {
    @include btn-outline($warn, $warn-color);
  }
  &.b-success,
  &.success {
    @include btn-outline($success, $success-color);
  }
  &.b-info,
  &.info {
    @include btn-outline($info, $info-color);
  }
  &.b-warning,
  &.warning {
    @include btn-outline($warning, $warning-color);
  }
  &.b-danger,
  &.danger {
    @include btn-outline($danger, $danger-color);
  }
  &.b-dark,
  &.dark {
    @include btn-outline($dark, $dark-color);
  }
  &.b-black,
  &.black {
    @include btn-outline($black, $black-color);
  }
  &.b-blackish,
  &.blackish {
    @include btn-outline($blackish, $black-color);
  }
  &.b-white {
    @include btn-outline($white, $dark-black);
  }
  &.b-whitish {
    @include btn-outline($whitish, $blackish);
  }
  &.b-light {
    @include btn-outline($light, $light-color);
  }
  &.b-bluish,
  &.bluish {
    @include btn-outline($bluish, $black-color);
  }
  &.b-orangish,
  &.orangish {
    @include btn-outline($orangish, $dark-greyish);
  }
  &.b-greenish,
  &.greenish {
    @include btn-outline($greenish, $black-color);
  }
  &.b-dark-greenish,
  &.dark-greenish {
    @include btn-outline($dark-greenish, $black-color);
  }
  &.btn-disabled {
    opacity: 0.65;
    cursor: not-allowed !important;
  }
}

.btn-icon {
  text-align: center;
  padding: 0 !important;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  position: relative;
  overflow: hidden;
  i {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    line-height: inherit;
    border-radius: inherit;
    @include transition(all 0.3s);
  }
  &.btn-xs {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  &.btn-sm {
    width: 1.775rem;
    height: 1.775rem;
    line-height: 1.775rem;
  }
  &.btn-lg {
    width: 3.167rem;
    height: 3.167rem;
    line-height: 3.167rem;
  }
  &.btn-social {
    i:last-child {
      top: 100%;
    }
  }
  &.btn-social:hover,
  &.btn-social:focus,
  &.btn-social.active {
    i:first-child {
      top: -100%;
    }
    i:last-child {
      top: 0;
      color: #fff;
    }
  }
  &.btn-social-colored {
    i:first-child {
      color: #fff;
    }
  }
}

.btn.white,
.btn.btn-default,
.btn.btn-secondary {
  border-color: $border-color;
}

.btn-default {
  background-color: #fff;
  color: $dark-black;
}

.btn-groups .btn {
  margin-bottom: .25rem;
}

.btn-fw {
  min-width: 7rem;
}

.btn-flat {
  border-radius: 0;
}

.btn-circle {
  border-radius: 50%;
}

.btn-white {
  background: white;

  &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]).active {
    box-shadow: none;
  }
}

#maitre-inline-button {
  border-radius: 0.25rem !important;
  font-size: 14px !important;
  padding: 7px 15px !important;
  display: inline-block !important;
  border: 1px solid #DCDFE6 !important;
  box-shadow: none !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  vertical-align: -2px !important;
}

#maitre-inline-button::after {
  content: "\1F381";
  padding-left: 6px;
}
