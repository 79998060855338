@mixin color-variant($bg, $color) {
  color: $color !important;
  background-color: $bg !important;
  .lt {
    background-color: lighten($bg, $color-percent);
  }
  .dk {
    background-color: darken($bg, $color-percent);
  }
  .bg {
    background-color: $bg;
  }
  &:disabled {
    background-color: rgba($bg, .5) !important;
  }
}

.lter {
  background-color: rgba(255, 255, 255, 0.035);
}

.dker {
  background-color: rgba(0, 0, 0, 0.035);
}

.light {
  background-color: $light;
}

.white {
  background-color: #fff;
}

.dark-white {
  color: $dark-black;
  background-color: #fff;
}

.black {
  @include color-variant($black, $black-color);
}

.dark {
  @include color-variant($dark, $dark-color);
}

.grey {
  @include color-variant($grey, $grey-color);
}

.primary {
  @include color-variant($primary, $primary-color);
}

.accent {
  @include color-variant($accent, $accent-color);
}

.warn {
  @include color-variant($warn, $warn-color);
}

.success {
  @include color-variant($success, $success-color);
}

.info {
  @include color-variant($info, $info-color);
}

.warning {
  @include color-variant($warning, $warning-color);
}

.danger {
  @include color-variant($danger, $danger-color);
}

.blue {
  @include color-variant($blue, $dark-white);
}

.bluish {
  @include color-variant($bluish, $dark-white);
}

.purplish {
  @include color-variant($purplish, $dark-white);
}

.whitish {
  @include color-variant($whitish, $dark-color);
}

.orangish {
  @include color-variant($orangish, $blackish);
}

.greenish {
  @include color-variant($greenish, $whitish);
}

.greyish {
  @include color-variant($greyish, $whitish);
}

.dark-greyish {
  @include color-variant($dark-greyish, $whitish);
}

.dark-greenish {
  @include color-variant($dark-greenish, $whitish);
}

.blackish {
  @include color-variant($blackish, $dark-white);
}

.new-blue {
  @include color-variant($new-blue, $whitish);
}

.blue-15 {
  @include color-variant(#2F80ED, $whitish);
}

.mellow-blue {
  @include color-variant($mellow-blue, $whitish);
}

.new-purple {
  @include color-variant($new-purple, $whitish);
}

.blue-purple-gradient {
  background-color: $new-blue;
  background-image: linear-gradient(135deg, $new-blue 0%, $new-purple 100%);
  color: $whitish;
}

.green-gradient {
  background-color: $greenish;
  background-image: linear-gradient(135deg, $greenish 0%, $dark-greenish 100%);
  color: $whitish;
}

.white-overlay {
  background-color: rgba(255, 255, 255, 0.85);
}

.black-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}

.gd-overlay {
  background-color: transparent !important;
  @include gradient-y(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.bg-auto {
  &:before {
    content: "";
    position: absolute;
    width: inherit;
    top: 0;
    bottom: 0;
    background-color: inherit;
    border: inherit;
  }

  &.b-l:before {
    margin-left: -1px;
  }

  &.b-r:before {
    margin-right: -1px;
  }
}

.bg-clip {
  background-clip: padding-box;
}

.no-bg {
  background-color: transparent !important;
}

@media (max-width: 767px) {
  .no-bg-xs {
    background-color: transparent;
  }
}
