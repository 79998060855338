/*

*/

$full-black:  		rgba(0, 0, 0, 1);
$dark-black:  		rgba(0, 0, 0, 0.87);
$light-black: 		rgba(0, 0, 0, 0.54);
$min-black:   		rgba(0, 0, 0, 0.065);

$full-white:  		rgba(255, 255, 255, 1);
$dark-white:  		rgba(255, 255, 255, 0.87);
$darkish-white: 	rgba(255, 255, 255, 0.65);
$light-white: 		rgba(255, 255, 255, 0.54);
$min-white:   		rgba(255, 255, 255, 0.1);

$primary:           #0067F4;
$accent:            #a88add;
$warn:              #FFB400;

$info:              #00B8D8;
$success:           #00BF50;
$warning:           #FFB400;
$danger:            #C4183C;

$light:             #f8f8f8;
$grey:              #424242;
$dark:              #2e3e4e;
$black:				#2a2b3c;
$white:				#ffffff;
$bluish:            #0067F4;
$new-blue:          #043791;
$mellow-blue:       #fafcff;
$new-purple:        #37234b;
$purplish:          #230e63;
$orangish:          $warn;
$yellowish:         #F2C94C;
$duskishblue:       #4B4D70;
$greenish:          #00BF50;
$dark-greenish:     #00A344;
$blackish:          #090A0D;
$greyish:           #7D7D7D;
$dark-greyish:      darken($greyish, 30%);
$whitish:           $white;
$primary:           $greenish;
$light-beige:       #FEF8E8;
$royal-blue:        #256EFF;
$light-grayish-blue:#DCDFE6;
$white-80-opacity:  rgba(255, 255, 255, 0.8);
$dark-gray:         #343434;

$black:             $blackish;
$dark-white:        $whitish;

$primary-color:     $dark-white;
$accent-color:		$dark-white;
$warn-color:		$dark-white;
$success-color:		$dark-white;
$info-color:		$dark-white;
$warning-color:		$dark-white;
$danger-color:     	$dark-white;
$light-color:		$dark-black;
$grey-color:		$dark-white;
$dark-color:		$dark-white;
$black-color:		$dark-white;

$html-bg:           #ffffff;
$body-bg:       	#fbfbfb;
$text-color:        $dark-black;
$text-muted:        $light-black;

$enable-shadows:	true;
$enable-hover-media-query: true;

$border-color:	 	rgba(120, 130, 140, 0.13);
$border-color-lt:	rgba(120, 130, 140, 0.065);
$border-color-lter:	rgba(120, 130, 140, 0.045);

$font-family-serif:              Georgia, "Times New Roman", Times, serif;
$font-family-body:               "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-alternative:        "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$font-size:          	  	     0.90rem;
$line-height:			  	     1.5;

$font-size-xl:            	     1.5rem;
$font-size-lg:            	     1.25rem;
$font-size-md:                   1rem;
$font-size-sm:            	     0.90rem;
$font-size-xs:            	     0.85rem;
$font-size-xxs:            	     0.75rem;

$spacer:				  	     1rem;
$spacer-x:				  	     1rem;
$spacer-y:					     1rem;

$link-color:                     inherit;
$hover-color: 			         inherit;
$color-percent:                  2.5%;
$color-lt-percent:               2.5%;
$color-dk-percent:               3.5%;

$border-radius-base:             3px;

$navbar-height:                  3.2rem;
$navbar-md-height:				 4rem;
$navbar-sm-height:               3rem;

$aside-width:                    15rem;
$aside-lg-width:                 15rem;
$aside-sm-width:                 11.25rem;
$aside-folded-width:             4rem;
$aside-folded-md-width:          5rem;
$aside-nav-height:               2.25rem;
$aside-nav-folded-height:        2rem;
$aside-nav-sub-height:           2rem;
$aside-nav-stacked-height:       2rem;
$navside-bg:                     #fbfbfb;

$footer-height:					 3.125rem;
$footer-height-small:			 2.435rem;

$label-bg:                       darken($light, 25%);

$table-border-color:             $border-color-lter;
$table-striped-color:            rgba(0, 0, 0, 0.025);
$list-group-border:				 $border-color-lt;

$input-border:                   rgba(120, 130, 140, 0.2);
$input-border-focus:             rgba(120, 130, 140, 0.3);
$input-border-radius:            0;

$arrow-width:         			 8px;
$arrow-outer-color:              $border-color;

$switch-width:                   32px;
$switch-height:                  18px;
$switch-md-width:                40px;
$switch-md-height:               24px;
$switch-lg-width:                50px;
$switch-lg-height:               30px;

$ui-check-size:                  16px;
$ui-check-md-size:               18px;
$ui-check-lg-size:               30px;
$ui-check-sign-size:             6px;
$ui-check-sign-color:            $primary;
$ui-check-checked-border:        $input-border;

$info: $greenish;
