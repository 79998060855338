.pos-rlt {
  position: relative;
  z-index: 1;
}

.pos-abt {
  position: absolute;
}

.pos-fix {
  position: fixed !important;
}

.pos-stc {
  position: static !important;
}

.block {
  display: block;
}

.block.hide {
  display: none;
}

.pull-none {
  float: none;
}

.inline {
  display: inline-block;
}

.inline-16 {
  width: 16px;
  height: 16px;
}

.inline-24 {
  width: 24px;
  height: 24px;
}

.none {
  display: none;
}

.active > .inline,
.active > .auto .inline {
  display: none;
}

.active > .none,
.active > .auto .none {
  display: inline-block;
}

.hide {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.rounded,
.circle {
  border-radius: 500px;
}

.clear {
  display: block;
  overflow: hidden;
}

.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  & ::selection {
    background: transparent; /* WebKit/Blink Browsers */
  }

  & ::-moz-selection {
    background: transparent; /* Gecko Browsers */
  }
}

@include media-breakpoint-down(sm) {
  .pos-stc-sm {
    position: static !important;
  }
}

@include media-breakpoint-down(xs) {
  .pos-stc-xs {
    position: static !important;
  }
}
