// streamline
.streamline {
	position: relative;
	border-color: $border-color;
	&:after,
	.sl-item:before,
	.sl-icon:before{
		content: '';
		position: absolute;
		border-color: inherit;
		border-width: 3px;
		border-style: solid;
		border-radius: 50%;
		width: 7px;
		height: 7px;
		margin-left: -4px;
		top: 6px;
		left: 0;
	}
	&:after{
		top: auto;
		bottom: 0;
	}
}

.sl-icon{
	position: absolute;
	left: -10px;
	z-index: 1;
	border: inherit;
	> i{
		width: 20px;
		height: 20px;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
		color: #fff;
	}
	.streamline &:before{
		width: 20px;
		height: 20px;
		border-width: 10px;
		margin: 0 !important;
		top: 0 !important;
	}
}

.sl-item{
	border-color: $border-color;
	position: relative;
	padding-bottom: 1px;
	@include clearfix();
	&:after{
		top: 2px;
		bottom: auto;
	}
	&.b-l{
		margin-left: -1px;
	}
}

.sl-left{
	float: left;
	position: relative;
	z-index: 1;
	margin-left: -20px;
	margin-bottom: 24px;
	img{
		max-width: 40px;
	}
	+ .sl-content{
		margin-left: 36px;
	}
}

.sl-content{
	margin-left: 24px;
	padding-bottom: 16px;
}

.sl-author{
	margin-bottom: 10px;
}

.sl-date{
	font-size: 0.85em;
}

.sl-footer{
	margin-bottom: 10px;
}
