// Google Fonts
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200..800);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200..800);

@font-face {
  font-family: 'BLOKK';
  src: url('../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.eot');
  src: url('../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.woff2') format('woff2'),
  url('../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.woff') format('woff'),
  url('../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.otf') format('opentype'),
  url('../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.ttf') format('truetype'),
  url('../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.svg#BLOKKRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'BLOKK';
    src: url('../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.svg') format('svg');
  }
}

body {
  font-family: $font-family-body;
  font-weight: 300;
  font-size: 0.95rem;
}

button, input, optgroup, select, textarea {
  font-family: $font-family-body;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-family-alternative;
}

.navside ul {
  font-family: $font-family-alternative;
}

#page-title {
  font-family: $font-family-alternative;
  font-weight: 500;
  font-size: 1rem;
}

nav-item dropdown {
  font-family: $font-family-alternative;
}

.navbar-brand {
  font-family: $font-family-alternative;
  font-weight: normal;
  font-size: 30px;
}

.login-brand > span {
  font-family: $font-family-alternative;
  font-weight: normal;
  font-size: 30px;
}

.login-brand {
  font-size: 20px;
  font-weight: 700;
}

.login .el-form-item__error {
  font-size: 11px;
}

#logout-button a i {
  font-size: 150%;
}

.wizard-header h2, .wizard-header h3, .wizard-header h4 {
  font-size: 24px;
  font-weight: bold;
}

.font-120 {
  font-size: 120%;
}

.form-hover-help {
  font-size: 12px;
}

.wizard .form-head {
  font-size: 16px;
}

.status-icon .material-icons,
.status-icon .fa {
  font-size: 24px;
}

.w-200 {
  font-size: 50px;
}

.filter-call-logs {
  & .filter-item {
    font-size: 0.800rem;
  }

  & .dropdown-item > i {
    font-size: 20px;
  }
}

.call-log-head {
  font-size: 100%;
}

#logout-button a i {
  font-size: 150%;
}

ol.special-ol > li:before {
  font-weight: bold;
  font-size: 28px;
}

.header-info {
  & span {
    font-size: 14px;
  }
}

.nav > li > a .nav-text {
  font-size: 16px;
  line-height: 1.625rem;
}

.nav > li.campaigns > a .nav-text {
  font-size: 14px;
}

.nav > li > a .nav-icon i {
  font-size: 20px;
}

.nav > li.campaigns > a .nav-icon i {
  font-size: 18px;
}

.add-campaign {
  font-weight: 400;
}

.filter-select {
  & .el-input__inner {
    font-weight: 400;
  }
}

.filter-div {
  font-weight: 600;
  font-size: 1rem;
}

.form-control-label {
  font-weight: bold;
}

.p-inline-block {
  font-size: 105%;
}

.conversync .form-control-static {
  font-weight: 600;
}

.mockup {
  font-family: "BLOKK";
  font-size: 24px;
  color: #F0F0F0;
  @extend .blink;
}

.font-alternative {
  font-family: $font-family-alternative;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and (min-device-pixel-ratio: 1.25),
only screen and (min-resolution: 200dpi),
only screen and (min-resolution: 1.25dppx) {
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; // fix fatty ff on mac
    text-rendering: optimizeLegibility;
  }
}
