.red { background-color: $red-500; color: $dark-white; }
.red-50 { background-color:  $red-50;  color: $dark-black; }
.red-100 { background-color: $red-100; color: $dark-black; }
.red-200 { background-color: $red-200; color: $dark-black; }
.red-300 { background-color: $red-300; color: $dark-black; }
.red-400 { background-color: $red-400; color: $dark-black; }
.red-500 { background-color: $red-500; color: $dark-white; }
.red-600 { background-color: $red-600; color: $dark-white; }
.red-700 { background-color: $red-700; color: $dark-white; }
.red-800 { background-color: $red-800; color: $dark-white; }
.red-900 { background-color: $red-900; color: $dark-white; }
.red-A100 { background-color: $red-A100; color: $dark-black; }
.red-A200 { background-color: $red-A200; color: $dark-white; }
.red-A400 { background-color: $red-A400; color: $dark-white; }
.red-A700 { background-color: $red-A700; color: $dark-white; }

.pink { background-color:     $pink-500; color: $dark-white; }
.pink-50 { background-color:  $pink-50;  color: $dark-black; }
.pink-100 { background-color: $pink-100; color: $dark-black; }
.pink-200 { background-color: $pink-200; color: $dark-black; }
.pink-300 { background-color: $pink-300; color: $dark-black; }
.pink-400 { background-color: $pink-400; color: $dark-black; }
.pink-500 { background-color: $pink-500; color: $dark-white; }
.pink-600 { background-color: $pink-600; color: $dark-white; }
.pink-700 { background-color: $pink-700; color: $dark-white; }
.pink-800 { background-color: $pink-800; color: $dark-white; }
.pink-900 { background-color: $pink-900; color: $dark-white; }
.pink-A100 { background-color: $pink-A100; color: $dark-black; }
.pink-A200 { background-color: $pink-A200; color: $dark-white; }
.pink-A400 { background-color: $pink-A400; color: $dark-white; }
.pink-A700 { background-color: $pink-A700; color: $dark-white; }

.purple { background-color: $purple-500; color: $dark-white; }
.purple-50 { background-color: $purple-50; color: $dark-black; }
.purple-100 { background-color: $purple-100; color: $dark-black; }
.purple-200 { background-color: $purple-200; color: $dark-black; }
.purple-300 { background-color: $purple-300; color: $dark-white; }
.purple-400 { background-color: $purple-400; color: $dark-white; }
.purple-500 { background-color: $purple-500; color: $dark-white; }
.purple-600 { background-color: $purple-600; color: $dark-white; }
.purple-700 { background-color: $purple-700; color: $dark-white; }
.purple-800 { background-color: $purple-800; color: $dark-white; }
.purple-900 { background-color: $purple-900; color: $dark-white; }
.purple-A100 { background-color: $purple-A100; color: $dark-black; }
.purple-A200 { background-color: $purple-A200; color: $dark-white; }
.purple-A400 { background-color: $purple-A400; color: $dark-white; }
.purple-A700 { background-color: $purple-A700; color: $dark-white; }

.deep-purple { background-color: $deep-purple-500; color: $dark-white; }
.deep-purple-50 { background-color: $deep-purple-50; color: $dark-black; }
.deep-purple-100 { background-color: $deep-purple-100; color: $dark-black; }
.deep-purple-200 { background-color: $deep-purple-200; color: $dark-black; }
.deep-purple-300 { background-color: $deep-purple-300; color: $dark-white; }
.deep-purple-400 { background-color: $deep-purple-400; color: $dark-white; }
.deep-purple-500 { background-color: $deep-purple-500; color: $dark-white; }
.deep-purple-600 { background-color: $deep-purple-600; color: $dark-white; }
.deep-purple-700 { background-color: $deep-purple-700; color: $dark-white; }
.deep-purple-800 { background-color: $deep-purple-800; color: $dark-white; }
.deep-purple-900 { background-color: $deep-purple-900; color: $dark-white; }
.deep-purple-A100 { background-color: $deep-purple-A100; color: $dark-black; }
.deep-purple-A200 { background-color: $deep-purple-A200; color: $dark-white; }
.deep-purple-A400 { background-color: $deep-purple-A400; color: $dark-white; }
.deep-purple-A700 { background-color: $deep-purple-A700; color: $dark-white; }

.indigo { background-color: $indigo-500; color: $dark-white; }
.indigo-50 { background-color: $indigo-50; color: $dark-black; }
.indigo-100 { background-color: $indigo-100; color: $dark-black; }
.indigo-200 { background-color: $indigo-200; color: $dark-black; }
.indigo-300 { background-color: $indigo-300; color: $dark-white; }
.indigo-400 { background-color: $indigo-400; color: $dark-white; }
.indigo-500 { background-color: $indigo-500; color: $dark-white; }
.indigo-600 { background-color: $indigo-600; color: $dark-white; }
.indigo-700 { background-color: $indigo-700; color: $dark-white; }
.indigo-800 { background-color: $indigo-800; color: $dark-white; }
.indigo-900 { background-color: $indigo-900; color: $dark-white; }
.indigo-A100 { background-color: $indigo-A100; color: $dark-black; }
.indigo-A200 { background-color: $indigo-A200; color: $dark-white; }
.indigo-A400 { background-color: $indigo-A400; color: $dark-white; }
.indigo-A700 { background-color: $indigo-A700; color: $dark-white; }

.blue { background-color: $blue-500; color: $dark-white; }
.blue-50 { background-color: $blue-50; color: $dark-black; }
.blue-100 { background-color: $blue-100; color: $dark-black; }
.blue-200 { background-color: $blue-200; color: $dark-black; }
.blue-300 { background-color: $blue-300; color: $dark-black; }
.blue-400 { background-color: $blue-400; color: $dark-black; }
.blue-500 { background-color: $blue-500; color: $dark-white; }
.blue-600 { background-color: $blue-600; color: $dark-white; }
.blue-700 { background-color: $blue-700; color: $dark-white; }
.blue-800 { background-color: $blue-800; color: $dark-white; }
.blue-900 { background-color: $blue-900; color: $dark-white; }
.blue-A100 { background-color: $blue-A100; color: $dark-black; }
.blue-A200 { background-color: $blue-A200; color: $dark-white; }
.blue-A400 { background-color: $blue-A400; color: $dark-white; }
.blue-A700 { background-color: $blue-A700; color: $dark-white; }

.light-blue { background-color: $light-blue-500; color: $dark-white; }
.light-blue-50 { background-color: $light-blue-50; color: $dark-black; }
.light-blue-100 { background-color: $light-blue-100; color: $dark-black; }
.light-blue-200 { background-color: $light-blue-200; color: $dark-black; }
.light-blue-300 { background-color: $light-blue-300; color: $dark-black; }
.light-blue-400 { background-color: $light-blue-400; color: $dark-black; }
.light-blue-500 { background-color: $light-blue-500; color: $dark-white; }
.light-blue-600 { background-color: $light-blue-600; color: $dark-white; }
.light-blue-700 { background-color: $light-blue-700; color: $dark-white; }
.light-blue-800 { background-color: $light-blue-800; color: $dark-white; }
.light-blue-900 { background-color: $light-blue-900; color: $dark-white; }
.light-blue-A100 { background-color: $light-blue-A100; color: $dark-black; }
.light-blue-A200 { background-color: $light-blue-A200; color: $dark-black; }
.light-blue-A400 { background-color: $light-blue-A400; color: $dark-black; }
.light-blue-A700 { background-color: $light-blue-A700; color: $dark-white; }

.cyan { background-color: $cyan-500; color: $dark-white; }
.cyan-50 { background-color: $cyan-50; color: $dark-black; }
.cyan-100 { background-color: $cyan-100; color: $dark-black; }
.cyan-200 { background-color: $cyan-200; color: $dark-black; }
.cyan-300 { background-color: $cyan-300; color: $dark-black; }
.cyan-400 { background-color: $cyan-400; color: $dark-black; }
.cyan-500 { background-color: $cyan-500; color: $dark-white; }
.cyan-600 { background-color: $cyan-600; color: $dark-white; }
.cyan-700 { background-color: $cyan-700; color: $dark-white; }
.cyan-800 { background-color: $cyan-800; color: $dark-white; }
.cyan-900 { background-color: $cyan-900; color: $dark-white; }
.cyan-A100 { background-color: $cyan-A100; color: $dark-black; }
.cyan-A200 { background-color: $cyan-A200; color: $dark-black; }
.cyan-A400 { background-color: $cyan-A400; color: $dark-black; }
.cyan-A700 { background-color: $cyan-A700; color: $dark-black; }

.teal { background-color: $teal-500; color: $dark-white; }
.teal-50 { background-color: $teal-50; color: $dark-black; }
.teal-100 { background-color: $teal-100; color: $dark-black; }
.teal-200 { background-color: $teal-200; color: $dark-black; }
.teal-300 { background-color: $teal-300; color: $dark-black; }
.teal-400 { background-color: $teal-400; color: $dark-black; }
.teal-500 { background-color: $teal-500; color: $dark-white; }
.teal-600 { background-color: $teal-600; color: $dark-white; }
.teal-700 { background-color: $teal-700; color: $dark-white; }
.teal-800 { background-color: $teal-800; color: $dark-white; }
.teal-900 { background-color: $teal-900; color: $dark-white; }
.teal-A100 { background-color: $teal-A100; color: $dark-black; }
.teal-A200 { background-color: $teal-A200; color: $dark-black; }
.teal-A400 { background-color: $teal-A400; color: $dark-black; }
.teal-A700 { background-color: $teal-A700; color: $dark-black; }

.green { background-color: $green-500; color: $dark-white; }
.green-50 { background-color: $green-50; color: $dark-black; }
.green-100 { background-color: $green-100; color: $dark-black; }
.green-200 { background-color: $green-200; color: $dark-black; }
.green-300 { background-color: $green-300; color: $dark-black; }
.green-400 { background-color: $green-400; color: $dark-black; }
.green-500 { background-color: $green-500; color: $dark-white; }
.green-600 { background-color: $green-600; color: $dark-white; }
.green-700 { background-color: $green-700; color: $dark-white; }
.green-800 { background-color: $green-800; color: $dark-white; }
.green-900 { background-color: $green-900; color: $dark-white; }
.green-A100 { background-color: $green-A100; color: $dark-black; }
.green-A200 { background-color: $green-A200; color: $dark-black; }
.green-A400 { background-color: $green-A400; color: $dark-black; }
.green-A700 { background-color: $green-A700; color: $dark-black; }

.light-green { background-color: $light-green-500; color: $dark-black; }
.light-green-50 { background-color: $light-green-50; color: $dark-black; }
.light-green-100 { background-color: $light-green-100; color: $dark-black; }
.light-green-200 { background-color: $light-green-200; color: $dark-black; }
.light-green-300 { background-color: $light-green-300; color: $dark-black; }
.light-green-400 { background-color: $light-green-400; color: $dark-black; }
.light-green-500 { background-color: $light-green-500; color: $dark-black; }
.light-green-600 { background-color: $light-green-600; color: $dark-black; }
.light-green-700 { background-color: $light-green-700; color: $dark-black; }
.light-green-800 { background-color: $light-green-800; color: $dark-white; }
.light-green-900 { background-color: $light-green-900; color: $dark-white; }
.light-green-A100 { background-color: $light-green-A100; color: $dark-black; }
.light-green-A200 { background-color: $light-green-A200; color: $dark-black; }
.light-green-A400 { background-color: $light-green-A400; color: $dark-black; }
.light-green-A700 { background-color: $light-green-A700; color: $dark-black; }

.lime { background-color: $lime-500; color: $dark-black; }
.lime-50 { background-color: $lime-50; color: $dark-black; }
.lime-100 { background-color: $lime-100; color: $dark-black; }
.lime-200 { background-color: $lime-200; color: $dark-black; }
.lime-300 { background-color: $lime-300; color: $dark-black; }
.lime-400 { background-color: $lime-400; color: $dark-black; }
.lime-500 { background-color: $lime-500; color: $dark-black; }
.lime-600 { background-color: $lime-600; color: $dark-black; }
.lime-700 { background-color: $lime-700; color: $dark-black; }
.lime-800 { background-color: $lime-800; color: $dark-black; }
.lime-900 { background-color: $lime-900; color: $dark-white; }
.lime-A100 { background-color: $lime-A100; color: $dark-black; }
.lime-A200 { background-color: $lime-A200; color: $dark-black; }
.lime-A400 { background-color: $lime-A400; color: $dark-black; }
.lime-A700 { background-color: $lime-A700; color: $dark-black; }

.yellow { background-color: $yellow-500; color: $dark-black; }
.yellow-50 { background-color: $yellow-50; color: $dark-black; }
.yellow-100 { background-color: $yellow-100; color: $dark-black; }
.yellow-200 { background-color: $yellow-200; color: $dark-black; }
.yellow-300 { background-color: $yellow-300; color: $dark-black; }
.yellow-400 { background-color: $yellow-400; color: $dark-black; }
.yellow-500 { background-color: $yellow-500; color: $dark-black; }
.yellow-600 { background-color: $yellow-600; color: $dark-black; }
.yellow-700 { background-color: $yellow-700; color: $dark-black; }
.yellow-800 { background-color: $yellow-800; color: $dark-black; }
.yellow-900 { background-color: $yellow-900; color: $dark-black; }
.yellow-A100 { background-color: $yellow-A100; color: $dark-black; }
.yellow-A200 { background-color: $yellow-A200; color: $dark-black; }
.yellow-A400 { background-color: $yellow-A400; color: $dark-black; }
.yellow-A700 { background-color: $yellow-A700; color: $dark-black; }

.amber { background-color: $amber-500; color: $dark-black; }
.amber-50 { background-color: $amber-50; color: $dark-black; }
.amber-100 { background-color: $amber-100; color: $dark-black; }
.amber-200 { background-color: $amber-200; color: $dark-black; }
.amber-300 { background-color: $amber-300; color: $dark-black; }
.amber-400 { background-color: $amber-400; color: $dark-black; }
.amber-500 { background-color: $amber-500; color: $dark-black; }
.amber-600 { background-color: $amber-600; color: $dark-black; }
.amber-700 { background-color: $amber-700; color: $dark-black; }
.amber-800 { background-color: $amber-800; color: $dark-black; }
.amber-900 { background-color: $amber-900; color: $dark-black; }
.amber-A100 { background-color: $amber-A100; color: $dark-black; }
.amber-A200 { background-color: $amber-A200; color: $dark-black; }
.amber-A400 { background-color: $amber-A400; color: $dark-black; }
.amber-A700 { background-color: $amber-A700; color: $dark-black; }

.orange { background-color: $orange-500; color: $dark-black; }
.orange-50 { background-color: $orange-50; color: $dark-black; }
.orange-100 { background-color: $orange-100; color: $dark-black; }
.orange-200 { background-color: $orange-200; color: $dark-black; }
.orange-300 { background-color: $orange-300; color: $dark-black; }
.orange-400 { background-color: $orange-400; color: $dark-black; }
.orange-500 { background-color: $orange-500; color: $dark-black; }
.orange-600 { background-color: $orange-600; color: $dark-black; }
.orange-700 { background-color: $orange-700; color: $dark-black; }
.orange-800 { background-color: $orange-800; color: $dark-white; }
.orange-900 { background-color: $orange-900; color: $dark-white; }
.orange-A100 { background-color: $orange-A100; color: $dark-black; }
.orange-A200 { background-color: $orange-A200; color: $dark-black; }
.orange-A400 { background-color: $orange-A400; color: $dark-black; }
.orange-A700 { background-color: $orange-A700; color: $dark-black; }

.deep-orange { background-color: $deep-orange-500; color: $dark-white; }
.deep-orange-50 { background-color: $deep-orange-50; color: $dark-black; }
.deep-orange-100 { background-color: $deep-orange-100; color: $dark-black; }
.deep-orange-200 { background-color: $deep-orange-200; color: $dark-black; }
.deep-orange-300 { background-color: $deep-orange-300; color: $dark-black; }
.deep-orange-400 { background-color: $deep-orange-400; color: $dark-black; }
.deep-orange-500 { background-color: $deep-orange-500; color: $dark-white; }
.deep-orange-600 { background-color: $deep-orange-600; color: $dark-white; }
.deep-orange-700 { background-color: $deep-orange-700; color: $dark-white; }
.deep-orange-800 { background-color: $deep-orange-800; color: $dark-white; }
.deep-orange-900 { background-color: $deep-orange-900; color: $dark-white; }
.deep-orange-A100 { background-color: $deep-orange-A100; color: $dark-black; }
.deep-orange-A200 { background-color: $deep-orange-A200; color: $dark-black; }
.deep-orange-A400 { background-color: $deep-orange-A400; color: $dark-white; }
.deep-orange-A700 { background-color: $deep-orange-A700; color: $dark-white; }

.brown { background-color: $brown-500; color: $dark-white; }
.brown-50 { background-color: $brown-50; color: $dark-black; }
.brown-100 { background-color: $brown-100; color: $dark-black; }
.brown-200 { background-color: $brown-200; color: $dark-black; }
.brown-300 { background-color: $brown-300; color: $dark-white; }
.brown-400 { background-color: $brown-400; color: $dark-white; }
.brown-500 { background-color: $brown-500; color: $dark-white; }
.brown-600 { background-color: $brown-600; color: $dark-white; }
.brown-700 { background-color: $brown-700; color: $dark-white; }
.brown-800 { background-color: $brown-800; color: $dark-white; }
.brown-900 { background-color: $brown-900; color: $dark-white; }

.blue-grey{ background-color: $blue-grey-500; color: $dark-white; }
.blue-grey-50 { background-color: $blue-grey-50; color: $dark-black; }
.blue-grey-100 { background-color: $blue-grey-100; color: $dark-black; }
.blue-grey-200 { background-color: $blue-grey-200; color: $dark-black; }
.blue-grey-300 { background-color: $blue-grey-300; color: $dark-black; }
.blue-grey-400 { background-color: $blue-grey-400; color: $dark-white; }
.blue-grey-500 { background-color: $blue-grey-500; color: $dark-white; }
.blue-grey-600 { background-color: $blue-grey-600; color: $dark-white; }
.blue-grey-700 { background-color: $blue-grey-700; color: $dark-white; }
.blue-grey-800 { background-color: $blue-grey-800; color: $dark-white; }
.blue-grey-900 { background-color: $blue-grey-900; color: $dark-white; }


.grey-50 { background-color: $grey-50; color: $dark-black; }
.grey-100 { background-color: $grey-100; color: $dark-black; }
.grey-200 { background-color: $grey-200; color: $dark-black; }
.grey-300 { background-color: $grey-300; color: $dark-black; }
.grey-400 { background-color: $grey-400; color: $dark-black; }
.grey-500 { background-color: $grey-500; color: $dark-black; }
.grey-600 { background-color: $grey-600; color: $dark-white; }
.grey-700 { background-color: $grey-700; color: $dark-white; }
.grey-800 { background-color: $grey-800; color: $dark-white; }
.grey-900 { background-color: $grey-900; color: $dark-white; }

.text-deep-purple-500 { color: $deep-purple-500 !important; }
.text-purple-500 { color: $purple-500 !important; }
.text-yellow-500 { color: $yellow-500 !important; }
.text-red-500 { color: $red-500 !important; }
.text-red-A400 { color: $red-A400 !important; }
.text-orange-500 { color: $orange-500 !important; }
.text-pink-500 { color: $pink-500 !important; }
.text-green-500 { color: $green-500 !important; }
.text-indigo-500 { color: $indigo-500 !important; }
.text-blue-500 { color: $blue-500 !important; }
.text-lime-500 { color: $lime-500 !important; }
.text-teal-500 { color: $teal-500 !important; }
.text-cyan-500 { color: $cyan-500 !important; }
.text-grey-50 { color: $grey-50 !important; }
.text-grey-100 { color: $grey-100 !important; }
.text-grey-200 { color: $grey-200 !important; }
.text-grey-300 { color: $grey-300 !important; }
.text-grey-400 { color: $grey-400 !important; }
.text-grey-500 { color: $grey-500 !important; }
.text-grey-800 { color: $grey-800 !important; }
.text-grey-900 { color: $grey-900 !important; }
.text-bluish { color: $bluish !important; }
.text-purplish { color: $purplish !important; }
.text-orangish { color: $orangish !important; }
.text-duskishblue { color: $duskishblue !important; }
.text-greenish { color: $greenish !important; }
.text-dark-greenish { color: $dark-greenish !important; }
.text-greyish { color: $greyish !important; }
.text-dark-greyish { color: $dark-greyish !important; }
.text-blackish { color: $blackish !important; }
.text-whitish { color: $whitish !important; }
.text-new-blue { color: $new-blue !important; }
.text-new-purple { color: $new-purple !important; }
.text-deep-orange { color: $deep-orange !important; }

.b-deep-purple-500 { border-color: $deep-purple-500 !important; }
.b-purple-500 { border-color: $purple-500 !important; }
.b-red-500 { border-color: $red-500 !important; }
.b-orange-500 { border-color: $orange-500 !important; }
.b-pink-500 { border-color: $pink-500 !important; }
.b-green-500 { border-color: $green-500 !important; }
.b-indigo-500 { border-color: $indigo-500 !important; }
.b-blue-500 { border-color: $blue-500 !important; }
.b-lime-500 { border-color: $lime-500 !important; }
.b-teal-500 { border-color: $teal-500 !important; }
.b-cyan-500 { border-color: $cyan-500 !important; }
.b-grey-800 { border-color: $grey-800 !important; }
.b-grey-900 { border-color: $grey-900 !important; }
.b-bluish { border-color: $bluish !important; }
.b-purplish { border-color: $purplish !important; }
.b-orangish { border-color: $orangish !important; }
.b-duskishblue { border-color: $duskishblue !important; }

.b-greenish { border-color: $greenish !important; }
.b-dark-greenish { border-color: $dark-greenish !important; }
.b-greyish { border-color: $greyish !important; }
.b-dker { border-color: rgba(0, 0, 0, 0.1) !important; }
.b-blackish { border-color: $blackish !important; }
.b-whitish { border-color: $whitish !important; }

@mixin linx($parent, $color) {
  a.#{$parent} {
    color: $color !important;
    &:visited {
      color: $color !important;
    }
    &:hover {
      color: darken($color, 10%) !important;
    }
    &:active {
      color: darken($color, 10%) !important;
    }
  }
}

@include linx("text-bluish", $bluish);
@include linx("text-purplish", $purplish);
@include linx("text-greenish", $greenish);
@include linx("text-dark-greenish", $dark-greenish);
