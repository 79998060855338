@include media-breakpoint-down(md) {
	body.container,
	.app .container{
		max-width: 100%;
		padding-left:  0;
		padding-right: 0;
	}
}
@include media-breakpoint-up(lg) {
	body.container{
		&,
		.container{
			padding-left: 0;
			padding-right: 0;
		}
		@include box-shadow(0 1px 1px rgba(0,0,0,0.1));
		.app-aside{
			left: inherit;
			right: auto;
			&.lg  ~ .app-content .app-header{
				width: 58.75rem - $aside-lg-width;
			}
			&.sm  ~ .app-content .app-header{
				width: 58.75rem - $aside-sm-width;
			}
			&.folded  ~ .app-content .app-header{
				width: 58.75rem - $aside-folded-width;
			}
			&.folded.md  ~ .app-content .app-header{
				width: 58.75rem - $aside-folded-md-width;
			}
			~ .app-content .app-header{
				width: 58.75rem - $aside-width;
			}
		}
		.app-header{
			left: inherit;
			right: auto;
			margin-left: 0;
			width: 58.75rem;
		}
	}
}

@include media-breakpoint-up(xl) {
	body.container{
		.app-aside{
			&.lg  ~ .app-content .app-header{
				width: 71.25rem - $aside-lg-width;
			}
			&.sm  ~ .app-content .app-header{
				width: 71.25rem - $aside-sm-width;
			}
			&.folded  ~ .app-content .app-header{
				width: 71.25rem - $aside-folded-width;
			}
			&.folded.md  ~ .app-content .app-header{
				width: 71.25rem - $aside-folded-md-width;
			}
			~ .app-content .app-header{
				width: 71.25rem - $aside-width;
			}
		}
		.app-header{
			width: 71.25rem;
		}
	}
}
