@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200..800);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200..800);
.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eceeef;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #eceeef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1200px;
  }
}
/*

*/
html {
  background-color: #ffffff;
}

body {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9rem;
  background-color: #fbfbfb;
}

*:focus {
  outline: 0 !important;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
}

a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}

button {
  color: inherit;
}

pre, .pre {
  color: inherit;
  background-color: #f8f8f8;
  border-color: rgba(120, 130, 140, 0.13);
}

blockquote {
  border-color: rgba(120, 130, 140, 0.13);
}

small {
  font-size: 95%;
}

sup {
  position: relative;
  top: -1em;
  font-size: 75%;
}

.pull-center {
  position: absolute;
  left: 50%;
}

.close {
  font-size: 1.3rem;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.center-block {
  margin: 0 auto;
}

.img-circle {
  border-radius: 100%;
}

.collapse.in,
.tooltip.in,
.fade.in {
  display: block;
  opacity: 1;
}

@media (max-width: 767px) {
  .pull-none-sm {
    float: none !important;
  }
}
@media (max-width: 575px) {
  .pull-none-xs {
    float: none !important;
  }
}
.label {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 90%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 400;
  background-color: #b8b8b8;
  color: #fff;
  border-radius: 0.2rem;
}
.label.no-bg {
  background-color: transparent;
  color: inherit;
}

.label.up {
  position: absolute;
  top: 5px;
  right: 5px;
  min-width: 4px;
  min-height: 4px;
  display: inline-block;
}

.label-lg {
  font-size: 1.33rem;
  padding: 5px 9px;
}

.label-sm {
  padding: 0.15em 0.35em;
}

.label-xs {
  padding: 0.1em 0.25em;
}

.list-group-item.active > .label,
.nav-pills > .active > a > .label {
  color: rgba(0, 0, 0, 0.87);
}

.btn .label {
  position: relative;
  top: -1px;
}

.btn {
  font-weight: 600;
  outline: 0 !important;
}
.btn:not([disabled]):hover, .btn:not([disabled]):focus, .btn:not([disabled]).active {
  box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
}
.btn > i.pull-left, .btn > i.pull-right {
  line-height: 1.5;
}
.btn.rounded {
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.btn-lg {
  font-size: 1rem;
}

.btn-md {
  padding: 0.4695rem 0.75rem;
  font-size: 0.95rem;
}

.btn-sm {
  font-size: 0.9rem;
}

.btn-xs {
  padding: 0.2195rem 0.5rem;
  font-size: 0.85rem;
}

.btn-outline {
  border-width: 1px;
  background-color: transparent;
  box-shadow: none !important;
}

.btn.b-primary:hover, .btn.primary:hover {
  color: #ffffff !important;
  background-color: #00ab47;
}
.btn.b-primary:focus, .btn.primary:focus {
  color: #ffffff !important;
  background-color: #00a645;
}
.btn.b-accent:hover, .btn.accent:hover {
  color: #ffffff !important;
  background-color: #9c7ad8;
}
.btn.b-accent:focus, .btn.accent:focus {
  color: #ffffff !important;
  background-color: #9976d7;
}
.btn.b-warn:hover, .btn.warn:hover {
  color: #ffffff !important;
  background-color: #eba600;
}
.btn.b-warn:focus, .btn.warn:focus {
  color: #ffffff !important;
  background-color: #e6a200;
}
.btn.b-success:hover, .btn.success:hover {
  color: #ffffff !important;
  background-color: #00ab47;
}
.btn.b-success:focus, .btn.success:focus {
  color: #ffffff !important;
  background-color: #00a645;
}
.btn.b-info:hover, .btn.info:hover {
  color: #ffffff !important;
  background-color: #00ab47;
}
.btn.b-info:focus, .btn.info:focus {
  color: #ffffff !important;
  background-color: #00a645;
}
.btn.b-warning:hover, .btn.warning:hover {
  color: #ffffff !important;
  background-color: #eba600;
}
.btn.b-warning:focus, .btn.warning:focus {
  color: #ffffff !important;
  background-color: #e6a200;
}
.btn.b-danger:hover, .btn.danger:hover {
  color: #ffffff !important;
  background-color: #b21636;
}
.btn.b-danger:focus, .btn.danger:focus {
  color: #ffffff !important;
  background-color: #ad1535;
}
.btn.b-dark:hover, .btn.dark:hover {
  color: #ffffff !important;
  background-color: #263441;
}
.btn.b-dark:focus, .btn.dark:focus {
  color: #ffffff !important;
  background-color: #25313e;
}
.btn.b-black:hover, .btn.black:hover {
  color: #ffffff !important;
  background-color: #010101;
}
.btn.b-black:focus, .btn.black:focus {
  color: #ffffff !important;
  background-color: black;
}
.btn.b-blackish:hover, .btn.blackish:hover {
  color: #ffffff !important;
  background-color: #010101;
}
.btn.b-blackish:focus, .btn.blackish:focus {
  color: #ffffff !important;
  background-color: black;
}
.btn.b-white:hover {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: whitesmoke;
}
.btn.b-white:focus {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #f2f2f2;
}
.btn.b-whitish:hover {
  color: #090A0D !important;
  background-color: whitesmoke;
}
.btn.b-whitish:focus {
  color: #090A0D !important;
  background-color: #f2f2f2;
}
.btn.b-light:hover {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #eeeeee;
}
.btn.b-light:focus {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #ebebeb;
}
.btn.b-bluish:hover, .btn.bluish:hover, .btn-input-group .btn.el-input-group__append:hover {
  color: #ffffff !important;
  background-color: #005ee0;
}
.btn.b-bluish:focus, .btn.bluish:focus, .btn-input-group .btn.el-input-group__append:focus {
  color: #ffffff !important;
  background-color: #005cdb;
}
.btn.b-orangish:hover, .btn.orangish:hover {
  color: #313131 !important;
  background-color: #eba600;
}
.btn.b-orangish:focus, .btn.orangish:focus {
  color: #313131 !important;
  background-color: #e6a200;
}
.btn.b-greenish:hover, .btn.greenish:hover {
  color: #ffffff !important;
  background-color: #00ab47;
}
.btn.b-greenish:focus, .btn.greenish:focus {
  color: #ffffff !important;
  background-color: #00a645;
}
.btn.b-dark-greenish:hover, .btn.dark-greenish:hover {
  color: #ffffff !important;
  background-color: #008f3b;
}
.btn.b-dark-greenish:focus, .btn.dark-greenish:focus {
  color: #ffffff !important;
  background-color: #008a39;
}
.btn.btn-disabled {
  opacity: 0.65;
  cursor: not-allowed !important;
}

.btn-icon {
  text-align: center;
  padding: 0 !important;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  position: relative;
  overflow: hidden;
}
.btn-icon i {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  line-height: inherit;
  border-radius: inherit;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-icon.btn-xs {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.btn-icon.btn-sm {
  width: 1.775rem;
  height: 1.775rem;
  line-height: 1.775rem;
}
.btn-icon.btn-lg {
  width: 3.167rem;
  height: 3.167rem;
  line-height: 3.167rem;
}
.btn-icon.btn-social i:last-child {
  top: 100%;
}
.btn-icon.btn-social:hover i:first-child, .btn-icon.btn-social:focus i:first-child, .btn-icon.btn-social.active i:first-child {
  top: -100%;
}
.btn-icon.btn-social:hover i:last-child, .btn-icon.btn-social:focus i:last-child, .btn-icon.btn-social.active i:last-child {
  top: 0;
  color: #fff;
}
.btn-icon.btn-social-colored i:first-child {
  color: #fff;
}

.btn.white,
.btn.btn-default,
.btn.btn-secondary {
  border-color: rgba(120, 130, 140, 0.13);
}

.btn-default {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.btn-groups .btn {
  margin-bottom: 0.25rem;
}

.btn-fw {
  min-width: 7rem;
}

.btn-flat {
  border-radius: 0;
}

.btn-circle {
  border-radius: 50%;
}

.btn-white {
  background: white;
}
.btn-white:not([disabled]):hover, .btn-white:not([disabled]):focus, .btn-white:not([disabled]).active {
  box-shadow: none;
}

#maitre-inline-button {
  border-radius: 0.25rem !important;
  font-size: 14px !important;
  padding: 7px 15px !important;
  display: inline-block !important;
  border: 1px solid #DCDFE6 !important;
  box-shadow: none !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  vertical-align: -2px !important;
}

#maitre-inline-button::after {
  content: "🎁";
  padding-left: 6px;
}

.card {
  border-color: rgba(120, 130, 140, 0.13);
}

.carousel-control .glyphicon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
  margin-left: -10px;
}

.dropdown-menu {
  font-size: 0.95rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform-origin: top left;
  -moz-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}
.dropdown-menu > li:not(.dropdown-item) > a {
  display: block;
  padding: 3px 20px;
}
.dropdown-menu > li:not(.dropdown-item) > a:hover, .dropdown-menu > li:not(.dropdown-item) > a:focus {
  background-color: rgba(0, 0, 0, 0.065);
}
.dropdown-menu > .divider {
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: rgba(120, 130, 140, 0.13);
}
.dropdown-menu .active {
  background-color: rgba(0, 0, 0, 0.065);
}
.dropdown-menu.pull-left {
  right: auto;
  left: 0;
}
.dropdown-menu.pull-right {
  left: auto;
  right: 0;
  -webkit-transform-origin: top right;
  -moz-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
}
.dropdown-menu.pull-up {
  top: 0;
}
.dropdown-menu.pull-down {
  top: 100% !important;
  left: 0 !important;
}
.dropup .dropdown-menu {
  -webkit-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}
.dropup .dropdown-menu.pull-right {
  -webkit-transform-origin: bottom right;
  -moz-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
}
.dropdown-menu.datepicker, .dropdown-menu.timepicker {
  padding: 6px;
  font-size: 0.8rem;
}
.dropdown-menu.datepicker .btn, .dropdown-menu.timepicker .btn {
  font-size: 0.8rem;
}
.dropdown-menu.datepicker .btn.btn-primary, .dropdown-menu.timepicker .btn.btn-primary {
  background-color: #00BF50;
  color: #fff;
}
.dropdown-menu.datepicker .btn:not(:hover), .dropdown-menu.timepicker .btn:not(:hover) {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.dropdown-item {
  color: inherit;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: inherit;
  background-color: rgba(0, 0, 0, 0.065);
}

.dropdown-divider {
  background-color: rgba(120, 130, 140, 0.13);
}

.flex-row > .nav-item > .dropdown-menu {
  position: absolute !important;
}

.dropdown-menu-scale {
  display: block;
  opacity: 0;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 250ms cubic-bezier(0.24, 0.22, 0.015, 1.56);
  -moz-transition: all 250ms cubic-bezier(0.24, 0.22, 0.015, 1.56);
  -ms-transition: all 250ms cubic-bezier(0.24, 0.22, 0.015, 1.56);
  -o-transition: all 250ms cubic-bezier(0.24, 0.22, 0.015, 1.56);
  transition: all 250ms cubic-bezier(0.24, 0.22, 0.015, 1.56);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: none;
}

.open > .dropdown-menu {
  display: block;
}

.show > .dropdown-menu-scale {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  pointer-events: auto;
  display: block !important;
}

.dropdown-header {
  padding: 8px 16px;
}

.dropdown-submenu {
  position: relative;
}
.dropdown-submenu:hover > .dropdown-menu, .dropdown-submenu:focus > .dropdown-menu {
  display: block;
}
.dropdown-submenu .dropdown-menu {
  left: 100%;
  top: 0;
  margin-top: -6px;
  margin-left: -1px;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-bottom: -6px;
}

.popover {
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 575px) {
  .dropdown-menu.pull-none-xs {
    left: 0;
  }
}
.form-control {
  border-color: rgba(120, 130, 140, 0.2);
  border-radius: 0;
}
.form-control:focus {
  border-color: rgba(120, 130, 140, 0.3);
}

.form-control-label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
}

.form-control-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
}

.custom-select {
  border-color: rgba(120, 130, 140, 0.13);
}

.form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.3;
}

.form-control::-moz-placeholder {
  color: inherit;
  opacity: 0.3;
}

.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: 0.3;
}

.form-control-spin {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}

.input-group-addon {
  border-color: rgba(120, 130, 140, 0.2) !important;
  background-color: transparent;
  color: inherit;
}

.form-group-stack .form-control {
  margin-top: -1px;
  position: relative;
  border-radius: 0;
}
.form-group-stack .form-control:focus, .form-group-stack .form-control:active {
  z-index: 1;
}

.form-validation .form-control.ng-dirty.ng-invalid {
  border-color: #C4183C;
}
.form-validation .form-control.ng-dirty.ng-valid, .form-validation .form-control.ng-dirty.ng-valid:focus {
  border-color: #00BF50;
}
.form-validation .ui-checks .ng-invalid.ng-dirty + i {
  border-color: #C4183C;
}

.form-file {
  position: relative;
}
.form-file input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.input-group-sm > .form-control {
  min-height: 1.95rem;
}

.active-checked {
  opacity: 0;
}

input:checked + .active-checked {
  opacity: 1;
}

.list-group {
  border-radius: 3px;
  background: #fff;
}
.list-group.no-radius .list-group-item {
  border-radius: 0 !important;
}
.list-group.no-borders .list-group-item {
  border: none;
}
.list-group.no-border .list-group-item {
  border-width: 1px 0;
}
.list-group.no-border .list-group-item {
  border-width: 1px 0;
}
.list-group.no-border .list-group-item:first-child {
  border-top-width: 0;
}
.list-group.no-border .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group.no-bg .list-group-item {
  background-color: transparent;
}
.list-group.list-group-gap {
  background: transparent;
}

.list-group-item {
  border-color: rgba(120, 130, 140, 0.065);
  background: transparent;
  padding: 12px 16px;
  background-clip: padding-box;
}
.list-group-item.media {
  margin-top: 0;
}
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  color: inherit;
  background-color: rgba(0, 0, 0, 0.065);
  border-color: rgba(120, 130, 140, 0.065);
  border-bottom-color: rgba(120, 130, 140, 0.065);
}
.list-group-item.active a, .list-group-item.active:hover a, .list-group-item.active:focus a {
  color: inherit;
}
.list-group-item:first-child {
  border-top-color: rgba(120, 130, 140, 0.13);
}
.list-group-item:last-child {
  border-bottom-color: rgba(120, 130, 140, 0.13);
}
.list-group-alt .list-group-item:nth-child(2n+2) {
  background-color: rgba(0, 0, 0, 0.065);
}
.list-group-alt .list-group-item.active:nth-child(2n+2) {
  background-color: rgba(0, 0, 0, 0.065);
}
.list-group-lg .list-group-item {
  padding: 16px 24px;
}
.list-group-md .list-group-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.list-group-sm .list-group-item {
  padding: 10px 12px;
}
.list-group-gap .list-group-item {
  margin-bottom: 5px;
  border-radius: 3px;
}
.list-group-gap .list-group-item:first-child {
  border-top-color: rgba(120, 130, 140, 0.065);
}
.list-group-gap .list-group-item:last-child {
  border-bottom-color: rgba(120, 130, 140, 0.065);
}

a.list-group-item {
  color: inherit;
}
a.list-group-item:hover, a.list-group-item:focus, a.list-group-item.hover {
  color: inherit;
  background-color: rgba(0, 0, 0, 0.065);
}

.modal .left {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal .right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal .top {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal .bottom {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.modal.in .left,
.modal.in .right,
.modal.in .top,
.modal.in .bottom, .modal.show .left,
.modal.show .right,
.modal.show .top,
.modal.show .bottom {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.modal.inactive {
  bottom: auto;
  overflow: visible;
}

.modal-open-aside {
  overflow: visible;
}

.modal > .left {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
}

.modal > .right {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
}

.modal > .top {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
}

.modal > .bottom {
  position: fixed;
  z-index: 1055;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-header,
.modal-content,
.modal-footer {
  border-color: rgba(120, 130, 140, 0.13);
}

.modal-content {
  color: rgba(0, 0, 0, 0.87);
}

.aside {
  color: rgba(0, 0, 0, 0.87);
}

.aside-header,
.aside-header .close {
  color: inherit !important;
  background-color: transparent !important;
}

.aside-header,
.aside-footer {
  border-color: rgba(120, 130, 140, 0.13) !important;
}

.aside-title {
  font-size: 1.2rem;
  margin: 0.45rem 0;
}

.modal.animate .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal.animate .fade-right-big {
  -webkit-animation: fadeOutLeftBig 0.5s;
  animation: fadeOutLeftBig 0.5s;
}
.modal.animate .fade-left-big {
  -webkit-animation: fadeOutRightBig 0.5s;
  animation: fadeOutRightBig 0.5s;
}
.modal.animate .fade-up-big {
  -webkit-animation: fadeOutUpBig 0.5s;
  animation: fadeOutUpBig 0.5s;
}
.modal.animate .fade-down-big {
  -webkit-animation: fadeOutDownBig 0.5s;
  animation: fadeOutDownBig 0.5s;
}
.modal.animate .fade-right {
  -webkit-animation: fadeOutLeft 0.5s;
  animation: fadeOutLeft 0.5s;
}
.modal.animate .fade-left {
  -webkit-animation: fadeOutRight 0.5s;
  animation: fadeOutRight 0.5s;
}
.modal.animate .fade-up {
  -webkit-animation: fadeOutUp 0.5s;
  animation: fadeOutUp 0.5s;
}
.modal.animate .fade-down {
  -webkit-animation: fadeOutDown 0.5s;
  animation: fadeOutDown 0.5s;
}
.modal.animate .flip-x {
  -webkit-animation: flipOutX 0.5s;
  animation: flipOutX 0.5s;
}
.modal.animate .flip-y {
  -webkit-animation: flipOutY 0.5s;
  animation: flipOutY 0.5s;
}
.modal.animate .zoom {
  -webkit-animation: zoomOut 0.5s;
  animation: zoomOut 0.5s;
}
.modal.animate .roll {
  -webkit-animation: rollOut 0.5s;
  animation: rollOut 0.5s;
}
.modal.animate .bounce {
  -webkit-animation: bounceOut 0.5s;
  animation: bounceOut 0.5s;
}
.modal.animate .rotate {
  -webkit-animation: rotateOut 0.5s;
  animation: rotateOut 0.5s;
}
.modal.animate.in .fade-right-big, .modal.animate.show .fade-right-big {
  -webkit-animation: fadeInRightBig 0.5s;
  animation: fadeInRightBig 0.5s;
}
.modal.animate.in .fade-left-big, .modal.animate.show .fade-left-big {
  -webkit-animation: fadeInLeftBig 0.5s;
  animation: fadeInLeftBig 0.5s;
}
.modal.animate.in .fade-up-big, .modal.animate.show .fade-up-big {
  -webkit-animation: fadeInUpBig 0.5s;
  animation: fadeInUpBig 0.5s;
}
.modal.animate.in .fade-down-big, .modal.animate.show .fade-down-big {
  -webkit-animation: fadeInDownBig 0.5s;
  animation: fadeInDownBig 0.5s;
}
.modal.animate.in .fade-right, .modal.animate.show .fade-right {
  -webkit-animation: fadeInRight 0.5s;
  animation: fadeInRight 0.5s;
}
.modal.animate.in .fade-left, .modal.animate.show .fade-left {
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}
.modal.animate.in .fade-up, .modal.animate.show .fade-up {
  -webkit-animation: fadeInUp 0.5s;
  animation: fadeInUp 0.5s;
}
.modal.animate.in .fade-down, .modal.animate.show .fade-down {
  -webkit-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s;
}
.modal.animate.in .flip-x, .modal.animate.show .flip-x {
  -webkit-animation: flipInX 0.5s;
  animation: flipInX 0.5s;
}
.modal.animate.in .flip-y, .modal.animate.show .flip-y {
  -webkit-animation: flipInY 0.5s;
  animation: flipInY 0.5s;
}
.modal.animate.in .zoom, .modal.animate.show .zoom {
  -webkit-animation: zoomIn 0.5s;
  animation: zoomIn 0.5s;
}
.modal.animate.in .roll, .modal.animate.show .roll {
  -webkit-animation: rollIn 0.5s;
  animation: rollIn 0.5s;
}
.modal.animate.in .bounce, .modal.animate.show .bounce {
  -webkit-animation: bounceIn 0.5s;
  animation: bounceIn 0.5s;
}
.modal.animate.in .rotate, .modal.animate.show .rotate {
  -webkit-animation: rotateIn 0.5s;
  animation: rotateIn 0.5s;
}

.nav {
  border: inherit;
}

.nav-item {
  border: inherit;
}

.nav-link {
  border: inherit;
  position: relative;
}

.flex-row .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.nav-md .nav-link {
  padding: 0.5rem 1rem;
}

.nav-sm .nav-link {
  padding: 0.25rem 0.75rem;
}

.nav-xs .nav-link {
  padding: 0.15rem 0.5rem;
  font-size: 90%;
}

.nav-rounded .nav-link {
  border-radius: 2rem;
}

.nav-tabs {
  border-bottom-width: 0;
  position: relative;
  z-index: 1;
}
.nav-tabs .nav-link {
  background: transparent !important;
  color: inherit !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: rgba(120, 130, 140, 0.13);
  border-bottom-color: transparent;
}

.tab-content.tab-alt .tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
}
.tab-content.tab-alt .tab-pane.active {
  height: auto;
  overflow: visible;
}

.nav-justified .nav-item {
  display: table-cell;
  width: 1%;
  float: none !important;
  text-align: center;
}

.nav-lists .nav-item {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  background-clip: padding-box;
}

.nav-active-border .nav-link:before {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  left: 50%;
  right: 50%;
  bottom: 0;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}
.nav-active-border .nav-link:hover:before, .nav-active-border .nav-link:focus:before, .nav-active-border .nav-link.active:before {
  left: 0%;
  right: 0%;
  border-bottom-color: inherit;
}
.nav-active-border.top .nav-link:before {
  bottom: auto;
  top: 0;
}
.nav-active-border.left .nav-link {
  float: none;
}
.nav-active-border.left .nav-link:before {
  border-bottom-width: 0;
  right: auto;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: transparent;
  left: 0;
  top: 50%;
  bottom: 50%;
}
.nav-active-border.left .nav-link:hover:before, .nav-active-border.left .nav-link:focus:before, .nav-active-border.left .nav-link.active:before {
  top: 0%;
  bottom: 0%;
  border-left-color: inherit;
}
.nav-active-border.left.right .nav-link:before {
  left: auto;
  right: 0;
}

.breadcrumb {
  background-color: rgba(0, 0, 0, 0.065);
}
.breadcrumb > li + li:before {
  content: "/ ";
}

.navbar {
  background: inherit;
  min-height: 3.2rem;
}
.navbar .up {
  margin: 0 -0.6em;
}

.navbar-md {
  min-height: 4rem;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}
.navbar-md .navbar {
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-sm {
  min-height: 3rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.navbar-sm .navbar {
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand {
  font-weight: bold;
}
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-brand img,
.navbar-brand svg {
  max-height: 24px;
  vertical-align: -4px;
  display: inline-block;
}
.navbar-brand > span {
  display: inline-block;
  line-height: 0.8;
  margin-left: 10px;
}
.navbar-brand.md img,
.navbar-brand.md svg {
  max-height: 32px;
  vertical-align: -8px;
}
.navbar-brand.lg img,
.navbar-brand.lg svg {
  max-height: 48px;
  vertical-align: -16px;
}
.pull-center .navbar-brand {
  margin-left: -50%;
  float: left;
}

.navbar-collapse.collapsing,
.navbar-collapse.collapse.show {
  background: inherit;
  left: 0;
  top: 100%;
  max-height: 100vh;
  overflow: auto;
  width: 100%;
}
.navbar-collapse.collapsing .nav-active-border,
.navbar-collapse.collapse.show .nav-active-border {
  border-color: transparent;
}

@media (max-width: 575px) {
  .navbar-toggleable .navbar-collapse.collapsing,
  .navbar-toggleable .navbar-collapse.collapse.show {
    padding: 0 1rem 4rem 1rem;
    position: absolute;
    z-index: 1040;
  }
}
@media (max-width: 767px) {
  .navbar-toggleable-sm .navbar-collapse.collapsing,
  .navbar-toggleable-sm .navbar-collapse.collapse.show {
    padding: 0 1rem 4rem 1rem;
    position: absolute;
    z-index: 1040;
  }
}
@media (max-width: 991px) {
  .navbar-toggleable-md .navbar-collapse.collapsing,
  .navbar-toggleable-md .navbar-collapse.collapse.show {
    padding: 0 1rem 4rem 1rem;
    position: absolute;
    z-index: 1040;
  }
}
@media (max-width: 1199px) {
  .navbar-toggleable-lg .navbar-collapse.collapsing,
  .navbar-toggleable-lg .navbar-collapse.collapse.show {
    padding: 0 1rem 4rem 1rem;
    position: absolute;
    z-index: 1040;
  }
}
.navbar-toggleable-xl .navbar-collapse.collapsing,
.navbar-toggleable-xl .navbar-collapse.collapse.show {
  padding: 0 1rem 4rem 1rem;
  position: absolute;
  z-index: 1040;
}

.pagination .page-item,
.pagination > li > a,
.pagination > li > span,
.pager .page-item,
.pager > li > a,
.pager > li > span {
  color: inherit !important;
  background-color: transparent !important;
  border-color: rgba(120, 130, 140, 0.13) !important;
}
.pagination .page-item:hover, .pagination .page-item:focus,
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus,
.pager .page-item:hover,
.pager .page-item:focus,
.pager > li > a:hover,
.pager > li > a:focus,
.pager > li > span:hover,
.pager > li > span:focus {
  border-color: rgba(120, 130, 140, 0.13);
  background-color: rgba(0, 0, 0, 0.065);
  color: inherit;
}
.pagination .page-item.active,
.pagination > .active > a,
.pagination > .active > span,
.pager .page-item.active,
.pager > .active > a,
.pager > .active > span {
  color: rgb(255, 255, 255) !important;
  background-color: #00BF50 !important;
  border-color: #00BF50 !important;
}

.pagination > li {
  display: inline;
  list-style: none;
}
.pagination > li > a {
  position: relative;
  float: left;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #0275d8;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li > a:focus {
  color: #014c8c;
  background-color: #eceeef;
  border-color: #ddd;
}
.pagination > li > a:hover {
  color: #014c8c;
  background-color: #eceeef;
  border-color: #ddd;
}

.pager {
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  list-style: none;
}
.pager::after {
  display: block;
  content: "";
  clear: both;
}
.pager li {
  display: inline;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: rgba(0, 0, 0, 0.065);
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-radius: 500px;
}
.pager li > a:focus {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.065);
}
.pager li > a:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.065);
}
.pager .disabled > a, .pager .disabled > a:focus {
  cursor: inherit;
  background-color: rgba(0, 0, 0, 0.065);
}
.pager .disabled > a:hover {
  cursor: inherit;
  background-color: rgba(0, 0, 0, 0.065);
}
.pager .disabled > span {
  cursor: inherit;
  background-color: rgba(0, 0, 0, 0.065);
}

.pager-next > a,
.pager-next > span {
  float: right;
}

.pager-prev > a,
.pager-prev > span {
  float: left;
}

.progress {
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.065);
}

.progress-xxs {
  height: 4px;
}

.progress-xs {
  height: 8px;
}

.progress-sm {
  height: 12px;
  font-size: 0.8em;
}

.col-0 {
  clear: left;
}

.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.row.no-gutter [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.row {
  margin-left: -12px;
  margin-right: -12px;
}
.row [class*=col-] {
  padding-left: 12px;
  padding-right: 12px;
}

.row-sm {
  margin-left: -8px;
  margin-right: -8px;
}
.row-sm [class*=col-] {
  padding-left: 8px;
  padding-right: 8px;
}

.row-xs {
  margin-left: -4px;
  margin-right: -4px;
}
.row-xs [class*=col-] {
  padding-left: 4px;
  padding-right: 4px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 991px) {
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .row [class*=col-] {
    padding-left: 8px;
    padding-right: 8px;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .no-gutters > .col,
  .no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
  .row-2 [class*=col] {
    width: 50%;
    float: left;
  }
  .row-2 .col-0 {
    clear: none;
  }
  .row-2 li:nth-child(odd) {
    clear: left;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .row {
    margin-left: -4px;
    margin-right: -4px;
  }
  .row [class*=col-] {
    padding-left: 4px;
    padding-right: 4px;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .no-gutters > .col,
  .no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
.panel .table {
  border-color: rgba(120, 130, 140, 0.045) !important;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th {
  padding-left: 16px;
  padding-right: 16px;
  border-color: rgba(120, 130, 140, 0.045);
}
.table > thead > tr > th {
  padding: 10px 16px;
  border-color: rgba(120, 130, 140, 0.045);
}

.table-condensed thead > tr > th,
.table-condensed tbody > tr > th,
.table-condensed tfoot > tr > th,
.table-condensed thead > tr > td,
.table-condensed tbody > tr > td,
.table-condensed tfoot > tr > td {
  padding: 5px;
}

.table-bordered {
  border-color: rgba(120, 130, 140, 0.045);
}

.table-striped > tbody > tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.025);
  background-clip: padding-box;
}
.table-striped > thead > th {
  background-color: rgba(0, 0, 0, 0.025);
  border-right: 1px solid rgba(120, 130, 140, 0.045);
}
.table-striped > thead > th:last-child {
  border-right: none;
}

.table-hover tr:hover,
.table tr.active,
.table td.active,
.table th.active {
  background-color: rgba(0, 0, 0, 0.065) !important;
}

.arrow {
  position: absolute;
  z-index: 10;
}
.arrow:before, .arrow:after {
  position: absolute;
  left: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-width: 9px;
  border-color: transparent;
  border-style: solid;
}
.arrow:after {
  border-width: 8px;
}
.arrow.top {
  top: -9px;
  left: 50%;
  margin-left: -9px;
}
.arrow.top.pull-in {
  top: -4px;
}
.arrow.top:before {
  border-bottom-color: rgba(120, 130, 140, 0.13);
  border-top-width: 0;
}
.arrow.top:after {
  top: 1px;
  left: 1px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: inherit;
  border-left-color: transparent;
  border-top-width: 0;
}
.arrow.right {
  top: 50%;
  right: 0;
  margin-top: -9px;
}
.arrow.right.pull-in {
  right: 4px;
}
.arrow.right:before {
  border-left-color: rgba(120, 130, 140, 0.13);
  border-right-width: 0;
}
.arrow.right:after {
  top: 1px;
  left: 0;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
  border-right-width: 0;
}
.arrow.bottom {
  bottom: 0;
  left: 50%;
  margin-left: -9px;
}
.arrow.bottom.pull-in {
  bottom: 4px;
}
.arrow.bottom:before {
  border-top-color: rgba(120, 130, 140, 0.13);
  border-bottom-width: 0;
}
.arrow.bottom:after {
  top: 0px;
  left: 1px;
  border-top-color: inherit;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-bottom-width: 0;
}
.arrow.left {
  top: 50%;
  left: -9px;
  margin-top: -9px;
}
.arrow.left.pull-in {
  left: -4px;
}
.arrow.left:before {
  border-right-color: rgba(120, 130, 140, 0.13);
  border-left-width: 0;
}
.arrow.left:after {
  top: 1px;
  left: 1px;
  border-top-color: transparent;
  border-right-color: inherit;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-left-width: 0;
}
.arrow.pull-left {
  left: 18px;
}
.arrow.pull-right {
  left: auto;
  right: 26px;
}
.arrow.pull-top {
  top: 18px;
}
.arrow.pull-bottom {
  top: auto;
  bottom: 26px;
}
.arrow.b-primary:before, .arrow.b-info:before, .arrow.b-success:before, .arrow.b-warning:before, .arrow.b-danger:before, .arrow.b-accent:before, .arrow.b-dark:before {
  border-color: transparent;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 40px;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
}
.avatar img {
  border-radius: 500px;
  width: 100%;
}
.avatar i {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
}
.avatar i.right {
  left: auto;
  right: 0;
}
.avatar i.bottom {
  left: auto;
  top: auto;
  bottom: 0;
  right: 0;
}
.avatar i.left {
  top: auto;
  bottom: 0;
}
.avatar i.on {
  background-color: #00BF50;
}
.avatar i.off {
  background-color: #f8f8f8;
}
.avatar i.away {
  background-color: #FFB400;
}
.avatar i.busy {
  background-color: #C4183C;
}
.avatar.w-32 i {
  margin: 0px;
}
.avatar.w-48 i {
  margin: 2px;
}
.avatar.w-56 i {
  margin: 3px;
}
.avatar.w-64 i {
  margin: 4px;
}
.avatar.w-96 i {
  margin: 9px;
}
.avatar.w-128 i {
  margin: 14px;
}

.row-col {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}
.row-col > [class*=col-],
.row-col > [class*=" col-"] {
  vertical-align: top;
  float: none;
  padding: 0;
  position: static;
}

.row-row {
  display: table-row;
  height: 100%;
}

.row-cell {
  display: table-cell;
  vertical-align: top;
}

.row-body {
  position: relative;
  height: 100%;
  width: 100%;
}
.ie .row-body {
  display: table-cell;
  overflow: auto;
}
.ie .row-body .row-inner {
  overflow: visible !important;
}

.row-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.v-m {
  vertical-align: middle !important;
}

.v-t {
  vertical-align: top !important;
}

.v-b {
  vertical-align: bottom !important;
}

.v-c {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .row-col > [class*=col-lg],
  .row-col > [class*=" col-lg"] {
    display: table-cell;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .row-col > [class*=col-md],
  .row-col > [class*=" col-md"] {
    display: table-cell;
    height: 100%;
  }
}
@media (min-width: 576px) {
  .row-col > [class*=col-sm],
  .row-col > [class*=" col-sm"] {
    display: table-cell;
    height: 100%;
  }
}
.row-col > [class*=col-xs],
.row-col > [class*=" col-xs"] {
  display: table-cell;
  height: 100%;
}

@media (max-width: 575px) {
  .row-col-xs {
    display: block;
  }
  .row-col-xs .row-body {
    overflow: visible !important;
  }
  .row-col-xs .row-inner {
    position: static;
  }
}
.box,
.box-color {
  background-color: #fff;
  position: relative;
  margin-bottom: 1.5rem;
}

.box-header {
  position: relative;
  padding: 1rem;
}
.box-header h2,
.box-header h3,
.box-header h4 {
  margin: 0;
  font-size: 18px;
  line-height: 1;
}
.box-header h3 {
  font-size: 16px;
}
.box-header h4 {
  font-size: 15px;
}
.box-header small {
  display: block;
  margin-top: 4px;
  opacity: 0.6;
}

.box-body {
  padding: 1rem;
}

.box-footer {
  padding: 1rem;
}

.box-divider {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  margin: 0 16px;
  height: 0;
}

.box-tool {
  position: absolute;
  right: 10px;
  top: 10px;
}
.box-tool .nav-link {
  padding: 0.25rem 0.35rem;
}

.box-shadow,
.box-shadow .box,
.box-shadow .box-color {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03), 0 1px 0 rgba(0, 0, 0, 0.03);
}

.box-shadow-z0,
.box-shadow-z0 .box,
.box-shadow-z0 .box-color {
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
}

.box-shadow-z1,
.box-shadow-z1 .box,
.box-shadow-z1 .box-color {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

.box-shadow-z2,
.box-shadow-z2 .box,
.box-shadow-z2 .box-color {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

.box-shadow-z3,
.box-shadow-z3 .box,
.box-shadow-z3 .box-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

.box-shadow-z4,
.box-shadow-z4 .box,
.box-shadow-z4 .box-color {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26), 0 -1px 0px rgba(0, 0, 0, 0.02);
}

.box-radius-1x,
.box-radius-1x .box,
.box-radius-1x .box-color {
  border-radius: 2px;
}

.box-radius-2x,
.box-radius-2x .box,
.box-radius-2x .box-color {
  border-radius: 3px;
}

.box-radius-3x,
.box-radius-3x .box,
.box-radius-3x .box-color {
  border-radius: 4px;
}

.ui-check {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}
.ui-check input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.ui-check input:checked + i:before {
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  background-color: #00BF50;
}
.ui-check input:checked + span .active {
  display: inherit;
}
.ui-check input[type=radio] + i, .ui-check input[type=radio] + i:before {
  border-radius: 50%;
}
.ui-check input[disabled] + i, fieldset[disabled] .ui-check input + i {
  border-color: rgba(134, 143, 152, 0.2);
}
.ui-check input[disabled] + i:before, fieldset[disabled] .ui-check input + i:before {
  background-color: rgba(134, 143, 152, 0.2);
}
.ui-check > i {
  width: 16px;
  height: 16px;
  line-height: 1;
  box-shadow: 0 0 1px rgba(120, 130, 140, 0.35);
  margin-left: -20px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  background-clip: padding-box;
  position: relative;
}
.ui-check > i:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0px;
  height: 0px;
  background-color: transparent;
}
.ui-check > span {
  margin-left: -20px;
}
.ui-check > span .active {
  display: none;
}
.ui-check.ui-check-color input:checked + i:before {
  background-color: #fff;
}

.ui-check-md input:checked + i:before {
  left: 6px;
  top: 6px;
}
.ui-check-md > i {
  width: 18px;
  height: 18px;
}

.ui-check-lg input:checked + i:before {
  width: 12px;
  height: 12px;
  left: 9px;
  top: 9px;
}
.ui-check-lg > i {
  width: 30px;
  height: 30px;
}

/*
Flexbox
*/
[layout],
[data-layout] {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

[layout=column],
[data-layout=column] {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

[layout=row],
[data-layout=row] {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

[flex],
[data-flex] {
  box-sizing: border-box;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/*layout*/
html {
  height: 100%;
}

body {
  height: auto;
  min-height: 100%;
  position: relative;
}

.app-aside {
  position: fixed !important;
  z-index: 1030;
  float: left;
  height: 100%;
}

.app-aside.modal {
  overflow: hidden !important;
}

.app-content {
  box-shadow: none;
}
.app-content::after {
  display: block;
  content: "";
  clear: both;
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: inherit;
  z-index: 1021;
}
.app-header ~ .app-body {
  padding-top: 3.2rem;
  padding-bottom: 3.125rem;
}
.app-header.navbar-md ~ .app-body {
  padding-top: 4rem;
}
.app-header.navbar-sm ~ .app-body {
  padding-top: 3rem;
}

.app-body-inner {
  padding-top: inherit !important;
  padding-bottom: inherit !important;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.app-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: inherit;
}
.app-footer:not(.hide) ~ .app-body {
  padding-bottom: 3.125rem;
}

.app-fixed {
  overflow: hidden;
}
.app-fixed .app {
  height: 100%;
}
.app-fixed .app-content {
  position: relative;
  min-height: 100%;
}
.app-fixed .app-footer {
  margin-left: 0;
}

@media (min-width: 992px) {
  .app-aside {
    opacity: 1;
    display: block !important;
  }
  .app-aside,
  .app-aside .scroll {
    width: 15rem;
  }
  .app-aside.lg:not(.folded), .app-aside.lg:not(.folded) .scroll {
    width: 15rem;
  }
  .app-aside.lg:not(.hide) ~ .app-content {
    margin-left: 15rem;
  }
  .app-aside.lg:not(.hide) ~ .app-content {
    margin-left: 15rem;
  }
  .app-aside.lg:not(.hide) ~ .app-content .fixed-header, .app-aside.lg:not(.hide) ~ .app-content .fixed-footer {
    margin-left: 15rem;
  }
  .app-aside.sm:not(.folded), .app-aside.sm:not(.folded) .scroll {
    width: 11.25rem;
  }
  .app-aside.sm:not(.hide) ~ .app-content {
    margin-left: 11.25rem;
  }
  .app-aside.sm:not(.hide) ~ .app-content .fixed-header, .app-aside.sm:not(.hide) ~ .app-content .fixed-footer {
    margin-left: 11.25rem;
  }
  .app-aside.folded {
    width: 4rem;
  }
  .app-aside.folded:not(.hide) ~ .app-content {
    margin-left: 4rem;
  }
  .app-aside.folded.md {
    width: 5rem;
  }
  .app-aside.folded.md:not(.hide) ~ .app-content {
    margin-left: 5rem;
  }
  .app-aside.folded.md:not(.hide) ~ .app-content .fixed-header, .app-aside.folded.md:not(.hide) ~ .app-content .fixed-footer {
    margin-left: 5rem;
  }
  .app-aside:not(.hide) ~ .app-content {
    margin-left: 15rem;
  }
  .app-aside:not(.hide) ~ .app-content .fixed-header, .app-aside:not(.hide) ~ .app-content .fixed-footer {
    margin-left: 15rem;
  }
  .app-aside .left {
    position: absolute;
    right: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .hide-scroll {
    width: auto;
    margin-right: -17px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991px) {
  .app-aside.modal {
    width: 100%;
    position: fixed;
    z-index: 1050;
  }
  .app-aside .left {
    position: fixed;
    width: 15rem;
  }
}
@media print {
  .app-aside,
  .app-header,
  .app-footer,
  .switcher {
    display: none;
  }
}
/* fix ie9 */
.ie9 .app-aside {
  position: static !important;
}
.ie9 .app-aside .left {
  width: inherit;
  position: absolute;
}

@media (max-width: 991px) {
  body.container,
  .app .container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  body.container {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  body.container,
  body.container .container {
    padding-left: 0;
    padding-right: 0;
  }
  body.container .app-aside {
    left: inherit;
    right: auto;
  }
  body.container .app-aside.lg ~ .app-content .app-header {
    width: 43.75rem;
  }
  body.container .app-aside.sm ~ .app-content .app-header {
    width: 47.5rem;
  }
  body.container .app-aside.folded ~ .app-content .app-header {
    width: 54.75rem;
  }
  body.container .app-aside.folded.md ~ .app-content .app-header {
    width: 53.75rem;
  }
  body.container .app-aside ~ .app-content .app-header {
    width: 43.75rem;
  }
  body.container .app-header {
    left: inherit;
    right: auto;
    margin-left: 0;
    width: 58.75rem;
  }
}
@media (min-width: 1200px) {
  body.container .app-aside.lg ~ .app-content .app-header {
    width: 56.25rem;
  }
  body.container .app-aside.sm ~ .app-content .app-header {
    width: 60rem;
  }
  body.container .app-aside.folded ~ .app-content .app-header {
    width: 67.25rem;
  }
  body.container .app-aside.folded.md ~ .app-content .app-header {
    width: 66.25rem;
  }
  body.container .app-aside ~ .app-content .app-header {
    width: 56.25rem;
  }
  body.container .app-header {
    width: 71.25rem;
  }
}
.list {
  padding-left: 0;
  padding-right: 0;
  border-radius: 3px;
}
.list.no-border {
  padding-top: 8px;
  padding-bottom: 8px;
}

.list-item {
  display: block;
  position: relative;
  padding: 12px 16px;
}
.list-item::after {
  display: block;
  content: "";
  clear: both;
}
.list-item:last-child .list-body:after {
  border: none;
}
.no-border .list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.no-padding .list-item {
  padding-left: 0;
  padding-right: 0;
}

.list-left {
  float: left;
  padding-right: 1rem;
}
.list-left + .list-body {
  margin-left: 56px;
}

.list-body h3 {
  font-size: 16px;
  margin: 0 0 3px 0;
  font-weight: normal;
}
.list-body:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid rgba(120, 130, 140, 0.065);
}
.inset .list-body:after {
  left: 72px;
}
.no-border .list-body:after {
  display: none;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #00BF50;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.pace .pace-activity {
  display: none;
  position: fixed;
  z-index: 2000;
  top: 5px;
  right: 5px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #00BF50;
  border-left-color: #00BF50;
  border-radius: 10px;
  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  -moz-animation: loading-bar-spinner 400ms linear infinite;
  -ms-animation: loading-bar-spinner 400ms linear infinite;
  -o-animation: loading-bar-spinner 400ms linear infinite;
  animation: loading-bar-spinner 400ms linear infinite;
}

.calling {
  background: url("../images/icons/calling.gif") no-repeat top center;
  background-size: 320px;
  background-position-y: -40px;
  padding-top: 120px;
}

@-webkit-keyframes loading-bar-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes loading-bar-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes loading-bar-spinner {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes loading-bar-spinner {
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.navside {
  border: inherit;
}
.navside ul {
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  display: block;
}
.navside [flex] {
  overflow: auto;
}
.navside .nav {
  border: inherit;
}
.navside .nav li {
  border: inherit;
  position: relative;
}
.navside .nav li:not(.active):hover, .navside .nav li:not(.active):focus {
  background-color: rgba(51, 51, 51, 0.065);
}
.navside .nav li li a {
  line-height: 2rem;
  padding-left: 3.5rem;
}
.navside .nav li li a .nav-text {
  padding: calc((2rem - 1.55em) / 2) 0;
}
.navside .nav li li li a {
  padding-left: 4.5rem;
}
.navside .nav li li li li a {
  padding-left: 5.5rem;
}
.navside .nav li a {
  display: block;
  padding: 0 1rem;
  line-height: 2.25rem;
  position: relative;
}
.navside .nav li a::after {
  display: block;
  content: "";
  clear: both;
}
.navside .nav li.active {
  background-color: rgba(0, 0, 0, 0.065);
}
.navside .nav li.active > a {
  background-color: transparent;
}
.navside .nav li.active > a .nav-caret i {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navside .nav-header {
  padding: 0 1rem;
  line-height: 2.25rem;
}
.navside .nav-header:hover, .navside .nav-header:focus {
  background-color: transparent !important;
}
.navside .nav-header:after {
  display: none;
}
.navside .navbar-brand {
  float: none;
  margin-right: 0;
}

.nav-fold {
  padding: 0.75rem 1rem;
}

.nav-text {
  display: block;
  line-height: 1.425rem;
  padding: calc((2.25rem - 1.55em) / 2) 0;
}
.nav > li > a .nav-text {
  font-weight: 500;
}
.nav > li li > a .nav-text {
  font-weight: 500;
}
.nav-text span,
.nav-text small {
  display: block;
}
.navside .nav-text {
  opacity: 0.75;
}
.navside a:hover > .nav-text, .navside a:focus > .nav-text, .navside .active > a > .nav-text {
  opacity: 1;
}
.navbar .nav-text {
  line-height: 1;
  vertical-align: middle;
  display: inline-block;
  padding: 0;
}
.navbar .nav-text span.text-xs, .navbar .nav-text span.el-divider__text.is-center {
  margin-top: 0.25rem;
}

.nav-icon {
  float: left;
  line-height: inherit;
  margin-right: 1rem;
  position: relative;
  top: -2px;
  min-width: 1.5rem;
  text-align: center;
}
.nav-icon i {
  position: relative;
}
.nav-icon i svg,
.nav-icon i img {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.navbar .nav-icon {
  margin-right: 0.5rem;
}

.nav-label {
  float: right;
  margin-left: 1rem;
  font-style: normal;
  z-index: 1;
}
.navbar .nav-label {
  margin-left: 0.5rem;
}

.nav-caret {
  float: right;
  margin-left: 1rem;
  opacity: 0.45;
}
.nav-caret i {
  -webkit-transition: transform 0.1s ease-in-out;
  -moz-transition: transform 0.1s ease-in-out;
  -ms-transition: transform 0.1s ease-in-out;
  -o-transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
}
.navbar .nav-caret {
  margin-left: 0.5rem;
}

.nav-sub {
  max-height: 0;
  overflow: hidden;
}
.active > .nav-sub {
  max-height: 50rem;
}

@media (min-width: 992px) {
  .folded,
  .folded .scroll {
    width: 4rem;
  }
  .folded.md,
  .folded.md .scroll {
    width: 5rem;
  }
  .folded .hidden-folded {
    display: none;
  }
  .folded .nav li ul {
    display: none;
  }
  .folded .nav > li > a {
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
  }
  .folded .nav > li > a .nav-caret,
  .folded .nav > li > a .nav-text {
    display: none;
  }
  .folded .nav > li > a .nav-label {
    position: absolute;
    top: -6px;
    right: 6px;
  }
  .folded .nav > li > a .nav-icon {
    float: none;
    line-height: 2rem;
    margin-left: 0;
    margin-right: 0;
    position: relative;
  }
  .folded.show-text .nav > li > a .nav-text {
    display: block;
    margin-top: -8px;
    line-height: 1;
    font-size: 0.8em;
    padding: 0.5rem 0 0.2rem 0;
  }
  .folded .navbar {
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
  }
  .folded .navbar-brand {
    float: none;
    margin: 0;
  }
  .folded .nav-stacked .nav {
    margin: 0 12px;
  }
  .folded .nav-fold {
    padding: 8px 16px;
  }
  .folded .nav-fold .pull-left {
    float: none !important;
    margin: 0;
  }
  .folded .nav-fold .pull-left img {
    width: 100%;
    height: auto;
  }
  .folded.nav-expand {
    -webkit-transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -moz-transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -ms-transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -o-transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
  }
  .folded.nav-expand:hover,
  .folded.nav-expand:hover .scroll, .folded.nav-expand:focus,
  .folded.nav-expand:focus .scroll, .folded.nav-expand.active,
  .folded.nav-expand.active .scroll {
    width: 15rem !important;
  }
  .folded.nav-expand:hover > *, .folded.nav-expand:focus > *, .folded.nav-expand.active > * {
    width: 15rem;
  }
  .folded.nav-expand:hover .hidden-folded, .folded.nav-expand:focus .hidden-folded, .folded.nav-expand.active .hidden-folded {
    display: block;
  }
  .folded.nav-expand:hover .hidden-folded.inline, .folded.nav-expand:focus .hidden-folded.inline, .folded.nav-expand.active .hidden-folded.inline {
    display: inline-block;
  }
  .folded.nav-expand:hover .nav li ul, .folded.nav-expand:focus .nav li ul, .folded.nav-expand.active .nav li ul {
    display: inherit;
  }
  .folded.nav-expand:hover .nav > li > a, .folded.nav-expand:focus .nav > li > a, .folded.nav-expand.active .nav > li > a {
    text-align: left;
    padding-left: 16px;
    padding-right: 16px;
  }
  .folded.nav-expand:hover .nav > li > a .nav-caret,
  .folded.nav-expand:hover .nav > li > a .nav-text, .folded.nav-expand:focus .nav > li > a .nav-caret,
  .folded.nav-expand:focus .nav > li > a .nav-text, .folded.nav-expand.active .nav > li > a .nav-caret,
  .folded.nav-expand.active .nav > li > a .nav-text {
    display: block;
  }
  .folded.nav-expand:hover .nav > li > a .nav-text, .folded.nav-expand:focus .nav > li > a .nav-text, .folded.nav-expand.active .nav > li > a .nav-text {
    font-size: 1em;
    margin: 0;
  }
  .folded.nav-expand:hover .nav > li > a .nav-label, .folded.nav-expand:focus .nav > li > a .nav-label, .folded.nav-expand.active .nav > li > a .nav-label {
    position: static;
  }
  .folded.nav-expand:hover .nav > li > a .nav-icon, .folded.nav-expand:focus .nav > li > a .nav-icon, .folded.nav-expand.active .nav > li > a .nav-icon {
    float: left;
    line-height: inherit;
    margin-right: 16px;
  }
  .folded.nav-expand:hover .navbar, .folded.nav-expand:focus .navbar, .folded.nav-expand.active .navbar {
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .folded.nav-expand:hover .nav-stacked .nav, .folded.nav-expand:focus .nav-stacked .nav, .folded.nav-expand.active .nav-stacked .nav {
    margin: 0 8px;
  }
  .folded.nav-expand:hover .nav-stacked .nav > li > a, .folded.nav-expand:focus .nav-stacked .nav > li > a, .folded.nav-expand.active .nav-stacked .nav > li > a {
    padding: 0 8px;
  }
  .folded.nav-expand:hover .nav-fold, .folded.nav-expand:focus .nav-fold, .folded.nav-expand.active .nav-fold {
    padding: 12px 16px;
  }
  .folded.nav-expand:hover .nav-fold .pull-left, .folded.nav-expand:focus .nav-fold .pull-left, .folded.nav-expand.active .nav-fold .pull-left {
    float: left !important;
  }
  .folded.nav-expand:hover .nav-fold .pull-left img, .folded.nav-expand:focus .nav-fold .pull-left img, .folded.nav-expand.active .nav-fold .pull-left img {
    height: auto;
  }
  .folded.nav-expand:hover .nav-fold .pull-left img.w-40, .folded.nav-expand:hover .nav-fold .pull-left img.w-300, .folded.nav-expand:hover .nav-fold .pull-left img.w-200, .folded.nav-expand:hover .nav-fold .pull-left img.w-72, .folded.nav-expand:focus .nav-fold .pull-left img.w-40, .folded.nav-expand:focus .nav-fold .pull-left img.w-300, .folded.nav-expand:focus .nav-fold .pull-left img.w-200, .folded.nav-expand:focus .nav-fold .pull-left img.w-72, .folded.nav-expand.active .nav-fold .pull-left img.w-40, .folded.nav-expand.active .nav-fold .pull-left img.w-300, .folded.nav-expand.active .nav-fold .pull-left img.w-200, .folded.nav-expand.active .nav-fold .pull-left img.w-72 {
    width: 40px;
  }
  .folded.nav-expand:hover .nav-fold .pull-left img.w-48, .folded.nav-expand:focus .nav-fold .pull-left img.w-48, .folded.nav-expand.active .nav-fold .pull-left img.w-48 {
    width: 48px;
  }
  .nav-dropup.modal,
  .nav-dropup [flex],
  .nav-dropup .row-body,
  .folded.nav-dropdown.modal,
  .folded.nav-dropdown [flex],
  .folded.nav-dropdown .row-body {
    overflow: visible !important;
  }
  .nav-dropup .nav > li:hover > ul, .nav-dropup .nav > li:focus > ul,
  .folded.nav-dropdown .nav > li:hover > ul,
  .folded.nav-dropdown .nav > li:focus > ul {
    display: block;
  }
  .nav-dropup .nav > li > ul,
  .folded.nav-dropdown .nav > li > ul {
    display: none;
    overflow: visible;
    max-height: 999px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.87);
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 100%;
    top: 0;
    padding: 6px 0;
  }
  .nav-dropup .nav > li > ul a,
  .folded.nav-dropdown .nav > li > ul a {
    padding-left: 16px !important;
    padding-right: 16px !important;
    min-width: 160px;
  }
  .nav-dropup .nav > li > ul li.active ul,
  .folded.nav-dropdown .nav > li > ul li.active ul {
    display: block;
  }
  .nav-dropup .nav > li > ul .nav-text,
  .folded.nav-dropdown .nav > li > ul .nav-text {
    padding: 6px 0 !important;
  }
  .nav-dropup .nav > li .nav-mega,
  .folded.nav-dropdown .nav > li .nav-mega {
    width: 320px;
  }
  .nav-dropup .nav > li .nav-mega > li,
  .folded.nav-dropdown .nav > li .nav-mega > li {
    width: 160px;
    float: left;
  }
  .nav-dropup .nav > li .nav-mega.nav-mega-3,
  .folded.nav-dropdown .nav > li .nav-mega.nav-mega-3 {
    width: 480px;
  }
}
.nav-border .nav > li:not(:disabled).active:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left-color: inherit;
  border-left-width: 3px;
  border-left-style: solid;
}

.nav-stacked .nav {
  margin: 0 8px;
}
.nav-stacked .nav > li > a {
  line-height: 2rem;
  padding: 0 0.5rem;
  margin: 2px 0;
}
.nav-stacked .nav > li > a .nav-text {
  padding: calc((2rem - 1.55em) / 2) 0;
}
.nav-stacked .nav > li li a {
  padding-left: 3rem;
}
.nav-stacked .nav > li li li a {
  padding-left: 4rem;
}
.nav-stacked .nav > li li li li a {
  padding-left: 5rem;
}
.nav-stacked .nav > li.active > a {
  color: #ffffff;
  background-color: #00BF50;
}
.nav-stacked .nav li a {
  border-radius: 3px;
}
.nav-stacked .nav .nav-header {
  padding-left: 8px;
  padding-right: 8px;
}

.nav-light .nav .nav-icon i svg,
.nav-light .nav .nav-icon i img {
  display: block;
}

.nav-center {
  text-align: center;
}
.nav-center .nav-icon {
  display: none;
}
.nav-center .nav-caret {
  position: absolute;
  right: 10px;
}
.nav-center .nav-label {
  position: absolute;
  padding-left: 10px;
}
.nav-center .nav a {
  padding-left: 16px !important;
}
.nav-center .nav-fold .pull-left {
  float: none !important;
  margin-bottom: 10px;
  display: inline-block;
}

.nav-active-bg .nav-link.active,
.nav-active-bg .nav > li.active > a {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #fbfbfb !important;
}

.nav-active-dark .nav-link.active,
.nav-active-dark .nav > li.active > a {
  color: #ffffff !important;
  background-color: #2e3e4e !important;
}

.nav-active-black .nav-link.active,
.nav-active-black .nav > li.active > a {
  color: #ffffff !important;
  background-color: #090A0D !important;
}

.nav-active-primary .nav-link.active,
.nav-active-primary .nav > li.active > a {
  color: #ffffff !important;
  background-color: #00BF50 !important;
}

.nav-active-accent .nav-link.active,
.nav-active-accent .nav > li.active > a {
  color: #ffffff !important;
  background-color: #a88add !important;
}

.nav-active-warn .nav-link.active,
.nav-active-warn .nav > li.active > a {
  color: #ffffff !important;
  background-color: #FFB400 !important;
}

.nav-active-success .nav-link.active,
.nav-active-success .nav > li.active > a {
  color: #ffffff !important;
  background-color: #00BF50 !important;
}

.nav-active-info .nav-link.active,
.nav-active-info .nav > li.active > a {
  color: #ffffff !important;
  background-color: #00BF50 !important;
}

.nav-active-warning .nav-link.active,
.nav-active-warning .nav > li.active > a {
  color: #ffffff !important;
  background-color: #FFB400 !important;
}

.nav-active-danger .nav-link.active,
.nav-active-danger .nav > li.active > a {
  color: #ffffff !important;
  background-color: #C4183C !important;
}

.nav-active-light .nav-link.active,
.nav-active-light .nav > li.active > a {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #f8f8f8 !important;
}

.nav-active-white .nav-link.active,
.nav-active-white .nav > li.active > a {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #ffffff !important;
}

.nav-active-purplish .nav-link.active,
.nav-active-purplish .nav > li.active > a {
  color: #ffffff !important;
  background-color: #230e63 !important;
}

.nav-active-bluish .nav-link.active,
.nav-active-bluish .nav > li.active > a {
  color: #ffffff !important;
  background-color: #0067F4 !important;
}

.nav-active-greenish .nav-link.active,
.nav-active-greenish .nav > li.active > a {
  color: #ffffff !important;
  background-color: #00BF50 !important;
}

.nav-active-blackish .nav-link.active,
.nav-active-blackish .nav > li.active > a {
  color: #ffffff !important;
  background-color: #090A0D !important;
}

.nav-active-red .nav-link.active,
.nav-active-red .nav > li.active > a {
  color: #ffffff !important;
  background-color: #f44336 !important;
}

.nav-active-pink .nav-link.active,
.nav-active-pink .nav > li.active > a {
  color: #ffffff !important;
  background-color: #e91e63 !important;
}

.nav-active-purple .nav-link.active,
.nav-active-purple .nav > li.active > a {
  color: #ffffff !important;
  background-color: #9c27b0 !important;
}

.nav-active-deep-purple .nav-link.active,
.nav-active-deep-purple .nav > li.active > a {
  color: #ffffff !important;
  background-color: #673ab7 !important;
}

.nav-active-indigo .nav-link.active,
.nav-active-indigo .nav > li.active > a {
  color: #ffffff !important;
  background-color: #3f51b5 !important;
}

.nav-active-blue .nav-link.active,
.nav-active-blue .nav > li.active > a {
  color: #ffffff !important;
  background-color: #2196f3 !important;
}

.nav-active-light-blue .nav-link.active,
.nav-active-light-blue .nav > li.active > a {
  color: #ffffff !important;
  background-color: #03a9f4 !important;
}

.nav-active-cyan .nav-link.active,
.nav-active-cyan .nav > li.active > a {
  color: #ffffff !important;
  background-color: #00bcd4 !important;
}

.nav-active-teal .nav-link.active,
.nav-active-teal .nav > li.active > a {
  color: #ffffff !important;
  background-color: #009688 !important;
}

.nav-active-green .nav-link.active,
.nav-active-green .nav > li.active > a {
  color: #ffffff !important;
  background-color: #4caf50 !important;
}

.nav-active-light-green .nav-link.active,
.nav-active-light-green .nav > li.active > a {
  color: #ffffff !important;
  background-color: #8bc34a !important;
}

.nav-active-lime .nav-link.active,
.nav-active-lime .nav > li.active > a {
  color: #ffffff !important;
  background-color: #cddc39 !important;
}

.nav-active-yellow .nav-link.active,
.nav-active-yellow .nav > li.active > a {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: #ffeb3b !important;
}

.nav-active-amber .nav-link.active,
.nav-active-amber .nav > li.active > a {
  color: #ffffff !important;
  background-color: #ffc107 !important;
}

.nav-active-orange .nav-link.active,
.nav-active-orange .nav > li.active > a {
  color: #ffffff !important;
  background-color: #ff9800 !important;
}

.nav-active-deep-orange .nav-link.active,
.nav-active-deep-orange .nav > li.active > a {
  color: #ffffff !important;
  background-color: #ff5722 !important;
}

.nav-active-brown .nav-link.active,
.nav-active-brown .nav > li.active > a {
  color: #ffffff !important;
  background-color: #795548 !important;
}

.nav-active-blue-grey .nav-link.active,
.nav-active-blue-grey .nav > li.active > a {
  color: #ffffff !important;
  background-color: #607d8b !important;
}

.nav-active-grey .nav-link.active,
.nav-active-grey .nav > li.active > a {
  color: #ffffff !important;
  background-color: #424242 !important;
}

.white.navside .nav-text, .white.navside .nav-icon {
  color: #7D7D7D;
  opacity: 0.6;
}
.white.navside a:hover > .nav-text, .white.navside a:focus > .nav-text, .white.navside .active > a > .nav-text, .white.navside .active.router-link-active > a > .nav-text {
  color: #0067F4;
  opacity: 1;
}
.white.navside a:hover > .nav-icon, .white.navside a:focus > .nav-icon, .white.navside .active > a > .nav-icon, .white.navside .active.router-link-active > a > .nav-icon {
  color: #0067F4;
  opacity: 1;
}
.white.navside .nav li a {
  color: #7D7D7D;
}
.white.navside .nav li a:hover, .white.navside .nav li a:focus {
  color: #0067F4;
  background-color: #ffffff;
}
.white.navside .nav li.active, .white.navside .nav li.active.router-link-active {
  color: #ffffff;
  background-color: #f2f2f2;
}
.white.navside .nav li.active a, .white.navside .nav li.active.router-link-active a {
  color: #ffffff;
  background-color: #f2f2f2;
}
.white.navside .nav li.active a:hover, .white.navside .nav li.active a:focus, .white.navside .nav li.active.router-link-active a:hover, .white.navside .nav li.active.router-link-active a:focus {
  color: #ffffff;
  background-color: #f2f2f2;
}

.white .navbar-nav > .nav-item > .nav-link, .white .navbar-nav > .nav-link {
  color: #7D7D7D;
}

.purplish.navside .nav-text, .purplish.navside .nav-icon {
  color: rgba(255, 255, 255, 0.65);
  opacity: 0.6;
}
.purplish.navside a:hover > .nav-text, .purplish.navside a:focus > .nav-text, .purplish.navside .active > a > .nav-text, .purplish.navside .active.router-link-active > a > .nav-text {
  color: #ffffff;
  opacity: 1;
}
.purplish.navside a:hover > .nav-icon, .purplish.navside a:focus > .nav-icon, .purplish.navside .active > a > .nav-icon, .purplish.navside .active.router-link-active > a > .nav-icon {
  color: #ffffff;
  opacity: 1;
}
.purplish.navside .nav li a {
  color: rgba(255, 255, 255, 0.65);
}
.purplish.navside .nav li a:hover, .purplish.navside .nav li a:focus {
  color: #ffffff;
  background-color: #230e63;
}
.purplish.navside .nav li.active, .purplish.navside .nav li.active.router-link-active {
  color: #eeff41;
  background-color: #1b0b4d;
}
.purplish.navside .nav li.active a, .purplish.navside .nav li.active.router-link-active a {
  color: #eeff41;
  background-color: #1b0b4d;
}
.purplish.navside .nav li.active a:hover, .purplish.navside .nav li.active a:focus, .purplish.navside .nav li.active.router-link-active a:hover, .purplish.navside .nav li.active.router-link-active a:focus {
  color: #eeff41;
  background-color: #1b0b4d;
}

.purplish .navbar-nav > .nav-item > .nav-link, .purplish .navbar-nav > .nav-link {
  color: rgba(255, 255, 255, 0.65);
}

.blackish.navside .nav-text, .blackish.navside .nav-icon {
  color: #ffffff;
  opacity: 0.6;
}
.blackish.navside a:hover > .nav-text, .blackish.navside a:focus > .nav-text, .blackish.navside .active > a > .nav-text, .blackish.navside .active.router-link-active > a > .nav-text {
  color: #ffffff;
  opacity: 1;
}
.blackish.navside a:hover > .nav-icon, .blackish.navside a:focus > .nav-icon, .blackish.navside .active > a > .nav-icon, .blackish.navside .active.router-link-active > a > .nav-icon {
  color: #ffffff;
  opacity: 1;
}
.blackish.navside .nav li a {
  color: #ffffff;
}
.blackish.navside .nav li a:hover, .blackish.navside .nav li a:focus {
  color: #ffffff;
  background-color: #090A0D;
}
.blackish.navside .nav li.active, .blackish.navside .nav li.active.router-link-active {
  color: #ffffff;
  background-color: black;
}
.blackish.navside .nav li.active a, .blackish.navside .nav li.active.router-link-active a {
  color: #ffffff;
  background-color: black;
}
.blackish.navside .nav li.active a:hover, .blackish.navside .nav li.active a:focus, .blackish.navside .nav li.active.router-link-active a:hover, .blackish.navside .nav li.active.router-link-active a:focus {
  color: #ffffff;
  background-color: black;
}

.blackish .navbar-nav > .nav-item > .nav-link, .blackish .navbar-nav > .nav-link {
  color: #ffffff;
}

.ui-icon {
  display: inline-block;
  text-align: center;
  height: 1em;
  background: transparent !important;
}
.ui-icon:before {
  position: relative;
  z-index: 1;
}
.ui-icon:after {
  content: "";
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 2em;
  width: 2em;
  height: 2em;
  top: -1.5em;
  display: block;
  position: relative;
  z-index: 0;
  background-clip: padding-box;
}
.ui-icon.b-2x:after {
  border-width: 2px;
}
.ui-icon.b-3x:after {
  border-width: 3px;
}
.ui-icon.ui-icon-sm:after {
  width: 1.7em;
  height: 1.7em;
  top: -1.35em;
}
.ui-icon.primary:after {
  background-color: #00BF50;
}
.ui-icon.success:after {
  background-color: #00BF50;
}
.ui-icon.info:after {
  background-color: #00BF50;
}
.ui-icon.warning:after {
  background-color: #FFB400;
}
.ui-icon.danger:after {
  background-color: #C4183C;
}
.ui-icon.accent:after {
  background-color: #a88add;
}
.ui-icon.dark:after {
  background-color: #2e3e4e;
}
.ui-icon.light:after {
  background-color: #f8f8f8;
}
.ui-icon.white:after {
  background-color: #fff;
}

.item {
  position: relative;
}
.item .top {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
}
.item .bottom {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}
.item .center {
  position: absolute;
  right: 0;
  left: 0;
  top: 42%;
}

.item-overlay {
  position: absolute;
  right: 0;
  left: 0;
  display: none;
}
.item-overlay.active,
.item-overlay .item:focus, .item:hover .item-overlay {
  display: block;
}
.item-overlay.w-full {
  text-align: center;
  top: 0;
  bottom: 0;
}

.item-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.item-bg img {
  position: absolute;
  width: 110%;
  left: 50%;
  top: 50%;
  margin-left: -55%;
  margin-top: -55%;
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.blur-5 {
  opacity: 0.5;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.opacity {
  opacity: 0.5;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.switcher {
  z-index: 1050;
  position: fixed;
  top: 78px;
  right: -240px;
  width: 240px;
  -webkit-transition: right 0.2s ease;
  -moz-transition: right 0.2s ease;
  -ms-transition: right 0.2s ease;
  -o-transition: right 0.2s ease;
  transition: right 0.2s ease;
  border: 1px solid rgba(120, 120, 120, 0.1);
  background-clip: padding-box;
}
.switcher.active {
  right: -2px;
  z-index: 1060;
}

.switcher .sw-btn {
  position: absolute;
  left: -43px;
  top: -1px;
  padding: 10px 15px;
  z-index: 1045;
  border: 1px solid rgba(120, 120, 120, 0.1);
  border-right-width: 0;
  background-clip: padding-box;
}

#sw-demo {
  top: 118px;
}
#sw-demo.active {
  top: 78px;
}

.list-icon i {
  margin-right: 16px;
}

.list-icon div {
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*Charts*/
.jqstooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: solid 1px #000 !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px !important;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.easyPieChart {
  position: relative;
  text-align: center;
}
.easyPieChart > div {
  position: absolute;
  width: 100%;
  line-height: 1;
  top: 40%;
}
.easyPieChart > div img {
  margin-top: -4px;
}
.easyPieChart canvas {
  vertical-align: middle;
}

#flotTip,
.flotTip {
  padding: 4px 10px;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: solid 1px #000 !important;
  z-index: 100;
  font-size: 12px;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.legendColorBox > div {
  border: none !important;
  margin: 5px;
}
.legendColorBox > div > div {
  border-radius: 10px;
}

.st-sort-ascent:before {
  content: "▲";
}

.st-sort-descent:before {
  content: "▼";
}

.st-selected td {
  background: rgba(0, 0, 0, 0.065);
}

.dark .btn-default.form-control,
.black .btn-default.form-control,
.grey .btn-default.form-control {
  background: transparent !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: inherit !important;
}

.ui-select-bootstrap > .ui-select-choices {
  overflow-y: scroll;
}

.box .dataTables_wrapper {
  padding-top: 10px;
}

.box .dataTables_wrapper > .row {
  margin: 0;
  width: 100%;
}

.box .dataTables_wrapper > .row > .col-sm-12 {
  padding: 0;
}

.dataTables_wrapper.form-inline .form-control {
  width: auto;
}

div.dataTables_length label,
div.dataTables_filter label {
  display: inline-block;
}

div.dataTables_paginate ul.pagination {
  display: inline-block;
}

.footable-odd {
  background-color: rgba(0, 0, 0, 0.025) !important;
}

.note-editor {
  border-width: 0 !important;
  overflow: visible !important;
  background-color: inherit;
}

.note-toolbar .note-color .dropdown-toggle {
  padding-left: 2px !important;
}

.note-editor .note-editing-area .note-editable,
.note-editor .note-statusbar {
  background-color: transparent !important;
  color: inherit !important;
}

.note-toolbar {
  background-color: rgba(120, 130, 140, 0.1);
}

.ie .ie-show {
  display: block;
}

.nav a.waves-effect {
  -webkit-mask-image: -webkit-radial-gradient(circle, #ffffff 100%, #000000 100%);
}

.sortable-placeholder {
  list-style: none;
  border: 1px dashed #fff;
  min-height: 40px;
}

.abn-tree {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.input-group > .ui-select-bootstrap.dropdown,
div.input-group > .select2-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
}

.input-group > .ui-select-bootstrap.dropdown .form-control,
div.input-group > .select2-container > .selection {
  width: 100%;
}

.streamline {
  position: relative;
  border-color: rgba(120, 130, 140, 0.13);
}
.streamline:after,
.streamline .sl-item:before,
.streamline .sl-icon:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-left: -4px;
  top: 6px;
  left: 0;
}
.streamline:after {
  top: auto;
  bottom: 0;
}

.sl-icon {
  position: absolute;
  left: -10px;
  z-index: 1;
  border: inherit;
}
.sl-icon > i {
  width: 20px;
  height: 20px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color: #fff;
}
.streamline .sl-icon:before {
  width: 20px;
  height: 20px;
  border-width: 10px;
  margin: 0 !important;
  top: 0 !important;
}

.sl-item {
  border-color: rgba(120, 130, 140, 0.13);
  position: relative;
  padding-bottom: 1px;
}
.sl-item::after {
  display: block;
  content: "";
  clear: both;
}
.sl-item:after {
  top: 2px;
  bottom: auto;
}
.sl-item.b-l {
  margin-left: -1px;
}

.sl-left {
  float: left;
  position: relative;
  z-index: 1;
  margin-left: -20px;
  margin-bottom: 24px;
}
.sl-left img {
  max-width: 40px;
}
.sl-left + .sl-content {
  margin-left: 36px;
}

.sl-content {
  margin-left: 24px;
  padding-bottom: 16px;
}

.sl-author {
  margin-bottom: 10px;
}

.sl-date {
  font-size: 0.85em;
}

.sl-footer {
  margin-bottom: 10px;
}

.ui-switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
  border-radius: 30px;
  background-color: #00BF50;
  margin: 0;
}
.ui-switch input {
  position: absolute;
  opacity: 0;
}
.ui-switch input:checked + i:before {
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 5px;
  border-width: 0;
  border-radius: 5px;
}
.ui-switch input:checked + i:after {
  margin-left: 15px;
}
.ui-switch i:before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 30px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.ui-switch i:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 16px;
  top: 1px;
  bottom: 1px;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  -webkit-transition: margin 0.3s;
  -moz-transition: margin 0.3s;
  -ms-transition: margin 0.3s;
  -o-transition: margin 0.3s;
  transition: margin 0.3s;
}

.ui-switch-md {
  width: 40px;
  height: 24px;
}
.ui-switch-md input:checked + i:after {
  margin-left: 17px;
}
.ui-switch-md i:after {
  width: 22px;
}

.ui-switch-lg {
  width: 50px;
  height: 30px;
}
.ui-switch-lg input:checked + i:after {
  margin-left: 21px;
}
.ui-switch-lg i:after {
  width: 28px;
}

.timeline {
  margin: 0;
  padding: 0;
}

.tl-item {
  display: block;
}
.tl-item::after {
  display: block;
  content: "";
  clear: both;
}

.visible-left {
  display: none;
}

.tl-wrap {
  display: block;
  margin-left: 6em;
  padding: 15px 0 15px 20px;
  border-style: solid;
  border-color: rgba(120, 130, 140, 0.13);
  border-width: 0 0 0 2px;
}
.tl-wrap::after {
  display: block;
  content: "";
  clear: both;
}
.tl-wrap:before {
  position: relative;
  content: "";
  float: left;
  top: 15px;
  margin-left: -26px;
  width: 10px;
  height: 10px;
  border-color: inherit;
  border-width: 3px;
  border-radius: 50%;
  border-style: solid;
  background: #f8f8f8;
  box-shadow: 0 0 0 4px #fbfbfb;
}

.tl-date {
  position: relative;
  top: 10px;
  float: left;
  margin-left: -7.5em;
  display: block;
  width: 4.5em;
  text-align: right;
}

.tl-content {
  display: inline-block;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tl-content.block {
  display: block;
  width: 100%;
}
.tl-content.panel {
  margin-bottom: 0;
}

.tl-header {
  display: block;
  width: 12em;
  text-align: center;
  margin-left: 1px;
}

.timeline-center .tl-item {
  margin-left: 50%;
}
.timeline-center .tl-item .tl-wrap {
  margin-left: -2px;
}
.timeline-center .tl-header {
  width: auto;
  margin-left: -1px;
}
.timeline-center .tl-left {
  margin-left: 0;
  margin-right: 50%;
}
.timeline-center .tl-left .hidden-left {
  display: none !important;
}
.timeline-center .tl-left .visible-left {
  display: inherit;
}
.timeline-center .tl-left .tl-wrap {
  float: right;
  margin-right: 0px;
  border-left-width: 0;
  border-right-width: 2px;
  padding-left: 0;
  padding-right: 20px;
}
.timeline-center .tl-left .tl-wrap:before {
  float: right;
  margin-left: 0;
  margin-right: -26px;
}
.timeline-center .tl-left .tl-date {
  float: right;
  margin-left: 0;
  margin-right: -8.5em;
  text-align: left;
}

.lter {
  background-color: rgba(255, 255, 255, 0.035);
}

.dker {
  background-color: rgba(0, 0, 0, 0.035);
}

.light {
  background-color: #f8f8f8;
}

.white {
  background-color: #fff;
}

.dark-white {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
}

.black {
  color: #ffffff !important;
  background-color: #090A0D !important;
}
.black .lt {
  background-color: #0e1015;
}
.black .dk {
  background-color: #040405;
}
.black .bg {
  background-color: #090A0D;
}
.black:disabled {
  background-color: rgba(9, 10, 13, 0.5) !important;
}

.dark {
  color: #ffffff !important;
  background-color: #2e3e4e !important;
}
.dark .lt {
  background-color: #334456;
}
.dark .dk {
  background-color: #293846;
}
.dark .bg {
  background-color: #2e3e4e;
}
.dark:disabled {
  background-color: rgba(46, 62, 78, 0.5) !important;
}

.grey {
  color: #ffffff !important;
  background-color: #424242 !important;
}
.grey .lt {
  background-color: #484848;
}
.grey .dk {
  background-color: #3c3c3c;
}
.grey .bg {
  background-color: #424242;
}
.grey:disabled {
  background-color: rgba(66, 66, 66, 0.5) !important;
}

.primary {
  color: #ffffff !important;
  background-color: #00BF50 !important;
}
.primary .lt {
  background-color: #00cc55;
}
.primary .dk {
  background-color: #00b24b;
}
.primary .bg {
  background-color: #00BF50;
}
.primary:disabled {
  background-color: rgba(0, 191, 80, 0.5) !important;
}

.accent {
  color: #ffffff !important;
  background-color: #a88add !important;
}
.accent .lt {
  background-color: #af94e0;
}
.accent .dk {
  background-color: #a180da;
}
.accent .bg {
  background-color: #a88add;
}
.accent:disabled {
  background-color: rgba(168, 138, 221, 0.5) !important;
}

.warn {
  color: #ffffff !important;
  background-color: #FFB400 !important;
}
.warn .lt {
  background-color: #ffb80d;
}
.warn .dk {
  background-color: #f2ab00;
}
.warn .bg {
  background-color: #FFB400;
}
.warn:disabled {
  background-color: rgba(255, 180, 0, 0.5) !important;
}

.success {
  color: #ffffff !important;
  background-color: #00BF50 !important;
}
.success .lt {
  background-color: #00cc55;
}
.success .dk {
  background-color: #00b24b;
}
.success .bg {
  background-color: #00BF50;
}
.success:disabled {
  background-color: rgba(0, 191, 80, 0.5) !important;
}

.info {
  color: #ffffff !important;
  background-color: #00BF50 !important;
}
.info .lt {
  background-color: #00cc55;
}
.info .dk {
  background-color: #00b24b;
}
.info .bg {
  background-color: #00BF50;
}
.info:disabled {
  background-color: rgba(0, 191, 80, 0.5) !important;
}

.warning {
  color: #ffffff !important;
  background-color: #FFB400 !important;
}
.warning .lt {
  background-color: #ffb80d;
}
.warning .dk {
  background-color: #f2ab00;
}
.warning .bg {
  background-color: #FFB400;
}
.warning:disabled {
  background-color: rgba(255, 180, 0, 0.5) !important;
}

.danger {
  color: #ffffff !important;
  background-color: #C4183C !important;
}
.danger .lt {
  background-color: #cf193f;
}
.danger .dk {
  background-color: #b91739;
}
.danger .bg {
  background-color: #C4183C;
}
.danger:disabled {
  background-color: rgba(196, 24, 60, 0.5) !important;
}

.blue {
  color: #ffffff !important;
  background-color: #2196f3 !important;
}
.blue .lt {
  background-color: #2d9cf4;
}
.blue .dk {
  background-color: #1590f2;
}
.blue .bg {
  background-color: #2196f3;
}
.blue:disabled {
  background-color: rgba(33, 150, 243, 0.5) !important;
}

.bluish, .btn-input-group .el-input-group__append {
  color: #ffffff !important;
  background-color: #0067F4 !important;
}
.bluish .lt, .btn-input-group .el-input-group__append .lt {
  background-color: #026dff;
}
.bluish .dk, .btn-input-group .el-input-group__append .dk {
  background-color: #0062e7;
}
.bluish .bg, .btn-input-group .el-input-group__append .bg {
  background-color: #0067F4;
}
.bluish:disabled, .btn-input-group .el-input-group__append:disabled {
  background-color: rgba(0, 103, 244, 0.5) !important;
}

.purplish {
  color: #ffffff !important;
  background-color: #230e63 !important;
}
.purplish .lt {
  background-color: #27106e;
}
.purplish .dk {
  background-color: #1f0c58;
}
.purplish .bg {
  background-color: #230e63;
}
.purplish:disabled {
  background-color: rgba(35, 14, 99, 0.5) !important;
}

.whitish {
  color: #ffffff !important;
  background-color: #ffffff !important;
}
.whitish .lt {
  background-color: white;
}
.whitish .dk {
  background-color: #f9f9f9;
}
.whitish .bg {
  background-color: #ffffff;
}
.whitish:disabled {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.orangish {
  color: #090A0D !important;
  background-color: #FFB400 !important;
}
.orangish .lt {
  background-color: #ffb80d;
}
.orangish .dk {
  background-color: #f2ab00;
}
.orangish .bg {
  background-color: #FFB400;
}
.orangish:disabled {
  background-color: rgba(255, 180, 0, 0.5) !important;
}

.greenish {
  color: #ffffff !important;
  background-color: #00BF50 !important;
}
.greenish .lt {
  background-color: #00cc55;
}
.greenish .dk {
  background-color: #00b24b;
}
.greenish .bg {
  background-color: #00BF50;
}
.greenish:disabled {
  background-color: rgba(0, 191, 80, 0.5) !important;
}

.greyish {
  color: #ffffff !important;
  background-color: #7D7D7D !important;
}
.greyish .lt {
  background-color: #838383;
}
.greyish .dk {
  background-color: #777777;
}
.greyish .bg {
  background-color: #7D7D7D;
}
.greyish:disabled {
  background-color: rgba(125, 125, 125, 0.5) !important;
}

.dark-greyish {
  color: #ffffff !important;
  background-color: #313131 !important;
}
.dark-greyish .lt {
  background-color: #373737;
}
.dark-greyish .dk {
  background-color: #2a2a2a;
}
.dark-greyish .bg {
  background-color: #313131;
}
.dark-greyish:disabled {
  background-color: rgba(49, 49, 49, 0.5) !important;
}

.dark-greenish {
  color: #ffffff !important;
  background-color: #00A344 !important;
}
.dark-greenish .lt {
  background-color: #00b049;
}
.dark-greenish .dk {
  background-color: #00963f;
}
.dark-greenish .bg {
  background-color: #00A344;
}
.dark-greenish:disabled {
  background-color: rgba(0, 163, 68, 0.5) !important;
}

.blackish {
  color: #ffffff !important;
  background-color: #090A0D !important;
}
.blackish .lt {
  background-color: #0e1015;
}
.blackish .dk {
  background-color: #040405;
}
.blackish .bg {
  background-color: #090A0D;
}
.blackish:disabled {
  background-color: rgba(9, 10, 13, 0.5) !important;
}

.new-blue {
  color: #ffffff !important;
  background-color: #043791 !important;
}
.new-blue .lt {
  background-color: #043c9d;
}
.new-blue .dk {
  background-color: #043285;
}
.new-blue .bg {
  background-color: #043791;
}
.new-blue:disabled {
  background-color: rgba(4, 55, 145, 0.5) !important;
}

.blue-15 {
  color: #ffffff !important;
  background-color: #2F80ED !important;
}
.blue-15 .lt {
  background-color: #3b87ee;
}
.blue-15 .dk {
  background-color: #2379ec;
}
.blue-15 .bg {
  background-color: #2F80ED;
}
.blue-15:disabled {
  background-color: rgba(47, 128, 237, 0.5) !important;
}

.mellow-blue {
  color: #ffffff !important;
  background-color: #fafcff !important;
}
.mellow-blue .lt {
  background-color: white;
}
.mellow-blue .dk {
  background-color: #edf4ff;
}
.mellow-blue .bg {
  background-color: #fafcff;
}
.mellow-blue:disabled {
  background-color: rgba(250, 252, 255, 0.5) !important;
}

.new-purple {
  color: #ffffff !important;
  background-color: #37234b !important;
}
.new-purple .lt {
  background-color: #3d2754;
}
.new-purple .dk {
  background-color: #311f42;
}
.new-purple .bg {
  background-color: #37234b;
}
.new-purple:disabled {
  background-color: rgba(55, 35, 75, 0.5) !important;
}

.blue-purple-gradient, .hover-menu .col-4:hover, .upgrade-notification {
  background-color: #043791;
  background-image: linear-gradient(135deg, #043791 0%, #37234b 100%);
  color: #ffffff;
}

.green-gradient {
  background-color: #00BF50;
  background-image: linear-gradient(135deg, #00BF50 0%, #00A344 100%);
  color: #ffffff;
}

.white-overlay {
  background-color: rgba(255, 255, 255, 0.85);
}

.black-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}

.gd-overlay {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
}

.bg-auto:before {
  content: "";
  position: absolute;
  width: inherit;
  top: 0;
  bottom: 0;
  background-color: inherit;
  border: inherit;
}
.bg-auto.b-l:before {
  margin-left: -1px;
}
.bg-auto.b-r:before {
  margin-right: -1px;
}

.bg-clip {
  background-clip: padding-box;
}

.no-bg {
  background-color: transparent !important;
}

@media (max-width: 767px) {
  .no-bg-xs {
    background-color: transparent;
  }
}
.dark .white,
.dark .box {
  background-color: #354759;
}
.dark.app {
  background-color: #2e3e4e;
}

.black .white,
.black .box {
  background-color: #101218;
}
.black.app {
  background-color: #090A0D;
}

.grey .white,
.grey .box {
  background-color: #4b4b4b;
}
.grey.app {
  background-color: #424242;
}

.dark .form-control,
.black .form-control,
.grey .form-control {
  background: transparent;
  background-color: rgba(255, 255, 255, 0.1);
  color: inherit;
}
.dark .form-control:focus,
.black .form-control:focus,
.grey .form-control:focus {
  border-color: rgba(255, 255, 255, 0.125);
}
.dark .input-group-btn .btn,
.black .input-group-btn .btn,
.grey .input-group-btn .btn {
  background-color: #00BF50 !important;
  color: inherit !important;
}
.dark .list-group,
.black .list-group,
.grey .list-group {
  background-color: transparent;
}

.pos-rlt {
  position: relative;
  z-index: 1;
}

.pos-abt {
  position: absolute;
}

.pos-fix {
  position: fixed !important;
}

.pos-stc {
  position: static !important;
}

.block {
  display: block;
}

.block.hide {
  display: none;
}

.pull-none {
  float: none;
}

.inline {
  display: inline-block;
}

.inline-16 {
  width: 16px;
  height: 16px;
}

.inline-24 {
  width: 24px;
  height: 24px;
}

.none {
  display: none;
}

.active > .inline,
.active > .auto .inline {
  display: none;
}

.active > .none,
.active > .auto .none {
  display: inline-block;
}

.hide {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.rounded,
.circle {
  border-radius: 500px;
}

.clear {
  display: block;
  overflow: hidden;
}

.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.no-select, .webrtc-dialer .no-select .el-input--mini .el-input__inner, .webrtc-dialer .el-pagination > .el-pagination__sizes > .el-select > .el-input > .el-input__inner .el-input--mini .el-input__inner, .webrtc-dialer .el-tabs__item .el-input--mini .el-input__inner, .webrtc-dialer .el-collapse.fullscreen .el-collapse-item .el-input--mini .el-input__inner, .el-collapse.fullscreen .webrtc-dialer .el-collapse-item .el-input--mini .el-input__inner, .webrtc-dialer .el-collapse-item__header .el-input--mini .el-input__inner, .webrtc-dialer input[type=radio]:hover .el-input--mini .el-input__inner, .webrtc-dialer label[for] .el-input--mini .el-input__inner, input[type=radio]:hover, label[for], .el-collapse-item__header, .el-collapse.fullscreen .el-collapse-item__header, .el-collapse.fullscreen .el-collapse-item, .el-tabs__item, .el-pagination > .el-pagination__sizes > .el-select > .el-input > .el-input__inner {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.no-select ::selection, .webrtc-dialer .no-select .el-input--mini .el-input__inner ::selection, .webrtc-dialer .el-tabs__item .el-input--mini .el-input__inner ::selection, .webrtc-dialer .el-collapse.fullscreen .el-collapse-item .el-input--mini .el-input__inner ::selection, .el-collapse.fullscreen .webrtc-dialer .el-collapse-item .el-input--mini .el-input__inner ::selection, .webrtc-dialer .el-collapse-item__header .el-input--mini .el-input__inner ::selection, .webrtc-dialer input[type=radio]:hover .el-input--mini .el-input__inner ::selection, .webrtc-dialer label[for] .el-input--mini .el-input__inner ::selection, input[type=radio]:hover ::selection, label[for] ::selection, .el-collapse-item__header ::selection, .el-collapse.fullscreen .el-collapse-item__header ::selection, .el-collapse.fullscreen .el-collapse-item ::selection, .el-tabs__item ::selection, .el-pagination > .el-pagination__sizes > .el-select > .el-input > .el-input__inner ::selection {
  background: transparent; /* WebKit/Blink Browsers */
}
.no-select ::-moz-selection, .webrtc-dialer .no-select .el-input--mini .el-input__inner ::-moz-selection, .webrtc-dialer .el-tabs__item .el-input--mini .el-input__inner ::-moz-selection, .webrtc-dialer .el-collapse.fullscreen .el-collapse-item .el-input--mini .el-input__inner ::-moz-selection, .el-collapse.fullscreen .webrtc-dialer .el-collapse-item .el-input--mini .el-input__inner ::-moz-selection, .webrtc-dialer .el-collapse-item__header .el-input--mini .el-input__inner ::-moz-selection, .webrtc-dialer input[type=radio]:hover .el-input--mini .el-input__inner ::-moz-selection, .webrtc-dialer label[for] .el-input--mini .el-input__inner ::-moz-selection, input[type=radio]:hover ::-moz-selection, label[for] ::-moz-selection, .el-collapse-item__header ::-moz-selection, .el-collapse.fullscreen .el-collapse-item__header ::-moz-selection, .el-collapse.fullscreen .el-collapse-item ::-moz-selection, .el-tabs__item ::-moz-selection, .el-pagination > .el-pagination__sizes > .el-select > .el-input > .el-input__inner ::-moz-selection {
  background: transparent; /* Gecko Browsers */
}

@media (max-width: 767px) {
  .pos-stc-sm {
    position: static !important;
  }
}
@media (max-width: 575px) {
  .pos-stc-xs {
    position: static !important;
  }
}
.no-border {
  border-color: transparent;
  border-width: 0;
}

.no-borders {
  border-width: 0 !important;
}

.b {
  border-color: rgba(120, 130, 140, 0.13);
  background-clip: padding-box;
}

.b-a {
  border: 1px solid rgba(120, 130, 140, 0.13);
}

.b-t {
  border-top: 1px solid rgba(120, 130, 140, 0.13);
}

.b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.b-b {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

.b-l {
  border-left: 1px solid rgba(120, 130, 140, 0.13);
}

.b-2x {
  border-width: 2px;
}

.b-3x {
  border-width: 3px;
}

.b-4x {
  border-width: 4px;
}

.b-5x {
  border-width: 5px;
}

.b-t-2x {
  border-top-width: 2px !important;
}

.b-t-3x {
  border-top-width: 3px !important;
}

.b-t-4x {
  border-top-width: 4px !important;
}

.b-t-5x {
  border-top-width: 5px !important;
}

.b-r-2x {
  border-right-width: 2px !important;
}

.b-r-3x {
  border-right-width: 3px !important;
}

.b-r-4x {
  border-right-width: 4px !important;
}

.b-r-5x {
  border-right-width: 5px !important;
}

.b-b-2x {
  border-bottom-width: 2px !important;
}

.b-b-3x {
  border-bottom-width: 3px !important;
}

.b-b-4x {
  border-bottom-width: 4px !important;
}

.b-b-5x {
  border-bottom-width: 5px !important;
}

.b-l-2x {
  border-left-width: 2px !important;
}

.b-l-3x {
  border-left-width: 3px !important;
}

.b-l-4x {
  border-left-width: 4px !important;
}

.b-l-5x {
  border-left-width: 5px !important;
}

.b-light {
  border-color: #f8f8f8;
}

.b-white {
  border-color: #fff;
}

.b-primary {
  border-color: #00BF50;
}

.b-t-primary {
  border-top-color: #00BF50;
}

.b-r-#e6f9ee {
  border-right-color: #00BF50;
}

.b-b-primary {
  border-bottom-color: #00BF50;
}

.b-l-primary {
  border-left-color: #00BF50;
}

.b-accent {
  border-color: #a88add;
}

.b-t-accent {
  border-top-color: #a88add;
}

.b-r-#f6f3fc {
  border-right-color: #a88add;
}

.b-b-accent {
  border-bottom-color: #a88add;
}

.b-l-accent {
  border-left-color: #a88add;
}

.b-warn {
  border-color: #FFB400;
}

.b-t-warn {
  border-top-color: #FFB400;
}

.b-r-#fff8e6 {
  border-right-color: #FFB400;
}

.b-b-warn {
  border-bottom-color: #FFB400;
}

.b-l-warn {
  border-left-color: #FFB400;
}

.b-success {
  border-color: #00BF50;
}

.b-t-success {
  border-top-color: #00BF50;
}

.b-r-#e6f9ee {
  border-right-color: #00BF50;
}

.b-b-success {
  border-bottom-color: #00BF50;
}

.b-l-success {
  border-left-color: #00BF50;
}

.b-info {
  border-color: #00BF50;
}

.b-t-info {
  border-top-color: #00BF50;
}

.b-r-#e6f9ee {
  border-right-color: #00BF50;
}

.b-b-info {
  border-bottom-color: #00BF50;
}

.b-l-info {
  border-left-color: #00BF50;
}

.b-warning {
  border-color: #FFB400;
}

.b-t-warning {
  border-top-color: #FFB400;
}

.b-r-#fff8e6 {
  border-right-color: #FFB400;
}

.b-b-warning {
  border-bottom-color: #FFB400;
}

.b-l-warning {
  border-left-color: #FFB400;
}

.b-danger {
  border-color: #C4183C;
}

.b-t-danger {
  border-top-color: #C4183C;
}

.b-r-#f9e8ec {
  border-right-color: #C4183C;
}

.b-b-danger {
  border-bottom-color: #C4183C;
}

.b-l-danger {
  border-left-color: #C4183C;
}

.b-light {
  border-color: #f8f8f8;
}

.b-t-light {
  border-top-color: #f8f8f8;
}

.b-r-#fefefe {
  border-right-color: #f8f8f8;
}

.b-b-light {
  border-bottom-color: #f8f8f8;
}

.b-l-light {
  border-left-color: #f8f8f8;
}

.b-grey {
  border-color: #424242;
}

.b-t-grey {
  border-top-color: #424242;
}

.b-r-#ececec {
  border-right-color: #424242;
}

.b-b-grey {
  border-bottom-color: #424242;
}

.b-l-grey {
  border-left-color: #424242;
}

.b-dark {
  border-color: #2e3e4e;
}

.b-t-dark {
  border-top-color: #2e3e4e;
}

.b-r-#eaeced {
  border-right-color: #2e3e4e;
}

.b-b-dark {
  border-bottom-color: #2e3e4e;
}

.b-l-dark {
  border-left-color: #2e3e4e;
}

.b-black {
  border-color: #090A0D;
}

.b-t-black {
  border-top-color: #090A0D;
}

.b-r-#e6e7e7 {
  border-right-color: #090A0D;
}

.b-b-black {
  border-bottom-color: #090A0D;
}

.b-l-black {
  border-left-color: #090A0D;
}

.b-white {
  border-color: #ffffff;
}

.b-t-white {
  border-top-color: #ffffff;
}

.b-r-white {
  border-right-color: #ffffff;
}

.b-b-white {
  border-bottom-color: #ffffff;
}

.b-l-white {
  border-left-color: #ffffff;
}

.b-blue {
  border-color: #2196f3;
}

.b-t-blue {
  border-top-color: #2196f3;
}

.b-r-#e9f5fe {
  border-right-color: #2196f3;
}

.b-b-blue {
  border-bottom-color: #2196f3;
}

.b-l-blue {
  border-left-color: #2196f3;
}

.b-bluish {
  border-color: #0067F4;
}

.b-t-bluish {
  border-top-color: #0067F4;
}

.b-r-#e6f0fe {
  border-right-color: #0067F4;
}

.b-b-bluish {
  border-bottom-color: #0067F4;
}

.b-l-bluish {
  border-left-color: #0067F4;
}

.b-orangish {
  border-color: #FFB400;
}

.b-t-orangish {
  border-top-color: #FFB400;
}

.b-r-#fff8e6 {
  border-right-color: #FFB400;
}

.b-b-orangish {
  border-bottom-color: #FFB400;
}

.b-l-orangish {
  border-left-color: #FFB400;
}

.no-b-t {
  border-top-width: 0;
}

.no-b-r {
  border-right-width: 0;
}

.no-b-b {
  border-bottom-width: 0;
}

.no-b-l {
  border-left-width: 0;
}

.b-dashed {
  border-style: dashed !important;
}

@media (max-width: 991px) {
  .no-border-sm {
    border-width: 0;
  }
}
@media (max-width: 767px) {
  .no-border-xs {
    border-width: 0;
  }
}
.hover-action {
  display: none;
}

.hover-rotate {
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  -moz-transition: all 0.2s ease-in-out 0.1s;
  -ms-transition: all 0.2s ease-in-out 0.1s;
  -o-transition: all 0.2s ease-in-out 0.1s;
  transition: all 0.2s ease-in-out 0.1s;
}

.hover-anchor:hover .hover-action,
.hover-anchor:focus .hover-action,
.hover-anchor:active .hover-action {
  display: inherit;
}
.hover-anchor:hover .hover-rotate,
.hover-anchor:focus .hover-rotate,
.hover-anchor:active .hover-rotate {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hover-top:hover,
.hover-top:focus {
  position: relative;
  z-index: 1000;
}

.m-a {
  margin: 1rem !important;
}

.m-t {
  margin-top: 1rem !important;
}

.m-r {
  margin-right: 1rem !important;
}

.m-b {
  margin-bottom: 1rem !important;
}

.m-l {
  margin-left: 1rem !important;
}

.m-x {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.m-y {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-a-lg {
  margin: 3rem !important;
}

.m-t-lg {
  margin-top: 3rem !important;
}

.m-r-lg {
  margin-right: 3rem !important;
}

.m-b-lg {
  margin-bottom: 3rem !important;
}

.m-l-lg {
  margin-left: 3rem !important;
}

.m-x-lg {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.m-y-lg {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.m-a-md {
  margin: 1.5rem !important;
}

.m-t-md {
  margin-top: 1.5rem !important;
}

.m-r-md {
  margin-right: 1.5rem !important;
}

.m-b-md {
  margin-bottom: 1.5rem !important;
}

.m-l-md {
  margin-left: 1.5rem !important;
}

.m-x-md {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.m-y-md {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-a-sm {
  margin: 0.5rem !important;
}

.m-t-sm {
  margin-top: 0.5rem !important;
}

.m-r-sm {
  margin-right: 0.5rem !important;
}

.m-b-sm {
  margin-bottom: 0.5rem !important;
}

.m-l-sm {
  margin-left: 0.5rem !important;
}

.m-x-sm {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.m-y-sm {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-a-xs {
  margin: 0.25rem !important;
}

.m-t-xs {
  margin-top: 0.25rem !important;
}

.m-r-xs {
  margin-right: 0.25rem !important;
}

.m-b-xs {
  margin-bottom: 0.25rem !important;
}

.m-l-xs {
  margin-left: 0.25rem !important;
}

.m-x-xs {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.m-y-xs {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.p-a {
  padding: 1rem !important;
}

.p-t {
  padding-top: 1rem !important;
}

.p-r {
  padding-right: 1rem !important;
}

.p-b {
  padding-bottom: 1rem !important;
}

.p-l {
  padding-left: 1rem !important;
}

.p-x {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.p-y {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-a-lg {
  padding: 3rem !important;
}

.p-t-lg {
  padding-top: 3rem !important;
}

.p-r-lg {
  padding-right: 3rem !important;
}

.p-b-lg {
  padding-bottom: 3rem !important;
}

.p-l-lg {
  padding-left: 3rem !important;
}

.p-x-lg {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.p-y-lg {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.p-a-md {
  padding: 1.5rem !important;
}

.p-t-md {
  padding-top: 1.5rem !important;
}

.p-r-md {
  padding-right: 1.5rem !important;
}

.p-b-md {
  padding-bottom: 1.5rem !important;
}

.p-l-md {
  padding-left: 1.5rem !important;
}

.p-x-md {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.p-y-md {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-a-sm {
  padding: 0.5rem !important;
}

.p-t-sm {
  padding-top: 0.5rem !important;
}

.p-r-sm {
  padding-right: 0.5rem !important;
}

.p-b-sm {
  padding-bottom: 0.5rem !important;
}

.p-l-sm {
  padding-left: 0.5rem !important;
}

.p-x-sm {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.p-y-sm {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-a-xs {
  padding: 0.25rem !important;
}

.p-t-xs {
  padding-top: 0.25rem !important;
}

.p-r-xs {
  padding-right: 0.25rem !important;
}

.p-b-xs {
  padding-bottom: 0.25rem !important;
}

.p-l-xs {
  padding-left: 0.25rem !important;
}

.p-x-xs {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.p-y-xs {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.padding {
  padding: 1.5rem 1.5rem;
}

.margin {
  margin-bottom: 1.5rem;
}

.padding-out {
  margin: -1.5rem -1.5rem;
}

@media (max-width: 991px) {
  .padding {
    padding: 1rem 1rem;
  }
  .padding-out {
    margin: -1rem -1rem;
  }
  .margin {
    margin-bottom: 1rem;
  }
  .box,
  .box-color {
    margin-bottom: 1rem;
  }
}
@media (max-width: 767px) {
  .padding {
    padding: 0.5rem 0.5rem;
  }
  .padding-out {
    margin: -0.5rem -0.5rem;
  }
  .margin {
    margin-bottom: 0.5rem;
  }
  .box,
  .box-color {
    margin-bottom: 0.5rem;
  }
  .no-padding-xs {
    padding: 0;
  }
}
.no-radius {
  border-radius: 0 !important;
}

.no-r-t {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.no-r-r {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.no-r-b {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.no-r-l {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.r {
  border-radius: 3px;
}

.r-t {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.r-r {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.r-b {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.r-l {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.r-2x, .el-divider__text.is-center {
  border-radius: 6px;
}

.r-3x {
  border-radius: 9px;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollable.hover,
.scrollable.hover > .row-inner {
  overflow-y: hidden;
}
.scrollable.hover > * {
  margin-top: -1px;
}
.scrollable.hover:hover, .scrollable.hover:focus, .scrollable.hover:active {
  overflow: visible;
  overflow-y: auto;
}
.scrollable.hover:hover > .row-inner, .scrollable.hover:focus > .row-inner, .scrollable.hover:active > .row-inner {
  overflow-y: auto;
}
.smart .scrollable,
.smart .scrollable > .row-inner {
  overflow-y: auto !important;
}
.scrollable::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
.scrollable::-webkit-scrollbar-thumb {
  background: #ffa000;
}
.scrollable.flipped {
  direction: rtl;
}
.scrollable.flipped .list-group {
  direction: ltr;
}

.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-x {
  overflow-x: auto;
}

a.primary:hover {
  background-color: #00b24b;
}

a.text-primary:hover {
  color: #00b24b;
}

.text-primary,
.text-primary-hover a:hover {
  color: #00BF50 !important;
}

a.warn:hover {
  background-color: #f2ab00;
}

a.text-warn:hover {
  color: #f2ab00;
}

.text-warn,
.text-warn-hover a:hover {
  color: #FFB400 !important;
}

a.accent:hover {
  background-color: #a180da;
}

a.text-accent:hover {
  color: #a180da;
}

.text-accent,
.text-accent-hover a:hover {
  color: #a88add !important;
}

a.success:hover {
  background-color: #00b24b;
}

a.text-success:hover {
  color: #00b24b;
}

.text-success,
.text-success-hover a:hover {
  color: #00BF50 !important;
}

a.info:hover {
  background-color: #00b24b;
}

a.text-info:hover {
  color: #00b24b;
}

.text-info,
.text-info-hover a:hover {
  color: #00BF50 !important;
}

a.warning:hover {
  background-color: #f2ab00;
}

a.text-warning:hover {
  color: #f2ab00;
}

.text-warning,
.text-warning-hover a:hover {
  color: #FFB400 !important;
}

a.danger:hover {
  background-color: #b91739;
}

a.text-danger:hover, .alo-custom-radio-buttons .alo-radio-button-danger a.el-radio-button__inner:hover {
  color: #b91739;
}

.text-danger, .alo-custom-radio-buttons .alo-radio-button-danger .el-radio-button__inner,
.text-danger-hover a:hover {
  color: #C4183C !important;
}

a.dark:hover {
  background-color: #293846;
}

a.text-dark:hover {
  color: #293846;
}

.text-dark,
.text-dark-hover a:hover {
  color: #2e3e4e !important;
}

a.blue:hover {
  background-color: #1590f2;
}

a.text-blue:hover {
  color: #1590f2;
}

.text-blue,
.text-blue-hover a:hover {
  color: #2196f3 !important;
}

.text-white {
  color: rgb(255, 255, 255) !important;
}

.text-white-lt {
  color: rgba(255, 255, 255, 0.54);
}

.text-white-dk {
  color: #ffffff;
}

.text-black {
  color: rgb(0, 0, 0);
}

.text-black-lt {
  color: rgba(0, 0, 0, 0.54);
}

.text-black-dk {
  color: rgba(0, 0, 0, 0.87);
}

.text-muted {
  color: inherit !important;
  opacity: 0.7;
}

.text-color {
  color: rgba(0, 0, 0, 0.87);
}

._100 {
  font-weight: 100 !important;
}

._200 {
  font-weight: 200 !important;
}

._300 {
  font-weight: 300 !important;
}

._400, .hover-menu .col-4 span.block, .notif-card, .warning-card, .help-card, .el-message-box__message p {
  font-weight: 400 !important;
}

._500 {
  font-weight: 500 !important;
}

._600, .alo-custom-radio-buttons .alo-radio-button-warning .el-radio-button__inner, .alo-custom-radio-buttons .alo-radio-button-danger .el-radio-button__inner, .help-card .el-popover__title, .hover-menu .col-4, .text-muted, .el-message-box__title, .contact-details .el-collapse-item__header {
  font-weight: 600 !important;
}

._700 {
  font-weight: 700 !important;
}

._800 {
  font-weight: 800 !important;
}

.text {
  font-size: 1rem;
}

.text-xxs {
  font-size: 0.75rem !important;
}

.text-xs, .el-divider__text.is-center {
  font-size: 0.85rem !important;
}

.text-sm {
  font-size: 0.9rem !important;
}

.text-md {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.25rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.text-2x {
  font-size: 2em;
}

.text-3x {
  font-size: 3em;
}

.text-4x {
  font-size: 4em;
}

.l-h {
  line-height: 1.5;
}

.l-h-1x {
  line-height: 1;
}

.l-h-2x {
  line-height: 2em;
}

.l-s-1x {
  letter-spacing: 1px;
}

.l-s-2x {
  letter-spacing: 2px;
}

.l-s-3x {
  letter-spacing: 3px;
}

.l-s-4x {
  letter-spacing: 4px;
}

.l-s-n-1x {
  letter-spacing: -1px;
}

.l-s-n-2x {
  letter-spacing: -2px;
}

.l-s-n-3x {
  letter-spacing: -3px;
}

.l-s-n-4x {
  letter-spacing: -4px;
}

.h-1x {
  height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.h-2x {
  height: 2.7rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.h-3x {
  height: 4.05rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.h-4x {
  height: 5.4rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text-u-c {
  text-transform: uppercase;
}

.text-l-t {
  text-decoration: line-through;
}

.text-u-l {
  text-decoration: underline;
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-shadow {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.text-break {
  word-wrap: break-word;
}

.w-8 {
  width: 8px;
  height: 8px;
  display: inline-block;
}

.w-16 {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.w-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.w-24 {
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
}

.w-30 {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
}

.w-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
}

.w-40, .w-300, .w-200, .w-72 {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}

.w-42 {
  width: 42px;
  height: 42px;
  line-height: 42px;
  display: inline-block;
  text-align: center;
}

.w-44 {
  width: 44px;
  height: 44px;
  line-height: 44px;
  display: inline-block;
  text-align: center;
}

.w-48 {
  width: 48px;
  height: 48px;
  line-height: 48px;
  display: inline-block;
  text-align: center;
}

.w-56 {
  width: 56px;
  height: 56px;
  line-height: 56px;
  display: inline-block;
  text-align: center;
}

.w-90 {
  width: 90%;
}

.w-64 {
  width: 64px;
}

.w-96 {
  width: 96px;
}

.w-128 {
  width: 128px;
}

.w-xxxs {
  width: 40px;
}

.w-xxs {
  width: 60px;
}

.w-xs {
  width: 90px;
}

.w-sm {
  width: 120px;
}

.w-m {
  width: 140px;
}

.w {
  width: 180px;
}

.w-md {
  width: 200px;
}

.w-lg {
  width: 230px !important;
}

.w-xl {
  width: 280px;
}

.w-xxl {
  width: 320px;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-v, .m-h-v {
  height: 100vh;
}

.h-v-5 {
  height: 50vh;
}

.h-2x {
  height: 2rem;
}

.min-w-0 {
  min-width: 0;
}

.min-w-5 {
  min-width: 5%;
}

.min-w-10 {
  min-width: 10%;
}

.min-w-15 {
  min-width: 15%;
}

.min-w-20 {
  min-width: 20%;
}

.min-w-25 {
  min-width: 25%;
}

.min-w-50 {
  min-width: 50%;
}

.min-w-75 {
  min-width: 75%;
}

.min-w-90 {
  min-width: 90%;
}

.min-w-100 {
  min-width: 100%;
}

.max-w-5 {
  max-width: 5%;
}

.max-w-10 {
  max-width: 10%;
}

.max-w-15 {
  max-width: 15%;
}

.max-w-20 {
  max-width: 20%;
}

.max-w-25 {
  max-width: 25%;
}

@media (max-width: 767px) {
  .w-auto-sm {
    width: auto;
  }
  .w-full-sm {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .w-auto-xs {
    width: auto;
  }
  .w-full-xs {
    width: 100%;
  }
}
.md-btn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  outline: none;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-style: inherit;
  font-variant: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.md-flat {
  background: transparent;
}

.md-btn.md-flat:not([disabled]):hover,
.md-btn.md-flat:not([disabled]):focus {
  background-color: rgba(158, 158, 158, 0.2);
}

.md-btn-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 2px;
}

.md-btn[disabled],
.md-btn.md-raised[disabled],
.md-btn.md-fab[disabled] {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
  cursor: not-allowed;
}

.md-fab {
  line-height: 44px;
}

.md-fab-offset {
  margin-top: -20px;
  margin-bottom: -20px;
}

.md-btn:focus {
  outline: none;
}

.md-btn:hover {
  text-decoration: none;
}

.md-btn.md-cornered {
  border-radius: 0;
}

.md-btn.md-icon {
  padding: 0;
  background: none;
}

.md-btn.md-raised {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.md-btn.md-fab {
  z-index: 20;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: 0.2s linear;
  transition-property: -webkit-transform, box-shadow;
  transition-property: transform, box-shadow;
}

.md-btn.md-raised:not([disabled]),
.md-btn.md-fab {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.md-btn.md-raised:not([disabled]):focus,
.md-btn.md-raised:not([disabled]):hover,
.md-btn.md-fab:not([disabled]):focus,
.md-btn.md-fab:not([disabled]):hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  -webkit-transform: translate3d(0, -1px, 0);
  transform: translate3d(0, -1px, 0);
}

.md-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: absolute;
}

.md-fab-bottom-left {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
  position: absolute;
}

.md-fab-top-right {
  top: 20px;
  right: 20px;
  bottom: auto;
  left: auto;
  position: absolute;
}

.md-fab-top-left {
  top: 20px;
  right: auto;
  bottom: auto;
  left: 20px;
  position: absolute;
}

.md-btn.md-fab.md-mini {
  width: 40px;
  height: 40px;
  line-height: 28px;
}

.md-btn.md-fab.md-sm {
  width: 48px;
  height: 48px;
  line-height: 36px;
}

.red {
  background-color: #f44336;
  color: #ffffff;
}

.red-50 {
  background-color: #ffebee;
  color: rgba(0, 0, 0, 0.87);
}

.red-100 {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.87);
}

.red-200 {
  background-color: #ef9a9a;
  color: rgba(0, 0, 0, 0.87);
}

.red-300 {
  background-color: #e57373;
  color: rgba(0, 0, 0, 0.87);
}

.red-400 {
  background-color: #ef5350;
  color: rgba(0, 0, 0, 0.87);
}

.red-500 {
  background-color: #f44336;
  color: #ffffff;
}

.red-600 {
  background-color: #e53935;
  color: #ffffff;
}

.red-700 {
  background-color: #d32f2f;
  color: #ffffff;
}

.red-800 {
  background-color: #c62828;
  color: #ffffff;
}

.red-900 {
  background-color: #b71c1c;
  color: #ffffff;
}

.red-A100 {
  background-color: #ff8a80;
  color: rgba(0, 0, 0, 0.87);
}

.red-A200 {
  background-color: #ff5252;
  color: #ffffff;
}

.red-A400 {
  background-color: #ff1744;
  color: #ffffff;
}

.red-A700 {
  background-color: #d50000;
  color: #ffffff;
}

.pink {
  background-color: #e91e63;
  color: #ffffff;
}

.pink-50 {
  background-color: #fce4ec;
  color: rgba(0, 0, 0, 0.87);
}

.pink-100 {
  background-color: #f8bbd0;
  color: rgba(0, 0, 0, 0.87);
}

.pink-200 {
  background-color: #f48fb1;
  color: rgba(0, 0, 0, 0.87);
}

.pink-300 {
  background-color: #f06292;
  color: rgba(0, 0, 0, 0.87);
}

.pink-400 {
  background-color: #ec407a;
  color: rgba(0, 0, 0, 0.87);
}

.pink-500 {
  background-color: #e91e63;
  color: #ffffff;
}

.pink-600 {
  background-color: #d81b60;
  color: #ffffff;
}

.pink-700 {
  background-color: #c2185b;
  color: #ffffff;
}

.pink-800 {
  background-color: #ad1457;
  color: #ffffff;
}

.pink-900 {
  background-color: #880e4f;
  color: #ffffff;
}

.pink-A100 {
  background-color: #ff80ab;
  color: rgba(0, 0, 0, 0.87);
}

.pink-A200 {
  background-color: #ff4081;
  color: #ffffff;
}

.pink-A400 {
  background-color: #f50057;
  color: #ffffff;
}

.pink-A700 {
  background-color: #c51162;
  color: #ffffff;
}

.purple {
  background-color: #9c27b0;
  color: #ffffff;
}

.purple-50 {
  background-color: #f3e5f5;
  color: rgba(0, 0, 0, 0.87);
}

.purple-100 {
  background-color: #e1bee7;
  color: rgba(0, 0, 0, 0.87);
}

.purple-200 {
  background-color: #ce93d8;
  color: rgba(0, 0, 0, 0.87);
}

.purple-300 {
  background-color: #ba68c8;
  color: #ffffff;
}

.purple-400 {
  background-color: #ab47bc;
  color: #ffffff;
}

.purple-500 {
  background-color: #9c27b0;
  color: #ffffff;
}

.purple-600 {
  background-color: #8e24aa;
  color: #ffffff;
}

.purple-700 {
  background-color: #7b1fa2;
  color: #ffffff;
}

.purple-800 {
  background-color: #6a1b9a;
  color: #ffffff;
}

.purple-900 {
  background-color: #4a148c;
  color: #ffffff;
}

.purple-A100 {
  background-color: #ea80fc;
  color: rgba(0, 0, 0, 0.87);
}

.purple-A200 {
  background-color: #e040fb;
  color: #ffffff;
}

.purple-A400 {
  background-color: #d500f9;
  color: #ffffff;
}

.purple-A700 {
  background-color: #aa00ff;
  color: #ffffff;
}

.deep-purple {
  background-color: #673ab7;
  color: #ffffff;
}

.deep-purple-50 {
  background-color: #ede7f6;
  color: rgba(0, 0, 0, 0.87);
}

.deep-purple-100 {
  background-color: #d1c4e9;
  color: rgba(0, 0, 0, 0.87);
}

.deep-purple-200 {
  background-color: #b39ddb;
  color: rgba(0, 0, 0, 0.87);
}

.deep-purple-300 {
  background-color: #9575cd;
  color: #ffffff;
}

.deep-purple-400 {
  background-color: #7e57c2;
  color: #ffffff;
}

.deep-purple-500 {
  background-color: #673ab7;
  color: #ffffff;
}

.deep-purple-600 {
  background-color: #5e35b1;
  color: #ffffff;
}

.deep-purple-700 {
  background-color: #512da8;
  color: #ffffff;
}

.deep-purple-800 {
  background-color: #4527a0;
  color: #ffffff;
}

.deep-purple-900 {
  background-color: #311b92;
  color: #ffffff;
}

.deep-purple-A100 {
  background-color: #b388ff;
  color: rgba(0, 0, 0, 0.87);
}

.deep-purple-A200 {
  background-color: #7c4dff;
  color: #ffffff;
}

.deep-purple-A400 {
  background-color: #651fff;
  color: #ffffff;
}

.deep-purple-A700 {
  background-color: #6200ea;
  color: #ffffff;
}

.indigo {
  background-color: #3f51b5;
  color: #ffffff;
}

.indigo-50 {
  background-color: #e8eaf6;
  color: rgba(0, 0, 0, 0.87);
}

.indigo-100 {
  background-color: #c5cae9;
  color: rgba(0, 0, 0, 0.87);
}

.indigo-200 {
  background-color: #9fa8da;
  color: rgba(0, 0, 0, 0.87);
}

.indigo-300 {
  background-color: #7986cb;
  color: #ffffff;
}

.indigo-400 {
  background-color: #5c6bc0;
  color: #ffffff;
}

.indigo-500 {
  background-color: #3f51b5;
  color: #ffffff;
}

.indigo-600 {
  background-color: #3949ab;
  color: #ffffff;
}

.indigo-700 {
  background-color: #303f9f;
  color: #ffffff;
}

.indigo-800 {
  background-color: #283593;
  color: #ffffff;
}

.indigo-900 {
  background-color: #1a237e;
  color: #ffffff;
}

.indigo-A100 {
  background-color: #8c9eff;
  color: rgba(0, 0, 0, 0.87);
}

.indigo-A200 {
  background-color: #536dfe;
  color: #ffffff;
}

.indigo-A400 {
  background-color: #3d5afe;
  color: #ffffff;
}

.indigo-A700 {
  background-color: #304ffe;
  color: #ffffff;
}

.blue {
  background-color: #2196f3;
  color: #ffffff;
}

.blue-50 {
  background-color: #e3f2fd;
  color: rgba(0, 0, 0, 0.87);
}

.blue-100 {
  background-color: #bbdefb;
  color: rgba(0, 0, 0, 0.87);
}

.blue-200 {
  background-color: #90caf9;
  color: rgba(0, 0, 0, 0.87);
}

.blue-300 {
  background-color: #64b5f6;
  color: rgba(0, 0, 0, 0.87);
}

.blue-400 {
  background-color: #42a5f5;
  color: rgba(0, 0, 0, 0.87);
}

.blue-500 {
  background-color: #2196f3;
  color: #ffffff;
}

.blue-600 {
  background-color: #1e88e5;
  color: #ffffff;
}

.blue-700 {
  background-color: #1976d2;
  color: #ffffff;
}

.blue-800 {
  background-color: #1565c0;
  color: #ffffff;
}

.blue-900 {
  background-color: #0d47a1;
  color: #ffffff;
}

.blue-A100 {
  background-color: #82b1ff;
  color: rgba(0, 0, 0, 0.87);
}

.blue-A200 {
  background-color: #448aff;
  color: #ffffff;
}

.blue-A400 {
  background-color: #2979ff;
  color: #ffffff;
}

.blue-A700 {
  background-color: #2962ff;
  color: #ffffff;
}

.light-blue {
  background-color: #03a9f4;
  color: #ffffff;
}

.light-blue-50 {
  background-color: #e1f5fe;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-100 {
  background-color: #b3e5fc;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-200 {
  background-color: #81d4fa;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-300 {
  background-color: #4fc3f7;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-400 {
  background-color: #29b6f6;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-500 {
  background-color: #03a9f4;
  color: #ffffff;
}

.light-blue-600 {
  background-color: #039be5;
  color: #ffffff;
}

.light-blue-700 {
  background-color: #0288d1;
  color: #ffffff;
}

.light-blue-800 {
  background-color: #0277bd;
  color: #ffffff;
}

.light-blue-900 {
  background-color: #01579b;
  color: #ffffff;
}

.light-blue-A100 {
  background-color: #80d8ff;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-A200 {
  background-color: #40c4ff;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-A400 {
  background-color: #00b0ff;
  color: rgba(0, 0, 0, 0.87);
}

.light-blue-A700 {
  background-color: #0091ea;
  color: #ffffff;
}

.cyan {
  background-color: #00bcd4;
  color: #ffffff;
}

.cyan-50 {
  background-color: #e0f7fa;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-100 {
  background-color: #b2ebf2;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-200 {
  background-color: #80deea;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-300 {
  background-color: #4dd0e1;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-400 {
  background-color: #26c6da;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-500 {
  background-color: #00bcd4;
  color: #ffffff;
}

.cyan-600 {
  background-color: #00acc1;
  color: #ffffff;
}

.cyan-700 {
  background-color: #0097a7;
  color: #ffffff;
}

.cyan-800 {
  background-color: #00838f;
  color: #ffffff;
}

.cyan-900 {
  background-color: #006064;
  color: #ffffff;
}

.cyan-A100 {
  background-color: #84ffff;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-A200 {
  background-color: #18ffff;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-A400 {
  background-color: #00e5ff;
  color: rgba(0, 0, 0, 0.87);
}

.cyan-A700 {
  background-color: #00b8d4;
  color: rgba(0, 0, 0, 0.87);
}

.teal {
  background-color: #009688;
  color: #ffffff;
}

.teal-50 {
  background-color: #e0f2f1;
  color: rgba(0, 0, 0, 0.87);
}

.teal-100 {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.87);
}

.teal-200 {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.87);
}

.teal-300 {
  background-color: #4db6ac;
  color: rgba(0, 0, 0, 0.87);
}

.teal-400 {
  background-color: #26a69a;
  color: rgba(0, 0, 0, 0.87);
}

.teal-500 {
  background-color: #009688;
  color: #ffffff;
}

.teal-600 {
  background-color: #00897b;
  color: #ffffff;
}

.teal-700 {
  background-color: #00796b;
  color: #ffffff;
}

.teal-800 {
  background-color: #00695c;
  color: #ffffff;
}

.teal-900 {
  background-color: #004d40;
  color: #ffffff;
}

.teal-A100 {
  background-color: #a7ffeb;
  color: rgba(0, 0, 0, 0.87);
}

.teal-A200 {
  background-color: #64ffda;
  color: rgba(0, 0, 0, 0.87);
}

.teal-A400 {
  background-color: #1de9b6;
  color: rgba(0, 0, 0, 0.87);
}

.teal-A700 {
  background-color: #00bfa5;
  color: rgba(0, 0, 0, 0.87);
}

.green {
  background-color: #4caf50;
  color: #ffffff;
}

.green-50 {
  background-color: #e8f5e9;
  color: rgba(0, 0, 0, 0.87);
}

.green-100 {
  background-color: #c8e6c9;
  color: rgba(0, 0, 0, 0.87);
}

.green-200 {
  background-color: #a5d6a7;
  color: rgba(0, 0, 0, 0.87);
}

.green-300 {
  background-color: #81c784;
  color: rgba(0, 0, 0, 0.87);
}

.green-400 {
  background-color: #66bb6a;
  color: rgba(0, 0, 0, 0.87);
}

.green-500 {
  background-color: #4caf50;
  color: #ffffff;
}

.green-600 {
  background-color: #43a047;
  color: #ffffff;
}

.green-700 {
  background-color: #388e3c;
  color: #ffffff;
}

.green-800 {
  background-color: #2e7d32;
  color: #ffffff;
}

.green-900 {
  background-color: #1b5e20;
  color: #ffffff;
}

.green-A100 {
  background-color: #b9f6ca;
  color: rgba(0, 0, 0, 0.87);
}

.green-A200 {
  background-color: #69f0ae;
  color: rgba(0, 0, 0, 0.87);
}

.green-A400 {
  background-color: #00e676;
  color: rgba(0, 0, 0, 0.87);
}

.green-A700 {
  background-color: #00c853;
  color: rgba(0, 0, 0, 0.87);
}

.light-green {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-50 {
  background-color: #f1f8e9;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-100 {
  background-color: #dcedc8;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-200 {
  background-color: #c5e1a5;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-300 {
  background-color: #aed581;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-400 {
  background-color: #9ccc65;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-500 {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-600 {
  background-color: #7cb342;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-700 {
  background-color: #689f38;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-800 {
  background-color: #558b2f;
  color: #ffffff;
}

.light-green-900 {
  background-color: #33691e;
  color: #ffffff;
}

.light-green-A100 {
  background-color: #ccff90;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-A200 {
  background-color: #b2ff59;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-A400 {
  background-color: #76ff03;
  color: rgba(0, 0, 0, 0.87);
}

.light-green-A700 {
  background-color: #64dd17;
  color: rgba(0, 0, 0, 0.87);
}

.lime {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}

.lime-50 {
  background-color: #f9fbe7;
  color: rgba(0, 0, 0, 0.87);
}

.lime-100 {
  background-color: #f0f4c3;
  color: rgba(0, 0, 0, 0.87);
}

.lime-200 {
  background-color: #e6ee9c;
  color: rgba(0, 0, 0, 0.87);
}

.lime-300 {
  background-color: #dce775;
  color: rgba(0, 0, 0, 0.87);
}

.lime-400 {
  background-color: #d4e157;
  color: rgba(0, 0, 0, 0.87);
}

.lime-500 {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.87);
}

.lime-600 {
  background-color: #c0ca33;
  color: rgba(0, 0, 0, 0.87);
}

.lime-700 {
  background-color: #afb42b;
  color: rgba(0, 0, 0, 0.87);
}

.lime-800 {
  background-color: #9e9d24;
  color: rgba(0, 0, 0, 0.87);
}

.lime-900 {
  background-color: #827717;
  color: #ffffff;
}

.lime-A100 {
  background-color: #f4ff81;
  color: rgba(0, 0, 0, 0.87);
}

.lime-A200 {
  background-color: #eeff41;
  color: rgba(0, 0, 0, 0.87);
}

.lime-A400 {
  background-color: #c6ff00;
  color: rgba(0, 0, 0, 0.87);
}

.lime-A700 {
  background-color: #aeea00;
  color: rgba(0, 0, 0, 0.87);
}

.yellow {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-50 {
  background-color: #fffde7;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-100 {
  background-color: #fff9c4;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-200 {
  background-color: #fff59d;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-300 {
  background-color: #fff176;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-400 {
  background-color: #ffee58;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-500 {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-600 {
  background-color: #fdd835;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-700 {
  background-color: #fbc02d;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-800 {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-900 {
  background-color: #f57f17;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-A100 {
  background-color: #ffff8d;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-A200 {
  background-color: #ffff00;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-A400 {
  background-color: #ffea00;
  color: rgba(0, 0, 0, 0.87);
}

.yellow-A700 {
  background-color: #ffd600;
  color: rgba(0, 0, 0, 0.87);
}

.amber {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}

.amber-50 {
  background-color: #fff8e1;
  color: rgba(0, 0, 0, 0.87);
}

.amber-100 {
  background-color: #ffecb3;
  color: rgba(0, 0, 0, 0.87);
}

.amber-200 {
  background-color: #ffe082;
  color: rgba(0, 0, 0, 0.87);
}

.amber-300 {
  background-color: #ffd54f;
  color: rgba(0, 0, 0, 0.87);
}

.amber-400 {
  background-color: #ffca28;
  color: rgba(0, 0, 0, 0.87);
}

.amber-500 {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}

.amber-600 {
  background-color: #ffb300;
  color: rgba(0, 0, 0, 0.87);
}

.amber-700 {
  background-color: #ffa000;
  color: rgba(0, 0, 0, 0.87);
}

.amber-800 {
  background-color: #ff8f00;
  color: rgba(0, 0, 0, 0.87);
}

.amber-900 {
  background-color: #ff6f00;
  color: rgba(0, 0, 0, 0.87);
}

.amber-A100 {
  background-color: #ffe57f;
  color: rgba(0, 0, 0, 0.87);
}

.amber-A200 {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}

.amber-A400 {
  background-color: #ffc400;
  color: rgba(0, 0, 0, 0.87);
}

.amber-A700 {
  background-color: #ffab00;
  color: rgba(0, 0, 0, 0.87);
}

.orange {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.orange-50 {
  background-color: #fff3e0;
  color: rgba(0, 0, 0, 0.87);
}

.orange-100 {
  background-color: #ffe0b2;
  color: rgba(0, 0, 0, 0.87);
}

.orange-200 {
  background-color: #ffcc80;
  color: rgba(0, 0, 0, 0.87);
}

.orange-300 {
  background-color: #ffb74d;
  color: rgba(0, 0, 0, 0.87);
}

.orange-400 {
  background-color: #ffa726;
  color: rgba(0, 0, 0, 0.87);
}

.orange-500 {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.87);
}

.orange-600 {
  background-color: #fb8c00;
  color: rgba(0, 0, 0, 0.87);
}

.orange-700 {
  background-color: #f57c00;
  color: rgba(0, 0, 0, 0.87);
}

.orange-800 {
  background-color: #ef6c00;
  color: #ffffff;
}

.orange-900 {
  background-color: #e65100;
  color: #ffffff;
}

.orange-A100 {
  background-color: #ffd180;
  color: rgba(0, 0, 0, 0.87);
}

.orange-A200 {
  background-color: #ffab40;
  color: rgba(0, 0, 0, 0.87);
}

.orange-A400 {
  background-color: #ff9100;
  color: rgba(0, 0, 0, 0.87);
}

.orange-A700 {
  background-color: #ff6d00;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange {
  background-color: #ff5722;
  color: #ffffff;
}

.deep-orange-50 {
  background-color: #fbe9e7;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-100 {
  background-color: #ffccbc;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-200 {
  background-color: #ffab91;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-300 {
  background-color: #ff8a65;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-400 {
  background-color: #ff7043;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-500 {
  background-color: #ff5722;
  color: #ffffff;
}

.deep-orange-600 {
  background-color: #f4511e;
  color: #ffffff;
}

.deep-orange-700 {
  background-color: #e64a19;
  color: #ffffff;
}

.deep-orange-800 {
  background-color: #d84315;
  color: #ffffff;
}

.deep-orange-900 {
  background-color: #bf360c;
  color: #ffffff;
}

.deep-orange-A100 {
  background-color: #ff9e80;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-A200 {
  background-color: #ff6e40;
  color: rgba(0, 0, 0, 0.87);
}

.deep-orange-A400 {
  background-color: #ff3d00;
  color: #ffffff;
}

.deep-orange-A700 {
  background-color: #dd2c00;
  color: #ffffff;
}

.brown {
  background-color: #795548;
  color: #ffffff;
}

.brown-50 {
  background-color: #efebe9;
  color: rgba(0, 0, 0, 0.87);
}

.brown-100 {
  background-color: #d7ccc8;
  color: rgba(0, 0, 0, 0.87);
}

.brown-200 {
  background-color: #bcaaa4;
  color: rgba(0, 0, 0, 0.87);
}

.brown-300 {
  background-color: #a1887f;
  color: #ffffff;
}

.brown-400 {
  background-color: #8d6e63;
  color: #ffffff;
}

.brown-500 {
  background-color: #795548;
  color: #ffffff;
}

.brown-600 {
  background-color: #6d4c41;
  color: #ffffff;
}

.brown-700 {
  background-color: #5d4037;
  color: #ffffff;
}

.brown-800 {
  background-color: #4e342e;
  color: #ffffff;
}

.brown-900 {
  background-color: #3e2723;
  color: #ffffff;
}

.blue-grey {
  background-color: #607d8b;
  color: #ffffff;
}

.blue-grey-50 {
  background-color: #eceff1;
  color: rgba(0, 0, 0, 0.87);
}

.blue-grey-100 {
  background-color: #cfd8dc;
  color: rgba(0, 0, 0, 0.87);
}

.blue-grey-200 {
  background-color: #b0bec5;
  color: rgba(0, 0, 0, 0.87);
}

.blue-grey-300 {
  background-color: #90a4ae;
  color: rgba(0, 0, 0, 0.87);
}

.blue-grey-400 {
  background-color: #78909c;
  color: #ffffff;
}

.blue-grey-500 {
  background-color: #607d8b;
  color: #ffffff;
}

.blue-grey-600 {
  background-color: #546e7a;
  color: #ffffff;
}

.blue-grey-700 {
  background-color: #455a64;
  color: #ffffff;
}

.blue-grey-800 {
  background-color: #37474f;
  color: #ffffff;
}

.blue-grey-900 {
  background-color: #263238;
  color: #ffffff;
}

.grey-50 {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.grey-100 {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.grey-200 {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.87);
}

.grey-300 {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.grey-400 {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}

.grey-500 {
  background-color: #9e9e9e;
  color: rgba(0, 0, 0, 0.87);
}

.grey-600 {
  background-color: #757575;
  color: #ffffff;
}

.grey-700 {
  background-color: #616161;
  color: #ffffff;
}

.grey-800 {
  background-color: #424242;
  color: #ffffff;
}

.grey-900 {
  background-color: #212121;
  color: #ffffff;
}

.text-deep-purple-500 {
  color: #673ab7 !important;
}

.text-purple-500 {
  color: #9c27b0 !important;
}

.text-yellow-500 {
  color: #ffeb3b !important;
}

.text-red-500 {
  color: #f44336 !important;
}

.text-red-A400 {
  color: #ff1744 !important;
}

.text-orange-500 {
  color: #ff9800 !important;
}

.text-pink-500 {
  color: #e91e63 !important;
}

.text-green-500 {
  color: #4caf50 !important;
}

.text-indigo-500 {
  color: #3f51b5 !important;
}

.text-blue-500 {
  color: #2196f3 !important;
}

.text-lime-500 {
  color: #cddc39 !important;
}

.text-teal-500 {
  color: #009688 !important;
}

.text-cyan-500 {
  color: #00bcd4 !important;
}

.text-grey-50 {
  color: #fafafa !important;
}

.text-grey-100 {
  color: #f5f5f5 !important;
}

.text-grey-200 {
  color: #eeeeee !important;
}

.text-grey-300 {
  color: #e0e0e0 !important;
}

.text-grey-400 {
  color: #bdbdbd !important;
}

.text-grey-500 {
  color: #9e9e9e !important;
}

.text-grey-800 {
  color: #424242 !important;
}

.text-grey-900 {
  color: #212121 !important;
}

.text-bluish {
  color: #0067F4 !important;
}

.text-purplish {
  color: #230e63 !important;
}

.text-orangish {
  color: #FFB400 !important;
}

.text-duskishblue {
  color: #4B4D70 !important;
}

.text-greenish, .webrtc-dialer.dark-skin-theme .minimize {
  color: #00BF50 !important;
}

.text-dark-greenish {
  color: #00A344 !important;
}

.text-greyish, .hover-menu .col-4 {
  color: #7D7D7D !important;
}

.text-dark-greyish {
  color: #313131 !important;
}

.text-blackish, .hover-menu .col-4 span.block {
  color: #090A0D !important;
}

.text-whitish, .hover-menu .col-4:hover span.block, .hover-menu .col-4:hover {
  color: #ffffff !important;
}

.text-new-blue {
  color: #043791 !important;
}

.text-new-purple {
  color: #37234b !important;
}

.text-deep-orange {
  color: #ff5722 !important;
}

.b-deep-purple-500 {
  border-color: #673ab7 !important;
}

.b-purple-500 {
  border-color: #9c27b0 !important;
}

.b-red-500 {
  border-color: #f44336 !important;
}

.b-orange-500 {
  border-color: #ff9800 !important;
}

.b-pink-500 {
  border-color: #e91e63 !important;
}

.b-green-500 {
  border-color: #4caf50 !important;
}

.b-indigo-500 {
  border-color: #3f51b5 !important;
}

.b-blue-500 {
  border-color: #2196f3 !important;
}

.b-lime-500 {
  border-color: #cddc39 !important;
}

.b-teal-500 {
  border-color: #009688 !important;
}

.b-cyan-500 {
  border-color: #00bcd4 !important;
}

.b-grey-800 {
  border-color: #424242 !important;
}

.b-grey-900 {
  border-color: #212121 !important;
}

.b-bluish {
  border-color: #0067F4 !important;
}

.b-purplish {
  border-color: #230e63 !important;
}

.b-orangish {
  border-color: #FFB400 !important;
}

.b-duskishblue {
  border-color: #4B4D70 !important;
}

.b-greenish {
  border-color: #00BF50 !important;
}

.b-dark-greenish {
  border-color: #00A344 !important;
}

.b-greyish {
  border-color: #7D7D7D !important;
}

.b-dker {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.b-blackish {
  border-color: #090A0D !important;
}

.b-whitish {
  border-color: #ffffff !important;
}

a.text-bluish {
  color: #0067F4 !important;
}
a.text-bluish:visited {
  color: #0067F4 !important;
}
a.text-bluish:hover {
  color: #0051c1 !important;
}
a.text-bluish:active {
  color: #0051c1 !important;
}

a.text-purplish {
  color: #230e63 !important;
}
a.text-purplish:visited {
  color: #230e63 !important;
}
a.text-purplish:hover {
  color: #130836 !important;
}
a.text-purplish:active {
  color: #130836 !important;
}

a.text-greenish, .webrtc-dialer.dark-skin-theme a.minimize {
  color: #00BF50 !important;
}
a.text-greenish:visited, .webrtc-dialer.dark-skin-theme a.minimize:visited {
  color: #00BF50 !important;
}
a.text-greenish:hover, .webrtc-dialer.dark-skin-theme a.minimize:hover {
  color: #008c3b !important;
}
a.text-greenish:active, .webrtc-dialer.dark-skin-theme a.minimize:active {
  color: #008c3b !important;
}

a.text-dark-greenish {
  color: #00A344 !important;
}
a.text-dark-greenish:visited {
  color: #00A344 !important;
}
a.text-dark-greenish:hover {
  color: #00702f !important;
}
a.text-dark-greenish:active {
  color: #00702f !important;
}

.md-form-group {
  padding: 18px 0 24px 0;
  position: relative;
}

.md-select {
  position: relative;
  z-index: 5;
  box-shadow: none;
  width: 100%;
  margin-top: 5px;
  font-weight: 800;
}
.md-select:focus ~ label, .md-select.focus ~ label {
  color: #00BF50;
  opacity: 1;
  top: 0 !important;
  font-size: 0.9em !important;
}
.float-label .md-select ~ label {
  top: 20px;
  font-size: 1em;
}
.float-label .md-select.ng-dirty ~ label, .float-label .md-select.has-value ~ label {
  top: 0;
  font-size: 0.9em;
}
.md-select ~ label {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 0.9em;
  font-weight: 600;
  position: absolute;
  z-index: 0;
  opacity: 0.8;
  display: inline-block;
  top: 0px;
  left: 0;
}
.md-select.disabled, .md-select[disabled] {
  opacity: 0.5;
}

.md-input, .md-input-style {
  background: transparent;
  position: relative;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  width: 100%;
  height: 34px;
  color: inherit;
  font-weight: 800;
}
.md-input:focus, .md-input-style:focus, .md-input.focus, .focus.md-input-style {
  border-color: #00BF50;
  border-bottom-width: 2px;
  padding-bottom: 1px;
}
.md-input:focus ~ label, .md-input-style:focus ~ label, .md-input.focus ~ label, .focus.md-input-style ~ label {
  color: #00BF50;
  opacity: 1;
  top: 0 !important;
  font-size: 0.9em !important;
}
.float-label .md-input ~ label, .float-label .md-input-style ~ label {
  top: 20px;
  font-size: 1em;
}
.float-label .md-input.ng-dirty ~ label, .float-label .ng-dirty.md-input-style ~ label, .float-label .md-input.has-value ~ label, .float-label .has-value.md-input-style ~ label {
  top: 0;
  font-size: 0.9em;
}
.md-input ~ label, .md-input-style ~ label {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 0.9em;
  font-weight: 600;
  position: absolute;
  z-index: 0;
  opacity: 0.8;
  display: inline-block;
  top: 0px;
  left: 0;
}
.md-input.disabled, .disabled.md-input-style, .md-input[disabled], [disabled].md-input-style {
  opacity: 0.5;
}

.md-form-group label {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 0.9em;
  font-weight: 600;
  position: absolute;
  z-index: 0;
  opacity: 0.8;
  display: inline-block;
  top: 0px;
  left: 0;
}

textarea.md-input, textarea.md-input-style {
  height: auto;
}

.md-input-white:focus, .md-input-white.focus {
  border-color: #fff;
}
.md-input-white:focus ~ label, .md-input-white.focus ~ label {
  color: #fff;
}

.md-input-msg {
  position: absolute;
  bottom: 0;
  line-height: 24px;
  font-size: 0.9em;
}

.md-input-msg.right {
  right: 0;
}

.md-check {
  cursor: pointer;
  padding-left: 1.25rem;
  margin: 0;
  display: inline-block;
  position: relative;
}
.md-check input {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  margin-left: -1.25rem;
}
.md-check input:checked + i:before {
  border-width: 0;
  background-color: inherit;
}
.md-check input:checked + span .active {
  display: inherit;
}
.md-check input[type=radio] + i, .md-check input[type=radio] + i:before {
  border-radius: 50%;
}
.md-check input[type=checkbox]:checked + i:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 6px;
  top: 2px;
  display: table;
  width: 6px;
  height: 12px;
  border: 2px solid;
  border-top: 0;
  border-left: 0;
  content: " ";
}
.md-check input[type=radio]:checked + i:after {
  position: absolute;
  left: 6px;
  top: 6px;
  display: table;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  content: " ";
}
.md-check input[disabled] + i:before, fieldset[disabled] .md-check input + i:before {
  opacity: 0.5;
}
.md-check input[disabled]:checked + i:before {
  opacity: 0.5;
}
.md-check > i {
  width: 0;
  height: 18px;
  line-height: 1;
  margin-left: -20px;
  margin-top: 1px;
  float: left;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: relative;
}
.md-check > i:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 100%;
  border: 2px solid #9e9e9e;
  border-radius: 2px;
}
.md-check > i.no-icon:after {
  display: none !important;
}

.md-switch {
  cursor: pointer;
  padding-left: 36px;
  margin: 0;
  min-height: 20px;
}
.md-switch input {
  position: absolute;
  cursor: pointer;
  width: 36px;
  height: 20px;
  z-index: 1;
  opacity: 0;
  margin-left: -36px;
}
.md-switch input:checked + i:before {
  background: inherit;
  opacity: 0.5;
}
.md-switch input:checked + i:after {
  background: inherit;
  left: 16px;
}
.md-switch input[disabled] + i:before, fieldset[disabled] .md-switch input + i:before {
  background-color: rgba(0, 0, 0, 0.12);
}
.md-switch input[disabled] + i:after, fieldset[disabled] .md-switch input + i:after {
  background-color: rgb(189, 189, 189);
}
.md-switch i {
  width: 0px;
  height: 18px;
  line-height: 1;
  margin-left: -36px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 44px;
  position: relative;
}
.md-switch i:before {
  content: "";
  left: 1px;
  width: 34px;
  top: 3px;
  height: 14px;
  border-radius: 8px;
  position: absolute;
  background-color: rgb(158, 158, 158);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.md-switch i:after {
  content: "";
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  outline: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

[ui-view].ng-leave {
  display: none;
}

[ui-view].ng-leave.smooth {
  display: block;
}

.smooth.ng-animate {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fade-in-right-big.ng-enter {
  -webkit-animation: fadeInRightBig 0.5s;
  animation: fadeInRightBig 0.5s;
}

.fade-in-right-big.ng-leave {
  -webkit-animation: fadeOutLeftBig 0.5s;
  animation: fadeOutLeftBig 0.5s;
}

.fade-in-left-big.ng-enter {
  -webkit-animation: fadeInLeftBig 0.5s;
  animation: fadeInLeftBig 0.5s;
}

.fade-in-left-big.ng-leave {
  -webkit-animation: fadeOutRightBig 0.5s;
  animation: fadeOutRightBig 0.5s;
}

.fade-in-up-big.ng-enter {
  -webkit-animation: fadeInUpBig 0.5s;
  animation: fadeInUpBig 0.5s;
}

.fade-in-up-big.ng-leave {
  -webkit-animation: fadeOutUpBig 0.5s;
  animation: fadeOutUpBig 0.5s;
}

.fade-in-down-big.ng-enter {
  -webkit-animation: fadeInDownBig 0.5s;
  animation: fadeInDownBig 0.5s;
}

.fade-in-down-big.ng-leave {
  -webkit-animation: fadeOutDownBig 0.5s;
  animation: fadeOutDownBig 0.5s;
}

.fade-in.ng-enter {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

.fade-in.ng-leave {
  -webkit-animation: fadeOut 0.5s;
  animation: fadeOut 0.5s;
}

.fade-in-right.ng-enter {
  -webkit-animation: fadeInRight 0.5s;
  animation: fadeInRight 0.5s;
}

.fade-in-right.ng-leave {
  -webkit-animation: fadeOutLeft 0.5s;
  animation: fadeOutLeft 0.5s;
}

.fade-in-left.ng-enter {
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}

.fade-in-left.ng-leave {
  -webkit-animation: fadeOutRight 0.5s;
  animation: fadeOutRight 0.5s;
}

.fade-in-up.ng-enter {
  -webkit-animation: fadeInUp 0.5s;
  animation: fadeInUp 0.5s;
}

.fade-in-up.ng-leave {
  -webkit-animation: fadeOutUp 0.5s;
  animation: fadeOutUp 0.5s;
}

.fade-in-down.ng-enter {
  -webkit-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s;
}

.fade-in-down.ng-leave {
  -webkit-animation: fadeOutDown 0.5s;
  animation: fadeOutDown 0.5s;
}

.el-table {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #7D7D7D !important;
}

.el-table thead {
  color: #313131 !important;
  font-weight: bold !important;
}

.el-table .descending .sort-caret.descending {
  color: #313131 !important;
  border-top-color: #00BF50 !important;
}

.el-table .ascending .sort-caret.ascending {
  color: #313131 !important;
  border-bottom-color: #00BF50 !important;
}

.el-step__head.is-success {
  color: #00BF50 !important;
  border-color: #00BF50 !important;
}

.el-step__title.is-success {
  color: #00BF50 !important;
}

.el-step__description.is-success {
  color: #00BF50 !important;
}

.el-button span > i.material-icons {
  line-height: 0;
}
.el-button.el-button--text span > i.material-icons {
  line-height: normal;
}

.el-button--text:hover, .el-button--text:focus {
  border-color: transparent !important;
  background-color: transparent !important;
}

.el-button--success:not(.is-plain) {
  background-color: #00BF50 !important;
  border-color: #00BF50 !important;
}
.el-button--success:not(.is-plain):hover {
  background-color: #00ab47 !important;
  color: #ffffff !important;
}
.el-button--success:not(.is-plain):focus {
  background-color: #00a645 !important;
  color: #ffffff !important;
}
.el-button--success:not(.is-plain):active {
  background-color: #008c3b !important;
}
.el-button--success:not(.is-plain).is-disabled, .el-button--success:not(.is-plain).is-disabled:active, .el-button--success:not(.is-plain).is-disabled:focus, .el-button--success:not(.is-plain).is-disabled:hover {
  background-color: rgba(0, 163, 68, 0.2) !important;
  border-color: rgba(0, 163, 68, 0.2) !important;
}

.el-button--purple:not(.is-plain) {
  background-color: #9c27b0 !important;
  border-color: #9c27b0 !important;
  color: #ffffff !important;
}
.el-button--purple:not(.is-plain):hover {
  background-color: #8d239f !important;
  color: #ffffff !important;
}
.el-button--purple:not(.is-plain):focus {
  background-color: #89229b !important;
  color: #ffffff !important;
}
.el-button--purple:not(.is-plain):active {
  background-color: #771e86 !important;
}
.el-button--purple:not(.is-plain).is-disabled, .el-button--purple:not(.is-plain).is-disabled:active, .el-button--purple:not(.is-plain).is-disabled:focus, .el-button--purple:not(.is-plain).is-disabled:hover {
  background-color: rgba(156, 39, 176, 0.2) !important;
  border-color: rgba(156, 39, 176, 0.2) !important;
}

.el-button-group .el-button--success:first-child {
  border-right-color: rgba(255, 255, 255, 0.5) !important;
}

.el-button-group .el-button--success:last-child {
  border-left-color: rgba(255, 255, 255, 0.5) !important;
}

.el-button--success.is-plain {
  border-color: #00BF50 !important;
  color: #00BF50 !important;
  border-color: #00f265;
}
.el-button--success.is-plain:hover {
  background-color: #00ab47 !important;
  color: #ffffff !important;
}
.el-button--success.is-plain:focus {
  background-color: #00a645 !important;
  color: #ffffff !important;
}
.el-button--success.is-plain:active {
  background-color: #008c3b !important;
}
.el-button--success.is-plain.is-disabled, .el-button--success.is-plain.is-disabled:active, .el-button--success.is-plain.is-disabled:focus, .el-button--success.is-plain.is-disabled:hover {
  border-color: rgba(0, 163, 68, 0.2) !important;
}

.el-button--info:hover, .el-button--info:focus {
  background: #a6a9ad !important;
  border-color: #a6a9ad !important;
  color: #fff !important;
}

.el-button--warning:hover, .el-button--warning:focus {
  background: #ebb563 !important;
  border-color: #ebb563 !important;
  color: #fff !important;
}

.el-button--danger:hover, .el-button--danger:focus {
  background: #f78989 !important;
  border-color: #f78989 !important;
  color: #fff !important;
}

.el-step__line-inner {
  -webkit-transition: 0.05s ease-out !important;
  transition: 0.05s ease-out !important;
}

.el-form--label-top .el-form-item__label {
  padding: 0 !important;
  margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label {
  font-weight: bold !important;
}
.el-form--label-top .el-form-item__label {
  line-height: 24px !important;
}

.el-form--label-left .el-form-item__label {
  padding: 0 !important;
  margin-bottom: 0px !important;
}
.el-form--label-left .el-form-item__label {
  font-weight: bold !important;
}

.el-form-item.is-required .el-form-item__label:before {
  display: none !important;
}

.el-form--inline .el-form-item {
  margin-bottom: 0px !important;
}

.el-checkbox {
  margin-bottom: 0 !important;
}

.el-table .material-icons {
  word-break: normal !important;
}

.el-table__empty-text {
  font-size: 26px !important;
  font-weight: bold !important;
  color: rgb(96, 98, 102) !important;
}

.el-loading-mask {
  z-index: 999 !important;
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #00BF50 !important;
  border-color: #00BF50 !important;
  -webkit-box-shadow: -1px 0 0 0 #00BF50 !important;
  box-shadow: -1px 0 0 0 #00BF50 !important;
  font-weight: 600 !important;
}

.el-button.text-md {
  font-size: 1.5rem !important;
}

.el-color-svpanel {
  width: 265px !important;
}

.el-pagination.is-background .el-pager li.active {
  background-color: #00BF50 !important;
}

.el-pagination.is-background .el-pager li.active:hover {
  color: #ffffff !important;
}

.el-pagination.is-background .el-pager li:hover {
  color: #00BF50 !important;
}

.el-radio-button:focus:not(.is-focus):not(:active) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bold-label .el-form-item__label {
  font-weight: 400 !important;
  color: #090A0D !important;
}

.right-align .el-input__inner {
  text-align: right !important;
  font-size: 14px !important;
}
.right-align .el-switch {
  float: right !important;
  margin-right: 10px !important;
}
.right-align .popper__arrow {
  left: auto !important;
  right: 35px !important;
}
.right-align .el-select__input {
  text-align: right !important;
}
.right-align .el-select__input:hover {
  cursor: pointer !important;
}

.no-bottom-margin .el-form-item__content, .lean-bottom-margin .el-form-item__content, .no-bottom-margin .el-form-item__label, .lean-bottom-margin .el-form-item__label {
  line-height: 25px !important;
}
.no-bottom-margin .el-input__inner, .lean-bottom-margin .el-input__inner {
  height: 25px !important;
}
.no-bottom-margin .el-input--mini .el-input__inner, .lean-bottom-margin .el-input--mini .el-input__inner {
  height: 28px !important;
}
.no-bottom-margin .el-form-item, .lean-bottom-margin .el-form-item {
  margin-bottom: 0px !important;
}

.lean-bottom-margin .el-form-item {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.fixed-line-height .el-form-item__content {
  line-height: 1em !important;
}

.no-bottom-margin.compact .el-form-item__content, .compact.lean-bottom-margin .el-form-item__content, .no-bottom-margin.compact .el-form-item__label, .compact.lean-bottom-margin .el-form-item__label {
  line-height: 25px !important;
}
.no-bottom-margin.compact .el-input__inner, .compact.lean-bottom-margin .el-input__inner {
  height: 26px !important;
  line-height: 26px;
}
.no-bottom-margin.compact .el-form-item__label, .compact.lean-bottom-margin .el-form-item__label {
  margin-bottom: 0rem !important;
}
.no-bottom-margin.compact .el-date-editor--daterange.el-input__inner, .compact.lean-bottom-margin .el-date-editor--daterange.el-input__inner {
  top: -2px !important;
  width: 195px !important;
}

.no-bottom-margin .el-date-editor .el-range__icon, .lean-bottom-margin .el-date-editor .el-range__icon, .no-bottom-margin .el-date-editor .el-range-separator, .lean-bottom-margin .el-date-editor .el-range-separator, .no-bottom-margin .el-date-editor .el-range__close-icon, .lean-bottom-margin .el-date-editor .el-range__close-icon {
  line-height: 32px !important;
}
.no-bottom-margin .el-date-editor.el-input__inner, .lean-bottom-margin .el-date-editor.el-input__inner {
  height: 40px !important;
}

.no-bottom-margin.compact .el-date-editor .el-range__icon, .compact.lean-bottom-margin .el-date-editor .el-range__icon, .no-bottom-margin.compact .el-date-editor .el-range-separator, .compact.lean-bottom-margin .el-date-editor .el-range-separator, .no-bottom-margin.compact .el-date-editor .el-range__close-icon, .compact.lean-bottom-margin .el-date-editor .el-range__close-icon {
  line-height: 16px !important;
}
.no-bottom-margin.compact .el-date-editor.el-input__inner, .compact.lean-bottom-margin .el-date-editor.el-input__inner {
  height: 24px !important;
}

.el-select-dropdown__item.selected {
  color: #00BF50 !important;
}

.el-radio-button {
  margin-bottom: 0 !important;
}

.el-radio-button.is-active .el-radio-button__inner:hover {
  color: #ffffff !important;
}

.wizard .el-form-item__content {
  line-height: 24px !important;
}

.el-upload, .el-upload-dragger {
  width: 100% !important;
}

.sidebar-wrap .el-dropdown {
  width: 100% !important;
}
.sidebar-wrap .el-dropdown .el-button-group .el-button--primary:first-child {
  width: 70% !important;
}

.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
  color: #00BF50 !important;
}

.dialog-padding .el-dialog__body {
  padding: 30px 20px 0px 20px !important;
}

.el-loading-mask.is-fullscreen {
  z-index: 9999 !important;
}

.el-progress-bar__inner {
  transition: all 0.5s ease !important;
}

.auto > .el-dialog {
  min-width: auto;
  width: auto;
}

.el-radio-button__inner:hover {
  color: #00BF50 !important;
}

.el-table th.is-sortable > .cell {
  position: relative !important;
  top: 1px !important;
}

.el-radio-button__inner {
  font-weight: 400 !important;
}

.el-tabs__item:hover {
  color: #0067F4 !important;
}

.el-tabs__item.is-active {
  font-weight: 600 !important;
}

.el-tabs__active-bar {
  background-color: #0067F4 !important;
}

.el-radio__input.is-checked + .el-radio__label:not(.custom-red-radio .el-radio__label) {
  color: #00A344 !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #00A344 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #00A344 !important;
  border-color: #00A344 !important;
}

.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: #00BF50 !important;
}

.el-textarea__inner:focus {
  border-color: #00BF50 !important;
}

.el-button {
  font-weight: 400 !important;
  font-size: 1rem !important;
}
.el-button.el-button--mini, .el-button.el-button--small {
  font-size: 14px !important;
}

.el-loading-spinner .path {
  stroke: #00BF50 !important;
}

.el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: 0 0 2px 2px #00A344 inset !important;
  box-shadow: 0 0 2px 2px #00A344 inset !important;
}

.el-switch.is-checked .el-switch__core {
  border-color: #00A344;
  background-color: #00A344;
  border: 1px solid #DCDFE6 !important;
}

.el-upload-dragger .el-upload__text em {
  color: #00A344 !important;
}

.el-upload-dragger:hover {
  border-color: #00A344 !important;
}

.el-radio__input.is-checked .el-radio__inner:not(.custom-red-radio .el-radio__inner) {
  border-color: #00A344 !important;
  background: #00A344 !important;
}

.el-progress.is-success .el-progress-bar__inner {
  background-color: #00BF50 !important;
}

.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ffffff !important;
  color: #00BF50 !important;
}

.el-breadcrumb__inner a:hover, .el-breadcrumb__inner:hover {
  color: #00A344 !important;
}

.el-alert__closebtn {
  top: calc(50% - 6px) !important;
}

.el-tabs__item {
  font-weight: 400 !important;
}

.el-table td, .el-table th {
  max-width: 0px;
}

.el-table--border::after, .el-table--group::after, .el-table::before {
  background-color: transparent !important;
}

.el-table__empty-block {
  min-height: 400px !important;
}
.el-table__empty-block.no-min-height {
  min-height: 0 !important;
}

.el-notification {
  width: 390px !important;
}

.el-notification.big {
  width: 475px !important;
}

.el-notification__content {
  text-align: left !important;
}

.el-popover {
  text-align: left !important;
  word-break: break-word !important;
  line-height: 1.75 !important;
}

.el-popover__title {
  font-weight: 600 !important;
}

.csat-table tr.el-table__row {
  cursor: pointer;
}

.el-button span {
  pointer-events: none;
}

.el-tooltip__popper {
  font-size: 14px !important;
  line-height: 18px;
}

.el-dialog__header {
  font-weight: bold !important;
  border-bottom: 0;
}
.el-dialog__header h5 {
  color: #232323;
}

.el-message-box__header {
  border-bottom: 1px solid #dedede;
}

.el-message-box__content {
  padding-top: 2rem;
}

.el-radio.is-bordered.is-checked:not(.custom-red-radio) {
  border-color: #00A344 !important;
}

.text-lg .el-radio.is-bordered {
  padding: 9px 20px 0 10px !important;
}
.text-lg .el-radio__label {
  font-size: 18px;
}

.el-button {
  max-height: 44px;
}

.el-input-number__decrease:hover, .el-input-number__increase:hover {
  color: #00A344 !important;
}

.el-input-number__decrease:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled) {
  border-color: #00A344 !important;
}

.el-table__expand-icon > .el-icon {
  font-size: 16px;
}

.filter-select .el-input .el-select__caret, .filter-select-contacts .el-input .el-select__caret {
  line-height: 16px !important;
}

.el-tabs__item {
  padding: 0px 30px !important;
}

.el-transfer__button {
  width: 40px;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.el-transfer-panel__item:hover {
  color: #00A344 !important;
}

.el-notification__content {
  margin: 0 !important;
}

.el-notification__group {
  margin-right: 13px !important;
}

.el-popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: #e4e7ed !important;
}

.el-popper[x-placement^=top] .popper__arrow {
  border-top-color: #e4e7ed !important;
}

.dark-mode.el-popper {
  background: #090A0D !important;
  border: 1px solid #090A0D !important;
  color: #ffffff !important;
  box-shadow: 0 2px 12px 0 rgba(9, 10, 13, 0.1) !important;
}
.dark-mode.el-popper[x-placement^=top] .popper__arrow {
  border-top-color: #090A0D !important;
}
.dark-mode.el-popper[x-placement^=top] .popper__arrow:after {
  border-top-color: #090A0D !important;
}
.dark-mode .el-select {
  background-color: #1e212b !important;
  border: 1px solid #1e212b !important;
  color: #ffffff !important;
}
.dark-mode .el-select .el-input__inner {
  background-color: #1e212b !important;
  border: 1px solid #1e212b !important;
  color: #ffffff !important;
}
.dark-mode .el-button--success:not(.is-plain).is-disabled {
  background-color: #00BF50 !important;
  border-color: #00BF50 !important;
}

.el-date-editor .el-range-separator {
  width: 10% !important;
}

.el-table .cell {
  word-break: break-word !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: #ffffff !important;
  background: #00BF50 !important;
}

.el-tabs__header {
  margin: 0px !important;
}

.el-tabs__content {
  background: #ffffff !important;
  padding-top: 15px !important;
}

.big-screen:not(.force-full-height) .full-height > .el-tabs__content, .big-screen:not(.force-full-height) .max-height > .el-tabs__content {
  height: 100%;
}
.big-screen.force-full-height .full-height > .el-tabs__content, .big-screen.force-full-height .max-height > .el-tabs__content {
  height: calc(100vh - 25px);
}
.big-screen .el-tabs__content {
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
}
.big-screen .el-badge__content.is-fixed.is-dot {
  right: -5px !important;
  top: 5px !important;
}

.embedded-screen .full-height > .el-tabs__content, .embedded-screen .max-height > .el-tabs__content {
  height: calc(100vh - 390px);
}
.embedded-screen .sidebar-full-height {
  height: calc(100vh - 390px);
}

.contact-details .el-collapse-item__arrow {
  position: relative;
  float: left;
  top: 18px;
  left: -11px;
  margin: 0 !important;
}
.contact-details .el-collapse-item > div {
  display: table;
  width: 100%;
}
.contact-details .el-collapse-item__header {
  display: table-cell !important;
  font-size: 14px !important;
  vertical-align: middle;
  padding-left: 25px;
}
.contact-details .el-collapse-item__content {
  padding-bottom: 10px;
  width: 362px;
}

.call-communication-info .el-collapse-item__content {
  padding-bottom: 5px;
}

.el-message-box__message p {
  line-height: 26px;
  font-size: 18px;
}

.el-notification__title {
  font-size: 17px !important;
}

.el-notification__content {
  margin-top: 0.25em !important;
  font-size: 15px !important;
  line-height: 22px !important;
}

.lean .el-card__header {
  padding: 0.5rem 0.5rem !important;
}
.lean .el-card__body {
  padding: 0.5rem 0rem !important;
}

.small-paddings .el-card__body, .small-paddings .el-card__header {
  padding: 8px 10px !important;
}

.el-card__body {
  word-break: break-word;
}

.el-card strong {
  font-size: 19px;
}

.el-button--light-blue-500 {
  background: #03a9f4 !important;
  color: #ffffff !important;
}
.el-button--light-blue-500:not([disabled]):hover, .el-button--light-blue-500:not([disabled]):focus, .el-button--light-blue-500:not([disabled]).active {
  box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
}

.el-button--deep-orange-500 {
  background: #ff5722 !important;
  color: #ffffff !important;
}
.el-button--deep-orange-500:not([disabled]):hover, .el-button--deep-orange-500:not([disabled]):focus, .el-button--deep-orange-500:not([disabled]).active {
  box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
}

.mega-dropdown-menu span.el-select__tags-text {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  float: left;
}

.el-message {
  font-size: 22px !important;
  font-weight: 500;
}
.el-message .el-message--content {
  font-size: 22px !important;
}

.el-button span > i.material-icons {
  line-height: 0.75em;
  height: auto;
}

.el-select-dropdown__item {
  padding: 0 0.75rem 0 0.75rem !important;
  border-left: 3px solid transparent;
  white-space: normal !important;
  min-height: 34px !important;
  height: auto !important;
  font-size: 14px !important;
}
.el-select-dropdown__item .media .media-body {
  padding: 0.5rem 0.5rem 0.5rem 0 !important;
  border-left: 3px solid transparent;
  font-weight: 400 !important;
  border-bottom: 1px solid #f9f9f9;
  line-height: 1.6;
}
.el-select-dropdown__item .media .media-body label {
  font-weight: bold;
  display: block;
  margin: 0;
}
.el-select-dropdown__item.selected {
  border-left: 3px solid #00BF50 !important;
}
.el-select-dropdown__item.selected .media .media-body {
  color: #656565 !important;
}
.el-select-dropdown__item.selected .media .media-body label {
  color: #00BF50 !important;
}
.el-select-dropdown__item.hover {
  background-color: rgba(212, 237, 218, 0.3) !important;
  color: #00BF50 !important;
}

.v-modal {
  background: rgba(0, 0, 0, 0.4) !important;
}

.el-radio {
  margin-right: 10px !important;
}

.el-radio-button--small .el-radio-button__inner {
  font-size: 14px !important;
}

.el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0 !important;
}

.el-input--small .el-input__inner {
  height: 34px !important;
  line-height: 32px !important;
}

.ivr-timeline .el-timeline-item__wrapper {
  padding-top: 1.5em;
  top: 2px !important;
}
.ivr-timeline .el-timeline-item__dot {
  left: -15px;
  z-index: 100;
}
.ivr-timeline .el-timeline-item__timestamp.is-top {
  display: none !important;
}
.ivr-timeline .el-form-item__label {
  font-size: 16px !important;
}
.ivr-timeline .form-hover-help {
  line-height: 1 !important;
}

.user_timeline .el-timeline-item__wrapper .el-timeline-item__timestamp {
  font-weight: 400 !important;
  color: #0e1015;
}

.timeline-dot {
  width: 40px;
  height: 20px;
  position: relative;
  top: -2px;
}
.timeline-dot .el-timeline-item__node--xlarge {
  left: 10px;
  width: 20px;
  height: 20px;
}

.el-tabs__content {
  overflow: visible !important;
}

.el-menu.no-border {
  border: 0 !important;
}

.el-transfer-panel__item.el-checkbox {
  display: inherit;
}

.el-collapse.fullscreen {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border: 0;
}
.el-collapse.fullscreen .el-collapse-item:first-child {
  border-style: solid;
  border-color: #EBEEF5;
  border-width: 1px 1px 0 1px;
}
.el-collapse.fullscreen .el-collapse-item:not(:first-child) {
  border: 1px solid #EBEEF5;
}
.el-collapse.fullscreen .el-collapse-item:last-child {
  border-style: solid;
  border-color: #EBEEF5;
  border-width: 0 1px 1px 1px;
}
.el-collapse.fullscreen .el-collapse-item__header {
  padding-left: 1em;
  border-bottom: 0;
}
.el-collapse.fullscreen .el-collapse-item__content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 0 !important;
}
.el-collapse.fullscreen .el-collapse-item__content > div {
  padding: 0 1em 0 1em;
}
.el-collapse.fullscreen .el-collapse-item__wrap {
  border-bottom: 0;
}
.el-collapse.fullscreen .el-collapse-item:not(.is-active) .el-collapse-item__content {
  display: none;
}
.el-collapse.fullscreen .el-collapse-item.is-active {
  display: flex;
  flex-direction: column;
  height: calc(100% - 199px);
}
.el-collapse.fullscreen .el-collapse-item.is-active .el-collapse-item__header {
  border-bottom: 1px solid #EBEEF5;
}
.el-collapse.fullscreen .el-collapse-item.is-active .el-collapse-item__wrap {
  height: 100%;
}

.el-form-item__label {
  margin-bottom: 0;
}

.el-radio, .el-radio__input {
  white-space: inherit !important;
  word-break: break-word !important;
}

.el-table .cell {
  text-overflow: initial !important;
}

.el-button--mini, .el-button--small {
  font-size: 14px;
}

.el-collapse-item__header {
  font-size: 14px !important;
}

.el-collapse-item__content {
  font-size: 14px !important;
}

.border-0 .el-collapse-item__header {
  border: 0 !important;
}
.border-0 .el-collapse-item__wrap {
  border: 0 !important;
}
.border-0 .el-collapse-item__arrow {
  margin: 0 15px 0 auto !important;
}

.el-tabs.no-header .el-tabs__header {
  display: none;
}
.el-tabs.no-header .el-tabs__content {
  padding-top: 0px !important;
}

.el-tabs.with-header .el-tabs__header {
  display: block;
}

.el-divider__text.is-center {
  text-align: center;
  padding: 0.5rem !important;
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
}

.nav-active-greenish .nav-link.active, .nav-active-greenish .nav > li.active > a {
  font-weight: 600 !important;
}

.item > .el-badge__content {
  font-size: 16px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  z-index: 1;
}

.el-button-group .el-button--info.is-plain:first-child {
  border-left-color: #d3d4d6 !important;
}

.el-button-group .el-button--info.is-plain:last-child {
  border-left-color: #d3d4d6 !important;
}

.el-icon-arrow-down:before {
  vertical-align: -1px;
}

.el-form-item__content {
  line-height: 32px !important;
}

.el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1) !important;
}

.el-checkbox {
  white-space: normal !important;
}

.el-checkbox__input {
  display: block !important;
  float: left !important;
  line-height: 1.6em !important;
}

.el-checkbox__label {
  display: block !important;
  margin-left: 15px !important;
}

.el-divider.no-border .el-divider__text {
  border: none !important;
}

.el-button--text.is-disabled {
  color: #e0e0e0 !important;
}

.el-transfer-panel__header {
  width: 100%;
  display: flex;
  align-items: center;
}
.el-transfer-panel__header .el-checkbox {
  width: 100%;
}

.el-transfer-panel__list {
  overflow-x: hidden !important;
}

.el-checkbox.is-bordered {
  display: inline-flex;
  align-items: center;
}
.el-checkbox.is-bordered .el-checkbox__input {
  line-height: 1 !important;
}

.el-border-checkbox-danger.is-checked {
  color: #e44f37 !important;
  border: 1px solid #e44f37 !important;
  background-color: #faebee !important;
}
.el-border-checkbox-danger.is-checked .el-checkbox__inner {
  background-color: #e44f37 !important;
  border-color: #e44f37 !important;
}
.el-border-checkbox-danger.is-checked .el-checkbox__label {
  color: #e44f37 !important;
}

.checkbox-md .el-checkbox__label {
  font-size: 16px !important;
}

.el-table.activity-reporting-table td, .el-table.activity-reporting-table th {
  padding: 5px 0;
  vertical-align: top;
}

.el-select .el-tag {
  color: #67C23A !important;
  background: #f0f9eb !important;
  border-color: #89E45C !important;
  border-radius: 2px !important;
  font-size: 14px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  box-shadow: none !important;
  font-weight: 600;
  word-wrap: break-word;
  max-width: 90%;
  white-space: initial;
  height: auto;
}
.el-select .el-tag .el-tag__close {
  background-color: #67C23A !important;
  color: #fff !important;
}
.el-select .el-tag .el-tag__close:hover {
  background-color: #00A344 !important;
}

.webrtc-communication-tags .el-select.tag-selector {
  max-width: 265px;
}

.is-active.pause-deep-orange .el-radio-button__inner {
  background-color: #ff5722 !important;
  border-color: #ff5722 !important;
  box-shadow: -1px 0 0 0 #ff5722 !important;
}

.expand-view-btn {
  color: #6c757d !important;
  background-color: #fbfbfb !important;
  border: none;
}

.shrink-view-btn {
  color: #00BF50 !important;
  background-color: #fbfbfb !important;
  border: none;
}

.el-select .el-input__inner:active, .el-select .el-input__inner:focus {
  border-color: #00BF50 !important;
}

.el-input.is-focus .el-input__inner {
  border-color: #00BF50 !important;
}

.el-tree-node.is-expanded .el-tree-node__children .el-tree-node {
  white-space: initial;
}
.el-tree-node.is-expanded .el-tree-node__children .el-tree-node .el-tree-node__content {
  height: auto;
}

.el-button--deep-orange.is-plain:focus, .el-button--deep-orange.is-plain:hover {
  background: #ffffff !important;
  border-color: #ff5722 !important;
  color: #ff5722 !important;
}

div.simpsocial-email iframe {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.el-alert .el-alert__description {
  font-size: 14px !important;
  font-weight: 600;
}

.kyc-review {
  overflow: auto;
  max-height: 80vh;
}
.kyc-review .el-button-group > .el-button:first-child {
  min-width: 150px;
}
.kyc-review .status-KYC_STATUS_ONE .el-button {
  background-color: white;
  color: #737373;
  border: 1px solid #737373;
}
.kyc-review .status-KYC_STATUS_ONE .el-button:hover {
  background-color: rgb(175, 175, 175);
  color: white;
}
.kyc-review .status-KYC_STATUS_REJECTED .el-button {
  background-color: white;
  color: #e22400;
  border: 1px solid #e22400;
}
.kyc-review .status-KYC_STATUS_REJECTED .el-button:hover {
  background-color: #e22400;
  color: white;
}
.kyc-review .status-KYC_STATUS_PENDING .el-button {
  background-color: white;
  color: #d58400;
  border: 1px solid #d58400;
}
.kyc-review .status-KYC_STATUS_PENDING .el-button:hover {
  background-color: #d58400;
  color: white;
}
.kyc-review .status-KYC_STATUS_APPROVED .el-button {
  background-color: white;
  color: #2684ff;
  border: 1px solid #2684ff;
}
.kyc-review .status-KYC_STATUS_APPROVED .el-button:hover {
  background-color: #2684ff;
  color: white;
}
.kyc-review .status-KYC_STATUS_FRAUD_SUSPECTED .el-button {
  background-color: white;
  color: #e22400;
  border: 1px solid #e22400;
}
.kyc-review .status-KYC_STATUS_FRAUD_SUSPECTED .el-button:hover {
  background-color: #e22400;
  color: white;
}
.kyc-review .status-STATUS_VERIFIED_FULL .el-button {
  background-color: white;
  color: #91e641;
  border: 1px solid #91e641;
}
.kyc-review .status-STATUS_VERIFIED_FULL .el-button:hover {
  background-color: #669c35;
  color: white;
}
.kyc-review .status-badge {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}
.kyc-review .status-badge-KYC_STATUS_ONE {
  background-color: #737373 !important;
  border: 1px solid #737373 !important;
}
.kyc-review .status-badge-KYC_STATUS_REJECTED {
  background-color: #e22400 !important;
  border: 1px solid #e22400 !important;
}
.kyc-review .status-badge-KYC_STATUS_PENDING {
  background-color: #d58400;
  border: 1px solid #d58400;
}
.kyc-review .status-badge-KYC_STATUS_APPROVED {
  background-color: #2684ff;
  border: 1px solid #2684ff;
}
.kyc-review .status-badge-KYC_STATUS_FRAUD_SUSPECTED {
  background-color: #e22400 !important;
  border: 1px solid #e22400 !important;
}
.kyc-review .status-badge-STATUS_VERIFIED_FULL {
  background-color: #91e641;
  border: 1px solid #91e641;
}
.kyc-review .status-display {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
  min-width: 400px;
  border-radius: 30px;
  padding-right: 120px;
  padding-left: 14px;
  font-size: 16px;
  position: relative;
  z-index: 1;
}
.kyc-review .status-display-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.0025em;
  text-align: left;
  margin-bottom: 0px;
  color: #040404;
}
.kyc-review .status-display__button {
  display: flex;
  background: #256EFF;
  color: white;
  border-radius: 30px;
  width: 64px;
  height: 35px !important;
  margin-left: -40px;
  align-items: center;
  padding-right: 75px;
  position: relative;
  z-index: 2;
}
.kyc-review .status-display__button.is-disabled {
  opacity: 100%;
}
.kyc-review .status-display__button.el-button.is-loading {
  width: 100px !important;
}
.kyc-review .status-display-KYC_STATUS_ONE {
  border: 1px solid #737373;
  color: #737373;
}
.kyc-review .status-display-KYC_STATUS_REJECTED {
  border: 1px solid #e22400;
  color: #e22400;
}
.kyc-review .status-display-KYC_STATUS_PENDING {
  border: 1px solid #d58400;
  color: #d58400;
}
.kyc-review .status-display-KYC_STATUS_APPROVED {
  border: 1px solid #2684ff;
  color: #2684ff;
}
.kyc-review .status-display-STATUS_VERIFIED_FULL {
  border: 1px solid #91e641;
  color: #91e641;
}
.kyc-review .status-display-KYC_STATUS_FRAUD_SUSPECTED {
  border: 1px solid #e22400;
  color: #e22400;
}
.kyc-review .details .container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.kyc-review .details .container > div {
  width: 32%;
  margin-bottom: 20px;
  margin-right: 20px;
}
.kyc-review .details .table {
  max-height: 30vh;
}
.kyc-review .details .account-log-table {
  max-height: 30vh;
}
.kyc-review .details .account-log-table .el-table__header, .kyc-review .details .account-log-table .el-table__body {
  border: 1px solid #D8D8D8 !important;
}
.kyc-review .details .account-log-table .el-table__header {
  border-bottom: none !important;
}
.kyc-review .details .account-log-table .el-table__body {
  border-top: none !important;
}
.kyc-review .details .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.kyc-review .details .col-span-2 {
  grid-column: span 2;
}
.kyc-review .details .col-span-3 {
  grid-column: span 3;
}
.kyc-review .link {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #256EFF;
  cursor: pointer;
}
.kyc-review .kyc-full-risk-evaluation {
  display: flex;
  border: 1px solid #D8D8D8 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.kyc-review .kyc-full-risk-evaluation .el-tree-node__label {
  font-size: 16px;
}
.kyc-review .note__time {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.0025em;
  text-align: left;
  color: #040404;
  padding-left: 10px;
  margin-bottom: 0;
}
.kyc-review .note__item {
  margin-top: 10px;
  border-radius: 30px;
  border: 1px solid var(--grey-70, #D8D8D8);
  padding: 12px 31px;
}
.kyc-review .note .action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.kyc-review .note .action > button {
  background-color: white;
  border: none;
  color: #256EFF;
  cursor: pointer;
}
.kyc-review .note .action > button:hover {
  background-color: white;
  color: rgba(37, 110, 255, 0.6941176471);
}
.kyc-review .add-note {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 22px;
  margin-right: 12px;
  font-size: 16px;
}
.kyc-review .add-note > button {
  background-color: white;
  border: none;
  color: #256EFF;
  cursor: pointer !important;
}
.kyc-review .add-note > button:hover {
  background-color: white;
  color: rgba(37, 110, 255, 0.6941176471);
}
.kyc-review .el-table__empty-block {
  max-height: 210px !important;
  min-height: 210px !important;
  max-width: 1500px !important;
}
.kyc-review .min-w-60 {
  min-width: 60% !important;
}

.account-registration .el-button {
  background-color: #256EFF !important;
  color: #fff !important;
  border-radius: 30px !important;
  text-transform: none;
}
.account-registration .el-button:hover {
  background-color: #3b79f5 !important;
  border-color: #3b79f5 !important;
  color: #FFFFFF !important;
}
.account-registration .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266 !important;
}
.account-registration .el-checkbox__input.is-checked .el-checkbox__inner, .account-registration .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0067F4 !important;
  border-color: #0067F4 !important;
}

.aloai-agents .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
}
.aloai-agents .el-tabs__nav {
  display: flex;
  float: none;
}
.aloai-agents .el-card.is-always-shadow, .aloai-agents .el-card.is-hover-shadow:focus, .aloai-agents .el-card.is-hover-shadow:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}
.aloai-agents .el-radio.is-bordered.is-checked:not(.custom-red-radio) {
  border-color: #0067F4 !important;
}
.aloai-agents .el-radio__input.is-checked + .el-radio__label:not(.custom-red-radio .el-radio__label) {
  color: inherit !important;
}
.aloai-agents .el-tabs__header {
  position: sticky;
  top: 178px;
  z-index: 2;
  background: white;
}
.aloai-agents .el-tabs__content {
  position: static;
  padding: 0 !important;
  z-index: 1;
}

.app .container-fluid {
  padding-left: 12px;
  padding-right: 12px;
}

.app .container {
  padding-left: 12px;
  padding-right: 12px;
}

.dashboard-intro {
  padding: 15px 15px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
}

ul, menu, dir {
  padding-inline-start: 1.5em;
}

.navbar-brand {
  text-align: center;
}

.navbar-brand img, .navbar-brand svg {
  max-height: 32px;
}

.navbar-brand > span {
  color: #ffffff;
}

.loader {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.navbar-brand, .navbar-item, .navbar-nav > .nav-item > .nav-link, .navbar-nav > .nav-link {
  padding: 0;
  white-space: nowrap;
}

.navbar {
  border: none;
  margin: 0;
  padding: 0 1rem;
  flex-shrink: 0;
  min-height: 3.2rem;
}

.fade-enter-active {
  -webkit-transition: opacity 0.2s ease-in;
  -moz-transition: opacity 0.2s ease-in;
  -ms-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}

.fade-enter {
  opacity: 0;
}

.login {
  padding-top: 100px !important;
}

.login-brand {
  padding: 0;
  white-space: nowrap;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.login-brand img, .login-brand svg {
  max-width: 200px;
  vertical-align: -6px;
  display: inline-block;
}

.login-brand > span {
  display: inline-block;
  line-height: 0.8;
  margin-left: 10px;
}

.login .el-form-item__label {
  line-height: 30px;
}

.login .el-form-item {
  margin-bottom: 15px;
}

.login .el-button {
  height: 40px;
}

.login #errors p {
  margin-bottom: 0px;
}

.navbar-brand img,
.navbar-brand svg {
  vertical-align: -10px;
}

.w-xxxl {
  width: 640px;
}

.btn {
  font-weight: 400;
  cursor: pointer;
}

.btn > i.material-icons.pull-left, .btn > i.material-icons.pull-right {
  line-height: 1.25;
}

.navside .nav li li a {
  padding-left: 2.8rem;
}

.nav-icon {
  margin-right: 0.5rem;
}

.campaigns {
  margin-bottom: 5px;
}

.top-border-gray {
  border-top: 1px solid #f3f3f4;
}

.right-border-gray {
  border-right: 1px solid #f3f3f4;
}

.bottom-border-gray {
  border-bottom: 1px solid #f3f3f4;
}

.left-border-gray {
  border-left: 1px solid #f3f3f4;
}

.lead {
  word-break: keep-all;
}

#logout-button {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
}

#logout-button a {
  padding: 0;
  color: #7D7D7D;
  line-height: 60px;
}

#logout-button:hover a {
  color: #ffffff;
  line-height: inherit;
}

#logout-button a > i {
  line-height: 60px;
}

#logout-button span {
  position: relative;
  top: -2px;
}

.mini-navbar #logout-button {
  width: 70px;
}

.navside .nav li.active.router-link-active {
  pointer-events: none;
  cursor: default;
}

.app-aside .navside .nav li .nav-icon {
  display: block;
}
.app-aside .navside .nav li .nav-icon.active {
  display: none;
}
.app-aside .navside .nav li.active .nav-icon {
  display: none;
}
.app-aside .navside .nav li.active .nav-icon.active {
  display: block;
}

.form-head {
  margin-bottom: 0;
  margin-top: 1rem;
}

.form-head-message {
  font-weight: 400;
  color: #090A0D;
  margin-bottom: 0;
}

.form-hover-help {
  border-bottom: dashed 1px rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54);
  line-height: 40px;
  cursor: pointer;
}

.centered-content {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wizard-header h2, .wizard-header h3, .wizard-header h4 {
  color: #090A0D;
}

.wizard .area-code {
  width: 70px;
}

.cursor-pointer, .status-icon {
  cursor: pointer;
}

.w-72 {
  width: 72px;
  height: 72px;
  line-height: 72px;
  border-radius: 50%;
}

.w-200 {
  width: 200px;
  height: 200px;
  line-height: 200px;
  border-radius: 50%;
}

.w-300 {
  width: 300px;
  height: 300px;
  line-height: 300px;
  border-radius: 50%;
}

.filter-call-logs > div:not(:last-child) {
  margin-left: 10px;
}
.filter-call-logs .dropdown-toggle::after {
  display: none;
}
.filter-call-logs .filter-item {
  padding: 5px 1rem;
}
.filter-call-logs .dropdown-item {
  padding: 5px 1rem;
}
.filter-call-logs .dropdown-item > i {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

/*==================================================
 * Effect 8
 * ===============================================*/
.effect7 {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
}

.effect8 {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
}

ol.special-ol {
  counter-reset: li; /* Initiate a counter */
  margin-left: 0; /* Remove the default left margin */
  padding-left: 0; /* Remove the default left padding */
}

ol.special-ol > li {
  position: relative; /* Create a positioning context */
  margin: 0 0 6px 2em; /* Give each list item a left margin to make room for the numbers */
  padding: 4px 8px; /* Add some spacing around the content */
  list-style: none; /* Disable the normal item numbering */
}

ol.special-ol > li:before {
  content: counter(li); /* Use the counter as content */
  counter-increment: li; /* Increment the counter by 1 */
  /* Position and style the number */
  position: absolute;
  top: -1px;
  left: -1.1em;
  /* Some space between the number and the content in browsers that support
     generated content but not positioning it (Camino 2 is one example) */
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
}

ol.special-ol > li > h1, h2, h3, h4, h5, h6 {
  color: rgba(0, 0, 0, 0.54);
}

.no-top-margin {
  margin-top: 0;
}

.mt--1 {
  margin-top: -1rem;
}

.no-overflow {
  overflow: unset;
}

.v-align-top {
  vertical-align: top;
}

.help-card {
  border: 1px solid #00BF50 !important;
  box-shadow: none !important;
  background-color: #DBF6E6 !important;
  color: #090A0D !important;
}

.warning-card {
  border: 1px solid #F2C94C !important;
  box-shadow: none !important;
  background-color: rgba(242, 201, 76, 0.08) !important;
  color: #090A0D !important;
}

.notif-card {
  border: 1px solid #0067F4 !important;
  box-shadow: none !important;
  background-color: rgba(0, 103, 244, 0.1) !important;
  color: #090A0D !important;
}

.pagination > li.disabled > a {
  cursor: default;
}

.box-body, .box-header {
  background-color: #ffffff;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.row-filter .el-radio__input {
  display: none;
}

.row-filter .el-radio {
  margin-left: 0px !important;
  margin-right: 7px;
}
.row-filter .el-radio:last-child {
  margin-right: 0px;
}

.row-filter .el-radio__label {
  padding-left: 0px;
}

.btn.b-blackish:focus {
  background-color: transparent !important;
  color: #090A0D !important;
}

.btn.b-bluish.dropdown-toggle:focus {
  background-color: transparent !important;
  color: #0033A0 !important;
}

.btn.b-greenish.dropdown-toggle:focus {
  background-color: transparent !important;
  color: #00BF50 !important;
}

.btn.b-dark-greenish.dropdown-toggle:focus {
  background-color: transparent !important;
  color: #00A344 !important;
}

.btn.white.dropdown-toggle:focus, .btn.white.dropdown-toggle:hover {
  -webkit-box-shadow: inset 0 -10rem 0px #ffffff;
  box-shadow: inset 0 -10rem 0px #ffffff;
}

.app-body .box, .box-color {
  margin-bottom: 0;
}

.p-inline-block {
  display: inline-block !important;
  line-height: normal;
  position: relative;
  top: 3px;
  text-align: right;
  color: #090A0D;
}

.company-name {
  display: inline-block;
}

.nav-link.dropdown-toggle::after {
  display: none;
}

.nav-link.dropdown-toggle {
  line-height: normal;
}

.nav-item > .dropdown-menu {
  padding: 10px 20px;
  min-width: 14rem;
}

.nav-item > .dropdown-menu {
  padding: 10px 0px;
}

.dropdown-item {
  padding: 10px 1.5rem;
}

.dropdown-item > i {
  margin-right: 10px;
}

.nav-padding {
  padding: 0px 11px;
}

.header-padding {
  padding: 1rem 1.5rem;
}

.add-campaign {
  position: relative;
  text-align: center;
  display: block;
  width: 200px;
  margin: 10px auto;
}

.navside .nav > li {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.navside .nav > li.campaigns {
  padding-top: 0;
  padding-bottom: 0;
}

.navside .nav > li#logout-button {
  padding-top: 0;
  padding-bottom: 0;
}

.app-footer {
  padding: 0 1.5rem;
}
.app-footer .footer {
  z-index: 1;
}

.app-footer .nav-link {
  padding: 0 0;
}

.mega-dropdown {
  width: 100%;
}

.mega-dropdown-menu {
  padding: 0.9rem 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  width: 500px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.filter-select, .filter-select-contacts {
  width: 100%;
}
.filter-select .el-input__inner, .filter-select-contacts .el-input__inner {
  border: none !important;
  color: #00A344 !important;
}
.filter-select .el-input .el-select__caret, .filter-select-contacts .el-input .el-select__caret {
  font-size: 16px !important;
  color: #00A344 !important;
}
.filter-select input::placeholder, .filter-select-contacts input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #00A344;
  opacity: 1; /* Firefox */
}
.filter-select input:-ms-input-placeholder, .filter-select-contacts input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #00A344;
}
.filter-select input::-ms-input-placeholder, .filter-select-contacts input::-ms-input-placeholder { /* Microsoft Edge */
  color: #00A344;
}
.filter-select input::-webkit-input-placeholder, .filter-select-contacts input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #00A344;
}
.filter-select input::-moz-placeholder, .filter-select-contacts input::-moz-placeholder { /* Firefox 19+ */
  color: #00A344;
}
.filter-select input:-moz-placeholder, .filter-select-contacts input:-moz-placeholder { /* Firefox 18- */
  color: #00A344;
}

.filter-select-contacts {
  width: auto;
}
.filter-select-contacts .el-input__inner {
  border: 1px solid #00A344 !important;
  color: #00A344 !important;
}

.filter-header {
  margin-top: 10px;
  display: block;
}

.filter-header:first-child {
  margin-top: 0px;
}

.filter-button {
  position: relative;
  top: -2px;
}

.filter-div {
  line-height: 1.25;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.4rem 0.25rem;
  border-radius: 0.25rem;
  outline: 0 !important;
  border-color: rgba(120, 130, 140, 0.13);
  height: 40px;
  width: 220px;
  overflow: hidden;
}
.filter-div .el-range-editor i.el-input__icon.el-range__close-icon {
  display: none !important;
}

#view > .padding ~ .padding {
  padding: 0.5rem 1.5rem;
}

#view > .padding {
  padding: 1.5rem 1.5rem;
}

.editr--content a {
  color: #00A344;
}

.form-control-label {
  line-height: 24px;
}

.sidebar-wrap {
  text-align: center;
}

.full-height, .max-height {
  height: 100%;
}

.big-screen.full-height, .big-screen.max-height {
  height: calc(100vh - 55px);
}

.contacts-page.full-height, .contacts-page.max-height {
  height: calc(100vh - 100px);
}

.sidebar-full-height {
  height: 100%;
  flex: 0 0 380px;
}

.max-height {
  position: absolute;
}

.nav-pills .nav-link {
  display: block;
  padding: 0.5em 1em;
  border-radius: 0.25rem;
}

.conversync {
  z-index: 1000;
}
.conversync .nav {
  display: block !important;
}
.conversync .timeline .tl-date {
  width: 6.5em;
  margin-left: -9.5em;
}
.conversync .timeline-center .tl-left .tl-date {
  margin-right: -9.5em;
}
.conversync .timeline-center .tl-left .tl-wrap {
  width: 100%;
}
.conversync .timeline .tl-content {
  width: 100%;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.1);
}
.conversync .form-control-static {
  line-height: 1.5;
}
.conversync .badge-contact {
  float: right;
}
.conversync .badge-contact .el-badge__content.is-fixed {
  top: 20px;
  right: 20px;
}
.conversync .form-control.grey-800:focus {
  color: #ffffff;
}

.campaign-badge {
  height: 12px !important;
}

.caller-status {
  width: 12px;
  height: 12px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  background-color: #6cc788;
  display: inline-block;
}

.hidden {
  visibility: hidden;
}

.dker {
  background-color: rgba(0, 0, 0, 0.1);
}

li.active .nav-icon {
  color: #ffffff;
}

.mt-wizard {
  margin-top: 11px;
}

.wizard-dialog {
  width: 748px !important;
}
.wizard-dialog .el-dialog__body {
  padding: 1rem;
}

.gif-dialog {
  width: 550px !important;
}
.gif-dialog .el-dialog__body {
  padding-top: 0 !important;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.announcement_body {
  font-size: 16px;
}
.announcement_body a {
  color: #00BF50;
}

.text-muted {
  word-break: break-word;
}

.text-medium {
  font-size: 14px;
  color: #303133;
  font-weight: 500;
}

.overview-boxes {
  height: 80px;
}

.integration-boxes {
  max-height: 80px;
  max-width: 150px;
}

.integration-box {
  height: 120px;
}
.integration-box .el-card__body {
  height: 100%;
  padding: 10px;
}
.integration-box .el-card__body .row {
  height: 100%;
}

.dn_item + .dn_item:before {
  content: ", ";
}

.ins_item + .ins_item:before {
  content: ", ";
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
ul.nav.card {
  background-color: inherit !important;
  border: none;
  border-radius: 0;
}

.blink, .mockup {
  animation: fade 1500ms infinite;
  -webkit-animation: fade 1500ms infinite;
}

.btn {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.filters {
  display: block;
}

.filter-input {
  max-width: 300px;
  font-size: 14px;
  padding: 0.2rem 0.75rem;
}

.placeholder {
  display: block;
  width: 100%;
  height: 100%;
}

.placeholder img {
  display: block;
  margin: auto;
  height: 100%;
}

.welcome-head {
  font-family: "Quicksand";
  font-size: 35px;
  font-weight: 600;
  color: #090A0D;
  text-align: center;
}

.welcome-message {
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 400;
  color: #090A0D;
  text-align: center;
}

.fixed-footer {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  margin: inherit;
}
.fixed-footer > .row > .col-12 {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0px 2px rgba(0, 0, 0, 0.24);
}

.fixed-header {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 3.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.fixed-header > .row > .col-12, .fixed-header > .row > .d-flex {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.mt-lg {
  margin-top: 80px;
}

.data-switch {
  position: absolute;
  top: 11px;
  right: 11px;
  z-index: 1;
}

.card-bottom {
  position: absolute;
  bottom: 1rem;
}

.clear-both {
  clear: both;
}

.large-arrow .el-collapse-item__arrow {
  font-size: 32px;
}

input[type=radio]:hover, label[for] {
  cursor: pointer;
}

.app-aside .left {
  overflow: hidden;
}

li.filters a.btn {
  opacity: 0.25;
}
li.filters:hover a.btn {
  opacity: 1;
}

.el-btn {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
  padding: 12px 20px;
  max-height: 42px;
}
.el-btn i.material-icons {
  line-height: 0.95em !important;
}
.el-btn i.fa {
  line-height: 0.95em !important;
}

.full-font-size {
  font-size: 100%;
}

div.pointer:hover {
  background: rgba(0, 0, 0, 0.03);
}

.contacts {
  height: 100%;
  padding-top: 45px;
}
.contacts .el-tabs__content {
  height: 100%;
}
.contacts .el-tab-pane {
  height: 100%;
  padding-top: 0.5rem;
}
.contacts .el-tabs__header {
  margin: 0 !important;
}

.back {
  position: absolute;
  z-index: 1;
  left: 20px;
  right: 20px;
}

.mark-read:hover {
  color: #00A344 !important;
}

.tab-badge .el-badge__content {
  top: 15px !important;
  right: 0px !important;
}

.add-user .el-form-item__error {
  position: relative !important;
}
.add-user .el-transfer__buttons {
  margin-bottom: 20px;
}

button.btn.dropdown-toggle {
  height: 40px;
  padding-top: 0;
  padding-bottom: 0;
}
button.btn.dropdown-toggle.report-settings-btn {
  margin-top: 4px;
  height: 34px;
}

.abs-bottom {
  position: fixed;
  bottom: 0;
}
.abs-bottom.abs-right {
  right: 8px;
}
.abs-bottom.abs-left {
  left: 8px;
}

.abs-top {
  position: absolute;
  top: 68px;
}
.abs-top.abs-right {
  right: 8px;
}
.abs-top.abs-left {
  left: 8px;
}

.subscription-status-tag {
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 17px;
  background: gray;
  color: white;
  margin-right: 5px;
}

.clickable {
  cursor: pointer;
}

.help {
  cursor: help;
}

.last-message {
  max-width: 200px;
  font-size: 13px;
}

.upgrade-notification {
  color: #ffffff !important;
}
.upgrade-notification .el-notification__content {
  margin: 0 !important;
  color: #ffffff !important;
}
.upgrade-notification.el-notification .el-icon-success {
  color: #ffffff !important;
  margin-top: 25px;
  font-size: 25px;
}
.upgrade-notification.el-notification {
  border: 0;
}

.profile-info {
  max-width: 315px;
  height: 3.2rem;
}

.bg-white {
  background-color: #ffffff;
}

.preview {
  max-height: 256px;
}

.agent-list {
  padding: 0;
  height: 256px;
  overflow-y: auto;
}

.mega-dropdown-menu .el-badge.default-filter-indicator a {
  max-width: 250px;
  word-break: break-word;
  display: inline-block;
  float: left;
  font-weight: 400;
}
.mega-dropdown-menu .el-badge.default-filter-indicator .el-badge__content {
  background-color: #00BF50;
  right: -3px;
  top: 12px;
}

.sequence .message {
  overflow: hidden;
  vertical-align: middle;
}
.sequence .row {
  width: calc(100% - 56px - 1rem);
}

.in-notification-link, .in-notification-link:hover, .in-notification-link:focus {
  color: #00bf50;
  text-decoration: underline;
}

.cursor-default {
  cursor: default !important;
}

.workflow-icon {
  position: relative;
  left: -0.35em;
  margin-right: -2px;
  height: 36px;
}

.bulk-messaging-icon {
  position: relative;
  left: -0.55em;
  top: -0.1em;
  margin-right: -9px;
  height: 36px;
}

.bulk-sms-counter {
  border-radius: 0.25rem;
  width: 16rem;
}
.bulk-sms-counter .text-center {
  width: 100%;
}

.input-group .form-control {
  height: auto;
  border-radius: 0.25rem;
}

.rejection-tooltip {
  max-width: 250px;
}

.dialog-create-company .el-dialog__body {
  padding-bottom: 1px;
}

.dialog-text {
  word-break: normal !important;
}

button:disabled {
  cursor: not-allowed;
}

.opaq {
  opacity: 0.5;
}
.opaq:hover {
  opacity: 1;
}

.operations {
  max-width: 160px;
}

.is-disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.transparent {
  background: transparent;
}

.message-body {
  left: 10px;
  font-size: 16px;
  line-height: 28px;
  word-break: break-word;
  max-width: 500px;
}
.message-body.text-left {
  left: auto;
  right: 10px;
}

.arrow-dker {
  border-color: rgba(0, 0, 0, 0) !important;
}

.arrow-bluish {
  border-color: rgba(0, 103, 244, 0.9) !important;
}

.arrow-greenish {
  border-color: rgba(0, 191, 80, 0.9) !important;
}

.edit-button, .edit-field-button {
  width: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  text-align: center;
}

.edit-field-button {
  top: 21px;
  visibility: hidden;
}
.edit-field-button.active {
  visibility: visible;
}

.md-form-group:hover .edit-field-button, .md-form-group:focus .edit-field-button {
  visibility: visible;
}

.contact-info {
  max-width: 360px;
  margin: 0 auto;
}

.contact-details .md-input, .contact-details .md-input-style {
  font-weight: 400;
}
.contact-details .md-input:disabled, .contact-details .md-input-style:disabled {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  opacity: 0.8;
}
.contact-details .btn {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
.contact-details .pl-5 {
  padding-left: 2.4rem !important;
}

.deactivated {
  filter: grayscale(100%);
}

a.linkified {
  text-decoration: underline;
}
a.linkified:hover, a.linkified:focus, a.linkified:active {
  opacity: 0.6;
}

.app-monitor-icon {
  position: absolute;
  right: 14px;
}

.btn i.material-icons {
  font-size: 20px;
}

.nav > li > a .nav-icon i {
  font-size: 24px !important;
  line-height: 35px !important;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 0.5em !important;
}

.draggable {
  cursor: move;
}

.dn-order {
  width: 10px;
}

.edit-operations {
  width: 100%;
}

.changelog-body a {
  color: #0067F4;
  text-decoration: underline;
}
.changelog-body p {
  margin-bottom: 0.25rem;
}

.height-60 {
  height: 60px;
}

.height-80 {
  height: 80px;
}

.height-300 {
  height: 300px;
}

.height-400 {
  height: 400px;
}

.height-500 {
  height: 500px;
}

.height-600 {
  height: 600px;
}

.height-700 {
  height: 700px;
}

.no-box-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.break-word {
  word-wrap: break-word;
  word-break: break-word;
}

.width-80 {
  max-width: 80px;
}
.width-80.pull-left, .width-80.pull-right, .width-80.fixed {
  width: 80px;
}

.width-100 {
  max-width: 100px;
}
.width-100.pull-left, .width-100.pull-right, .width-100.fixed {
  width: 100px;
}

.width-120 {
  max-width: 120px;
}
.width-120.pull-left, .width-120.pull-right, .width-120.fixed {
  width: 120px;
}

.width-150 {
  max-width: 150px;
}
.width-150.pull-left, .width-150.pull-right, .width-150.fixed {
  width: 150px;
}

.width-200 {
  max-width: 200px;
}
.width-200.pull-left, .width-200.pull-right, .width-200.fixed {
  width: 200px;
}

.width-230 {
  max-width: 230px;
}
.width-230.pull-left, .width-230.pull-right, .width-230.fixed {
  width: 230px;
}

.width-300 {
  max-width: 300px;
}
.width-300.pull-left, .width-300.pull-right, .width-300.fixed {
  width: 300px;
}

.width-400 {
  max-width: 400px;
}
.width-400.pull-left, .width-400.pull-right, .width-400.fixed {
  width: 400px;
}

.width-500 {
  max-width: 500px;
}
.width-500.pull-left, .width-500.pull-right, .width-500.fixed {
  width: 500px;
}

.width-600 {
  max-width: 600px;
}
.width-600.pull-left, .width-600.pull-right, .width-600.fixed {
  width: 600px;
}

.width-700 {
  max-width: 700px;
}
.width-700.pull-left, .width-700.pull-right, .width-700.fixed {
  width: 700px;
}

.width-800 {
  max-width: 800px;
}
.width-800.pull-left, .width-800.pull-right, .width-800.fixed {
  width: 800px;
}

.width-120 {
  max-width: 120px;
}
.width-120.pull-left, .width-120.pull-right, .width-120.fixed {
  width: 120px;
}

.width-160 {
  max-width: 160px;
}
.width-160.pull-left, .width-160.pull-right, .width-160.fixed {
  width: 160px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

.status i {
  width: 15px;
  height: 15px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  display: block;
}

.el-transfer-panel {
  width: 300px !important;
}
.el-transfer-panel .el-transfer-panel__list.is-filterable {
  height: 160px;
}

.no-opacity {
  opacity: 1 !important;
}

.flex-row.dash {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.hours.close {
  opacity: 1 !important;
}

.font-awesome-button {
  line-height: 24px;
}

.navbar {
  height: 3.2rem;
}

.nav-border .nav > li:not(:disabled).active:after {
  color: #00BF50;
  border-left-width: 5px;
}

.no-border {
  border: none;
}

.dropdown-toggle > i.material-icons {
  vertical-align: 2px;
}

.app-body.max-height {
  padding-bottom: 0;
}

.navside {
  background-color: #fbfbfb;
}

.navbar .up {
  top: 7px;
  right: 0px;
}

.hover-menu .col-4 .active {
  background-color: transparent;
}
.hover-menu .col-4 .icon {
  display: block;
}
.hover-menu .col-4 .icon.active {
  display: none;
}
.hover-menu .col-4:hover .icon {
  display: none;
}
.hover-menu .col-4:hover .icon.active {
  display: block;
}

.v-tour .v-step {
  z-index: 10000;
}
.v-tour .v-step .v-step__arrow[data-v-aa0cbe42] {
  border-color: #090A0D;
}
.v-tour .v-step[data-v-aa0cbe42] {
  background: #090A0D;
  font-size: 18px;
}

b,
strong {
  font-weight: 700;
}

.hover-opacity:hover {
  opacity: 1;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.hover-text-blackish:hover {
  color: #090A0D !important;
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}

.no-left-border-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.no-right-border-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.navside .nav li a .nav-label {
  margin-left: 0;
}

.filter-item {
  width: 200px;
}

.el-button i.fa {
  line-height: 0.9em;
}

.padding-top-large {
  padding-top: 5em !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky !important;
  top: 60px;
}

.banner-font {
  font-family: "Poppins", sans-serif;
}

.pattern {
  background-image: url("../images/pattern2_overlay.svg"), url("../images/pattern2.jpg");
  background-repeat: no-repeat;
  background-size: 49vw, cover;
  background-position: center;
}

.pattern-xmas {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../images/xmas-tree.jpg");
  background-color: #01132B;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.m-h-v {
  min-height: 100vh;
}

.h-48 {
  height: 48px;
}

@media (max-width: 959px) {
  .message .el-badge.item {
    max-width: 100%;
  }
  .message-body.text-left {
    max-width: calc(100vw - 90px);
  }
  .el-badge__content.is-fixed.is-dot {
    right: -5px !important;
    top: 5px;
  }
}
@media (max-width: 767px) {
  .mega-dropdown-menu {
    width: 94vw;
  }
  #pageTitle {
    font-size: 16px;
  }
  .mega-dropdown-menu .el-badge.default-filter-indicator a {
    max-width: 140px;
  }
  .fixed-footer.padding {
    padding: 0.5rem 1rem !important;
  }
  ol.special-ol > li {
    margin: 0;
  }
  .conversync .timeline .tl-date {
    width: 5.5em;
    margin-left: -8.5em;
  }
  .conversync .contacts .el-tabs__nav {
    margin: 0 auto;
    position: relative;
    max-width: 265px;
    float: none;
  }
  .app-header ~ .app-body {
    padding-bottom: 2.435rem;
  }
  .app-footer:not(.hide) ~ .app-body {
    padding-bottom: 2.435rem;
  }
  .m-y {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }
  .footer {
    display: none;
  }
  .help-card .card-bottom {
    position: relative;
    margin-top: 100px;
  }
  ol > li {
    margin: 0;
  }
  ol > li:before {
    display: none;
  }
  .is-live {
    display: none;
  }
}
@media (max-width: 748px) {
  .custom-el-dialog .el-dialog {
    width: 100%;
  }
  .el-dialog {
    width: 100% !important;
  }
  .wizard-dialog img.w-300 {
    width: 150px;
    height: 150px;
    line-height: 150px;
  }
}
@media (max-width: 600px) {
  .el-btn {
    padding: 0;
  }
}
@media (max-width: 480px) {
  .hover-menu {
    left: -103px !important;
  }
}
@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.new-card {
  padding: 20px;
  border: 1px solid #EBEEF5;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #FFF;
  color: #303133;
  transition: 0.3s;
  border-radius: 4px;
  overflow: hidden;
}

.navbar {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.el-card__body .help {
  float: right;
}

.allow-select {
  -webkit-touch-callout: default; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.allow-select ::selection {
  background: rgba(0, 191, 80, 0.1) !important; /* WebKit/Blink Browsers */
}
.allow-select ::-moz-selection {
  background: rgba(0, 191, 80, 0.1) !important; /* Gecko Browsers */
}

.fixed-width-500 {
  flex: 0 0 500px;
}

.stripe-btn {
  background: url("../images/integrations/stripe-icon.svg") no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.bats-btn {
  background: url("../images/integrations/bats-icon.png") no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.guesty-btn {
  background: url("../images/integrations/guesty-icon.png") no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.zoho-btn {
  background: url("../images/integrations/zoho-icon.svg") no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.hubspot-btn {
  background: url("../images/integrations/hubspot-icon.svg") no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}
.hubspot-btn.small {
  width: 24px;
  height: 24px;
}

.pipedrive-btn {
  background: url("../images/integrations/pipedrive-icon.svg") no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}
.pipedrive-btn.small {
  width: 24px;
  height: 24px;
}

.gohighlevel-btn {
  background: url("../images/integrations/ghl-icon.png") no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}
.gohighlevel-btn.small {
  width: 24px;
  height: 24px;
}

.custom-link-builder-btn {
  background: url("../images/integrations/link-solid.svg") no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

#close-container {
  margin-bottom: 50px;
}

#giphy-grid {
  margin-top: 20px;
  height: 300px;
}

.tabs-pt-0 .el-tabs__content {
  padding-top: 0px !important;
}

.note-usage {
  width: 480px !important;
  margin: 0 auto !important;
}

.code-bg {
  background: #f8f8f8;
}

.el-upload-list.el-upload-list--text {
  padding-bottom: 10px;
}

body .nav-tabs .nav-link.rounded-top-left {
  border-top-right-radius: 0;
}

body .nav-tabs .nav-link.rounded-top-right {
  border-top-left-radius: 0;
}

.md-input-style {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  opacity: 0.8;
  display: block;
}

.dropdown-toggle::after {
  vertical-align: 0.155em;
}

.handle-whitespace {
  white-space: pre-line;
}

.not-contacted-filter .el-input__inner,
.not-contacted-filter .el-input-group__append,
.not-responded-filter .el-input__inner,
.not-responded-filter .el-input-group__append {
  padding: 0px 10px;
}

.not-contacted-filter .el-input__inner::-webkit-outer-spin-button,
.not-contacted-filter .el-input__inner::-webkit-inner-spin-button,
.not-responded-filter .el-input__inner::-webkit-outer-spin-button,
.not-responded-filter .el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.not-contacted-filter .el-form-item__error,
.not-responded-filter .el-form-item__error {
  position: relative;
  text-align: right;
  float: right;
  margin-right: 0.5rem;
  width: auto;
  line-height: 1.5em;
}

.help-text {
  font-weight: 400;
  font-size: 0.95rem;
  width: 100%;
  line-height: 1;
  color: #7D7D7D !important;
  margin-top: 10px;
}
.help-text .form-hover-help {
  line-height: 1 !important;
}

.campaign-selector .el-tag--mini {
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-dropdown-menu__item {
  font-size: 14px !important;
}
.el-dropdown-menu__item--delete {
  color: #FA003F !important;
}
.el-dropdown-menu__item--delete:hover {
  color: #940025 !important;
}

.container-filter-search {
  position: relative;
  z-index: 3000;
}
.container-filter-search .focused .el-input-group__append {
  border-color: #00BF50;
}
.container-filter-search .el-input__inner {
  border-right: 0;
}
.container-filter-search .el-input-group__append {
  background-color: #ffffff;
}
.container-filter-search .dropdown {
  position: absolute;
  z-index: 1100;
  left: 0;
  top: 38px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #DCDFE6;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
}
.container-filter-search .dropdown .table-filters {
  width: calc(100% - 1px);
}
.container-filter-search .dropdown .table-filters td {
  vertical-align: middle;
  font-weight: bold;
  padding: 0.3rem 1rem;
}
.container-filter-search .dropdown .table-filters tr:hover td {
  background-color: #f9f9f9;
}
.container-filter-search .dropdown .el-input__inner {
  text-align: right !important;
  background-color: transparent;
}
.container-filter-search .slide-enter-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.container-filter-search .slide-leave-active {
  -moz-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.container-filter-search .slide-enter-to, .container-filter-search .slide-leave {
  max-height: 100px;
  overflow: hidden;
}
.container-filter-search .slide-enter, .container-filter-search .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

/* Event Colors */
.event-color-type-12 {
  color: #C6DAFC;
}
.event-color-type-12.status-8 {
  color: #2D96F3;
}
.event-color-type-12.status-8.is_past {
  color: #C6DAFC;
}
.event-color-type-12.status-4 {
  color: #498A7B;
}
.event-color-type-12.status-5 {
  color: #B93E1D;
}
.event-color-type-12.status-14 {
  color: #DC3545;
}

.event-color-type-13 {
  color: #FBD763;
}
.event-color-type-13.status-8 {
  color: #EABA20;
}
.event-color-type-13.status-8.is_past {
  color: #f7de94;
}
.event-color-type-13.status-4 {
  color: #ff9800;
}
.event-color-type-13.status-5 {
  color: #8A3350;
}
.event-color-type-13.status-3 {
  color: #DC3545;
}

.event-bg-color-type-12 {
  background-color: #C6DAFC;
  color: #ffffff;
}
.event-bg-color-type-12.status-11 {
  background-color: #2D96F3;
}
.event-bg-color-type-12.status-11.is_past {
  background-color: #C6DAFC;
}
.event-bg-color-type-12.status-9 {
  background-color: #498A7B;
}
.event-bg-color-type-12.status-10 {
  background-color: #B93E1D;
  text-decoration: line-through;
}
.event-bg-color-type-12.status-14 {
  background-color: #DC3545;
}

.event-bg-color-type-13 {
  color: #ffffff;
  background-color: #FBD763;
}
.event-bg-color-type-13.status-8 {
  background-color: #EABA20;
}
.event-bg-color-type-13.status-8.is_past {
  background-color: #f7de94;
}
.event-bg-color-type-13.status-4 {
  background-color: #ff9800;
}
.event-bg-color-type-13.status-5 {
  background-color: #8A3350;
  text-decoration: line-through;
}
.event-bg-color-type-13.status-3 {
  background-color: #DC3545;
}

#el-dialog-watch-video .el-dialog {
  background-color: #000 !important;
}
#el-dialog-watch-video .el-dialog__title {
  color: #efefef;
}
#el-dialog-watch-video .el-dialog__body {
  padding: 0 !important;
}

.lh-1 {
  line-height: 1.2 !important;
}

.lh-1p4 {
  line-height: 1.4 !important;
}

.fs-1p1 {
  font-size: 1.1rem;
}

.color-999 {
  color: #999999;
}

.audio-recorder .align-items-end {
  height: 60%;
}
.audio-recorder .btn {
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: inline-block;
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
}
.audio-recorder .btn i {
  font-size: 30px;
}
.audio-recorder .active {
  -webkit-animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
}
@-webkit-keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.1);
    box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.1);
    background-color: #e53935;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.1);
    box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.1);
    background-color: #e53935;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

/**
Import Wizard Styling
 */
.import-wizard .import-wizard-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1110;
  background-color: rgba(255, 255, 255, 0.8);
}
.import-wizard .container-table-csv {
  min-height: 60vh;
  width: 100%;
  overflow: auto;
}
.import-wizard .container-table-csv .cell-warning {
  color: #856404;
  background-color: #fff3cd;
}
.import-wizard .container-table-csv .cell-danger {
  color: #721c24;
  background-color: #f8d7da;
}
.import-wizard .table-csv {
  white-space: nowrap;
  color: #232323;
}
.import-wizard .select-table-header {
  min-width: 150px;
}
.import-wizard .el-dialog__body {
  padding: 1rem;
}
.import-wizard .el-upload-dragger {
  position: relative;
  height: 60vh;
  width: 100%;
  border: 3px dashed #dedede;
  background-color: #fafafa;
}
.import-wizard .el-upload-dragger .dz-label {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  text-align: center;
}
.import-wizard .el-upload-dragger .dz-label p {
  font-size: 20px;
  color: #909090;
}
.import-wizard .el-upload-dragger .dz-label .fas {
  font-size: 75px;
  color: #dedede;
  margin-bottom: 1rem;
}
.import-wizard .step-0 {
  min-height: 60vh;
}
.import-wizard .step-0 .dnc-info-container {
  margin-top: 8rem !important;
}
.import-wizard .step-1 {
  position: relative;
}
.import-wizard .step-1 .container-template-download {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translateX(-50%);
  z-index: 100;
  border-radius: 4px;
  padding: 1rem;
}
.import-wizard .step-1 .container-template-download p {
  margin: 0;
  font-size: 18px;
  color: #909090;
}
.import-wizard .step-1 .table-responsive {
  height: 60vh;
}
.import-wizard .container-import-summary {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
}
.import-wizard .container-import-summary h2 {
  font-weight: 300;
  margin-bottom: 2rem;
}
.import-wizard .container-import-summary .fa-file-import {
  font-size: 125px;
  margin-bottom: 2rem;
  color: #d0d0d0;
}
.import-wizard .container-import-summary .fa-check {
  color: #00BF50;
}
.import-wizard .el-radio.new-ring-group, .import-wizard .el-radio.choose-ring-group {
  height: auto !important;
  line-height: 1.2em;
}

.alert-aloware {
  border-radius: 0 4px 4px 0;
  border-left-width: 5px;
  word-break: break-word;
}
.alert-aloware h6 {
  color: #155724;
}
.alert-aloware .alert-icon {
  font-size: 30px;
  align-self: center;
  margin-right: 1rem;
}

.audio-recorder-wrapper {
  width: 55%;
  float: left;
}
.audio-recorder-wrapper + .audio-uploader-wrapper {
  width: 45%;
  display: inline-block;
  margin-top: 24px;
}

.audio-recorder-container > div {
  height: 180px;
}
.audio-recorder-container > div .el-form-item__content {
  height: 100%;
}
.audio-recorder-container > div:first-child {
  width: 79%;
}
.audio-recorder-container > div:last-child {
  width: 20%;
  text-align: center;
}

.btn-group-sm > .btn, .btn-sm {
  line-height: 1.7;
}

.container-campaign-summary .finish-icon {
  color: rgb(208, 208, 208);
}
.container-campaign-summary .finish-icon.is-fax {
  font-size: 50px;
}
.container-campaign-summary .finish-icon:not(.is-fax) {
  width: 65px;
  opacity: 0.7;
}
.container-campaign-summary .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}
.container-campaign-summary .el-tabs__item {
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.container-campaign-summary h2 {
  font-weight: 400;
  margin-bottom: 2rem;
}

.container-account-imports .import-status-1 {
  font-weight: bold;
  color: #0d47a1;
}
.container-account-imports .import-status-2 {
  font-weight: bold;
  color: #E3AE24;
}
.container-account-imports .import-status-3 {
  font-weight: bold;
  color: #00A344;
}
.container-account-imports .import-status-4 {
  font-weight: bold;
  color: #C4183C;
}
.container-account-imports .import-status-5 {
  font-weight: bold;
  color: #7D7D7D;
}
.container-account-imports .import-status-6 {
  font-weight: bold;
  color: #C4183C;
}
.container-account-imports .import-selection-button {
  padding: 7px 12px !important;
  height: 100%;
}

.account-import-selection-container {
  padding: 7px 0 !important;
  margin-top: 0 !important;
}
.account-import-selection-container .popper__arrow {
  display: none !important;
}
.account-import-selection-container::after {
  display: none;
}
.account-import-selection-container .el-dropdown-menu__item {
  padding: 0 19px !important;
}
.account-import-selection-container .icon-container {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-import-selection-container .icon-container.hubspot-icon-container {
  background: #FF7A59;
}
.account-import-selection-container .icon-container .item-icon {
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  margin-right: 0 !important;
}
.account-import-selection-container .csv-icon {
  width: 18px;
  height: 18px;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.white-space-initial {
  white-space: initial;
}

.white-space-normal {
  white-space: normal;
}

.d-relative {
  position: relative;
}

.edit-contact .first-name .el-input__inner, .edit-contact .domain .el-input__inner, .edit-contact .last-name .el-input__inner, .edit-contact .max-negative-balance .el-input__inner,
.edit-configurations .first-name .el-input__inner,
.edit-configurations .domain .el-input__inner,
.edit-configurations .last-name .el-input__inner,
.edit-configurations .max-negative-balance .el-input__inner {
  background: transparent;
  position: relative;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  width: 100%;
  height: 34px;
  color: inherit;
  font-weight: 800;
  padding: 0 0 !important;
}
.edit-contact .first-name .el-input__inner:focus, .edit-contact .domain .el-input__inner:focus, .edit-contact .last-name .el-input__inner:focus, .edit-contact .max-negative-balance .el-input__inner:focus,
.edit-configurations .first-name .el-input__inner:focus,
.edit-configurations .domain .el-input__inner:focus,
.edit-configurations .last-name .el-input__inner:focus,
.edit-configurations .max-negative-balance .el-input__inner:focus {
  border-color: #00BF50;
  border-bottom-width: 2px;
  padding-bottom: 1px;
}

.open-hubspot-btn {
  background-color: #ff7a59;
}

.domain .el-input__suffix {
  z-index: 9;
}

.hubspot-company-id .el-input__suffix {
  z-index: 9;
}

/** +--------------------------------------------------------------------------------------------------------------------
 * | Custom Styling Form Sign Up Process
 * +--------------------------------------------------------------------------------------------------------------------
 * | The page can only be found in http://app.alodev.org/signup
 */
#container-signup h3 {
  color: #232323;
  font-weight: 400;
  margin-bottom: 2rem;
}
#container-signup .card {
  border-radius: 8px;
}
#container-signup .form-group label {
  display: block;
  line-height: 1;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: bold;
}
#container-signup .form-group .text-danger, #container-signup .form-group .alo-custom-radio-buttons .alo-radio-button-danger .el-radio-button__inner, .alo-custom-radio-buttons .alo-radio-button-danger #container-signup .form-group .el-radio-button__inner {
  font-size: 13px;
}
#container-signup .form-group .form-control {
  border-radius: 4px;
}
#container-signup .btn-signup {
  font-weight: bold;
  height: 42px;
  border-radius: 21px;
  border-width: 2px;
}

.container-trial-modal {
  text-align: center;
  padding: 0 3rem 3rem 3rem;
}
.container-trial-modal h2, .container-trial-modal h3 {
  font-weight: 400;
  margin-bottom: 2rem;
  color: #323232;
}
.container-trial-modal .fa-user-clock {
  font-size: 120px;
  display: block;
  margin-bottom: 2rem;
  color: #d0d0d0;
}
.container-trial-modal p {
  font-size: 20px;
  color: #323232;
  margin-bottom: 2rem;
  word-break: normal;
}

/* + -------------------------------------------------------------------------------------------------------------------
 * |  <---- End of Signup Styling
 * + -------------------------------------------------------------------------------------------------------------------
 */
/* + -------------------------------------------------------------------------------------------------------------------
 * |  <---- Start of Account Registration Styling
 * + -------------------------------------------------------------------------------------------------------------------
 */
.account-registration {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.account-registration .row.el-input {
  height: 35px !important;
}
.account-registration .stepper__container {
  display: flex;
  text-align: center;
  text-align: -webkit-center;
}
.account-registration .text-h4 {
  font-size: 2.5rem;
  color: black;
}
@media (max-width: 575px) {
  .account-registration .text-h4 {
    font-size: 20px;
  }
}
@media (min-width: 576px) {
  .account-registration .text-h4 {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .account-registration .text-h4 {
    font-size: 2.5rem;
  }
}
.account-registration .text-h5 {
  text-align: start;
  font-size: 1.25rem !important;
  color: black;
}
.account-registration .text-body1 {
  font-size: 1.25rem;
  margin-top: 28px;
  width: 82%;
  color: black;
}
@media (max-width: 575px) {
  .account-registration .text-body1 {
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .account-registration .text-body1 {
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .account-registration .text-body1 {
    font-size: 1.25rem;
    margin-top: 28px;
    width: 82%;
  }
}
.account-registration .password-hint ul {
  padding-top: 10px;
  margin-bottom: 0 !important;
}
.account-registration .password-hint li {
  list-style: none;
  font-size: 14px;
  color: #000000;
  margin-top: 4px;
}
.account-registration .zipcode-hint {
  padding-top: 0;
  padding-left: 10px;
  font-size: 14px;
  color: #000000;
  text-align: left;
}
.account-registration .negative-top {
  margin-top: -10px;
}
.account-registration .banner {
  align-items: center;
  background: linear-gradient(240deg, rgba(0, 55, 255, 0.1647058824) 0%, rgba(34, 255, 0, 0.1098039216) 100%);
  height: 100%;
  min-height: 100%;
}
.account-registration .banner .card {
  background-color: #ffffff;
  border-radius: 12px;
  margin-right: 12px;
}
.account-registration .banner .card > h3 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: black;
}
.account-registration .banner .card > p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding: 0 24px 10px 24px;
  color: black;
}
.account-registration .stepper__container {
  display: flex;
  flex-direction: column;
  height: 90vh !important;
}
.account-registration .stepper__container .stepper__content__header {
  position: relative;
  top: 0;
  z-index: 10;
  background-color: white;
}
.account-registration .stepper__container .stepper__content {
  flex-grow: 1;
  height: 56vh;
  overflow-y: auto;
}
@media (min-height: 720px) {
  .account-registration .stepper__container .stepper__content {
    height: 50vh;
  }
  .account-registration .stepper__container .stepper__container {
    height: 70vh !important;
  }
}
@media (min-height: 800px) {
  .account-registration .stepper__container .stepper__container {
    height: 90vh !important;
  }
}
@media (min-height: 900px) {
  .account-registration .stepper__container .stepper__content {
    height: 56vh;
  }
}
.account-registration ::-webkit-scrollbar {
  width: 9px !important;
  max-height: 397px !important;
}
.account-registration ::-webkit-scrollbar-thumb {
  background-color: #256EFF !important;
  border-radius: 6px !important;
}
.account-registration ::-webkit-scrollbar-thumb:hover {
  background-color: #256EFF !important;
}
.account-registration ::-webkit-scrollbar-track {
  background-color: #E0E0E0 !important;
  border-radius: 6px !important;
}
@media (max-width: 575px) {
  .account-registration .banner {
    display: none !important;
  }
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
  .account-registration .stepper__container .stepper__content__header {
    max-width: 100%;
    position: relative;
    z-index: 10;
    background-color: white;
    font-size: 10px;
  }
}
@media (min-width: 576px) {
  .account-registration .banner {
    display: none !important;
  }
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
  .account-registration .stepper__container .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
  .account-registration .stepper__container .stepper__container .stepper__content__header {
    max-width: 100%;
    position: relative;
    z-index: 10;
    background-color: white;
    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .account-registration .banner {
    display: none !important;
  }
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
    padding-left: 4vh;
  }
}
@media (min-width: 992px) {
  .account-registration .banner {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .account-registration .banner {
    display: flex !important;
  }
}
@media (max-width: 575px) {
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
}
@media (min-width: 576px) {
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
  }
}
@media (min-width: 768px) {
  .account-registration .stepper__container {
    display: flex;
    text-align: -webkit-center;
    padding-left: 4vh;
  }
}
.account-registration .w-65 {
  width: 65%;
}
.account-registration .min-h-60 {
  min-height: 60vh;
}
.account-registration .terms-iframe {
  border: 0;
  width: 100%;
  height: 30vh; /* Default iframe height */
  padding-bottom: 20px;
}
.account-registration .accept-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 8px;
  margin-top: 10px;
}
.account-registration .accept-box .carrier-fees {
  position: relative;
  left: -8.1%;
}
@media (max-width: 575px) {
  .account-registration .terms-iframe {
    height: 30vh;
  }
}
@media (min-width: 576px) {
  .account-registration .terms-iframe {
    height: 30vh;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media (min-width: 768px) {
  .account-registration .terms-iframe {
    height: 30vh;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media (min-width: 992px) {
  .account-registration .terms-iframe {
    height: 30vh;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
.account-registration .h-55 {
  height: 55vh;
}
.account-registration .h-75 {
  height: 75%;
}
.account-registration .logo {
  z-index: 11;
}
.account-registration .px-xl {
  padding-left: 48px;
  padding-right: 48px;
}
.account-registration .pt-xl {
  padding-top: 48px;
}
.account-registration .pt-40 {
  padding-top: 40px !important;
}
.account-registration .phone-row {
  display: flex;
  height: 35px;
  width: 100%;
}
.account-registration .phone-row > .col-md-4 {
  flex-grow: 1;
}
.account-registration #recaptcha-element {
  position: relative;
  left: -12%;
}
.account-registration .form-actions {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  margin-bottom: 40px;
  margin-left: 24px;
}
@media (max-height: 500px) {
  .account-registration .form-actions {
    margin-bottom: 68px;
  }
}
@media (min-height: 500px) and (max-height: 700px) {
  .account-registration .form-actions {
    margin-bottom: 60px;
  }
}
.account-registration .loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.account-registration .loading-icon {
  width: 250px;
  height: 250px;
}
.account-registration .loading-text {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}
.account-registration .g-recaptcha {
  margin-left: -62px;
}
.account-registration .banner-ssu-account-creation {
  background-image: url("../images/ssu-account-creation.png");
}
.account-registration .banner-ssu-start {
  background-image: url("../images/ssu-start.png");
}

/* + -------------------------------------------------------------------------------------------------------------------
* |  <---- End of Account Registration Styling
* + -------------------------------------------------------------------------------------------------------------------
*/
li .pro-label {
  background: #ffa000;
  font-size: 100%;
}
li .pro-label-text {
  color: #ffa000;
}
li.active .pro-label {
  background: #ffa000;
  font-size: 100%;
}
li.active .pro-label-text {
  color: #ffa000;
}

.agent-avatar i {
  width: 14px;
  height: 14px;
  bottom: -2px !important;
  right: -2px !important;
}

i.status-badge {
  width: 14px;
  height: 14px;
  border-width: 0px;
  border-style: solid;
  border-radius: 100%;
  display: inline-block;
  vertical-align: -2px;
}

i.workflow-status {
  margin-top: 5px;
  min-width: 10px;
  min-height: 10px;
  max-width: 10px;
  max-height: 10px;
  width: 10px;
  height: 10px;
  border-width: 0px;
  border-style: solid;
  border-radius: 100%;
  display: inline-block;
  vertical-align: -2px;
}

a.workflow-link:hover {
  color: #1e5093;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.big-screen.max-height.no-footer {
  height: 100% !important;
}

.edit-contact-popover .edit-button, .edit-contact-popover .edit-field-button {
  right: -32px;
}

.md-input, .md-input-style {
  height: auto;
  min-height: 34px;
  line-height: 34px;
  font-weight: 500 !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

/* +--------------------------------------------------------------------------------------------------------------------
 * | Header Notification Styling
 * +--------------------------------------------------------------------------------------------------------------------
 */
#container-user-notifications {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1110;
}

.header-notification {
  width: 100%;
  z-index: 1110;
  padding: 0.5rem 1rem;
  vertical-align: middle;
  position: relative;
}
.header-notification .btn-close:hover {
  opacity: 0.5;
}
.header-notification.hn-warning {
  background-color: #fff3cd;
  border-left: 5px solid #ffeeba;
  color: #856404;
}
.header-notification.hn-warning .btn-close {
  color: #856404;
}
.header-notification.hn-warning .btn-close:hover {
  opacity: 0.5;
  color: #856404;
}
.header-notification.hn-10dlc-warning {
  background-color: #fff532;
  border-left: 5px solid #fff50a;
  color: #2c2b02;
}
.header-notification.hn-10dlc-warning .btn-close {
  color: #2c2b02;
}
.header-notification.hn-10dlc-warning .btn-close:hover {
  opacity: 0.5;
  color: #2c2b02;
}
.header-notification.hn-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.header-notification.hn-success .btn-close {
  color: #155724;
}
.header-notification.hn-success .btn-close:hover {
  opacity: 0.5;
  color: #155724;
}
.header-notification.hn-info {
  color: #000000;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.header-notification.hn-info .btn-close {
  color: #004085;
}
.header-notification.hn-info .btn-close:hover {
  opacity: 0.5;
  color: #004085;
}
.header-notification.hn-info .modal-link {
  color: var(--Primary-Blue, #256EFF);
}
.header-notification.hn-info .modal-link:hover {
  text-decoration: underline;
}
.header-notification.hn-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.header-notification.hn-danger .btn-close {
  color: #721c24;
}
.header-notification.hn-danger .btn-close:hover {
  opacity: 0.5;
  color: #721c24;
}

.el-checkbox.is-bordered.is-checked {
  border-color: #00A344 !important;
}

.force-scrollbar-view ::-webkit-scrollbar, .el-table.activity-reporting-table ::-webkit-scrollbar {
  -webkit-appearance: none;
}
.force-scrollbar-view ::-webkit-scrollbar:vertical, .el-table.activity-reporting-table ::-webkit-scrollbar:vertical {
  width: 7px;
}
.force-scrollbar-view ::-webkit-scrollbar:horizontal, .el-table.activity-reporting-table ::-webkit-scrollbar:horizontal {
  height: 7px;
}
.force-scrollbar-view ::-webkit-scrollbar-thumb, .el-table.activity-reporting-table ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.el-alert.big .el-alert__description, .el-alert.big .el-alert__title {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.form-label {
  line-height: 1.5;
  margin-bottom: 0.4rem;
}
.form-label label {
  font-weight: bold;
  display: block;
  margin: 0;
  color: #303133;
}
.form-label h5 {
  font-size: 1rem !important;
  margin-bottom: 0.2rem !important;
  color: #656565 !important;
}
.form-label h6 {
  font-size: 0.8rem !important;
  margin-bottom: 0.1rem !important;
}
.form-label small {
  font-size: 14px;
  color: #767676;
  word-break: keep-all;
}

.btn-input-group .el-input-group__append {
  font-weight: 400;
}

.table-routing-test .td-status {
  width: 90px;
}
.table-routing-test .td-order {
  padding-top: 1rem;
  width: 20px;
  vertical-align: top;
  text-align: center;
}
.table-routing-test .td-can-take-calls {
  padding-top: 1rem;
  width: 180px;
  vertical-align: top;
}
.table-routing-test .el-collapse {
  border-color: #fff !important;
}
.table-routing-test .el-collapse .el-collapse-item__header {
  border-color: #fff !important;
}
.table-routing-test .el-collapse .el-collapse-item__wrap {
  border-color: #fff !important;
}

.original-line-height {
  line-height: 1.2 !important;
}

.waveform-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  background: transparent;
}

#waveform {
  width: calc(100% - 110px);
  padding-left: 5px;
  padding-right: 5px;
}

.waveform-timeline {
  width: 80px;
  text-align: right;
}

.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #EFEFEF;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}
.play-button:hover {
  background: #DDD;
}

#table-accounts thead th {
  border-top: 0;
  border-bottom: 0;
}
#table-accounts tbody td {
  color: #545454;
  border-top: 1px solid #f0f0f0;
  border-color: rgba(120, 130, 140, 0.4);
}

#container-table-accounts, #container-table-presignups {
  min-height: calc(100vh - 200px);
  position: relative;
  overflow: auto;
}
#container-table-accounts .message-centered, #container-table-presignups .message-centered {
  position: absolute;
  z-index: 110;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #d0d0d0;
  font-size: 26px;
}
#container-table-accounts .message-centered i, #container-table-presignups .message-centered i {
  font-size: 60px;
  display: block;
  margin-bottom: 1rem;
}

#container-account-details table td {
  vertical-align: top;
}

.a-primary {
  color: #2196f3 !important;
}
.a-primary:hover {
  opacity: 0.8;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-600 {
  font-weight: 600;
}

.stick-top-with-header {
  top: 120px;
  z-index: 560;
}
.stick-top-with-header.top-70 {
  top: 70px;
}

.page-anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -120px;
}

.list-group-alo .list-group-item {
  border: 0;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}
.list-group-alo .list-group-item-action:hover, .list-group-alo .list-group-item-action:active {
  border-left: 2px solid #00BF50;
  background-color: #fefefe;
}

.input-suffix {
  height: 40px;
  vertical-align: middle;
  align-self: center;
  font-weight: bold;
  line-height: 40px;
  padding: 0 1rem 0 0;
  color: #DCDFE6;
}
.input-suffix.active {
  color: #00A344 !important;
}

.form-aloware {
  color: #878787 !important;
  position: relative;
}
.form-aloware h1, .form-aloware h2, .form-aloware h3, .form-aloware h4, .form-aloware h5, .form-aloware h6 {
  color: #656565 !important;
}
.form-aloware p {
  color: #656565;
  word-break: normal;
}
.form-aloware small {
  font-size: 14px;
  line-height: 1.6;
  display: inline-block;
}
.form-aloware .el-form-item {
  position: relative;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 2rem;
}
.form-aloware .el-form-item.no-border {
  border: 0;
  padding-bottom: 0;
}
.form-aloware .el-form-item.shine:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100% + 10px);
  background-color: rgba(0, 191, 80, 0.1);
  animation: fade 2s linear;
  opacity: 0;
  border-radius: 4px;
}
.form-aloware .el-form-item.shine:before.no-border {
  height: calc(100% + 20px);
}
@keyframes fade {
  0%, 100% {
    opacity: 0;
    display: none !important;
  }
  50% {
    opacity: 1;
    display: block !important;
  }
}
.form-aloware .switch-label .el-switch__core {
  align-self: center;
  margin-right: 0.5rem;
}
.form-aloware .switch-label label {
  margin: 2px 0 0 0 !important;
  font-weight: bold;
  line-height: 20px;
  vertical-align: middle;
}
.form-aloware .switch-label label.inactive {
  color: #c0c0c0 !important;
}
.form-aloware .switch-label .flex-basis-25 {
  flex-basis: 25%;
}
.form-aloware hr {
  border-top: 1px solid #f2f2f2 !important;
}
.form-aloware .input-block {
  display: block;
  width: 100% !important;
}
.form-aloware .input-block input {
  display: block;
  width: 100%;
}

.checkbox-label-description {
  height: inherit !important;
  display: block;
  margin-left: 0 !important;
  margin-bottom: 12px !important;
}
.checkbox-label-description .el-checkbox__input {
  line-height: 35px !important;
}
.checkbox-label-description .el-checkbox__label label {
  color: #232323;
  display: block;
  margin: 0;
  font-weight: bold;
  line-height: 1.1;
}
.checkbox-label-description .el-checkbox__label small {
  color: #999;
  font-weight: 300;
  line-height: 1;
}
.checkbox-label-description.is-checked .el-checkbox__label label {
  color: #00BF50 !important;
}

.checkbox-group {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  margin-bottom: 8px;
}
.checkbox-group-area {
  padding: 10px;
}
.checkbox-group-item {
  display: flex !important;
  align-items: center !important;
}
.checkbox-group-item > span {
  padding-left: 0 !important;
}

.textarea-container {
  position: relative;
}
.textarea-container .el-textarea textarea.el-textarea__inner {
  resize: none;
}
.textarea-container.with-label .el-textarea textarea.el-textarea__inner {
  padding-bottom: 36px;
}
.textarea-label {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 5px;
  color: #343434;
  font-weight: 400;
}

.with-label .el-textarea__inner {
  padding-bottom: 20px;
}

.container-form-height-indicator {
  position: sticky;
  bottom: 70px;
  text-align: center;
  width: 100%;
  z-index: 1010;
}
.container-form-height-indicator .btn-form-height-indicator {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  color: #00A344;
  border: 0;
  outline: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.container-form-height-indicator .btn-form-height-indicator:hover {
  background-color: #efefef;
  opacity: 0.8;
  cursor: pointer;
}
.container-form-height-indicator .btn-form-height-indicator i {
  font-size: 20px;
  line-height: 40px;
  vertical-align: middle;
}

.el-input-copy .el-input__inner {
  cursor: pointer;
}

.list-ring-group .list-ring-group-item {
  border: 1px solid #e0e0e0;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: #fdfdfd;
}
.line-greet-record .card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.line-greet-record .card .card-header {
  background-color: #fefefe;
}
.line-greet-record .card .card-header {
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
}

#account-settings-tab .el-tabs__content {
  background: transparent !important;
  overflow: hidden !important;
}
#account-settings-tab.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #090A0D;
}
#account-settings-tab.el-tabs--border-card > .el-tabs__header {
  background: transparent !important;
  border-bottom: 0px;
}
#account-settings-tab.el-tabs--left .el-tabs__item.is-left {
  text-align: left !important;
  margin: 0px 0 10px -1px;
}
#account-settings-tab.el-tabs--left .el-tabs__item.is-left.is-active {
  border-radius: 4px;
  background: var(--Light-Background, rgba(37, 110, 255, 0.12));
  border-color: transparent !important;
}

.contacts-list .list-item {
  font-size: 14px;
}

.h-24 {
  height: 24px;
}

.table-transparent {
  background-color: transparent;
  border: 0;
}
.table-transparent tbody, .table-transparent thead, .table-transparent tr, .table-transparent td, .table-transparent th {
  background-color: transparent !important;
  border: 0 !important;
}
.table-transparent td, .table-transparent th {
  padding: 0 0 1rem 0 !important;
}

.col-2-4 {
  width: 20%;
}

.summary-card-hover:hover {
  -webkit-box-shadow: 0 0 5px 2px #6ADA96;
  -moz-box-shadow: 0 0 5px 2px #6ADA96;
  box-shadow: 0 0 0px 1px #6ADA96;
  background: rgba(110, 218, 152, 0.08);
}
.summary-card-hover .countup-hover small.text-sm:not(.sms-text) {
  min-height: 36.8px;
  display: block;
}
.summary-card-hover .flex-equal-height {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.summary-card-hover .title,
.summary-card-hover .metric-label,
.summary-card-hover .metric-value,
.summary-card-hover .metric-total-label,
.summary-card-hover .metric-total-value {
  font-family: Roboto;
}
.summary-card-hover .title {
  color: #040404;
  font-size: 15px !important;
  font-weight: bold;
  letter-spacing: 0.38px;
}
.summary-card-hover .metric-label {
  color: #666666 !important;
  font-size: 14px !important;
  letter-spacing: 0.35px;
}
.summary-card-hover .metric-value {
  color: #040404;
  font-size: 26px !important;
  font-weight: bold;
  letter-spacing: 0.35px;
}
.summary-card-hover .metric-total-label {
  color: #666666;
  font-size: 10px !important;
  letter-spacing: 0.13px;
}
.summary-card-hover .metric-total-value {
  color: #666666;
  font-size: 20px !important;
  letter-spacing: 0.27px;
  line-height: 24px;
}
.summary-card-hover .countup-hover:not(.unclickable):hover {
  cursor: pointer;
}
.summary-card-hover .countup-hover:not(.unclickable):hover .metric-value,
.summary-card-hover .countup-hover:hover .hover-value {
  color: #6ADA96 !important;
}
.summary-card-hover .countup-hover:not(.unclickable):hover .metric-label {
  color: #040404 !important;
}
.summary-card-hover .countup-hover.unclickable:hover {
  cursor: initial !important;
  pointer-events: none;
}

.cursor-initial {
  cursor: initial;
}

.card-border-left {
  border-left: 1px solid rgba(120, 130, 140, 0.13) !important;
}

.summary-numbers-v-align-30 {
  transform: translateY(30%);
}

.summary-numbers-v-align {
  transform: translateY(80%);
}

.img-with-icon {
  margin-left: -8px;
  vertical-align: top;
  font-size: 13px;
  font-weight: 900;
}

.position-absolute-right {
  position: absolute;
  right: 0;
  z-index: 9;
}

.left-align-chart .highcharts-legend {
  transform: translate(52%, 16%);
}

.align-sub {
  vertical-align: sub !important;
}

.el-pagination .el-pager .number.active {
  color: #00A344;
}

.comm-log-options {
  border: 1px solid #D5D5D5;
  line-height: 24px;
  border-radius: 8px;
  background: #fff;
  font-weight: 900;
  color: #D5D5D5;
}

.comm-log-options:hover {
  cursor: pointer;
  border: 1px solid #000;
  color: #000;
}

/**
------------------------------------------------------------------------------------------------------------------------
Analytics Styling
------------------------------------------------------------------------------------------------------------------------
 */
.analytics .donut-center-label {
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.analytics .donut-center-label h3 {
  font-size: 56px;
  color: #040404;
  margin: 0;
}
.analytics .donut-center-label label {
  font-weight: 400;
  color: #323232;
}

article {
  color: #323232;
  font-weight: 300;
}
article p {
  font-size: 16px;
  word-break: keep-all;
}

@keyframes blink {
  50% {
    color: transparent;
  }
}
.loader__dot {
  animation: 1s blink infinite;
}

.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}

.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}

.text-dead {
  color: #898989;
  font-weight: 300;
}

#reports-activity-reporting-tab .dropdown-toggle::after {
  content: none;
}

.small-v-loading .el-loading-spinner {
  top: unset !important;
  margin-top: unset !important;
}
.small-v-loading .el-loading-spinner svg {
  height: 18px !important;
  width: 18px !important;
}

.medium-v-loading {
  height: 23px;
  top: -4px !important;
}

.table-aloware th {
  border-top: 0;
}

.sms-reminder-template-variables {
  font-size: 0.8rem;
  margin-right: 10px;
  cursor: pointer;
}
.sms-reminder-template-variables:hover {
  color: green !important;
}

#container-dashboard h1, #container-dashboard h2, #container-dashboard h3, #container-dashboard h4, #container-dashboard h5, #container-dashboard h6, #container-dashboard p, #container-dashboard label, #container-dashboard input, #container-dashboard button, #container-dashboard select, #container-dashboard div,
.popover-metric h1,
.popover-metric h2,
.popover-metric h3,
.popover-metric h4,
.popover-metric h5,
.popover-metric h6,
.popover-metric p,
.popover-metric label,
.popover-metric input,
.popover-metric button,
.popover-metric select,
.popover-metric div {
  font-family: "Roboto", sans-serif !important;
}
#container-dashboard h1, #container-dashboard h2, #container-dashboard h3, #container-dashboard h4, #container-dashboard h5, #container-dashboard h6, #container-dashboard p, #container-dashboard label,
.popover-metric h1,
.popover-metric h2,
.popover-metric h3,
.popover-metric h4,
.popover-metric h5,
.popover-metric h6,
.popover-metric p,
.popover-metric label {
  color: #323232;
}
#container-dashboard label,
.popover-metric label {
  font-weight: 400;
}
#container-dashboard p, #container-dashboard div,
.popover-metric p,
.popover-metric div {
  font-size: 14px;
  color: #323232;
  line-height: 1.4;
}
#container-dashboard small,
.popover-metric small {
  font-size: 13px;
  font-weight: 400;
}
#container-dashboard .difference,
.popover-metric .difference {
  display: inline-block;
  font-weight: 500;
}
#container-dashboard .difference.increase,
.popover-metric .difference.increase {
  color: #00A344;
}
#container-dashboard .difference.decrease,
.popover-metric .difference.decrease {
  color: #e74c3c;
}
#container-dashboard .difference.equal,
.popover-metric .difference.equal {
  color: #999;
}

#container-dashboard h1 {
  font-size: 1.8rem;
  font-weight: 500 !important;
}
#container-dashboard .metric-widget {
  text-align: center;
}
#container-dashboard .metric-widget:hover {
  background-color: #F2FBF6;
  border-color: #00BF4A;
  cursor: pointer;
}
#container-dashboard .metric-widget p {
  margin: 0;
}
#container-dashboard .metric-widget h1, #container-dashboard .metric-widget h2, #container-dashboard .metric-widget h3, #container-dashboard .metric-widget h4, #container-dashboard .metric-widget h5, #container-dashboard .metric-widget h6 {
  margin-bottom: 0;
  line-height: 1;
  color: #323232;
  display: inline-block;
}
#container-dashboard .metric-widget h1 {
  font-size: 50px;
}
#container-dashboard .metric-widget h2 {
  font-size: 2rem;
  font-weight: 700;
}
#container-dashboard .metric-widget .difference {
  display: inline-block;
  font-weight: 500;
}
#container-dashboard .metric-widget .difference.increase {
  color: #00A344;
}
#container-dashboard .metric-widget .difference.decrease {
  color: #e74c3c;
}
#container-dashboard .metric-widget .difference.equal {
  color: #999;
}
#container-dashboard .container-outbound-connection-rate-widget {
  position: relative;
}
#container-dashboard .container-outbound-connection-rate-widget .label-connection-rate {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 900;
  text-align: center;
}
#container-dashboard .container-outbound-connection-rate-widget .label-connection-rate h1 {
  color: #00bf4a;
  font-size: 80px;
  font-weight: 400 !important;
  margin: 0;
}
#container-dashboard .container-outbound-connection-rate-widget .label-connection-rate h2 {
  color: #323232;
  font-size: 50px;
  font-weight: 500 !important;
  margin: 0;
}
#container-dashboard .container-outbound-connection-rate-widget .label-connection-rate p {
  font-weight: 300 !important;
  font-size: 20px;
}

.container-controls {
  padding: 0.5rem 1rem;
  border-top: 2px solid #D2D2D2;
  background-color: #efefef;
}

.sms-reminders-wrapper table button {
  padding: 7px 10px;
  font-size: 0.8rem !important;
}
.sms-reminders-wrapper table td:first-child {
  width: 70%;
}
.sms-reminders-wrapper table td:nth-child(2), .sms-reminders-wrapper table td:nth-child(3) {
  padding: 0.75rem 0.5rem;
}

.sold-report-container,
.messenger-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.sold-report-container iframe,
.messenger-container iframe {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.flashy-counts {
  -webkit-animation: counts-glow 1s ease-in-out infinite alternate;
  -moz-animation: counts-glow 1s ease-in-out infinite alternate;
  animation: counts-glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes counts-glow {
  from {
    box-shadow: 0 0 10px #FFFF00, 0 0 12px #FFFF00, 0 0 12px #dc3545, 0 0 12px #dc3545, 0 0 12px #dc3545, 0 0 12px #dc3545, 0 0 12px #dc3545;
  }
  to {
    box-shadow: 0 0 3px #fff, 0 0 3px #dc3545, 0 0 3px #dc3545, 0 0 3px #dc3545, 0 0 5px #dc3545, 0 0 3px #dc3545, 0 0 3px #dc3545;
  }
}
.md-form-group .btn-edit {
  visibility: hidden;
}
.md-form-group:hover .btn-edit {
  visibility: visible;
}

.variable-copied-notify {
  bottom: 20px !important;
  top: auto !important;
}

.outbound-call-count-filter .el-form-item__error {
  position: relative;
  text-align: center;
  float: right;
  width: 362px;
}
.outbound-call-count-filter .inputs {
  width: 485px;
}

.mention {
  background: rgba(0, 191, 80, 0.075);
  color: rgb(0, 191, 80);
}

.support-tools .el-tabs__nav {
  display: grid !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.support-tools.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #090A0D;
}
.support-tools.el-tabs--border-card > .el-tabs__header {
  background: transparent !important;
  border-bottom: 0px;
}
.support-tools.el-tabs--left .el-tabs__item.is-left {
  text-align: left !important;
  margin: 0px 0 10px -1px;
}
.support-tools.el-tabs--left .el-tabs__item.is-left.is-active {
  border-radius: 4px;
  background: var(--Light-Background, rgba(37, 110, 255, 0.12));
  border-color: transparent !important;
}
.support-tools .el-tabs__content {
  position: static !important;
}

.support-tools .tab-content {
  height: calc(100vh - 276.19px);
}

.contacts-tab .highlight,
.communications-tab .highlight {
  background: rgba(0, 191, 80, 0.1);
  color: rgb(0, 191, 80);
}

.support-tools .search-resource-text {
  font-size: 26px;
  color: #d0d0d0;
}

.support-tools .search-resource-text i {
  font-size: 60px;
  margin-bottom: 1rem;
}

.min-height-screen {
  min-height: calc(100vh - 50px);
}

.container-listing {
  padding: 1.5rem;
  padding-bottom: 0;
  background-color: #fff;
}
.container-listing .listing-heading {
  color: #202125;
  align-self: center;
  margin: 0;
}
.container-listing .container-header {
  margin-bottom: 1.5rem;
}
.container-listing .container-listing-tools {
  padding: 0.75rem;
  border-top: 4px solid #e6e6e6;
  background-color: #f6f6f6;
}
.container-listing .container-listing-tools #search-input {
  height: 37px;
}
.container-listing .container-listing-tools .el-button--small,
.container-listing .container-listing-tools .el-button--small.is-round {
  padding: 10.5px 15px;
  border-radius: 4px;
}
.container-listing .container-listing-tools .el-button + .el-button {
  margin-left: 0px;
}
.container-listing .table h6 {
  color: #202125 !important;
}
.container-listing .table th {
  border-top: 0;
}
.container-listing .table tr:hover td {
  background-color: #fafafa;
}
.container-listing .table tr:hover th {
  background-color: transparent !important;
}
.container-listing .table td {
  padding: 1.5rem 0.5rem;
}
.container-listing .table th {
  padding: 0.5rem;
}
.container-listing .table th:first-child {
  padding-left: 0;
}
.container-listing .table.table-sm td {
  padding: 0.75rem 0.5rem;
}
.container-listing .table.table-sm th {
  padding: 0.5rem;
}
.container-listing .container-footer {
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}
.container-listing .el-pagination button, .container-listing .el-pagination .number, .container-listing .el-pagination .btn-quicknext, .container-listing .el-pagination .btn-quickprev {
  background-color: transparent !important;
  border: 1px solid transparent;
  margin: 0 !important;
  padding: 0 !important;
}
.container-listing .el-pagination .number:hover,
.container-listing .el-pagination .btn-quicknext:hover,
.container-listing .el-pagination .btn-quickprev:hover {
  color: #00BF50 !important;
  border: 1px solid #00BF50;
  border-radius: 3px;
  cursor: pointer;
}

.dialog-blank .el-dialog__header,
.dialog-blank .el-dialog__body {
  padding: 0 !important;
}

.radio-btn-block {
  display: block;
  width: 100%;
}
.radio-btn-block h6 {
  margin-bottom: 0.4rem !important;
}
.radio-btn-block .el-radio-button__inner {
  display: block;
  width: 100%;
}
.radio-btn-block .el-radio-button__inner .fa-chevron-right {
  font-size: 20px;
  color: #e9e9e9;
}
.radio-btn-block .el-radio-button__inner.is-active h6 {
  color: #fefefe;
}

.btn-dialog-close {
  padding-top: 0 !important;
  font-size: 20px !important;
  color: #dedede !important;
}
.btn-dialog-close:hover {
  color: #676767 !important;
}

.radio-group-hover .el-radio-button:hover .el-radio-button__inner {
  border: 1px solid #00bf50;
  background-color: rgba(0, 191, 80, 0.1);
}

#form-item-sequence-types {
  max-height: 60vh;
  overflow-y: scroll;
  margin: 0 -1.5rem;
  padding: 1.5rem;
  box-shadow: inset 0px -11px 8px -10px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
}
#form-item-sequence-types .el-radio-button .sequence-type-description, #form-item-sequence-types .el-radio-button .sequence-type-description-danger {
  white-space: break-spaces;
}

.message.shine {
  animation: shine-fade 2s linear;
}
@keyframes shine-fade {
  0%, 100% {
    background-color: rgba(0, 191, 80, 0);
    display: none !important;
  }
  50% {
    background-color: rgba(0, 191, 80, 0.1);
    display: block !important;
  }
}

.sequence-step-preview {
  border-top: 1px solid #f9f9f9;
  margin-top: 1rem;
}

.sequence-message {
  display: inline-block;
  position: relative;
  padding: 1.2rem;
  background: #f3f3f3;
  border-radius: 8px 8px 0 8px;
  max-width: 400px;
  text-align: left;
}
.sequence-message .fa-caret-left {
  position: absolute;
  right: 0;
  bottom: -1.2rem;
  color: #f3f3f3;
  font-size: 40px;
}

.w-80 {
  width: 80% !important;
}

/* integration settings */
.crm-integrations-unavailable {
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 24px;
  padding: 30px;
}

.integration-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.sequence-switch {
  padding: 0.5rem 0.7rem;
  border-radius: 4px;
  height: 40px;
}
.sequence-switch.running {
  border: 1px solid #00bf50;
}
.sequence-switch.invalid {
  border: 1px solid #C4183C;
}
.sequence-switch.paused {
  border: 1px solid #f4b30d;
}

.hover-lower-opacity:hover {
  color: #00bd50 !important;
}
.hover-lower-opacity:hover h1, .hover-lower-opacity:hover h2, .hover-lower-opacity:hover h3, .hover-lower-opacity:hover h4, .hover-lower-opacity:hover h5, .hover-lower-opacity:hover h6, .hover-lower-opacity:hover div, .hover-lower-opacity:hover span, .hover-lower-opacity:hover p, .hover-lower-opacity:hover label, .hover-lower-opacity:hover small {
  color: #00bd50 !important;
}
.hover-lower-opacity:hover span, .hover-lower-opacity:hover p, .hover-lower-opacity:hover label, .hover-lower-opacity:hover small {
  opacity: 0.8;
}

.hover-lower-opacity-warning:hover {
  color: #ffb400 !important;
  cursor: pointer;
}
.hover-lower-opacity-warning:hover h1, .hover-lower-opacity-warning:hover h2, .hover-lower-opacity-warning:hover h3, .hover-lower-opacity-warning:hover h4, .hover-lower-opacity-warning:hover h5, .hover-lower-opacity-warning:hover h6, .hover-lower-opacity-warning:hover div, .hover-lower-opacity-warning:hover span, .hover-lower-opacity-warning:hover p, .hover-lower-opacity-warning:hover label, .hover-lower-opacity-warning:hover small {
  color: #ffb400 !important;
}
.hover-lower-opacity-warning:hover span, .hover-lower-opacity-warning:hover p, .hover-lower-opacity-warning:hover label, .hover-lower-opacity-warning:hover small {
  opacity: 0.8;
}

.hover-lower-opacity-primary:hover {
  color: #409eff !important;
  cursor: pointer;
}
.hover-lower-opacity-primary:hover h1, .hover-lower-opacity-primary:hover h2, .hover-lower-opacity-primary:hover h3, .hover-lower-opacity-primary:hover h4, .hover-lower-opacity-primary:hover h5, .hover-lower-opacity-primary:hover h6, .hover-lower-opacity-primary:hover div, .hover-lower-opacity-primary:hover span, .hover-lower-opacity-primary:hover p, .hover-lower-opacity-primary:hover label, .hover-lower-opacity-primary:hover small {
  color: #409eff !important;
}
.hover-lower-opacity-primary:hover span, .hover-lower-opacity-primary:hover p, .hover-lower-opacity-primary:hover label, .hover-lower-opacity-primary:hover small {
  opacity: 0.8;
}

.hover-lower-opacity-danger:hover {
  color: #f56c6c !important;
  cursor: pointer;
}
.hover-lower-opacity-danger:hover h1, .hover-lower-opacity-danger:hover h2, .hover-lower-opacity-danger:hover h3, .hover-lower-opacity-danger:hover h4, .hover-lower-opacity-danger:hover h5, .hover-lower-opacity-danger:hover h6, .hover-lower-opacity-danger:hover div, .hover-lower-opacity-danger:hover span, .hover-lower-opacity-danger:hover p, .hover-lower-opacity-danger:hover label, .hover-lower-opacity-danger:hover small {
  color: #f56c6c !important;
}
.hover-lower-opacity-danger:hover span, .hover-lower-opacity-danger:hover p, .hover-lower-opacity-danger:hover label, .hover-lower-opacity-danger:hover small {
  opacity: 0.8;
}

.button-select .el-input__inner {
  border-radius: 20px;
}
.button-select .el-tag {
  border-radius: 12px !important;
  padding-left: 0.4rem;
  padding-right: 0.8rem;
}
.button-select .el-select__tags {
  padding-left: 0.2rem;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
}

span.nav-icon.messenger img {
  filter: invert(30%);
  height: 24px;
  width: 24px;
}

span.nav-icon.active.messenger img {
  filter: invert(1) contrast(500%);
}

.report-dispositions-table table.el-table__footer td {
  background-color: aliceblue;
}

.btn-contact-shortcut,
.input-contact-shortcut input {
  height: 43.99px;
}

#btn-mark-unanswered-read {
  border-left: 1px solid #DCDFE6;
}

a:hover.is-underline {
  text-decoration: underline !important;
}

.force-full-height .sidebar-full-height {
  height: calc(100vh - 25px) !important;
}

.disabled-wrapper {
  display: inline-block !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

#toolbox-scheduled-msg .el-select {
  width: 150px;
}
#toolbox-scheduled-msg .el-select .el-input--small {
  font-size: 14px !important;
}
#toolbox-scheduled-msg .el-select .el-input__inner {
  height: 37px !important;
}

#popover-scheduled-msg {
  max-height: 250px;
  overflow-y: auto;
}

.messenger-info, .messenger-info a {
  font-size: 0.8rem;
}

.message-input {
  border: none;
  max-height: 15rem;
  height: 38px;
  resize: none;
  border-radius: 5px 5px 0 0;
  overflow-y: auto;
}

.message-input:disabled {
  cursor: not-allowed;
}

.message-actions {
  min-height: 1rem;
  border-top: 1px solid transparent;
}

.message-actions .action-item {
  font-size: 1rem;
}

.message-actions .action-item i {
  font-size: 1.2rem;
}

.attachment-preview {
  border: solid 1px rgba(234, 234, 234, 0.7803921569);
  padding: 2px;
  margin-right: 1rem;
  border-radius: 5px;
  box-shadow: 1px 1px 1px #ddd;
  word-break: break-all;
  position: relative;
  flex-shrink: 0;
}

.attachment-preview img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.attachment-preview:hover .attachment-preview-close {
  visibility: visible;
}

.attachment-preview-close {
  position: absolute;
  top: -7px;
  right: -7px;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 4px !important;
  padding-bottom: 1px !important;
  visibility: hidden;
}

.schedule-dialog .el-dialog__body {
  padding: 10px 23px;
}

.display-table {
  display: table !important;
}

.response-error-container {
  max-height: 500px;
  line-height: 2em;
}

.right-10-per {
  right: 10%;
}

.ss-messenger-frame {
  height: 47px;
  width: 146px;
  overflow: hidden;
  margin-left: 15px;
  margin-top: 0px;
  position: relative;
}

.ss-messenger-iframe-wrapper-click {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  cursor: pointer;
}

.search-toolbar {
  width: 100%;
}
@media (max-width: 768px) {
  .search-toolbar {
    margin-bottom: 10px;
  }
}
@media (min-width: 576px) {
  .search-toolbar {
    width: 300px;
  }
}

.border-bottom-dashed {
  border-bottom: 1px dashed;
}

.contact-merge-confirm .el-message-box__content {
  padding: 1.5rem 1.5rem;
}
.contact-merge-confirm .el-message-box__status {
  top: 0;
  transform: translateY(0);
}
.contact-merge-confirm .el-message-box__status .el-icon-info {
  font-size: 18px !important;
}
.contact-merge-confirm .el-message-box__message p {
  font-size: 14px;
  line-height: 18px;
}
.contact-merge-confirm .el-message-box__btns {
  display: flex;
}
.contact-merge-confirm .el-message-box__btns button:nth-child(2) {
  text-overflow: ellipsis;
  overflow: hidden;
  color: #606266;
  background: #FFF;
  border: 1px solid #DCDFE6;
}
.contact-merge-confirm .el-message-box__btns button:nth-child(2):hover {
  background-color: rgba(0, 191, 80, 0.1) !important;
  color: #00BF50 !important;
  border-color: rgba(0, 191, 80, 0.4) !important;
}

.pointer-not-allowed {
  cursor: not-allowed !important;
}

.dropdown-toggle-caret-none .dropdown-toggle::after {
  display: none;
}

#avatar-agent-statuses .dropdown-item.btn-disabled:hover {
  color: #6c757d;
  background-color: transparent;
}
#avatar-agent-statuses .dropdown-item.btn-disabled .status-badge {
  opacity: 0.5;
}

.agent-status-filter .el-select-dropdown__wrap {
  max-height: 450px !important;
}

.scroll-y {
  overflow-y: auto !important;
}

.min-height-10-vh {
  min-height: 10vh !important;
}

.min-height-20-vh {
  min-height: 20vh !important;
}

.min-height-40-vh {
  min-height: 40vh !important;
}

.max-height-60-vh {
  max-height: 60vh !important;
}

.bg-status-offline {
  background: #828282;
}

.bg-status-online,
.bg-workflow-success {
  background: #27ae60;
}

.bg-status-busy,
.bg-workflow-error {
  background: #eb5757;
}

.bg-status-on-break {
  background: #f2994a;
}

.bg-status-on-call {
  background: #2f80ed;
}

.bg-status-wrap-up {
  background: #9b51e0;
}

.bg-status-ringing {
  background: #00c0cc;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.import-log-empty-text {
  line-height: 30px !important;
}
.import-log-empty-text .import-log-note {
  font-size: 14px;
  word-break: break-word;
}

.el-tooltip__popper.is-power-dialer-start-button-tooltip {
  color: #606266;
  background-color: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  line-height: 1.5;
  border: 1px solid #EBEEF5;
}
.el-tooltip__popper.is-power-dialer-start-button-tooltip .popper__arrow {
  border-bottom-color: #EBEEF5 !important;
  filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
}
.el-tooltip__popper.is-power-dialer-start-button-tooltip .popper__arrow::after {
  border-bottom-color: #FFFFFF !important;
}

.el-tooltip__popper.is-upgrade-tooltip {
  color: #FFFFFF;
  background-color: #256EFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  line-height: 1.5;
  border-radius: 12px;
  border: 1px solid #256EFF;
}
.el-tooltip__popper.is-upgrade-tooltip .popper__arrow {
  border-top-color: #256EFF !important;
  border-bottom-color: #256EFF !important;
  filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
}
.el-tooltip__popper.is-upgrade-tooltip .popper__arrow::after {
  border-top-color: #256EFF !important;
  border-bottom-color: #256EFF !important;
}

.custom-confirmation-dialog-class .el-message-box__title {
  margin-bottom: 10px;
  margin-right: 1em;
}
.custom-confirmation-dialog-class .el-message-box__btns {
  margin-top: 1em;
  display: flex;
}

.min-w-0 {
  min-width: 0 !important;
}

.wrap-auto-margin > div, .wrap-auto-margin > span {
  margin: 4px;
}
.wrap-auto-margin.margin-x > div, .wrap-auto-margin.margin-x > span {
  margin: 0 4px;
}
.wrap-auto-margin.margin-y > div, .wrap-auto-margin.margin-y > span {
  margin: 4px 0;
}

.min-width-210 {
  min-width: 210px !important;
}

.min-width-240 {
  min-width: 240px !important;
}

.alo-empty-text {
  line-height: 25px;
}
.alo-empty-text .empty-text-subject {
  font-size: 18px;
}
.alo-empty-text .empty-text-info {
  font-size: 16px;
}

.borderless-input input {
  border: 0px !important;
}

.integration-tabs .el-tabs__item {
  font-weight: bold !important;
  font-size: 16px;
}

.card-plan-usage {
  color: #121212;
}
.card-plan-usage .header {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.customer-report-issue-dialog {
  background: none !important;
}

.audio-player {
  width: 300px;
  margin-top: 10px;
}

.integration-api-titles {
  font-size: 1.6rem;
}

.alo-button {
  border-radius: 8px !important;
  padding: 7px 12px !important;
}

.power-dialer-session-setting-form {
  border-radius: 8px !important;
  padding: 20px 27px;
}
.power-dialer-session-setting-form .el-dialog__header {
  display: none;
}
.power-dialer-session-setting-form .el-dialog__body {
  padding: 0px !important;
}

.dialer-session-setting-item:not(:last-child) {
  border-bottom: solid 1px #ebebeb;
}
.dialer-session-setting-item:hover {
  background: #ebebeb;
}
.dialer-session-setting-item:hover .delete {
  display: block;
}
.dialer-session-setting-item .dialer-session-setting-edit {
  cursor: pointer;
}
.dialer-session-setting-item .delete {
  cursor: pointer;
  display: none;
}

.power-dialer-form-input {
  height: 22px;
  font-size: 16px;
  border: none;
  padding: 1px 7px;
  width: fit-content;
}
.power-dialer-form-input:focus {
  border: solid 1px #ebebeb;
  background: white;
  border-radius: 8px;
}

#powerDialerSessionSettingForm .title-label {
  font-size: 13px;
}

.inbox-filter .inbox-empty-image {
  width: 40% !important;
}
.inbox-filter .inbox-settings-table {
  border-radius: 6px;
  margin-top: 1em;
  min-height: 300px;
  max-height: 500px;
  overflow: auto;
}
.inbox-filter .inbox-settings-table .el-table__empty-block {
  padding-top: 2em;
  min-height: 200px !important;
}
.inbox-filter .inbox-settings-table .inbox-table-row .action-button {
  font-size: 12px !important;
  visibility: hidden;
}
.inbox-filter .inbox-settings-table .inbox-table-row .update-button {
  color: #256EFF;
}
.inbox-filter .inbox-settings-table .inbox-table-row .delete-button {
  margin-left: -3em;
}
.inbox-filter .inbox-settings-table .inbox-table-row:hover .action-button {
  visibility: unset;
}
.inbox-filter .settings-title {
  line-height: 31px;
}

.inbox-filter-dialog button {
  border-radius: 6px;
}
.inbox-filter-dialog input {
  font-size: 14px;
}
.inbox-filter-dialog .filter-name input {
  font-size: 16px;
}
.inbox-filter-dialog .filter-name-text {
  font-size: 16px;
  color: #040404;
  font-weight: 600;
}
.inbox-filter-dialog .filter-name-edit {
  font-size: 14px;
}
.inbox-filter-dialog .filter-name-edit i {
  color: #62666E;
}
.inbox-filter-dialog .filter-name-edit:hover i {
  color: #0067F4;
}
.inbox-filter-dialog .el-icon-close {
  visibility: hidden;
}
.inbox-filter-dialog .el-dialog__body {
  padding: 2em 1em 0em 1em !important;
  max-height: 90vh;
  min-height: 80vh;
}
.inbox-filter-dialog .el-dialog__body .inbox-main-form {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 10px;
}
.inbox-filter-dialog .inbox-filter-section-title {
  font-weight: 600;
  color: #040404;
}
.inbox-filter-dialog .el-dialog {
  overflow: hidden;
  border-radius: 10px;
}
.inbox-filter-dialog .dialog-title {
  color: #95989E;
}
.inbox-filter-dialog .input-pl-0 input {
  padding-left: 0 !important;
}
.inbox-filter-dialog .override-switch .el-form-item__label {
  width: 100%;
  line-height: 20px;
  margin-top: 0.5em;
}
.inbox-filter-dialog .handling-section-override .el-form-item__label {
  line-height: 20px;
  margin-top: 15px;
}
.inbox-filter-dialog .el-form-item__label {
  font-size: 13px !important;
  text-align: left;
  word-break: break-word;
  color: #62666E !important;
}
.inbox-filter-dialog .form-cancel-button {
  background: #95989E;
  color: #fff !important;
}
.inbox-filter-dialog .form-cancel-button:hover {
  background-color: #909399 !important;
  color: #fff !important;
}
.inbox-filter-dialog .hidden-date-field {
  visibility: hidden;
  position: absolute;
  left: 0;
}

/**
 * Font Manipulators
 */
.font-16-600, .inbox-filter .settings-title {
  font-size: 16px;
  font-weight: 600;
}

.font-14-400, .inbox-filter-dialog .filter-name, .inbox-filter-dialog .dialog-title {
  font-size: 14px;
  font-weight: 400;
}

/**
 * Scroll bar manipulators
 */
.alo-thin-scrollbar, .inbox-filter, .inbox-filter-dialog {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.alo-thin-scrollbar ::-webkit-scrollbar, .inbox-filter ::-webkit-scrollbar, .inbox-filter-dialog ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: initial;
}
.alo-thin-scrollbar ::-webkit-scrollbar-track, .inbox-filter ::-webkit-scrollbar-track, .inbox-filter-dialog ::-webkit-scrollbar-track {
  background: #f2f2f2;
}
.alo-thin-scrollbar ::-webkit-scrollbar-thumb, .inbox-filter ::-webkit-scrollbar-thumb, .inbox-filter-dialog ::-webkit-scrollbar-thumb {
  background: #d8d8d8;
}
.alo-thin-scrollbar ::-webkit-scrollbar-thumb:hover, .inbox-filter ::-webkit-scrollbar-thumb:hover, .inbox-filter-dialog ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/**
 * Button manipulators
 */
.alo-blue-button, .inbox-filter .create-settings-button, .inbox-filter-dialog .form-save-button {
  background-color: #256EFF !important;
  color: #fff !important;
}
.alo-blue-button:hover, .inbox-filter .create-settings-button:hover, .inbox-filter-dialog .form-save-button:hover {
  background-color: #145ca4 !important;
  color: #fff !important;
}

/**
 * Positioning items
 */
.right-0 {
  right: 0 !important;
}

.width-fit-content {
  width: fit-content;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.store-icons img {
  width: 133px;
  height: 40px;
}
.store-icons .ios-container {
  margin-left: 2px;
}

.agent-status-button {
  font-size: 14px !important;
  padding: 2px !important;
}

.agents-condensed-summary-report-table thead > tr > th,
.agents-condensed-summary-report-table tbody > tr > th,
.agents-condensed-summary-report-table tfoot > tr > th,
.agents-condensed-summary-report-table thead > tr > td,
.agents-condensed-summary-report-table tbody > tr > td,
.agents-condensed-summary-report-table tfoot > tr > td {
  padding: 1px !important;
}

.broadcast-schedule-input input {
  height: 35px !important;
  border-radius: 6px;
}

.voicemail-attention-box {
  width: 731px;
  height: 149px;
  margin-left: 18.5px;
  margin-top: 11px;
}
.voicemail-attention-box .note {
  width: 478px;
  height: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.0025em;
  color: #000000;
}
.voicemail-attention-box .contain {
  display: flex;
  margin-bottom: 7px;
  align-items: center;
}
.voicemail-attention-box .voicemail-confirmation-attention {
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  align-items: flex-start;
  padding: 3px 8px;
  background: #F2C94C;
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.0025em;
  /* Grey/100 */
  color: #040404;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px 3px 10px;
}
.voicemail-attention-box .icon {
  margin-left: 2.25px;
}
.voicemail-attention-box .message {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.0025em;
  color: #000000;
  border: 3px solid #F2C94C;
  box-sizing: border-box;
}

.alo-custom-radio-buttons .alo-radio-button-danger.is-active .el-radio-button__inner {
  background-color: #EB5757 !important;
  border-color: #EB5757 !important;
  box-shadow: -1px 0 0 0 #EB5757 !important;
  color: #fff !important;
}
.alo-custom-radio-buttons .alo-radio-button-warning .el-radio-button__inner {
  color: #D29F02 !important;
}
.alo-custom-radio-buttons .alo-radio-button-warning.is-active .el-radio-button__inner {
  background-color: #D29F02 !important;
  border-color: #D29F02 !important;
  box-shadow: -1px 0 0 0 #D29F02 !important;
  color: #fff !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-title {
  font-size: 1.2rem;
}

#adminAccountFilters {
  position: absolute;
  z-index: 1;
}

#adminAccountFilters .account-filters {
  padding: 0px 25px !important;
  max-height: calc(88vh - 3.2rem);
  overflow: auto;
}

#adminAccountFilters .el-drawer__header {
  background: #F4F4F6;
  padding: 15px 19px;
}

#adminAccountFilters .el-drawer__header > :first-child {
  font-weight: 500;
  color: black !important;
  font-size: 18px;
}

@media only screen and (max-width: 1300px) {
  #adminAccountFilters .el-drawer {
    width: 40% !important;
  }
}
@media only screen and (max-width: 1100px) {
  #adminAccountFilters .el-drawer {
    width: 60% !important;
  }
}
@media only screen and (max-width: 900px) {
  #adminAccountFilters .el-drawer {
    width: 70% !important;
  }
}
@media only screen and (max-width: 600px) {
  #adminAccountFilters .el-drawer {
    width: 100% !important;
  }
}

.admin-accounts-page .admin-buttons-container .admin-buttons-inner {
  float: right;
}
@media only screen and (max-width: 991px) {
  .admin-accounts-page .admin-buttons-container {
    margin-top: 10px;
  }
  .admin-accounts-page .admin-buttons-container .admin-buttons-inner {
    float: left;
  }
}
@media only screen and (max-width: 767px) {
  .admin-accounts-page .admin-action-button {
    margin-top: 10px;
  }
  .admin-accounts-page .admin-buttons-inner .filter-buttons {
    width: 100%;
    margin-bottom: 10px;
  }
}

.admin-filter-button-container {
  position: absolute;
  width: 100%;
}

.admin-accounts-page {
  position: relative;
  overflow-x: hidden;
}

.alo-default-app-selector-dialog .el-dialog {
  border-radius: 6px;
}
.alo-default-app-selector-dialog .alo-default-app-selector-body {
  padding: 1.25rem !important;
}
.alo-default-app-selector-dialog .alo-default-app-selector-title {
  font-size: 1.125rem !important;
}

.default-app-selector .el-button--mini {
  border-radius: 0.25rem !important;
}
.default-app-selector .default-app-dropdown .el-button-group {
  display: flex !important;
}
.default-app-selector .default-app-dropdown .el-button-group .el-button:first-child {
  padding-left: 10px !important;
  padding-right: 14px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.default-app-selector .default-app-dropdown .el-button-group .el-button:last-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.alo-popover.el-popper[x-placement^=bottom] {
  margin-top: 5px;
}
.alo-popover .popper__arrow {
  display: none !important;
}

.default-app-button {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.default-app-selector .default-app-button {
  border-radius: 0.25rem !important;
}

.default-app-dropdown {
  margin-top: 0 !important;
}
.default-app-dropdown .label-text {
  font-weight: 400;
  color: #62666E;
}
.default-app-dropdown .el-dropdown-menu {
  margin: 0 !important;
}
.default-app-dropdown .popper__arrow {
  display: none !important;
}

.lost-connection-dialog .el-dialog__body {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.dialog-business-hours {
  max-width: 678px;
}
.dialog-business-hours.el-message-box {
  padding-bottom: 0;
}
.dialog-business-hours .el-message-box__header {
  border: none;
  padding: 20px 24px 0;
}
.dialog-business-hours .el-message-box__content {
  padding: 28px 24px 0;
}
.dialog-business-hours .el-message-box__btns {
  display: flex;
  text-align: center;
  padding: 28px 24px 20px;
}
.dialog-business-hours .el-message-box__btns button {
  width: 100%;
}
.dialog-business-hours .el-message-box__btns button:first-child {
  margin: 0 0.3rem 0 0;
}
.dialog-business-hours .el-message-box__btns button:nth-child(2) {
  margin: 0 0 0 0.3rem;
}

.display-grid {
  display: grid !important;
}

.alert-grey {
  background-color: #EBEBEB;
  border-color: #dedede;
  color: #898989;
}
.alert-grey hr {
  border-top-color: #d2d2d2;
}
.alert-grey .alert-link {
  color: #707070;
}

.sequence-holiday-banner .el-alert__description {
  margin: 0px 0 0 !important;
}

.info-alert-box .el-alert__description {
  margin: 0px 0 0 !important;
}

.alo-talk-info-container {
  margin-left: 6px;
  margin-right: 16px;
}
.alo-talk-info-container .alo-talk-info-button {
  color: #256EFF !important;
  font-weight: 500;
  font-size: 14px;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.alo-talk-info-container .alo-talk-info-button img {
  margin-top: -2px;
}

.border-blue {
  border-color: #256EFF !important;
}

.border-4 {
  border-width: 4px !important;
}

.status-background {
  background-color: #EBEBEB !important;
}

.nav > li > a .nav-icon i.fa-fighter-jet {
  font-size: 18px !important;
  line-height: 36px !important;
}

.form-capture-token-reset-pop-up .el-message-box__header {
  border: none;
}
.form-capture-token-reset-pop-up .el-message-box__message p {
  line-height: 22px;
  font-size: 14px;
}

.integration-settings-drawer {
  color: #010101;
}
.integration-settings-drawer .el-collapse-item__arrow {
  margin: 0 8px 0 2px;
}
.integration-settings-drawer .el-drawer__body {
  overflow-y: auto;
}
.integration-settings-drawer h3, .integration-settings-drawer h5, .integration-settings-drawer label {
  color: #010101;
}

.flex-1 {
  flex: 1;
}

.flex-3 {
  flex: 3;
}

.webrtc-label-position-transfer {
  position: relative;
  left: 220px;
  bottom: 20px;
}

.step-editor-email-buttons .btn.el-popover__reference {
  line-height: 1.75;
  margin-bottom: 0;
}

#el-dialog-compliance-wizard .el-dialog__body {
  word-break: break-word !important;
}

a.el-green-hover:hover {
  color: #00BF50 !important;
}

.half-opaq {
  opacity: 0.5 !important;
}

.line-height-normal {
  line-height: normal;
}

.circle-number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-color: #1f67ef;
  color: white;
  border-radius: 50%;
  font-size: 10px;
  margin-right: 5px;
}

.circle-number-title {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #1f67ef;
  color: white;
  border-radius: 50%;
  font-size: 13px;
}

.g-recaptcha {
  transform: scale(0.8);
  transform-origin: 0 0;
}

/* Default Styles */
.terms-conditions-dialog > .el-dialog {
  width: 70%;
  top: 4vh;
  margin-top: 0 !important;
}

.terms-iframe {
  border: 0;
  width: 100%;
  height: 50vh; /* Default iframe height */
}

@media (max-width: 479px) {
  .terms-conditions-dialog > .el-dialog {
    width: 100%;
    top: 0vh;
    margin-top: 0 !important;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .terms-conditions-dialog > .el-dialog {
    width: 90%;
    top: 0vh;
    margin-top: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .terms-conditions-dialog > .el-dialog {
    width: 80%;
    top: 0vh;
    margin-top: 0 !important;
  }
}
@media (min-width: 1024px) {
  .terms-conditions-dialog > .el-dialog {
    width: 80%;
    top: 4vh;
    margin-top: 0 !important;
  }
}
@media (max-height: 479px) {
  .terms-iframe {
    height: 40vh;
  }
}
@media (min-height: 480px) and (max-height: 767px) {
  .terms-iframe {
    height: 44vh;
  }
}
@media (min-height: 768px) and (max-height: 1023px) {
  .terms-iframe {
    height: 56vh;
  }
}
@media (min-height: 1024px) {
  .terms-iframe {
    height: 58vh;
  }
}
.info-text {
  color: #000 !important;
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.beta-box {
  position: absolute;
  top: 7px;
  left: 7px;
  transform: translate(0, 0);
}

.beta-label {
  background: #ffa000;
}

@media (max-width: 1199px) {
  #page-title {
    display: none;
  }
  .referralhero {
    display: none !important;
  }
}
.trial-banner.button {
  height: 100%;
}
.trial-banner .activator-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 34px;
  border-radius: 4px;
  border: none;
  background-color: rgba(37, 255, 72, 0.12) !important;
  cursor: pointer;
}
.trial-banner .activator-button:hover {
  background-color: rgba(37, 255, 73, 0.15) !important;
}
.trial-banner .activator-icon {
  flex-shrink: 0;
  color: #00A04F;
  width: 24px;
  height: 24px;
}
.trial-banner .video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2247483000; /* Updated to avoid announce kit banner overlapping this video modal  */
  padding: 40px;
}
.trial-banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.trial-banner .modal-content {
  position: relative;
  width: 80%;
  max-width: 800px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 20px;
}
.trial-banner .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px 15px;
}
.trial-banner .modal-header h3 {
  margin: 0;
  font-size: 1.5em;
  color: #333;
}
.trial-banner .close-button {
  background: none;
  border: none;
  font-size: 18px; /* Smaller font size */
  font-weight: 300; /* Lighter font weight */
  line-height: 1;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
}
.trial-banner .close-button:hover {
  color: #555; /* Slightly darker on hover for feedback */
}
.trial-banner .modal-body {
  padding: 0;
}
.trial-banner .modal-body iframe {
  width: 100%;
  height: 50vh;
  border: none;
}
.trial-banner .modal-notes {
  padding: 15px;
  font-size: 1em;
  color: #666;
  font-weight: normal;
  text-align: center;
}
.trial-banner .modal-link {
  text-align: center;
  color: var(--Primary-Blue, #256EFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.button-index {
  z-index: 1 !important;
  margin-right: 1rem;
}
.button-index .q-btn__wrapper {
  margin-bottom: 3px;
}
.button-index .block {
  padding-right: 20px;
  padding-left: 20px;
  min-width: 200px;
}
.button-index > a {
  color: #256EFF !important;
  font-size: 0.875rem;
  text-decoration: none !important;
}
.button-index > a:hover {
  text-decoration: underline !important;
}

.demo--button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
}
.demo--button > img {
  margin-right: 0.5rem;
}

.integrations.banner {
  padding: 20px 16px;
  background-color: #FEF8E8;
}
.integrations.banner .text {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.integrations.banner .text .link, .integrations.banner .text .link--bold {
  color: #256EFF;
}
.integrations.banner .text .link--bold {
  font-weight: 600;
}
.integrations.banner .text a, .integrations.banner .text div {
  display: inline;
}

.dialog-kyc {
  border-radius: 30px !important;
  background-color: white;
  color: black;
  width: 630px !important;
}
.dialog-kyc-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem !important;
  padding-top: 0 !important;
}
.dialog-kyc h6 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  color: black;
}
.dialog-kyc p {
  font-size: 0.875rem;
  font-weight: 400;
  padding-left: 3.4rem;
  padding-right: 3.4rem;
  padding-top: 10px;
  line-height: 1.25rem;
  letter-spacing: 0.01786em;
  color: black;
  word-break: initial;
}
.dialog-kyc .el-dialog__body {
  padding: 30px 20px 0px 20px !important;
}

.signup--title {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 40px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.signup--subtitle {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.signup-container {
  background: linear-gradient(to bottom right, #b3c9f7, #bcf7bd);
  backdrop-filter: blur(79.8780517578px);
}
.signup-wrapper {
  padding: 30px;
  border-radius: 30px;
  background-color: #fff;
  backdrop-filter: blur(100px);
}
.signup-submit__button {
  background-color: #256EFF !important;
  color: #fff !important;
  border-radius: 30px !important;
  text-transform: none;
}
.signup-submit__button:hover {
  background-color: #145ca4 !important;
}
.signup-checkbox__link {
  color: #256EFF;
  font-weight: 500;
  text-decoration: none;
}
.signup-checkbox__link:hover {
  color: #256EFF;
  text-decoration: underline;
}
.signup-checkbox__container.is-checked.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ffffff !important;
}
.signup-wrapper__success {
  display: flex;
  align-items: center;
  padding: 30px;
  border-radius: 30px;
  background-color: #fff;
  backdrop-filter: blur(100px);
  text-align: center;
  max-width: 600px;
  min-height: 300px;
}
.signup-wrapper__success__title {
  color: #646569;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
}
.signup-wrapper__success__subtitle {
  color: #646569;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}
.signup-meeting-wrapper {
  border-radius: 30px;
  backdrop-filter: blur(100px);
  background-color: #fff;
  width: 800px;
  height: 800px;
}
@media (max-height: 767px) {
  .signup-meeting-wrapper {
    width: 720px;
    height: 670px;
  }
}
@media (max-width: 767px) {
  .signup-meeting-wrapper {
    width: 480px;
    height: 500px;
  }
}
@media (max-width: 479px) {
  .signup-meeting-wrapper {
    width: 380px;
    height: 660px;
  }
}
@media (max-width: 400px) {
  .signup-meeting-wrapper {
    width: 360px;
    height: 660px;
  }
}
@media (max-width: 380px) {
  .signup-meeting-wrapper {
    width: 350px;
    height: 660px;
  }
}
@media (max-width: 370px) {
  .signup-meeting-wrapper {
    width: 340px;
    height: 660px;
  }
}

.iti {
  width: 100% !important;
}

.iti__tel-input {
  width: 100% !important;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.iti__tel-input:focus {
  border-color: #00BF50 !important;
}
.iti__tel-input.error {
  border-color: #EB5757 !important;
}

.toast-kyc {
  border-radius: 10px !important;
  /*width: 80% !important;
  top: 20px !important;*/
}
.toast-kyc .el-dialog__header {
  padding: 0px !important;
}

.export-summary-dropdown {
  z-index: 100;
}

.twilio-badge {
  color: #fff;
  background-color: #F22E46;
}

.telnyx-badge {
  color: #000;
  background-color: #00E3AA;
}

.commio-badge {
  color: #fff;
  background-color: #0277FF;
}

.hosted-on-badge {
  color: #fff;
  background-color: #9B51E0;
}

.custom-message-display > span.suffix.negative--margin {
  margin-left: -3px !important;
}

.input-field--icon {
  display: flex !important;
  align-items: center;
  height: 100%;
  padding-right: 6px;
  font-size: 20px;
  cursor: pointer;
}

.ring-group {
  height: 300px;
  overflow-y: auto;
  padding-right: 16px;
  padding-left: 16px;
}
.ring-group--title {
  font-size: 16px;
  font-weight: 600;
}
.ring-group--divider {
  border-top: 1px solid #ebebeb;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-to-teams--dialog .el-dialog__body {
  padding: 0 25px 40px !important;
}
.user-to-teams .title {
  font-size: 16px;
  font-weight: 600;
}
.user-to-teams--label {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
}
.user-to-teams .description {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  padding-bottom: 12px;
}
.user-to-teams--divider {
  border-top: 1px solid #ebebeb;
  padding-top: 10px;
  padding-bottom: 10px;
}
.user-to-teams .content {
  overflow-y: auto;
  height: 300px;
  padding-right: 16px;
  padding-left: 16px;
}

.teams-tab .title {
  font-size: 22px;
  font-weight: 600;
  text-align: left;
}
.teams-tab .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}
.teams-tab .actions-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #F6F6F6;
  border-top: 3px solid #E6E6E6;
  width: 100%;
}
.teams-tab .actions-bar .text {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  min-width: 60px;
}
.teams-tab .actions-bar .w-60 {
  width: 60%;
}

.teams .dialog--title {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
}
.teams .el-dialog__body {
  padding: 18px 25px 30px !important;
}

.has_conflict_color_outline {
  border-color: #FA003F;
  color: #FA003F;
}

.has_conflict_color_outline:hover {
  border-color: #FA003F;
  background-color: #FA003F;
  color: #FFF;
}

.has_conflict_color_background {
  background-color: #FA003F;
}

.conflicted_numbers_modal {
  color: #040404 !important;
}
.conflicted_numbers_modal .el-table {
  color: #040404 !important;
}
.conflicted_numbers_modal .el-dialog__header {
  text-align: center;
  padding-top: 40px;
}
.conflicted_numbers_modal .el-dialog__title {
  font-size: 16px;
}
.conflicted_numbers_modal .el-dialog__headerbtn {
  top: 8px;
  right: 8px;
}
.conflicted_numbers_modal .el-dialog__headerbtn .el-dialog__close {
  color: #00BF4A;
}
.conflicted_numbers_modal .el-dialog__body {
  padding: 10px 38px 0 38px !important;
}
.conflicted_numbers_modal .conflicted_contacts_table tbody tr:last-child td {
  border-bottom: none;
}

.ordered-user-team-selector .layer-label {
  width: 76px;
  background-color: #ebebeb;
  border-radius: 4px;
  color: #000000;
  text-align: center;
  margin-right: 12px;
}
.ordered-user-team-selector .select-label {
  width: 50px;
  margin-right: 6px;
  text-align: center;
  height: 100%;
  border-radius: 4px;
}

.el-select.teams-select .el-tag {
  color: #256EFF !important;
  background: #D3E2FF !important;
  border-color: #256EFF !important;
  border-radius: 2px !important;
}
.el-select.teams-select .el-tag__close {
  background-color: #256EFF !important;
}
.el-select.teams-select .el-tag__close:hover {
  background-color: #3b79f5 !important;
}

.el-select-dropdown.teams-select-popper .el-select-dropdown__item.selected {
  border-left: 3px solid #256EFF !important;
  color: #256EFF !important;
}

.el-select-dropdown.teams-select-popper .el-select-dropdown__item:hover {
  background-color: #D3E2FF !important;
  color: #256EFF !important;
}

.el-select-dropdown.teams-select-popper .el-select-dropdown__item.selected .media .media-body label {
  color: #256EFF !important;
}

.aloai-fa-btn-custom {
  color: white;
  text-shadow: -1px -1px 0 #bababa, 1px -1px 0 #bababa, -1px 1px 0 #bababa, 1px 1px 0 #bababa;
}

.aloai-instructions .el-textarea__inner {
  resize: none;
}

.tags-deprecation-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #FFFFE7;
  border: #F2C94D 1px solid;
  border-radius: 5px;
}
.tags-deprecation-banner .left-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.tags-deprecation-banner .trial--text {
  margin: 0;
  font-size: 0.8rem;
  margin-right: 2rem;
}
@media only screen and (max-width: 1280px) {
  .tags-deprecation-banner .trial-banner {
    flex-direction: column;
    align-items: flex-start;
  }
  .tags-deprecation-banner .left-content {
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
  .tags-deprecation-banner .left-content > .trial--text {
    flex: 1 0 100%;
  }
}

.company-teams-list .el-table__row :hover {
  cursor: pointer;
  color: #00BF50 !important;
}
.company-teams-list .el-table--enable-row-transition .el-table__body td {
  transition: none !important;
}

.ring-group-banner-msg {
  margin-top: 1rem !important;
}

.btn-dark-warning {
  background-color: #FD8709;
}

.wz-text-wrap {
  white-space: normal;
  word-wrap: break-word;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.wz-option-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wz-option-number {
  white-space: nowrap;
  margin-left: auto;
  text-align: right;
}

.wz-title-wrapper {
  display: flex;
  align-items: center;
}

.wz-icon-size {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.wz-title-content {
  display: flex;
  align-items: center;
}

.wz-title-text {
  font-weight: bold;
  font-size: 1.5rem;
}

.line-horizontal-buttons button {
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
}

.wz-pricing-table {
  width: 100%;
  border-collapse: collapse;
}

.wz-pricing-table th,
.wz-pricing-table td {
  border: 1px solid #ccc;
  padding: 10px;
}

.wz-pricing-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.wz-pricing-table td {
  background-color: #ffffff;
  color: #333;
  text-align: left;
}

.wz-pricing-table th,
.wz-pricing-table td {
  width: 33%;
}

.wz-pricing-table tbody tr:hover {
  background-color: #e9ecef;
}

.custom-red-radio .el-radio__input.is-checked + .el-radio__label {
  color: red !important;
}

.custom-red-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: red !important;
  background: red !important;
}

.custom-red-radio.is-bordered.is-checked {
  border-color: red !important;
}

.team-dialog {
  border-radius: 10px !important;
}
.team-dialog .el-dialog__body {
  font-size: 16px;
  font-weight: 400;
  word-break: keep-all;
}

.aloai-promotion-dialog {
  border-radius: 10px !important;
  background: transparent !important;
  position: absolute !important;
  margin: auto !important;
  top: 100px;
  right: 5px;
  width: 400px !important;
}

.ai-info-box {
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 15px;
  font-family: Arial, sans-serif;
  background: linear-gradient(90deg, #9333ea, #6b46c1);
  color: white;
  word-break: break-word;
}
.ai-info-box .ai-info-box-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.ai-info-box .ai-info-box-header .ai-info-box-icon {
  font-size: 24px;
  margin-right: 10px;
}
.ai-info-box .ai-info-box-header .ai-info-box-title {
  font-size: 16px;
  font-weight: bold;
}
.ai-info-box .ai-info-box-content {
  margin-bottom: 10px;
}
.ai-info-box .ai-info-box-content strong {
  font-weight: bold;
}
.ai-info-box .ai-info-box-links a {
  color: #ffe4e6;
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 5px;
}
.ai-info-box .ai-info-box-links a:last-child {
  margin-bottom: 0;
}

/* Container for the entire effect */
.ai-effect-container {
  position: relative;
  border-radius: 0.5rem; /* rounded-lg */
  overflow: hidden;
}

/* Gradient background */
.ai-effect-gradient {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to bottom right, #60A5FA, #A855F7, #EC4899); /* from-blue-400 via-purple-500 to-pink-500 */
  opacity: 0.75;
}

/* Blur effect */
.ai-effect-blur {
  position: absolute;
  inset: 0;
  backdrop-filter: blur(4px); /* backdrop-blur-sm */
}

/* Content container */
.ai-effect-content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9); /* bg-white bg-opacity-90 */
  margin: 2px;
  border-radius: 0.3rem; /* rounded-lg */
}
.ai-effect-content ul, .ai-effect-content ol {
  padding-inline-start: 16px;
}
.ai-effect-content h3 {
  font-size: 13px;
}

/* Gradient text effect */
.ai-effect-gradient-text {
  cursor: pointer;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  color: transparent !important;
  background-image: linear-gradient(to right, #2563EB, #9333EA) !important; /* from-blue-600 to-purple-600 */
}
.ai-effect-gradient-text .el-radio__input.is-disabled + span.el-radio__label {
  color: transparent !important;
}
.ai-effect-gradient-text .el-radio__input.is-checked + .el-radio__label:not(.custom-red-radio .el-radio__label) {
  color: transparent !important;
}

.header-placeholder {
  visibility: hidden;
  width: 100%;
}

@font-face {
  font-family: "BLOKK";
  src: url("../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.eot");
  src: url("../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.woff2") format("woff2"), url("../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.woff") format("woff"), url("../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.otf") format("opentype"), url("../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.ttf") format("truetype"), url("../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.svg#BLOKKRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "BLOKK";
    src: url("../fonts/BLOKKNeue-Regular/BLOKKNeue-Regular.svg") format("svg");
  }
}
body {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  font-size: 0.95rem;
}

button, input, optgroup, select, textarea {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.navside ul {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

#page-title {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

nav-item dropdown {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.navbar-brand {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
  font-size: 30px;
}

.login-brand > span {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
  font-size: 30px;
}

.login-brand {
  font-size: 20px;
  font-weight: 700;
}

.login .el-form-item__error {
  font-size: 11px;
}

#logout-button a i {
  font-size: 150%;
}

.wizard-header h2, .wizard-header h3, .wizard-header h4 {
  font-size: 24px;
  font-weight: bold;
}

.font-120 {
  font-size: 120%;
}

.form-hover-help {
  font-size: 12px;
}

.wizard .form-head {
  font-size: 16px;
}

.status-icon .material-icons,
.status-icon .fa {
  font-size: 24px;
}

.w-200 {
  font-size: 50px;
}

.filter-call-logs .filter-item {
  font-size: 0.8rem;
}
.filter-call-logs .dropdown-item > i {
  font-size: 20px;
}

.call-log-head {
  font-size: 100%;
}

#logout-button a i {
  font-size: 150%;
}

ol.special-ol > li:before {
  font-weight: bold;
  font-size: 28px;
}

.header-info span {
  font-size: 14px;
}

.nav > li > a .nav-text {
  font-size: 16px;
  line-height: 1.625rem;
}

.nav > li.campaigns > a .nav-text {
  font-size: 14px;
}

.nav > li > a .nav-icon i {
  font-size: 20px;
}

.nav > li.campaigns > a .nav-icon i {
  font-size: 18px;
}

.add-campaign {
  font-weight: 400;
}

.filter-select .el-input__inner, .filter-select-contacts .el-input__inner {
  font-weight: 400;
}

.filter-div {
  font-weight: 600;
  font-size: 1rem;
}

.form-control-label {
  font-weight: bold;
}

.p-inline-block {
  font-size: 105%;
}

.conversync .form-control-static {
  font-weight: 600;
}

.mockup {
  font-family: "BLOKK";
  font-size: 24px;
  color: #F0F0F0;
}

.font-alternative {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25), only screen and (min-resolution: 200dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
}
.text-dark {
  color: #1d1d1d !important;
}

.bg-dark {
  background: #1d1d1d !important;
}

.text-primary {
  color: #1976d2 !important;
}

.bg-primary {
  background: #1976d2 !important;
}

.text-secondary {
  color: #26a69a !important;
}

.bg-secondary {
  background: #26a69a !important;
}

.text-accent {
  color: #9c27b0 !important;
}

.bg-accent {
  background: #9c27b0 !important;
}

.text-positive {
  color: #21ba45 !important;
}

.bg-positive {
  background: #21ba45 !important;
}

.text-negative {
  color: #c10015 !important;
}

.bg-negative {
  background: #c10015 !important;
}

.text-info {
  color: #31ccec !important;
}

.bg-info {
  background: #31ccec !important;
}

.text-warning {
  color: #f2c037 !important;
}

.bg-warning {
  background: #f2c037 !important;
}

.text-white {
  color: #fff !important;
}

.bg-white {
  background: #fff !important;
}

.text-black {
  color: #000 !important;
}

.bg-black {
  background: #000 !important;
}

.text-transparent {
  color: transparent !important;
}

.bg-transparent {
  background: transparent !important;
}

.text-separator {
  color: rgba(0, 0, 0, 0.12) !important;
}

.bg-separator {
  background: rgba(0, 0, 0, 0.12) !important;
}

.text-dark-separator {
  color: hsla(0, 0%, 100%, 0.28) !important;
}

.bg-dark-separator {
  background: hsla(0, 0%, 100%, 0.28) !important;
}

.text-red {
  color: #f44336 !important;
}

.text-red-1 {
  color: #ffebee !important;
}

.text-red-2 {
  color: #ffcdd2 !important;
}

.text-red-3 {
  color: #ef9a9a !important;
}

.text-red-4 {
  color: #e57373 !important;
}

.text-red-5 {
  color: #ef5350 !important;
}

.text-red-6 {
  color: #f44336 !important;
}

.text-red-7 {
  color: #e53935 !important;
}

.text-red-8 {
  color: #d32f2f !important;
}

.text-red-9 {
  color: #c62828 !important;
}

.text-red-10 {
  color: #b71c1c !important;
}

.text-red-11 {
  color: #ff8a80 !important;
}

.text-red-12 {
  color: #ff5252 !important;
}

.text-red-13 {
  color: #ff1744 !important;
}

.text-red-14 {
  color: #d50000 !important;
}

.text-pink {
  color: #e91e63 !important;
}

.text-pink-1 {
  color: #fce4ec !important;
}

.text-pink-2 {
  color: #f8bbd0 !important;
}

.text-pink-3 {
  color: #f48fb1 !important;
}

.text-pink-4 {
  color: #f06292 !important;
}

.text-pink-5 {
  color: #ec407a !important;
}

.text-pink-6 {
  color: #e91e63 !important;
}

.text-pink-7 {
  color: #d81b60 !important;
}

.text-pink-8 {
  color: #c2185b !important;
}

.text-pink-9 {
  color: #ad1457 !important;
}

.text-pink-10 {
  color: #880e4f !important;
}

.text-pink-11 {
  color: #ff80ab !important;
}

.text-pink-12 {
  color: #ff4081 !important;
}

.text-pink-13 {
  color: #f50057 !important;
}

.text-pink-14 {
  color: #c51162 !important;
}

.text-purple {
  color: #9c27b0 !important;
}

.text-purple-1 {
  color: #f3e5f5 !important;
}

.text-purple-2 {
  color: #e1bee7 !important;
}

.text-purple-3 {
  color: #ce93d8 !important;
}

.text-purple-4 {
  color: #ba68c8 !important;
}

.text-purple-5 {
  color: #ab47bc !important;
}

.text-purple-6 {
  color: #9c27b0 !important;
}

.text-purple-7 {
  color: #8e24aa !important;
}

.text-purple-8 {
  color: #7b1fa2 !important;
}

.text-purple-9 {
  color: #6a1b9a !important;
}

.text-purple-10 {
  color: #4a148c !important;
}

.text-purple-11 {
  color: #ea80fc !important;
}

.text-purple-12 {
  color: #e040fb !important;
}

.text-purple-13 {
  color: #d500f9 !important;
}

.text-purple-14 {
  color: #a0f !important;
}

.text-deep-purple {
  color: #673ab7 !important;
}

.text-deep-purple-1 {
  color: #ede7f6 !important;
}

.text-deep-purple-2 {
  color: #d1c4e9 !important;
}

.text-deep-purple-3 {
  color: #b39ddb !important;
}

.text-deep-purple-4 {
  color: #9575cd !important;
}

.text-deep-purple-5 {
  color: #7e57c2 !important;
}

.text-deep-purple-6 {
  color: #673ab7 !important;
}

.text-deep-purple-7 {
  color: #5e35b1 !important;
}

.text-deep-purple-8 {
  color: #512da8 !important;
}

.text-deep-purple-9 {
  color: #4527a0 !important;
}

.text-deep-purple-10 {
  color: #311b92 !important;
}

.text-deep-purple-11 {
  color: #b388ff !important;
}

.text-deep-purple-12 {
  color: #7c4dff !important;
}

.text-deep-purple-13 {
  color: #651fff !important;
}

.text-deep-purple-14 {
  color: #6200ea !important;
}

.text-indigo {
  color: #3f51b5 !important;
}

.text-indigo-1 {
  color: #e8eaf6 !important;
}

.text-indigo-2 {
  color: #c5cae9 !important;
}

.text-indigo-3 {
  color: #9fa8da !important;
}

.text-indigo-4 {
  color: #7986cb !important;
}

.text-indigo-5 {
  color: #5c6bc0 !important;
}

.text-indigo-6 {
  color: #3f51b5 !important;
}

.text-indigo-7 {
  color: #3949ab !important;
}

.text-indigo-8 {
  color: #303f9f !important;
}

.text-indigo-9 {
  color: #283593 !important;
}

.text-indigo-10 {
  color: #1a237e !important;
}

.text-indigo-11 {
  color: #8c9eff !important;
}

.text-indigo-12 {
  color: #536dfe !important;
}

.text-indigo-13 {
  color: #3d5afe !important;
}

.text-indigo-14 {
  color: #304ffe !important;
}

.text-blue {
  color: #2196f3 !important;
}

.text-blue-1 {
  color: #e3f2fd !important;
}

.text-blue-2 {
  color: #bbdefb !important;
}

.text-blue-3 {
  color: #90caf9 !important;
}

.text-blue-4 {
  color: #64b5f6 !important;
}

.text-blue-5 {
  color: #42a5f5 !important;
}

.text-blue-6 {
  color: #2196f3 !important;
}

.text-blue-7 {
  color: #1e88e5 !important;
}

.text-blue-8 {
  color: #1976d2 !important;
}

.text-blue-9 {
  color: #1565c0 !important;
}

.text-blue-10 {
  color: #0d47a1 !important;
}

.text-blue-11 {
  color: #82b1ff !important;
}

.text-blue-12 {
  color: #448aff !important;
}

.text-blue-13 {
  color: #2979ff !important;
}

.text-blue-14 {
  color: #2962ff !important;
}

.text-blue-15 {
  color: #2F80ED !important;
}

.text-light-blue {
  color: #03a9f4 !important;
}

.text-light-blue-1 {
  color: #e1f5fe !important;
}

.text-light-blue-2 {
  color: #b3e5fc !important;
}

.text-light-blue-3 {
  color: #81d4fa !important;
}

.text-light-blue-4 {
  color: #4fc3f7 !important;
}

.text-light-blue-5 {
  color: #29b6f6 !important;
}

.text-light-blue-6 {
  color: #03a9f4 !important;
}

.text-light-blue-7 {
  color: #039be5 !important;
}

.text-light-blue-8 {
  color: #0288d1 !important;
}

.text-light-blue-9 {
  color: #0277bd !important;
}

.text-light-blue-10 {
  color: #01579b !important;
}

.text-light-blue-11 {
  color: #80d8ff !important;
}

.text-light-blue-12 {
  color: #40c4ff !important;
}

.text-light-blue-13 {
  color: #00b0ff !important;
}

.text-light-blue-14 {
  color: #0091ea !important;
}

.text-cyan {
  color: #00bcd4 !important;
}

.text-cyan-1 {
  color: #e0f7fa !important;
}

.text-cyan-2 {
  color: #b2ebf2 !important;
}

.text-cyan-3 {
  color: #80deea !important;
}

.text-cyan-4 {
  color: #4dd0e1 !important;
}

.text-cyan-5 {
  color: #26c6da !important;
}

.text-cyan-6 {
  color: #00bcd4 !important;
}

.text-cyan-7 {
  color: #00acc1 !important;
}

.text-cyan-8 {
  color: #0097a7 !important;
}

.text-cyan-9 {
  color: #00838f !important;
}

.text-cyan-10 {
  color: #006064 !important;
}

.text-cyan-11 {
  color: #84ffff !important;
}

.text-cyan-12 {
  color: #18ffff !important;
}

.text-cyan-13 {
  color: #00e5ff !important;
}

.text-cyan-14 {
  color: #00b8d4 !important;
}

.text-teal {
  color: #009688 !important;
}

.text-teal-1 {
  color: #e0f2f1 !important;
}

.text-teal-2 {
  color: #b2dfdb !important;
}

.text-teal-3 {
  color: #80cbc4 !important;
}

.text-teal-4 {
  color: #4db6ac !important;
}

.text-teal-5 {
  color: #26a69a !important;
}

.text-teal-6 {
  color: #009688 !important;
}

.text-teal-7 {
  color: #00897b !important;
}

.text-teal-8 {
  color: #00796b !important;
}

.text-teal-9 {
  color: #00695c !important;
}

.text-teal-10 {
  color: #004d40 !important;
}

.text-teal-11 {
  color: #a7ffeb !important;
}

.text-teal-12 {
  color: #64ffda !important;
}

.text-teal-13 {
  color: #1de9b6 !important;
}

.text-teal-14 {
  color: #00bfa5 !important;
}

.text-green {
  color: #4caf50 !important;
}

.text-green-1 {
  color: #e8f5e9 !important;
}

.text-green-2 {
  color: #c8e6c9 !important;
}

.text-green-3 {
  color: #a5d6a7 !important;
}

.text-green-4 {
  color: #81c784 !important;
}

.text-green-5 {
  color: #66bb6a !important;
}

.text-green-6 {
  color: #4caf50 !important;
}

.text-green-7 {
  color: #43a047 !important;
}

.text-green-8 {
  color: #388e3c !important;
}

.text-green-9 {
  color: #2e7d32 !important;
}

.text-green-10 {
  color: #1b5e20 !important;
}

.text-green-11 {
  color: #b9f6ca !important;
}

.text-green-12 {
  color: #69f0ae !important;
}

.text-green-13 {
  color: #00e676 !important;
}

.text-green-14 {
  color: #00c853 !important;
}

.text-light-green {
  color: #8bc34a !important;
}

.text-light-green-1 {
  color: #f1f8e9 !important;
}

.text-light-green-2 {
  color: #dcedc8 !important;
}

.text-light-green-3 {
  color: #c5e1a5 !important;
}

.text-light-green-4 {
  color: #aed581 !important;
}

.text-light-green-5 {
  color: #9ccc65 !important;
}

.text-light-green-6 {
  color: #8bc34a !important;
}

.text-light-green-7 {
  color: #7cb342 !important;
}

.text-light-green-8 {
  color: #689f38 !important;
}

.text-light-green-9 {
  color: #558b2f !important;
}

.text-light-green-10 {
  color: #33691e !important;
}

.text-light-green-11 {
  color: #ccff90 !important;
}

.text-light-green-12 {
  color: #b2ff59 !important;
}

.text-light-green-13 {
  color: #76ff03 !important;
}

.text-light-green-14 {
  color: #64dd17 !important;
}

.text-lime {
  color: #cddc39 !important;
}

.text-lime-1 {
  color: #f9fbe7 !important;
}

.text-lime-2 {
  color: #f0f4c3 !important;
}

.text-lime-3 {
  color: #e6ee9c !important;
}

.text-lime-4 {
  color: #dce775 !important;
}

.text-lime-5 {
  color: #d4e157 !important;
}

.text-lime-6 {
  color: #cddc39 !important;
}

.text-lime-7 {
  color: #c0ca33 !important;
}

.text-lime-8 {
  color: #afb42b !important;
}

.text-lime-9 {
  color: #9e9d24 !important;
}

.text-lime-10 {
  color: #827717 !important;
}

.text-lime-11 {
  color: #f4ff81 !important;
}

.text-lime-12 {
  color: #eeff41 !important;
}

.text-lime-13 {
  color: #c6ff00 !important;
}

.text-lime-14 {
  color: #aeea00 !important;
}

.text-yellow {
  color: #ffeb3b !important;
}

.text-yellow-1 {
  color: #fffde7 !important;
}

.text-yellow-2 {
  color: #fff9c4 !important;
}

.text-yellow-3 {
  color: #fff59d !important;
}

.text-yellow-4 {
  color: #fff176 !important;
}

.text-yellow-5 {
  color: #ffee58 !important;
}

.text-yellow-6 {
  color: #ffeb3b !important;
}

.text-yellow-7 {
  color: #fdd835 !important;
}

.text-yellow-8 {
  color: #fbc02d !important;
}

.text-yellow-9 {
  color: #f9a825 !important;
}

.text-yellow-10 {
  color: #f57f17 !important;
}

.text-yellow-11 {
  color: #ffff8d !important;
}

.text-yellow-12 {
  color: #ff0 !important;
}

.text-yellow-13 {
  color: #ffea00 !important;
}

.text-yellow-14 {
  color: #ffd600 !important;
}

.text-amber {
  color: #ffc107 !important;
}

.text-amber-1 {
  color: #fff8e1 !important;
}

.text-amber-2 {
  color: #ffecb3 !important;
}

.text-amber-3 {
  color: #ffe082 !important;
}

.text-amber-4 {
  color: #ffd54f !important;
}

.text-amber-5 {
  color: #ffca28 !important;
}

.text-amber-6 {
  color: #ffc107 !important;
}

.text-amber-7 {
  color: #ffb300 !important;
}

.text-amber-8 {
  color: #ffa000 !important;
}

.text-amber-9 {
  color: #ff8f00 !important;
}

.text-amber-10 {
  color: #ff6f00 !important;
}

.text-amber-11 {
  color: #ffe57f !important;
}

.text-amber-12 {
  color: #ffd740 !important;
}

.text-amber-13 {
  color: #ffc400 !important;
}

.text-amber-14 {
  color: #ffab00 !important;
}

.text-orange {
  color: #ff9800 !important;
}

.text-orange-1 {
  color: #fff3e0 !important;
}

.text-orange-2 {
  color: #ffe0b2 !important;
}

.text-orange-3 {
  color: #ffcc80 !important;
}

.text-orange-4 {
  color: #ffb74d !important;
}

.text-orange-5 {
  color: #ffa726 !important;
}

.text-orange-6 {
  color: #ff9800 !important;
}

.text-orange-7 {
  color: #fb8c00 !important;
}

.text-orange-8 {
  color: #f57c00 !important;
}

.text-orange-9 {
  color: #ef6c00 !important;
}

.text-orange-10 {
  color: #e65100 !important;
}

.text-orange-11 {
  color: #ffd180 !important;
}

.text-orange-12 {
  color: #ffab40 !important;
}

.text-orange-13 {
  color: #ff9100 !important;
}

.text-orange-14 {
  color: #ff6d00 !important;
}

.text-deep-orange {
  color: #ff5722 !important;
}

.text-deep-orange-1 {
  color: #fbe9e7 !important;
}

.text-deep-orange-2 {
  color: #ffccbc !important;
}

.text-deep-orange-3 {
  color: #ffab91 !important;
}

.text-deep-orange-4 {
  color: #ff8a65 !important;
}

.text-deep-orange-5 {
  color: #ff7043 !important;
}

.text-deep-orange-6 {
  color: #ff5722 !important;
}

.text-deep-orange-7 {
  color: #f4511e !important;
}

.text-deep-orange-8 {
  color: #e64a19 !important;
}

.text-deep-orange-9 {
  color: #d84315 !important;
}

.text-deep-orange-10 {
  color: #bf360c !important;
}

.text-deep-orange-11 {
  color: #ff9e80 !important;
}

.text-deep-orange-12 {
  color: #ff6e40 !important;
}

.text-deep-orange-13 {
  color: #ff3d00 !important;
}

.text-deep-orange-14 {
  color: #dd2c00 !important;
}

.text-brown {
  color: #795548 !important;
}

.text-brown-1 {
  color: #efebe9 !important;
}

.text-brown-2 {
  color: #d7ccc8 !important;
}

.text-brown-3 {
  color: #bcaaa4 !important;
}

.text-brown-4 {
  color: #a1887f !important;
}

.text-brown-5 {
  color: #8d6e63 !important;
}

.text-brown-6 {
  color: #795548 !important;
}

.text-brown-7 {
  color: #6d4c41 !important;
}

.text-brown-8 {
  color: #5d4037 !important;
}

.text-brown-9 {
  color: #4e342e !important;
}

.text-brown-10 {
  color: #3e2723 !important;
}

.text-brown-11 {
  color: #d7ccc8 !important;
}

.text-brown-12 {
  color: #bcaaa4 !important;
}

.text-brown-13 {
  color: #8d6e63 !important;
}

.text-brown-14 {
  color: #5d4037 !important;
}

.text-grey {
  color: #9e9e9e !important;
}

.text-grey-1 {
  color: #fafafa !important;
}

.text-grey-2 {
  color: #f5f5f5 !important;
}

.text-grey-3 {
  color: #eee !important;
}

.text-grey-4 {
  color: #e0e0e0 !important;
}

.text-grey-5 {
  color: #bdbdbd !important;
}

.text-grey-6 {
  color: #9e9e9e !important;
}

.text-grey-7 {
  color: #757575 !important;
}

.text-grey-8 {
  color: #616161 !important;
}

.text-grey-9 {
  color: #424242 !important;
}

.text-grey-10 {
  color: #212121 !important;
}

.text-grey-11 {
  color: #f5f5f5 !important;
}

.text-grey-12 {
  color: #eee !important;
}

.text-grey-13 {
  color: #bdbdbd !important;
}

.text-grey-14 {
  color: #616161 !important;
}

.text-blue-grey {
  color: #607d8b !important;
}

.text-blue-grey-1 {
  color: #eceff1 !important;
}

.text-blue-grey-2 {
  color: #cfd8dc !important;
}

.text-blue-grey-3 {
  color: #b0bec5 !important;
}

.text-blue-grey-4 {
  color: #90a4ae !important;
}

.text-blue-grey-5 {
  color: #78909c !important;
}

.text-blue-grey-6 {
  color: #607d8b !important;
}

.text-blue-grey-7 {
  color: #546e7a !important;
}

.text-blue-grey-8 {
  color: #455a64 !important;
}

.text-blue-grey-9 {
  color: #37474f !important;
}

.text-blue-grey-10 {
  color: #263238 !important;
}

.text-blue-grey-11 {
  color: #cfd8dc !important;
}

.text-blue-grey-12 {
  color: #b0bec5 !important;
}

.text-blue-grey-13 {
  color: #78909c !important;
}

.text-blue-grey-14 {
  color: #455a64 !important;
}

.bg-red {
  background: #f44336 !important;
}

.bg-red-1 {
  background: #ffebee !important;
}

.bg-red-2 {
  background: #ffcdd2 !important;
}

.bg-red-3 {
  background: #ef9a9a !important;
}

.bg-red-4 {
  background: #e57373 !important;
}

.bg-red-5 {
  background: #ef5350 !important;
}

.bg-red-6 {
  background: #f44336 !important;
}

.bg-red-7 {
  background: #e53935 !important;
}

.bg-red-8 {
  background: #d32f2f !important;
}

.bg-red-9 {
  background: #c62828 !important;
}

.bg-red-10 {
  background: #b71c1c !important;
}

.bg-red-11 {
  background: #ff8a80 !important;
}

.bg-red-12 {
  background: #ff5252 !important;
}

.bg-red-13 {
  background: #ff1744 !important;
}

.bg-red-14 {
  background: #d50000 !important;
}

.bg-pink {
  background: #e91e63 !important;
}

.bg-pink-1 {
  background: #fce4ec !important;
}

.bg-pink-2 {
  background: #f8bbd0 !important;
}

.bg-pink-3 {
  background: #f48fb1 !important;
}

.bg-pink-4 {
  background: #f06292 !important;
}

.bg-pink-5 {
  background: #ec407a !important;
}

.bg-pink-6 {
  background: #e91e63 !important;
}

.bg-pink-7 {
  background: #d81b60 !important;
}

.bg-pink-8 {
  background: #c2185b !important;
}

.bg-pink-9 {
  background: #ad1457 !important;
}

.bg-pink-10 {
  background: #880e4f !important;
}

.bg-pink-11 {
  background: #ff80ab !important;
}

.bg-pink-12 {
  background: #ff4081 !important;
}

.bg-pink-13 {
  background: #f50057 !important;
}

.bg-pink-14 {
  background: #c51162 !important;
}

.bg-purple {
  background: #9c27b0 !important;
}

.bg-purple-1 {
  background: #f3e5f5 !important;
}

.bg-purple-2 {
  background: #e1bee7 !important;
}

.bg-purple-3 {
  background: #ce93d8 !important;
}

.bg-purple-4 {
  background: #ba68c8 !important;
}

.bg-purple-5 {
  background: #ab47bc !important;
}

.bg-purple-6 {
  background: #9c27b0 !important;
}

.bg-purple-7 {
  background: #8e24aa !important;
}

.bg-purple-8 {
  background: #7b1fa2 !important;
}

.bg-purple-9 {
  background: #6a1b9a !important;
}

.bg-purple-10 {
  background: #4a148c !important;
}

.bg-purple-11 {
  background: #ea80fc !important;
}

.bg-purple-12 {
  background: #e040fb !important;
}

.bg-purple-13 {
  background: #d500f9 !important;
}

.bg-purple-14 {
  background: #a0f !important;
}

.bg-deep-purple {
  background: #673ab7 !important;
}

.bg-deep-purple-1 {
  background: #ede7f6 !important;
}

.bg-deep-purple-2 {
  background: #d1c4e9 !important;
}

.bg-deep-purple-3 {
  background: #b39ddb !important;
}

.bg-deep-purple-4 {
  background: #9575cd !important;
}

.bg-deep-purple-5 {
  background: #7e57c2 !important;
}

.bg-deep-purple-6 {
  background: #673ab7 !important;
}

.bg-deep-purple-7 {
  background: #5e35b1 !important;
}

.bg-deep-purple-8 {
  background: #512da8 !important;
}

.bg-deep-purple-9 {
  background: #4527a0 !important;
}

.bg-deep-purple-10 {
  background: #311b92 !important;
}

.bg-deep-purple-11 {
  background: #b388ff !important;
}

.bg-deep-purple-12 {
  background: #7c4dff !important;
}

.bg-deep-purple-13 {
  background: #651fff !important;
}

.bg-deep-purple-14 {
  background: #6200ea !important;
}

.bg-indigo {
  background: #3f51b5 !important;
}

.bg-indigo-1 {
  background: #e8eaf6 !important;
}

.bg-indigo-2 {
  background: #c5cae9 !important;
}

.bg-indigo-3 {
  background: #9fa8da !important;
}

.bg-indigo-4 {
  background: #7986cb !important;
}

.bg-indigo-5 {
  background: #5c6bc0 !important;
}

.bg-indigo-6 {
  background: #3f51b5 !important;
}

.bg-indigo-7 {
  background: #3949ab !important;
}

.bg-indigo-8 {
  background: #303f9f !important;
}

.bg-indigo-9 {
  background: #283593 !important;
}

.bg-indigo-10 {
  background: #1a237e !important;
}

.bg-indigo-11 {
  background: #8c9eff !important;
}

.bg-indigo-12 {
  background: #536dfe !important;
}

.bg-indigo-13 {
  background: #3d5afe !important;
}

.bg-indigo-14 {
  background: #304ffe !important;
}

.bg-blue {
  background: #2196f3 !important;
}

.bg-blue-1 {
  background: #e3f2fd !important;
}

.bg-blue-2 {
  background: #bbdefb !important;
}

.bg-blue-3 {
  background: #90caf9 !important;
}

.bg-blue-4 {
  background: #64b5f6 !important;
}

.bg-blue-5 {
  background: #42a5f5 !important;
}

.bg-blue-6 {
  background: #2196f3 !important;
}

.bg-blue-7 {
  background: #1e88e5 !important;
}

.bg-blue-8 {
  background: #1976d2 !important;
}

.bg-blue-9 {
  background: #1565c0 !important;
}

.bg-blue-10 {
  background: #0d47a1 !important;
}

.bg-blue-11 {
  background: #82b1ff !important;
}

.bg-blue-12 {
  background: #448aff !important;
}

.bg-blue-13 {
  background: #2979ff !important;
}

.bg-blue-14 {
  background: #2962ff !important;
}

.bg-blue-15 {
  background: #2F80ED !important;
}

.bg-blue-16 {
  background: #101840 !important;
}

.bg-light-blue {
  background: #03a9f4 !important;
}

.bg-light-blue-1 {
  background: #e1f5fe !important;
}

.bg-light-blue-2 {
  background: #b3e5fc !important;
}

.bg-light-blue-3 {
  background: #81d4fa !important;
}

.bg-light-blue-4 {
  background: #4fc3f7 !important;
}

.bg-light-blue-5 {
  background: #29b6f6 !important;
}

.bg-light-blue-6 {
  background: #03a9f4 !important;
}

.bg-light-blue-7 {
  background: #039be5 !important;
}

.bg-light-blue-8 {
  background: #0288d1 !important;
}

.bg-light-blue-9 {
  background: #0277bd !important;
}

.bg-light-blue-10 {
  background: #01579b !important;
}

.bg-light-blue-11 {
  background: #80d8ff !important;
}

.bg-light-blue-12 {
  background: #40c4ff !important;
}

.bg-light-blue-13 {
  background: #00b0ff !important;
}

.bg-light-blue-14 {
  background: #0091ea !important;
}

.bg-cyan {
  background: #00bcd4 !important;
}

.bg-cyan-1 {
  background: #e0f7fa !important;
}

.bg-cyan-2 {
  background: #b2ebf2 !important;
}

.bg-cyan-3 {
  background: #80deea !important;
}

.bg-cyan-4 {
  background: #4dd0e1 !important;
}

.bg-cyan-5 {
  background: #26c6da !important;
}

.bg-cyan-6 {
  background: #00bcd4 !important;
}

.bg-cyan-7 {
  background: #00acc1 !important;
}

.bg-cyan-8 {
  background: #0097a7 !important;
}

.bg-cyan-9 {
  background: #00838f !important;
}

.bg-cyan-10 {
  background: #006064 !important;
}

.bg-cyan-11 {
  background: #84ffff !important;
}

.bg-cyan-12 {
  background: #18ffff !important;
}

.bg-cyan-13 {
  background: #00e5ff !important;
}

.bg-cyan-14 {
  background: #00b8d4 !important;
}

.bg-teal {
  background: #009688 !important;
}

.bg-teal-1 {
  background: #e0f2f1 !important;
}

.bg-teal-2 {
  background: #b2dfdb !important;
}

.bg-teal-3 {
  background: #80cbc4 !important;
}

.bg-teal-4 {
  background: #4db6ac !important;
}

.bg-teal-5 {
  background: #26a69a !important;
}

.bg-teal-6 {
  background: #009688 !important;
}

.bg-teal-7 {
  background: #00897b !important;
}

.bg-teal-8 {
  background: #00796b !important;
}

.bg-teal-9 {
  background: #00695c !important;
}

.bg-teal-10 {
  background: #004d40 !important;
}

.bg-teal-11 {
  background: #a7ffeb !important;
}

.bg-teal-12 {
  background: #64ffda !important;
}

.bg-teal-13 {
  background: #1de9b6 !important;
}

.bg-teal-14 {
  background: #00bfa5 !important;
}

.bg-green {
  background: #4caf50 !important;
}

.bg-green-1 {
  background: #e8f5e9 !important;
}

.bg-green-2 {
  background: #c8e6c9 !important;
}

.bg-green-3 {
  background: #a5d6a7 !important;
}

.bg-green-4 {
  background: #81c784 !important;
}

.bg-green-5 {
  background: #66bb6a !important;
}

.bg-green-6 {
  background: #4caf50 !important;
}

.bg-green-7 {
  background: #43a047 !important;
}

.bg-green-8 {
  background: #388e3c !important;
}

.bg-green-9 {
  background: #2e7d32 !important;
}

.bg-green-10 {
  background: #1b5e20 !important;
}

.bg-green-11 {
  background: #b9f6ca !important;
}

.bg-green-12 {
  background: #69f0ae !important;
}

.bg-green-13 {
  background: #00e676 !important;
}

.bg-green-14 {
  background: #00c853 !important;
}

.bg-light-green {
  background: #8bc34a !important;
}

.bg-light-green-1 {
  background: #f1f8e9 !important;
}

.bg-light-green-2 {
  background: #dcedc8 !important;
}

.bg-light-green-3 {
  background: #c5e1a5 !important;
}

.bg-light-green-4 {
  background: #aed581 !important;
}

.bg-light-green-5 {
  background: #9ccc65 !important;
}

.bg-light-green-6 {
  background: #8bc34a !important;
}

.bg-light-green-7 {
  background: #7cb342 !important;
}

.bg-light-green-8 {
  background: #689f38 !important;
}

.bg-light-green-9 {
  background: #558b2f !important;
}

.bg-light-green-10 {
  background: #33691e !important;
}

.bg-light-green-11 {
  background: #ccff90 !important;
}

.bg-light-green-12 {
  background: #b2ff59 !important;
}

.bg-light-green-13 {
  background: #76ff03 !important;
}

.bg-light-green-14 {
  background: #64dd17 !important;
}

.bg-lime {
  background: #cddc39 !important;
}

.bg-lime-1 {
  background: #f9fbe7 !important;
}

.bg-lime-2 {
  background: #f0f4c3 !important;
}

.bg-lime-3 {
  background: #e6ee9c !important;
}

.bg-lime-4 {
  background: #dce775 !important;
}

.bg-lime-5 {
  background: #d4e157 !important;
}

.bg-lime-6 {
  background: #cddc39 !important;
}

.bg-lime-7 {
  background: #c0ca33 !important;
}

.bg-lime-8 {
  background: #afb42b !important;
}

.bg-lime-9 {
  background: #9e9d24 !important;
}

.bg-lime-10 {
  background: #827717 !important;
}

.bg-lime-11 {
  background: #f4ff81 !important;
}

.bg-lime-12 {
  background: #eeff41 !important;
}

.bg-lime-13 {
  background: #c6ff00 !important;
}

.bg-lime-14 {
  background: #aeea00 !important;
}

.bg-yellow {
  background: #ffeb3b !important;
}

.bg-yellow-1 {
  background: #fffde7 !important;
}

.bg-yellow-2 {
  background: #fff9c4 !important;
}

.bg-yellow-3 {
  background: #fff59d !important;
}

.bg-yellow-4 {
  background: #fff176 !important;
}

.bg-yellow-5 {
  background: #ffee58 !important;
}

.bg-yellow-6 {
  background: #ffeb3b !important;
}

.bg-yellow-7 {
  background: #fdd835 !important;
}

.bg-yellow-8 {
  background: #fbc02d !important;
}

.bg-yellow-9 {
  background: #f9a825 !important;
}

.bg-yellow-10 {
  background: #f57f17 !important;
}

.bg-yellow-11 {
  background: #ffff8d !important;
}

.bg-yellow-12 {
  background: #ff0 !important;
}

.bg-yellow-13 {
  background: #ffea00 !important;
}

.bg-yellow-14 {
  background: #ffd600 !important;
}

.bg-amber {
  background: #ffc107 !important;
}

.bg-amber-1 {
  background: #fff8e1 !important;
}

.bg-amber-2 {
  background: #ffecb3 !important;
}

.bg-amber-3 {
  background: #ffe082 !important;
}

.bg-amber-4 {
  background: #ffd54f !important;
}

.bg-amber-5 {
  background: #ffca28 !important;
}

.bg-amber-6 {
  background: #ffc107 !important;
}

.bg-amber-7 {
  background: #ffb300 !important;
}

.bg-amber-8 {
  background: #ffa000 !important;
}

.bg-amber-9 {
  background: #ff8f00 !important;
}

.bg-amber-10 {
  background: #ff6f00 !important;
}

.bg-amber-11 {
  background: #ffe57f !important;
}

.bg-amber-12 {
  background: #ffd740 !important;
}

.bg-amber-13 {
  background: #ffc400 !important;
}

.bg-amber-14 {
  background: #ffab00 !important;
}

.bg-orange {
  background: #ff9800 !important;
}

.bg-orange-1 {
  background: #fff3e0 !important;
}

.bg-orange-2 {
  background: #ffe0b2 !important;
}

.bg-orange-3 {
  background: #ffcc80 !important;
}

.bg-orange-4 {
  background: #ffb74d !important;
}

.bg-orange-5 {
  background: #ffa726 !important;
}

.bg-orange-6 {
  background: #ff9800 !important;
}

.bg-orange-7 {
  background: #fb8c00 !important;
}

.bg-orange-8 {
  background: #f57c00 !important;
}

.bg-orange-9 {
  background: #ef6c00 !important;
}

.bg-orange-10 {
  background: #e65100 !important;
}

.bg-orange-11 {
  background: #ffd180 !important;
}

.bg-orange-12 {
  background: #ffab40 !important;
}

.bg-orange-13 {
  background: #ff9100 !important;
}

.bg-orange-14 {
  background: #ff6d00 !important;
}

.bg-deep-orange {
  background: #ff5722 !important;
}

.bg-deep-orange-1 {
  background: #fbe9e7 !important;
}

.bg-deep-orange-2 {
  background: #ffccbc !important;
}

.bg-deep-orange-3 {
  background: #ffab91 !important;
}

.bg-deep-orange-4 {
  background: #ff8a65 !important;
}

.bg-deep-orange-5 {
  background: #ff7043 !important;
}

.bg-deep-orange-6 {
  background: #ff5722 !important;
}

.bg-deep-orange-7 {
  background: #f4511e !important;
}

.bg-deep-orange-8 {
  background: #e64a19 !important;
}

.bg-deep-orange-9 {
  background: #d84315 !important;
}

.bg-deep-orange-10 {
  background: #bf360c !important;
}

.bg-deep-orange-11 {
  background: #ff9e80 !important;
}

.bg-deep-orange-12 {
  background: #ff6e40 !important;
}

.bg-deep-orange-13 {
  background: #ff3d00 !important;
}

.bg-deep-orange-14 {
  background: #dd2c00 !important;
}

.bg-brown {
  background: #795548 !important;
}

.bg-brown-1 {
  background: #efebe9 !important;
}

.bg-brown-2 {
  background: #d7ccc8 !important;
}

.bg-brown-3 {
  background: #bcaaa4 !important;
}

.bg-brown-4 {
  background: #a1887f !important;
}

.bg-brown-5 {
  background: #8d6e63 !important;
}

.bg-brown-6 {
  background: #795548 !important;
}

.bg-brown-7 {
  background: #6d4c41 !important;
}

.bg-brown-8 {
  background: #5d4037 !important;
}

.bg-brown-9 {
  background: #4e342e !important;
}

.bg-brown-10 {
  background: #3e2723 !important;
}

.bg-brown-11 {
  background: #d7ccc8 !important;
}

.bg-brown-12 {
  background: #bcaaa4 !important;
}

.bg-brown-13 {
  background: #8d6e63 !important;
}

.bg-brown-14 {
  background: #5d4037 !important;
}

.bg-grey {
  background: #9e9e9e !important;
}

.bg-grey-1 {
  background: #fafafa !important;
}

.bg-grey-2 {
  background: #f5f5f5 !important;
}

.bg-grey-3 {
  background: #eee !important;
}

.bg-grey-4 {
  background: #e0e0e0 !important;
}

.bg-grey-5 {
  background: #bdbdbd !important;
}

.bg-grey-6 {
  background: #9e9e9e !important;
}

.bg-grey-7 {
  background: #757575 !important;
}

.bg-grey-8 {
  background: #616161 !important;
}

.bg-grey-9 {
  background: #424242 !important;
}

.bg-grey-10 {
  background: #212121 !important;
}

.bg-grey-11 {
  background: #f5f5f5 !important;
}

.bg-grey-12 {
  background: #eee !important;
}

.bg-grey-13 {
  background: #bdbdbd !important;
}

.bg-grey-14 {
  background: #616161 !important;
}

.bg-blue-grey {
  background: #607d8b !important;
}

.bg-blue-grey-1 {
  background: #eceff1 !important;
}

.bg-blue-grey-2 {
  background: #cfd8dc !important;
}

.bg-blue-grey-3 {
  background: #b0bec5 !important;
}

.bg-blue-grey-4 {
  background: #90a4ae !important;
}

.bg-blue-grey-5 {
  background: #78909c !important;
}

.bg-blue-grey-6 {
  background: #607d8b !important;
}

.bg-blue-grey-7 {
  background: #546e7a !important;
}

.bg-blue-grey-8 {
  background: #455a64 !important;
}

.bg-blue-grey-9 {
  background: #37474f !important;
}

.bg-blue-grey-10 {
  background: #263238 !important;
}

.bg-blue-grey-11 {
  background: #cfd8dc !important;
}

.bg-blue-grey-12 {
  background: #b0bec5 !important;
}

.bg-blue-grey-13 {
  background: #78909c !important;
}

.bg-blue-grey-14 {
  background: #455a64 !important;
}

.page-campaign-settings .tracking-sources label {
  display: inline;
}

.security-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.security-code-wrapper {
  display: inline-block;
  margin: auto;
  min-width: 240px;
  text-align: center;
}
@media only screen and (max-device-width: 736px) {
  .security-code-wrapper {
    max-width: 320px;
  }
}
.security-code-wrapper .security-code-field {
  width: 40px;
  display: inline-block;
  margin-right: 10px;
  float: left;
}
@media only screen and (max-device-width: 736px) {
  .security-code-wrapper .security-code-field {
    float: none;
    margin-right: 4px;
  }
}
.security-code-wrapper .security-code-field .form-control {
  width: 44px;
  height: 44px;
  font-size: 30px;
  text-align: center;
  padding: 0;
}
@media only screen and (max-device-width: 736px) {
  .security-code-wrapper .security-code-field .form-control {
    width: 42px;
    height: 42px;
    margin: 0;
  }
}

.dialer-icon {
  position: relative;
  left: -1px;
  top: -1px;
}

.webrtc-dialer {
  background: #ffffff;
  width: 300px;
  max-height: 535px;
  position: fixed;
  z-index: 2001;
  bottom: 0px;
  right: 12px;
  /* dark skin theme for webrtc dialer */
}
.webrtc-dialer .el-input.is-disabled .el-input__inner {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #7D7D7D !important;
}
.webrtc-dialer.widget {
  width: 300px;
  right: auto;
  left: 0px;
}
.webrtc-dialer .webrtc-header {
  height: 100px;
  font-size: 13px;
  z-index: 2001;
}
.webrtc-dialer .webrtc-header .header-right {
  position: absolute;
  top: 10px;
  right: 10px;
}
.webrtc-dialer .webrtc-header .contact-name {
  max-width: 80%;
}
.webrtc-dialer .webrtc-header #signal-strength {
  height: 20px;
  list-style: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.webrtc-dialer .webrtc-header #signal-strength li {
  display: inline-block;
  width: 5px;
  float: left;
  height: 100%;
  margin-right: 1px;
}
.webrtc-dialer .webrtc-header #signal-strength li.pretty-strong {
  padding-top: 0px;
}
.webrtc-dialer .webrtc-header #signal-strength li.strong {
  padding-top: 5px;
}
.webrtc-dialer .webrtc-header #signal-strength li.weak {
  padding-top: 10px;
}
.webrtc-dialer .webrtc-header #signal-strength li.very-weak {
  padding-top: 15px;
}
.webrtc-dialer .webrtc-header #signal-strength li div {
  height: 100%;
  border: 1px solid white;
}
.webrtc-dialer .webrtc-header #signal-strength li div.active {
  background: white;
}
.webrtc-dialer .webrtc-header .phone-form .el-form-item .el-input__inner {
  padding-right: 0;
  padding-left: 0;
}
.webrtc-dialer .webrtc-header .phone-form .el-form-item .el-form-item__error {
  top: 80%;
  display: none;
}
.webrtc-dialer .webrtc-header .el-form {
  width: 80%;
  float: left;
}
.webrtc-dialer .webrtc-header .el-form .el-input__inner {
  width: 100%;
}
.webrtc-dialer .webrtc-header .phone-form input {
  color: #ffffff;
  text-shadow: 0 0 0 #7D7D7D;
  border: none;
  background: transparent;
  font-size: 24px;
  text-align: left;
  width: 100%;
}
.webrtc-dialer .webrtc-header .phone-form input.on-call {
  font-size: 15px !important;
  line-height: 15px;
}
.webrtc-dialer .webrtc-header .phone-form input:focus {
  outline: none;
}
.webrtc-dialer .webrtc-header .phone-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}
.webrtc-dialer .webrtc-header .phone-form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ffffff;
  opacity: 0.5;
}
.webrtc-dialer .webrtc-header .phone-form input::-ms-input-placeholder { /* Microsoft Edge */
  color: #ffffff;
  opacity: 0.5;
}
.webrtc-dialer .webrtc-header .phone-form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #ffffff;
  opacity: 0.5;
}
.webrtc-dialer .webrtc-header .phone-form input::-moz-placeholder { /* Firefox 19+ */
  color: #ffffff;
  opacity: 0.5;
}
.webrtc-dialer .webrtc-header .phone-form input:-moz-placeholder { /* Firefox 18- */
  color: #ffffff;
  opacity: 0.5;
}
.webrtc-dialer .webrtc-header button i {
  font-size: 29px;
}
.webrtc-dialer .webrtc-header button:not([disabled]):not(.solid):focus, .webrtc-dialer .webrtc-header button:not([disabled]):not(.solid):hover, .webrtc-dialer .webrtc-header button:not([disabled]):not(.solid):active {
  background-color: transparent !important;
}
.webrtc-dialer .webrtc-header button.unhold:focus, .webrtc-dialer .webrtc-header button.unhold:hover, .webrtc-dialer .webrtc-header button.unhold:active {
  background-color: #00a645 !important;
}
.webrtc-dialer .webrtc-header .search-btn i {
  font-size: 20px;
}
.webrtc-dialer .communication-info {
  height: 392px;
}
.webrtc-dialer .communication-info .scrollable {
  height: 350px;
}
.webrtc-dialer .call-communication-info {
  height: 304px;
  overflow-y: scroll;
}
.webrtc-dialer .dummy {
  width: 80px;
  height: 80px;
}
.webrtc-dialer .in {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.webrtc-dialer .number-btn-wrapper {
  background: #ffffff;
  border-right: 1px solid rgba(173, 210, 218, 0.3);
  border-bottom: 1px solid rgba(173, 210, 218, 0.3);
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.webrtc-dialer .refresh-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 2px;
  margin-right: 2px;
  padding: 0px;
  font-size: 18px !important;
  color: #00A344;
}
.webrtc-dialer .number-text {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 24px;
  color: #043791;
}
.webrtc-dialer .number-text-sub {
  font-family: "Nunito", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  color: #313131;
  text-align: center;
  display: block;
}
.webrtc-dialer .call-btn {
  background: #00BF50;
  color: #ffffff;
  padding: 1.5rem 1rem;
}
.webrtc-dialer .call-btn i {
  font-size: 36px;
}
.webrtc-dialer .hangup-btn {
  background: #C4183C;
  color: #ffffff;
  padding: 1.5rem 1rem;
}
.webrtc-dialer .hangup-btn i {
  font-size: 36px;
}
.webrtc-dialer .minimize {
  z-index: 2002;
}
.webrtc-dialer .help {
  z-index: 2002;
}
.webrtc-dialer .settings {
  z-index: 2002;
}
.webrtc-dialer .note-menu .note-wrapper {
  height: 320px;
}
.webrtc-dialer .transfer-menu .transfer-wrapper {
  height: 320px;
}
.webrtc-dialer .copy-btn {
  color: #ffffff !important;
  background: transparent !important;
  padding: 5px 0 !important;
  border: 0 !important;
  font-size: 13px !important;
}
.webrtc-dialer .copy-btn i {
  font-size: inherit !important;
}
.webrtc-dialer .copy-btn:not([disabled]):focus, .webrtc-dialer .copy-btn:not([disabled]):hover, .webrtc-dialer .copy-btn:not([disabled]):active {
  color: rgba(255, 255, 255, 0.6) !important;
}
.webrtc-dialer.dark-skin-theme {
  background: #090A0D;
  color: #ffffff;
  /* for close button on dark skin theme */
}
.webrtc-dialer.dark-skin-theme .webrtc-header input {
  color: #ffffff;
  text-shadow: 0 0 0 #ffffff;
}
.webrtc-dialer.dark-skin-theme .number-btn-wrapper {
  background: #090A0D;
}
.webrtc-dialer.dark-skin-theme .number-text {
  color: #ffffff;
}
.webrtc-dialer.dark-skin-theme .number-text {
  color: #ffffff;
}
.webrtc-dialer.small {
  width: 270px;
}
.webrtc-dialer.small .dummy {
  width: auto;
  height: 70px;
}
.webrtc-dialer.small .communication-info {
  height: 360px;
}
.webrtc-dialer.small .communication-info .scrollable {
  height: 312px;
}
.webrtc-dialer.small .note-menu .note-wrapper {
  height: 280px;
}
.webrtc-dialer.small .transfer-menu .transfer-wrapper {
  height: 280px;
}
.webrtc-dialer.small .overview-boxes {
  height: 70px;
}

#dialer {
  z-index: 2000;
}

.button-custom-list {
  padding: 0;
  margin: 10px 0;
  list-style: none;
}

.button-custom-list li {
  margin-bottom: 10px;
}

.button-custom-list li:last-child {
  margin-bottom: 0;
}