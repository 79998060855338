@mixin not-last-child($selector) {
  & #{$selector}:not(:last-child) {
    @content;
  }
}

@function headings($from:1, $to:6) {
  @if $from == $to {
    @return 'h#{$from}';
  } @else {
    @return 'h#{$from},' + headings($from+1, $to);
  }
}

.app .container-fluid {
  padding-left: 12px;
  padding-right: 12px;
}

.app .container {
  padding-left: 12px;
  padding-right: 12px;
}

.dashboard-intro {
  padding: 15px 15px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
}

ul, menu, dir {
  padding-inline-start: 1.5em;
}

.navbar-brand {
  text-align: center;
}

.navbar-brand img, .navbar-brand svg {
  max-height: 32px;
}

.navbar-brand > span {
  color: $white;
}

.loader {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.navbar-brand, .navbar-item, .navbar-nav > .nav-item > .nav-link, .navbar-nav > .nav-link {
  padding: 0;
  white-space: nowrap;
}

.navbar {
  border: none;
  margin: 0;
  padding: 0 1rem;
  flex-shrink: 0;
  min-height: 3.2rem;
}

.fade-enter-active {
  @include transition(opacity .2s ease-in);
}

.fade-enter {
  opacity: 0
}

.login {
  padding-top: 100px !important;
}

.login-brand {
  padding: 0;
  white-space: nowrap;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.login-brand {
  img, svg {
    max-width: 200px;
    vertical-align: -6px;
    display: inline-block;
  }
}

.login-brand > span {
  display: inline-block;
  line-height: 0.8;
  margin-left: 10px;
}

.login .el-form-item__label {
  line-height: 30px;
}

.login .el-form-item {
  margin-bottom: 15px;
}

.login .el-button {
  height: 40px;
}

.login #errors p {
  margin-bottom: 0px;
}

.navbar-brand {
  img,
  svg {
    vertical-align: -10px;
  }
}

.w-xxxl {
  width: 640px;
}

.btn {
  font-weight: 400;
  cursor: pointer;
}

.btn > i.material-icons.pull-left, .btn > i.material-icons.pull-right {
  line-height: 1.25;
}

.navside .nav li li a {
  padding-left: 2.8rem;
}

.nav-icon {
  margin-right: 0.5rem;
}

.campaigns {
  margin-bottom: 5px;
}

.top-border-gray {
  border-top: 1px solid #f3f3f4;
}

.right-border-gray {
  border-right: 1px solid #f3f3f4;
}

.bottom-border-gray {
  border-bottom: 1px solid #f3f3f4;
}

.left-border-gray {
  border-left: 1px solid #f3f3f4;
}

.lead {
  word-break: keep-all;
}

#logout-button {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
}

#logout-button a {
  padding: 0;
  color: $greyish;
  line-height: 60px;
}

#logout-button:hover a {
  color: $dark-color;
  line-height: inherit;
}

#logout-button a > i {
  line-height: 60px;
}

#logout-button span {
  position: relative;
  top: -2px;
}

.mini-navbar #logout-button {
  width: 70px;
}

.navside .nav li.active.router-link-active {
  pointer-events: none;
  cursor: default;
}

.app-aside .navside .nav li {
  & .nav-icon {
    display: block;
  }

  & .nav-icon.active {
    display: none;
  }

  &.active {
    & .nav-icon {
      display: none;
    }

    & .nav-icon.active {
      display: block;
    }
  }
}

.form-head {
  margin-bottom: 0;
  margin-top: 1rem;
}

.form-head-message {
  font-weight: 400;
  color: $blackish;
  margin-bottom: 0;
}

.form-hover-help {
  border-bottom: dashed 1px $light-black;
  color: $light-black;
  line-height: 40px;
  cursor: pointer;
}

.centered-content {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wizard-header h2, .wizard-header h3, .wizard-header h4 {
  color: $blackish;
}

.wizard {
  & .area-code {
    width: 70px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.status-icon {
  @extend .cursor-pointer;
}

.w-72 {
  @extend .w-40;
  width: 72px;
  height: 72px;
  line-height: 72px;
  border-radius: 50%;
}

.w-200 {
  @extend .w-40;
  width: 200px;
  height: 200px;
  line-height: 200px;
  border-radius: 50%;
}

.w-300 {
  @extend .w-40;
  width: 300px;
  height: 300px;
  line-height: 300px;
  border-radius: 50%;
}

.filter-call-logs {
  @include not-last-child('> div') {
    margin-left: 10px;
  }

  & .dropdown-toggle::after {
    display: none;
  }

  & .filter-item {
    padding: 5px 1rem;
  }

  & .dropdown-item {
    padding: 5px 1rem;

    & > i {
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
  }
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

/*==================================================
 * Effect 8
 * ===============================================*/
.effect7 {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
}

.effect8 {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
}

ol.special-ol {
  counter-reset: li; /* Initiate a counter */
  margin-left: 0; /* Remove the default left margin */
  padding-left: 0; /* Remove the default left padding */
}

ol.special-ol > li {
  position: relative; /* Create a positioning context */
  margin: 0 0 6px 2em; /* Give each list item a left margin to make room for the numbers */
  padding: 4px 8px; /* Add some spacing around the content */
  list-style: none; /* Disable the normal item numbering */
}

ol.special-ol > li:before {
  content: counter(li); /* Use the counter as content */
  counter-increment: li; /* Increment the counter by 1 */
  /* Position and style the number */
  position: absolute;
  top: -1px;
  left: -1.1em;
  /* Some space between the number and the content in browsers that support
     generated content but not positioning it (Camino 2 is one example) */
  text-align: center;
  color: $light-black;
}

ol.special-ol > li > #{headings(1,6)} {
  color: $light-black;
}

.no-top-margin {
  margin-top: 0;
}

.mt--1 {
  margin-top: -1rem;
}

.no-overflow {
  overflow: unset;
}

.v-align-top {
  vertical-align: top;
}

.help-card {
  border: 1px solid $greenish !important;
  box-shadow: none !important;
  background-color: #DBF6E6 !important;
  color: $blackish !important;
  @extend ._400;
}

.warning-card {
  border: 1px solid $yellowish !important;
  box-shadow: none !important;
  background-color: rgba(242, 201, 76, 0.08) !important;
  color: $blackish !important;
  @extend ._400;
}

.notif-card {
  border: 1px solid $bluish !important;
  box-shadow: none !important;
  background-color: rgba($bluish, 0.1) !important;
  color: $blackish !important;
  @extend ._400;
}

.pagination > li.disabled > a {
  cursor: default;
}

.box-body, .box-header {
  background-color: #ffffff;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.row-filter .el-radio__input {
  display: none;
}

.row-filter .el-radio {
  margin-left: 0px !important;
  margin-right: 7px;

  &:last-child {
    margin-right: 0px;
  }
}

.row-filter .el-radio__label {
  padding-left: 0px;
}

.btn.b-blackish:focus {
  background-color: transparent !important;
  color: $blackish !important;
}

.btn.b-bluish.dropdown-toggle:focus {
  background-color: transparent !important;
  color: #0033A0 !important;
}

.btn.b-greenish.dropdown-toggle:focus {
  background-color: transparent !important;
  color: $greenish !important;
}

.btn.b-dark-greenish.dropdown-toggle:focus {
  background-color: transparent !important;
  color: $dark-greenish !important;
}

.btn.white.dropdown-toggle {
  &:focus, &:hover {
    -webkit-box-shadow: inset 0 -10rem 0px $white;
    box-shadow: inset 0 -10rem 0px $white;
  }
}

.app-body .box, .box-color {
  margin-bottom: 0;
}

.p-inline-block {
  display: inline-block !important;
  line-height: normal;
  position: relative;
  top: 3px;
  text-align: right;
  color: $blackish;
}

.company-name {
  display: inline-block;
}

.nav-link.dropdown-toggle::after {
  display: none;
}

.nav-link.dropdown-toggle {
  line-height: normal;
}

.nav-item > .dropdown-menu {
  padding: 10px 20px;
  min-width: 14rem;
}

.nav-item > .dropdown-menu {
  padding: 10px 0px;
}

.dropdown-item {
  padding: 10px 1.5rem;
}

.dropdown-item > i {
  margin-right: 10px;
}

.nav-padding {
  padding: 0px 11px;
}

.header-padding {
  padding: 1.0rem 1.5rem;
}

.add-campaign {
  position: relative;
  text-align: center;
  display: block;
  width: 200px;
  margin: 10px auto;
}

.navside .nav > li {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.navside .nav > li.campaigns {
  padding-top: 0;
  padding-bottom: 0;
}

.navside .nav > li#logout-button {
  padding-top: 0;
  padding-bottom: 0;
}

.app-footer {
  padding: 0 1.5rem;

  & .footer {
    z-index: 1;
  }
}

.app-footer .nav-link {
  padding: 0 0;
}

.mega-dropdown {
  width: 100%;
}

.mega-dropdown-menu {
  padding: 0.9rem 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  width: 500px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.filter-select {
  width: 100%;

  & .el-input__inner {
    border: none !important;
    color: $dark-greenish !important;
  }

  & .el-input .el-select__caret {
    font-size: 16px !important;
    color: $dark-greenish !important;
  }

  & input {
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $dark-greenish;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $dark-greenish;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $dark-greenish;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $dark-greenish;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: $dark-greenish;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: $dark-greenish;
    }
  }
}

.filter-select-contacts {
  @extend .filter-select;

  width: auto;

  & .el-input__inner {
    border: 1px solid $dark-greenish !important;
    color: $dark-greenish !important;
  }
}

.filter-header {
  margin-top: 10px;
  display: block;
}

.filter-header:first-child {
  margin-top: 0px;
}

.filter-button {
  position: relative;
  top: -2px;
}

.filter-div {
  line-height: 1.25;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .4rem .25rem;
  border-radius: .25rem;
  outline: 0 !important;
  border-color: $border-color;
  height: 40px;
  width: 220px;
  overflow: hidden;

  & .el-range-editor i.el-input__icon.el-range__close-icon {
    display: none !important;
  }
}

#view > .padding ~ .padding {
  padding: 0.5rem 1.5rem;
}

#view > .padding {
  padding: 1.5rem 1.5rem;
}

.editr--content a {
  color: $dark-greenish;
}

.form-control-label {
  line-height: 24px;
}

.sidebar-wrap {
  text-align: center;
}

.full-height {
  height: 100%;
}

.big-screen.full-height {
  height: calc(100vh - 55px);
}

.contacts-page.full-height {
  height: calc(100vh - 100px);
}

.sidebar-full-height {
  height: 100%;
  flex: 0 0 380px;
}

.max-height {
  @extend .full-height;
  position: absolute;
}

.nav-pills .nav-link {
  display: block;
  padding: .5em 1em;
  border-radius: .25rem;
}

.conversync {
  z-index: 1000;

  & .nav {
    display: block !important;
  }

  & .timeline .tl-date {
    width: 6.5em;
    margin-left: -9.5em;
  }

  & .timeline-center .tl-left .tl-date {
    margin-right: -9.5em;
  }

  & {
    .timeline-center .tl-left .tl-wrap {
      width: 100%;
    }

    .timeline .tl-content {
      width: 100%;
      -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.1);
    }
  }

  & .form-control-static {
    line-height: 1.5;
  }

  & .badge-contact {
    float: right;

    & .el-badge__content.is-fixed {
      top: 20px;
      right: 20px;
    }
  }

  & .form-control.grey-800:focus {
    color: $white;
  }
}

.campaign-badge {
  height: 12px !important;
}

.caller-status {
  width: 12px;
  height: 12px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  background-color: #6cc788;
  display: inline-block;
}

.hidden {
  visibility: hidden;
}

.dker {
  background-color: rgba(0, 0, 0, 0.1);
}

li.active .nav-icon {
  color: $whitish;
}

.mt-wizard {
  margin-top: 11px;
}

.wizard-dialog {
  width: 748px !important;

  & .el-dialog__body {
    padding: 1rem;
  }
}

.gif-dialog {
  width: 550px !important;

  & .el-dialog__body {
    padding-top: 0 !important;
  }
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.announcement_body {
  font-size: 16px;

  & a {
    color: $greenish;
  }
}

.text-muted {
  @extend ._600;
  word-break: break-word;
}

.text-medium {
  font-size: 14px;
  color: #303133;
  font-weight: 500;
}

.overview-boxes {
  height: 80px;
}

.integration-boxes {
  max-height: 80px;
  max-width: 150px;
}

.integration-box {
  height: 120px;

  & .el-card__body {
    height: 100%;
    padding: 10px;

    & .row {
      height: 100%;
    }
  }
}

.dn_item + .dn_item:before {
  content: ", ";
}

.ins_item + .ins_item:before {
  content: ", ";
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

ul.nav.card {
  background-color: inherit !important;
  border: none;
  border-radius: 0;
}

.blink {
  animation: fade 1500ms infinite;
  -webkit-animation: fade 1500ms infinite;
}

.btn {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.filters {
  display: block;
}

.filter-input {
  max-width: 300px;
  font-size: 14px;
  padding: 0.2rem .75rem;
}

.pre {
  @extend pre;
}

.placeholder {
  display: block;
  width: 100%;
  height: 100%;
}

.placeholder img {
  display: block;
  margin: auto;
  height: 100%;
}

.welcome-head {
  font-family: 'Quicksand';
  font-size: 35px;
  font-weight: 600;
  color: #090A0D;
  text-align: center;
}

.welcome-message {
  font-family: 'Quicksand';
  font-size: 16px;
  font-weight: 400;
  color: #090A0D;
  text-align: center;
}

.fixed-footer {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  margin: inherit;

  & > .row > .col-12 {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 0px 2px rgba(0, 0, 0, 0.24);
  }
}

.fixed-header {
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    top: $navbar-height;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    & > .row > .col-12,
    & > .row > .d-flex {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
}

.mt-lg {
  margin-top: 80px;
}

.data-switch {
  position: absolute;
  top: 11px;
  right: 11px;
  z-index: 1;
}

.card-bottom {
  position: absolute;
  bottom: 1rem;
}

.clear-both {
  clear: both;
}

.large-arrow {
  & .el-collapse-item__arrow {
    font-size: 32px;
  }
}

input[type=radio]:hover, label[for] {
  cursor: pointer;
  @extend .no-select;
}

.app-aside .left {
  overflow: hidden;
}

li.filters {
  & a.btn {
    opacity: 0.25;
  }

  &:hover a.btn {
    opacity: 1;
  }
}

.el-btn {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
  padding: 12px 20px;
  max-height: 42px;

  & i.material-icons {
    line-height: 0.95em !important;
  }

  & i.fa {
    line-height: 0.95em !important;
  }
}

.full-font-size {
  font-size: 100%;
}

div.pointer:hover {
  background: rgba(0, 0, 0, 0.03);
}

.contacts {
  height: 100%;
  padding-top: 45px;

  & .el-tabs__content {
    height: 100%;
  }

  & .el-tab-pane {
    height: 100%;
    padding-top: 0.5rem;
  }

  & .el-tabs__header {
    margin: 0 !important;
  }
}

.back {
  position: absolute;
  z-index: 1;
  left: 20px;
  right: 20px;
}

.mark-read:hover {
  color: $dark-greenish !important;
}

.tab-badge {
  & .el-badge__content {
    top: 15px !important;
    right: 0px !important;
  }
}

.add-user {
  & .el-form-item__error {
    position: relative !important;
  }

  & .el-transfer__buttons {
    margin-bottom: 20px;
  }
}

button.btn.dropdown-toggle {
  height: 40px;
  padding-top: 0;
  padding-bottom: 0;

  &.report-settings-btn {
    margin-top: 4px;
    height: 34px;
  }
}

.abs-bottom {
  position: fixed;
  bottom: 0;

  &.abs-right {
    right: 8px;
  }

  &.abs-left {
    left: 8px;
  }
}

.abs-top {
  position: absolute;
  top: 68px;

  &.abs-right {
    right: 8px;
  }

  &.abs-left {
    left: 8px;
  }
}

.subscription-status-tag {
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 17px;
  background: gray;
  color: #ffff;
  margin-right: 5px;
}

.clickable {
  cursor: pointer;
}

.help {
  cursor: help;
}

.last-message {
  max-width: 200px;
  font-size: 13px;
}

.upgrade-notification {
  @extend .blue-purple-gradient;
  color: $whitish !important;

  & .el-notification__content {
    margin: 0 !important;
    color: $whitish !important;
  }

  &.el-notification .el-icon-success {
    color: $whitish !important;
    margin-top: 25px;
    font-size: 25px;
  }

  &.el-notification {
    border: 0;
  }
}

.profile-info {
  max-width: 315px;
  height: 3.2rem;
}

.bg-white {
  background-color: $whitish;
}

.preview {
  max-height: 256px;
}

.agent-list {
  padding: 0;
  height: 256px;
  overflow-y: auto;
}

.mega-dropdown-menu {
  .el-badge {
    &.default-filter-indicator {
      & a {
        max-width: 250px;
        word-break: break-word;
        display: inline-block;
        float: left;
        font-weight: 400;
      }

      .el-badge__content {
        background-color: $greenish;
        right: -3px;
        top: 12px;
      }
    }
  }
}

.sequence {
  & .message {
    overflow: hidden;
    vertical-align: middle;
  }

  & .row {
    width: calc(100% - 56px - 1rem);
  }
}

.in-notification-link, .in-notification-link:hover, .in-notification-link:focus {
  color: #00bf50;
  text-decoration: underline;
}

.cursor-default {
  cursor: default !important;
}

.workflow-icon {
  position: relative;
  left: -0.35em;
  margin-right: -2px;
  height: 36px;
}

.bulk-messaging-icon {
  position: relative;
  left: -0.55em;
  top: -0.1em;
  margin-right: -9px;
  height: 36px;
}

.bulk-sms-counter {
  border-radius: $btn-border-radius;
  width: 16rem;

  & .text-center {
    width: 100%;
  }
}

.input-group .form-control {
  height: auto;
  border-radius: .25rem;
}

.rejection-tooltip {
  max-width: 250px;
}

.dialog-create-company {
  .el-dialog__body {
    padding-bottom: 1px;
  }
}

.dialog-text {
    word-break: normal !important;
}

button:disabled {
  cursor: not-allowed;
}

.opaq {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.operations {
  max-width: 160px;
}

.is-disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.transparent {
  background: transparent;
}

.message-body {
  left: 10px;
  font-size: 16px;
  line-height: 28px;
  word-break: break-word;

  &.text-left {
    left: auto;
    right: 10px;
  }

  max-width: 500px;
}

.arrow-dker {
  border-color: rgba(0, 0, 0, 0) !important;
}

.arrow-bluish {
  border-color: rgba($bluish, 0.9) !important;
}

.arrow-greenish {
  border-color: rgba($greenish, 0.9) !important;
}

.edit-button {
  width: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  text-align: center;
}

.edit-field-button {
  @extend .edit-button;
  top: 21px;
  visibility: hidden;

  &.active {
    visibility: visible;
  }
}

.md-form-group {
  &:hover,
  &:focus {
    & .edit-field-button {
      visibility: visible;
    }
  }
}

.contact-info {
  max-width: 360px;
  margin: 0 auto;
}

.contact-details {
  & .md-input {
    font-weight: 400;
  }

  & .md-input:disabled {
    font-family: $font-family-alternative;
    font-size: 15px;
    opacity: 0.8;
  }

  & .btn {
    font-family: $font-family-alternative;
  }

  & .pl-5 {
    padding-left: 2.4rem !important;
  }
}

.deactivated {
  filter: grayscale(100%);
}

a.linkified {
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.6;
  }
}

.app-monitor-icon {
  position: absolute;
  right: 14px;
}

.btn i.material-icons {
  font-size: 20px;
}

.nav > li > a .nav-icon i {
  font-size: 24px !important;
  line-height: 35px !important;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 0.5em !important;
}

.draggable {
  cursor: move;
}

.dn-order {
  width: 10px;
}

.edit-operations {
  width: 100%;
}

.changelog-body {
  & a {
    color: $bluish;
    text-decoration: underline;
  }

  & p {
    margin-bottom: 0.25rem;
  }
}

.height-60 {
  height: 60px;
}

.height-80 {
  height: 80px;
}

.height-300 {
  height: 300px;
}

.height-400 {
  height: 400px;
}

.height-500 {
  height: 500px;
}

.height-600 {
  height: 600px;
}

.height-700 {
  height: 700px;
}

.no-box-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.break-word {
  word-wrap: break-word;
  word-break: break-word;
}

.width-80 {
  max-width: 80px;

  &.pull-left, &.pull-right, &.fixed {
    width: 80px;
  }
}

.width-100 {
  max-width: 100px;

  &.pull-left, &.pull-right, &.fixed {
    width: 100px;
  }
}

.width-120 {
  max-width: 120px;

  &.pull-left, &.pull-right, &.fixed {
    width: 120px;
  }
}

.width-150 {
  max-width: 150px;

  &.pull-left, &.pull-right, &.fixed {
    width: 150px;
  }
}

.width-200 {
  max-width: 200px;

  &.pull-left, &.pull-right, &.fixed {
    width: 200px;
  }
}

.width-230 {
  max-width: 230px;

  &.pull-left, &.pull-right, &.fixed {
    width: 230px;
  }
}

.width-300 {
  max-width: 300px;

  &.pull-left, &.pull-right, &.fixed {
    width: 300px;
  }
}

.width-400 {
  max-width: 400px;

  &.pull-left, &.pull-right, &.fixed {
    width: 400px;
  }
}

.width-500 {
  max-width: 500px;

  &.pull-left, &.pull-right, &.fixed {
    width: 500px;
  }
}

.width-600 {
  max-width: 600px;

  &.pull-left, &.pull-right, &.fixed {
    width: 600px;
  }
}

.width-700 {
  max-width: 700px;

  &.pull-left, &.pull-right, &.fixed {
    width: 700px;
  }
}

.width-800 {
  max-width: 800px;

  &.pull-left, &.pull-right, &.fixed {
    width: 800px;
  }
}

.width-120 {
  max-width: 120px;

  &.pull-left, &.pull-right, &.fixed {
    width: 120px;
  }
}

.width-160 {
  max-width: 160px;

  &.pull-left, &.pull-right, &.fixed {
    width: 160px;
  }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

.status i {
  width: 15px;
  height: 15px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  display: block;
}

.el-transfer-panel {
  width: 300px !important;

  .el-transfer-panel__list.is-filterable {
    height: 160px;
  }
}

.no-opacity {
  opacity: 1 !important;
}

.flex-row.dash {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.hours.close {
  opacity: 1 !important;
}

.font-awesome-button {
  line-height: 24px;
}

.navbar {
  height: 3.2rem;
}

.nav-border .nav > li:not(:disabled).active:after {
  color: $greenish;
  border-left-width: 5px;
}

.no-border {
  border: none;
}

.dropdown-toggle > i.material-icons {
  vertical-align: 2px;
}

.app-body.max-height {
  padding-bottom: 0;
}

.navside {
  background-color: $navside-bg;
}

.navbar .up {
  top: 7px;
  right: 0px;
}

.hover-menu {
  & .col-4 {
    @extend .text-greyish;
    @extend ._600;

    & span.block {
      @extend .text-blackish;
      @extend ._400;
    }

    & .active {
      background-color: transparent;
    }

    & .icon {
      display: block;
    }

    & .icon.active {
      display: none;
    }

    &:hover {
      @extend .blue-purple-gradient;
      @extend .text-whitish;

      & span.block {
        @extend .text-whitish;
      }

      & .icon {
        display: none;
      }

      & .icon.active {
        display: block;
      }
    }
  }
}

.v-tour {
  & .v-step {
    z-index: 10000;

    & .v-step__arrow[data-v-aa0cbe42] {
      border-color: $blackish;
    }
  }

  & .v-step[data-v-aa0cbe42] {
    background: $blackish;
    font-size: 18px;
  }
}

b,
strong {
  font-weight: 700;
}

.hover-opacity:hover {
  opacity: 1;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.hover-text-blackish:hover {
  color: $blackish !important;
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}

.no-left-border-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.no-right-border-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.navside .nav li a .nav-label {
  margin-left: 0;
}

.filter-item {
  width: 200px;
}

.el-button i.fa {
  line-height: 0.9em;
}

.padding-top-large {
  padding-top: 5em !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky !important;
  top: 60px;
}

// .pattern {
//   background-image: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .7)), url("../images/pattern.jpg");
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center center;
// }

.banner-font {
  font-family: 'Poppins', sans-serif;
}

.pattern {
  background-image: url("../images/pattern2_overlay.svg"), url("../images/pattern2.jpg");
  background-repeat: no-repeat;
  background-size: 49vw, cover;
  background-position: center;
}

.pattern-xmas {
  background-image: linear-gradient(rgba(0, 0, 0, 0.20), rgba(0, 0, 0, 0.20)), url("../images/xmas-tree.jpg");
  background-color: #01132B;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.m-h-v {
  min-height: 100vh;
  @extend .h-v;
}

.h-48 {
  height: 48px;
}

@media (max-width: 959px) {
  .message .el-badge.item {
    max-width: 100%;
  }

  .message-body.text-left {
    max-width: calc(100vw - 90px);
  }

  .el-badge__content.is-fixed.is-dot {
    right: -5px !important;
    top: 5px;
  }
}

@media (max-width: 767px) {
  .mega-dropdown-menu {
    width: 94vw;
  }

  #pageTitle {
    font-size: 16px;
  }

  .mega-dropdown-menu {
    .el-badge {
      &.default-filter-indicator {
        & a {
          max-width: 140px;
        }
      }
    }
  }

  .fixed-footer.padding {
    padding: 0.5rem 1rem !important;
  }

  ol.special-ol > li {
    margin: 0;
  }

  .conversync {
    & .timeline .tl-date {
      width: 5.5em;
      margin-left: -8.5em;
    }

    & .contacts .el-tabs__nav {
      margin: 0 auto;
      position: relative;
      max-width: 265px;
      float: none;
    }
  }

  .app-header {
    ~ .app-body {
      padding-bottom: $footer-height-small;
    }
  }

  .app-footer {
    &:not(.hide) ~ .app-body {
      padding-bottom: $footer-height-small;
    }
  }

  .m-y {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }

  .footer {
    display: none;
  }

  .help-card .card-bottom {
    position: relative;
    margin-top: 100px;
  }

  ol > li {
    margin: 0;

    &:before {
      display: none;
    }
  }

  .is-live {
    display: none;
  }
}

@media (max-width: 748px) {
  .custom-el-dialog {
    & .el-dialog {
      width: 100%;
    }
  }

  .el-dialog {
    width: 100% !important;
  }

  .wizard-dialog {
    & img.w-300 {
      width: 150px;
      height: 150px;
      line-height: 150px;
    }
  }
}

@media (max-width: 600px) {
  .el-btn {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .hover-menu {
    left: -103px !important;
  }
}

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1.0;
  }
}

.new-card {
  padding: 20px;
  border: 1px solid #EBEEF5;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .1);
  background-color: #FFF;
  color: #303133;
  transition: .3s;
  border-radius: 4px;
  overflow: hidden;
}

.navbar {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.el-card__body .help {
  float: right;
}

.help-card {
  & .el-popover__title {
    @extend ._600;
  }
}

.allow-select {
  -webkit-touch-callout: default; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently supported by Chrome and Opera */

  & ::selection {
    background: rgba($greenish, 0.1) !important; /* WebKit/Blink Browsers */
  }

  & ::-moz-selection {
    background: rgba($greenish, 0.1) !important; /* Gecko Browsers */
  }
}

.fixed-width-500 {
  flex: 0 0 500px;
}

.stripe-btn {
  background: url('../images/integrations/stripe-icon.svg') no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.bats-btn {
  background: url('../images/integrations/bats-icon.png') no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.guesty-btn {
  background: url('../images/integrations/guesty-icon.png') no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.zoho-btn {
  background: url('../images/integrations/zoho-icon.svg') no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

.hubspot-btn {
  background: url('../images/integrations/hubspot-icon.svg') no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;

  &.small {
    width: 24px;
    height: 24px;
  }
}

.pipedrive-btn {
  background: url('../images/integrations/pipedrive-icon.svg') no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;

  &.small {
    width: 24px;
    height: 24px;
  }
}

.gohighlevel-btn {
  background: url('../images/integrations/ghl-icon.png') no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;

  &.small {
    width: 24px;
    height: 24px;
  }
}

.custom-link-builder-btn {
  background: url('../images/integrations/link-solid.svg') no-repeat center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}

#close-container {
  margin-bottom: 50px;
}

#giphy-grid {
  margin-top: 20px;
  height: 300px;
}

.tabs-pt-0 .el-tabs__content {
  padding-top: 0px !important;
}

.note-usage {
  width: 480px !important;
  margin: 0 auto !important;
}

.code-bg {
  background: #f8f8f8;
}

.el-upload-list.el-upload-list--text {
  padding-bottom: 10px;
}

body .nav-tabs .nav-link.rounded-top-left {
  border-top-right-radius: 0;
}

body .nav-tabs .nav-link.rounded-top-right {
  border-top-left-radius: 0;
}

.md-input-style {
  font-family: $font-family-alternative;
  font-size: 15px;
  opacity: 0.8;
  display: block;

  @extend .md-input;
}

.dropdown-toggle::after {
  vertical-align: .155em;
}

.handle-whitespace {
  white-space: pre-line;
}

.not-contacted-filter .el-input__inner,
.not-contacted-filter .el-input-group__append,
.not-responded-filter .el-input__inner,
.not-responded-filter .el-input-group__append {
  padding: 0px 10px;
}

.not-contacted-filter .el-input__inner::-webkit-outer-spin-button,
.not-contacted-filter .el-input__inner::-webkit-inner-spin-button,
.not-responded-filter .el-input__inner::-webkit-outer-spin-button,
.not-responded-filter .el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.not-contacted-filter,
.not-responded-filter {
  .el-form-item__error {
    position: relative;
    text-align: right;
    float: right;
    margin-right: .5rem;
    width: auto;
    line-height: 1.5em;
  }
}

.help-text {
  font-weight: 400;
  font-size: 0.95rem;
  width: 100%;
  line-height: 1;
  color: #7D7D7D !important;
  margin-top: 10px;

  & .form-hover-help {
    line-height: 1 !important;
  }
}

.campaign-selector .el-tag--mini {
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-dropdown-menu__item {
  font-size: 14px !important;

  &--delete {
    color: $dropdown-link-delete-color !important;
    &:hover {
      color: $dropdown-link-delete-hover-color !important;
    }
  }
}

.container-filter-search {
  position: relative;
  z-index: 3000;

  .focused {
    .el-input-group__append {
      border-color: #00BF50;
    }
  }

  .el-input__inner {
    border-right: 0;
  }

  .el-input-group__append {
    background-color: #ffffff;
  }

  .dropdown {
    position: absolute;
    z-index: 1100;
    left: 0;
    top: 38px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #DCDFE6;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, .1);

    .table-filters {
      width: calc(100% - 1px);

      td {
        vertical-align: middle;
        font-weight: bold;
        padding: 0.3rem 1rem;
      }

      tr:hover td {
        background-color: #f9f9f9;
      }
    }

    .el-input__inner {
      text-align: right !important;
      background-color: transparent;
    }
  }

  .slide-enter-active {
    -moz-transition-duration: 0.1s;
    -webkit-transition-duration: 0.1s;
    -o-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.1s;
    -webkit-transition-duration: 0.1s;
    -o-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
}

/* Event Colors */
.event-color-type-12 {
  color: #C6DAFC;

  &.status-8 {
    color: #2D96F3;

    &.is_past {
      color: #C6DAFC;
    }
  }

  &.status-4 {
    color: #498A7B;
  }

  &.status-5 {
    color: #B93E1D;
  }

  &.status-14 {
    color: #DC3545;
  }
}

.event-color-type-13 {
  color: #FBD763;

  &.status-8 {
    color: #EABA20;

    &.is_past {
      color: #f7de94;
    }
  }

  &.status-4 {
    color: #ff9800;
  }

  &.status-5 {
    color: #8A3350;
  }

  &.status-3 {
    color: #DC3545;
  }
}

.event-bg-color-type-12 {
  background-color: #C6DAFC;
  color: #ffffff;

  &.status-11 {
    background-color: #2D96F3;

    &.is_past {
      background-color: #C6DAFC;
    }
  }

  &.status-9 {
    background-color: #498A7B;
  }

  &.status-10 {
    background-color: #B93E1D;
    text-decoration: line-through;
  }

  &.status-14 {
    background-color: #DC3545;
  }
}

.event-bg-color-type-13 {
  color: #ffffff;
  background-color: #FBD763;

  &.status-8 {
    background-color: #EABA20;

    &.is_past {
      background-color: #f7de94;
    }
  }

  &.status-4 {
    background-color: #ff9800;
  }

  &.status-5 {
    background-color: #8A3350;
    text-decoration: line-through;
  }

  &.status-3 {
    background-color: #DC3545;
  }
}

#el-dialog-watch-video {
  .el-dialog {
    background-color: #000 !important;
  }

  .el-dialog__title {
    color: #efefef;
  }

  .el-dialog__body {
    padding: 0 !important;
  }
}

.lh-1 {
  line-height: 1.2 !important;
}

.lh-1p4 {
  line-height: 1.4 !important;
}

.fs-1p1 {
  font-size: 1.1rem;
}

.color-999 {
  color: #999999;
}

.audio-recorder {
  & .align-items-end {
    height: 60%;
  }

  & .btn {
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: inline-block;
    cursor: pointer;
    -webkit-box-shadow: 0 0 0 0 rgba(232, 76, 61, .7);
    box-shadow: 0 0 0 0 rgba(232, 76, 61, .7);

    i {
      font-size: 30px;
    }
  }

  & .active {
    -webkit-animation: pulse 1.25s cubic-bezier(.66, 0, 0, 1) infinite;
    animation: pulse 1.25s cubic-bezier(.66, 0, 0, 1) infinite;

    @-webkit-keyframes pulse {
      to {
        -webkit-box-shadow: 0 0 0 10px rgba(239, 83, 80, .1);
        box-shadow: 0 0 0 10px rgba(239, 83, 80, .1);
        background-color: #e53935;
        -webkit-transform: scale(.9);
        transform: scale(.9);
      }
    }

    @keyframes pulse {
      to {
        -webkit-box-shadow: 0 0 0 10px rgba(239, 83, 80, .1);
        box-shadow: 0 0 0 10px rgba(239, 83, 80, .1);
        background-color: #e53935;
        -webkit-transform: scale(.9);
        transform: scale(.9);
      }
    }
  }
}

/**
Import Wizard Styling
 */
.import-wizard {
  .import-wizard-loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1110;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .container-table-csv {
    min-height: 60vh;
    width: 100%;
    overflow: auto;

    .cell-warning {
      color: #856404;
      background-color: #fff3cd;
    }

    .cell-danger {
      color: #721c24;
      background-color: #f8d7da;
    }
  }

  .table-csv {
    white-space: nowrap;
    color: #232323;
  }

  .select-table-header {
    min-width: 150px;
  }

  .el-dialog__body {
    padding: 1rem;
  }

  .el-upload-dragger {
    position: relative;
    height: 60vh;
    width: 100%;
    border: 3px dashed #dedede;
    background-color: #fafafa;

    .dz-label {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -40%);
      text-align: center;

      p {
        font-size: 20px;
        color: #909090;
      }

      .fas {
        font-size: 75px;
        color: #dedede;
        margin-bottom: 1rem;
      }
    }
  }

  .step-0 {
    min-height: 60vh;

    .dnc-info-container {
      margin-top: 8rem !important;
    }
  }

  .step-1 {
    position: relative;

    .container-template-download {
      position: absolute;
      left: 50%;
      top: 70%;
      transform: translateX(-50%);
      z-index: 100;
      border-radius: 4px;
      padding: 1rem;

      p {
        margin: 0;
        font-size: 18px;
        color: #909090;
      }
    }
  }

  .step-1 {
    .table-responsive {
      height: 60vh;
    }
  }

  .container-import-summary {
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;

    h2 {
      font-weight: 300;
      margin-bottom: 2rem;
    }

    .fa-file-import {
      font-size: 125px;
      margin-bottom: 2rem;
      color: #d0d0d0;
    }

    .fa-check {
      color: #00BF50;
    }
  }

  .el-radio {
    &.new-ring-group, &.choose-ring-group {
      height: auto !important;
      line-height: 1.2em;
    }
  }
}

.alert-aloware {
  border-radius: 0 4px 4px 0;
  border-left-width: 5px;
  word-break: break-word;

  h6 {
    color: #155724;
  }

  .alert-icon {
    font-size: 30px;
    align-self: center;
    margin-right: 1rem;
  }
}

.audio-recorder-wrapper {
  width: 55%;
  float: left;

  & + .audio-uploader-wrapper {
    width: 45%;
    display: inline-block;
    margin-top: 24px;
  }
}

.audio-recorder-container {
  > div {
    height: 180px;

    .el-form-item__content {
      height: 100%;
    }

    &:first-child {
      width: 79%;
    }

    &:last-child {
      width: 20%;
      text-align: center;
    }
  }
}

.btn-group-sm > .btn, .btn-sm {
  line-height: 1.7;
}

.container-campaign-summary {
  & .finish-icon {
    &.is-fax {
      font-size: 50px;
    }

    &:not(.is-fax) {
      width: 65px;
      opacity: 0.7;
    }

    color: rgb(208, 208, 208);
  }

  & .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }

  & .el-tabs__item {
    font-size: 1rem !important;
    font-weight: 400 !important;
  }

  h2 {
    font-weight: 400;
    margin-bottom: 2rem;
  }
}

.container-account-imports {
  .import-status-1 {
    font-weight: bold;
    color: #0d47a1;
  }

  .import-status-2 {
    font-weight: bold;
    color: #E3AE24;
  }

  .import-status-3 {
    font-weight: bold;
    color: #00A344;
  }

  .import-status-4 {
    font-weight: bold;
    color: #C4183C;
  }

  .import-status-5 {
    font-weight: bold;
    color: #7D7D7D;
  }

  .import-status-6 {
    font-weight: bold;
    color: #C4183C;
  }

  .import-selection-button {
    padding: 7px 12px !important;
    height: 100%;
  }
}

.account-import-selection-container {
  padding: 7px 0 !important;
  margin-top: 0 !important;

  .popper__arrow {
    display: none !important;
  }

  &::after {
    display: none;
  }

  .el-dropdown-menu__item {
    padding: 0 19px !important;
  }

  .icon-container {
    height: 18px;
    width: 18px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.hubspot-icon-container {
      background: #FF7A59;
    }

    .item-icon {
      font-size: 10px;
      font-weight: 600;
      color: #fff;
      margin-right: 0 !important;
    }
  }

  .csv-icon {
    width: 18px;
    height: 18px;
  }
}

.white-space-no-wrap {
  white-space: nowrap;
}

.white-space-initial {
  white-space: initial;
}

.white-space-normal {
  white-space: normal;
}

.d-relative {
  position: relative;
}

.edit-contact,
.edit-configurations {
  & .first-name .el-input__inner,
  & .domain .el-input__inner,
  & .last-name .el-input__inner,
  & .max-negative-balance .el-input__inner {
    background: transparent;
    position: relative;
    z-index: 5;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(160, 160, 160, 0.2);
    width: 100%;
    height: 34px;
    color: inherit;
    font-weight: 800;
    padding: 0 0 !important;

    &:focus {
      border-color: $primary;
      border-bottom-width: 2px;
      padding-bottom: 1px;
    }
  }
}

.open-hubspot-btn {
  background-color: #ff7a59;
}

.domain {
  & .el-input__suffix {
    z-index: 9;
  }
}

.hubspot-company-id {
  & .el-input__suffix {
    z-index: 9;
  }
}

/** +--------------------------------------------------------------------------------------------------------------------
 * | Custom Styling Form Sign Up Process
 * +--------------------------------------------------------------------------------------------------------------------
 * | The page can only be found in http://app.alodev.org/signup
 */
#container-signup {

  h3 {
    color: #232323;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .card {
    border-radius: 8px;
  }

  .form-group {
    label {
      display: block;
      line-height: 1;
      margin-bottom: .5rem;
      font-size: 14px;
      font-weight: bold;
    }

    .text-danger {
      font-size: 13px;
    }

    .form-control {
      border-radius: 4px;
    }
  }

  .btn-signup {
    font-weight: bold;
    height: 42px;
    border-radius: 21px;
    border-width: 2px;
  }
}

.container-trial-modal {
  text-align: center;
  padding: 0 3rem 3rem 3rem;

  h2, h3 {
    font-weight: 400;
    margin-bottom: 2rem;
    color: #323232
  }

  .fa-user-clock {
    font-size: 120px;
    display: block;
    margin-bottom: 2rem;
    color: #d0d0d0
  }

  p {
    font-size: 20px;
    color: #323232;
    margin-bottom: 2rem;
    word-break: normal;
  }
}

/* + -------------------------------------------------------------------------------------------------------------------
 * |  <---- End of Signup Styling
 * + -------------------------------------------------------------------------------------------------------------------
 */

/* + -------------------------------------------------------------------------------------------------------------------
 * |  <---- Start of Account Registration Styling
 * + -------------------------------------------------------------------------------------------------------------------
 */


.account-registration {
  display: flex;
  height: 100vh;
  overflow: hidden;

  // Base Styles
  .row.el-input {
    height: 35px !important;
  }

  .stepper__container {
    display: flex;
    text-align: center;
    text-align: -webkit-center;
  }

  .text-h4 {
    font-size: 2.5rem;
    color: black;
  }

  @include screen('xs') {
    .text-h4 {
      font-size: 20px;
    }
  }

  @include screen('sm') {
    .text-h4 {
      font-size: 20px;
    }
  }

  @include screen('md') {
    .text-h4 {
      font-size: 2.5rem;
    }
  }

  .text-h5 {
    text-align: start;
    font-size: 1.25rem !important;
    color: black;
  }

  .text-body1 {
    font-size: 1.25rem;
    margin-top: 28px;
    width: 82%;
    color: black;
  }

  @include screen('xs') {
    .text-body1 {
      font-size: 14px;
      margin-top: 10px;
      width: 100%;
    }
  }

  @include screen('sm') {
    .text-body1 {
      font-size: 14px;
      margin-top: 10px;
      width: 100%;
    }
  }

  @include screen('md') {
    .text-body1 {
      font-size: 1.25rem;
      margin-top: 28px;
      width: 82%;
    }
  }

  .password-hint {
    ul {
      padding-top: 10px;
      margin-bottom: 0 !important;
    }

    li {
      list-style: none;
      font-size: 14px;
      color: #000000;
      margin-top: 4px;
    }
  }

  .zipcode-hint {
    padding-top: 0;
    padding-left: 10px;
    font-size: 14px;
    color: #000000;
    text-align: left;
  }

  .negative-top {
    margin-top: -10px;
  }

  .banner {
    align-items: center;
    background: linear-gradient(240deg, #0037ff2a 0%, #22ff001c 100%);
    height: 100%;
    min-height: 100%;

    .card {
      background-color: #ffffff;
      border-radius: 12px;
      margin-right: 12px;

      > h3 {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color: black;
      }

      > p {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        padding: 0 24px 10px 24px;
        color: black;
      }
    }
  }

  // Stepper Container Details
  .stepper__container {
    display: flex;
    flex-direction: column;
    height: 90vh !important;

    .stepper__content__header {
      position: relative;
      top: 0;
      z-index: 10;
      background-color: white;
    }

    .stepper__content {
      flex-grow: 1;
      height: 56vh;
      overflow-y: auto;
    }

    @media (min-height: 720px) {
      .stepper__content {
        height: 50vh;
      }

      .stepper__container {
        height: 70vh !important;
      }
    }

    @media (min-height: 800px) {
      .stepper__container {
        height: 90vh !important;
      }
    }

    @media (min-height: 900px) {
      .stepper__content {
        height: 56vh;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 9px !important;
    max-height: 397px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #256EFF !important;
    border-radius: 6px !important;
    &:hover {
      background-color: #256EFF !important;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #E0E0E0 !important;
    border-radius: 6px !important;
  }

  @include screen('xs') {
    .banner {
      display: none !important;
    }

    .stepper__container {
      display: flex;
      text-align: -webkit-center;

      .stepper__content__header {
        max-width: 100%;
        position: relative;
        z-index: 10;
        background-color: white;
        font-size: 10px
      }
    }
  }

  @include screen('sm') {
    .banner {
      display: none !important;
    }

    .stepper__container {
      display: flex;
      text-align: -webkit-center;

      .stepper__container {
        display: flex;
        text-align: -webkit-center;

        .stepper__content__header {
          max-width: 100%;
          position: relative;
          z-index: 10;
          background-color: white;
          font-size: 10px;
        }
      }
    }
  }

  @include screen('md') {
    .banner {
      display: none !important;
    }

    .stepper__container {
      display: flex;
      text-align: -webkit-center;
      padding-left: 4vh;
    }
  }

  @include screen('lg') {
    .banner {
      display: none !important;
    }
  }

  @include screen('xl') {
    .banner {
      display: flex !important;
    }
  }

  @include screen('xs') {
    .stepper__container {
      display: flex;
      text-align: -webkit-center;
    }
  }

  @include screen('sm') {
    .stepper__container {
      display: flex;
      text-align: -webkit-center;
    }
  }

  @include screen('md') {
    .min-w-100 {
      // width: 100vh !important;
    }

    .stepper__container {
      display: flex;
      text-align: -webkit-center;
      padding-left: 4vh;
    }
  }

  // Miscellaneous Classes
  .w-65 {
    width: 65%;
  }
  .min-h-60 {
    min-height: 60vh;
  }

  .terms-iframe {
    border: 0;
    width: 100%;
    height: 30vh; /* Default iframe height */
    padding-bottom: 20px;
  }

  .accept-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 8px;
    margin-top: 10px;

    .carrier-fees {
      position: relative;
      left: -8.1%;
    }
  }

  @include screen('xs') {
    .terms-iframe {
      height: 30vh;
    }
  }

  @include screen('sm') {
    .terms-iframe {
      height: 30vh;
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }

  @include screen('md') {
    .terms-iframe {
      height: 30vh;
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }

  @include screen('lg') {
    .terms-iframe {
      height: 30vh;
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }

  .h-55 {
    height: 55vh;
  }

  .h-75 {
    height: 75%;
  }

  .logo  {
    //position: absolute !important;
    z-index: 11;
  }

  .px-xl {
    padding-left: 48px;
    padding-right: 48px;
  }

  .pt-xl {
    padding-top: 48px;
  }

  .pt-40 {
    padding-top: 40px !important;
  }

  .phone-row {
    display: flex;
    height: 35px;
    width: 100%;

    > .col-md-4 {
      flex-grow: 1;
    }
  }

  #recaptcha-element {
    position: relative;
    left: -12%;
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin-bottom: 40px;
    margin-left: 24px;
  }

  @media (max-height: 500px) {
    .form-actions {
      margin-bottom: 68px;
    }
  }

  @media (min-height: 500px) and (max-height: 700px) {
    .form-actions {
      margin-bottom: 60px;
    }
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .loading-icon {
    width: 250px;
    height: 250px;
  }

  .loading-text {
     font-size: 20px;
     font-weight: 500;
     margin-top: 20px;
   }

   .g-recaptcha {
     margin-left: -62px;
   }

   .banner-ssu-account-creation {
     background-image: url('../images/ssu-account-creation.png');
   }

   .banner-ssu-start {
     background-image: url('../images/ssu-start.png');
   }
}


 /* + -------------------------------------------------------------------------------------------------------------------
 * |  <---- End of Account Registration Styling
 * + -------------------------------------------------------------------------------------------------------------------
 */


li {
  .pro-label {
    background: $amber-700;
    font-size: 100%;
  }

  .pro-label-text {
    color: $amber-700;
  }

  &.active {
    & .pro-label {
      background: $amber-700;
      font-size: 100%;
    }

    & .pro-label-text {
      color: $amber-700;
    }
  }
}

.agent-avatar i {
  width: 14px;
  height: 14px;
  bottom: -2px !important;
  right: -2px !important;
}

i.status-badge {
  width: 14px;
  height: 14px;
  border-width: 0px;
  border-style: solid;
  border-radius: 100%;
  display: inline-block;
  vertical-align: -2px;
}

i.workflow-status {
  margin-top: 5px;
  min-width: 10px;
  min-height: 10px;
  max-width: 10px;
  max-height: 10px;
  width: 10px;
  height: 10px;
  border-width: 0px;
  border-style: solid;
  border-radius: 100%;
  display: inline-block;
  vertical-align: -2px;
}

a.workflow-link:hover {
  color: #1e5093;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.big-screen.max-height.no-footer {
  height: 100% !important;
}

.edit-contact-popover {
  & .edit-button {
    right: -32px;
  }
}

.md-input, .md-input-style {
  height: auto;
  min-height: 34px;
  line-height: 34px;
  font-weight: 500 !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

/* +--------------------------------------------------------------------------------------------------------------------
 * | Header Notification Styling
 * +--------------------------------------------------------------------------------------------------------------------
 */
//.body-with-notification {
//  .app-aside, .app-header {
//    top: 50px;
//  }
//
//  .app-body {
//    padding-top: 102px !important;
//  }
//
//  .fixed-header {
//    top: 102px !important;
//  }
//}

#container-user-notifications {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1110;
}

.header-notification {
  width: 100%;
  z-index: 1110;
  padding: .5rem 1rem;
  vertical-align: middle;
  position: relative;

  .btn-close {
    &:hover {
      opacity: 0.5;
    }
  }

  &.hn-warning {
    background-color: #fff3cd;
    border-left: 5px solid #ffeeba;
    color: #856404;

    .btn-close {
      color: #856404;

      &:hover {
        opacity: 0.5;
        color: #856404;
      }
    }
  }

  &.hn-10dlc-warning {
    background-color: #fff532;
    border-left: 5px solid #fff50a;
    color: #2c2b02;

    .btn-close {
      color: #2c2b02;

      &:hover {
        opacity: 0.5;
        color: #2c2b02;
      }
    }
  }

  &.hn-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;

    .btn-close {
      color: #155724;

      &:hover {
        opacity: 0.5;
        color: #155724;
      }
    }
  }

  &.hn-info {
    color: #000000;
    background-color: #cce5ff;
    border-color: #b8daff;

    .btn-close {
      color: #004085;

      &:hover {
        opacity: 0.5;
        color: #004085;
      }
    }

    .modal-link {
      color: var(--Primary-Blue, #256EFF);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.hn-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;

    .btn-close {
      color: #721c24;

      &:hover {
        opacity: 0.5;
        color: #721c24;
      }
    }
  }
}

.el-checkbox.is-bordered.is-checked {
  border-color: #00A344 !important;
}

.force-scrollbar-view {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 7px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.el-alert.big {
  .el-alert__description, .el-alert__title {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
}

.form-label {
  line-height: 1.5;
  margin-bottom: 0.4rem;

  label {
    font-weight: bold;
    display: block;
    margin: 0;
    color: #303133;
  }

  h5 {
    font-size: 1rem !important;
    margin-bottom: .2rem !important;
    color: #656565 !important;
  }

  h6 {
    font-size: 0.8rem !important;
    margin-bottom: .1rem !important;
  }

  small {
    font-size: 14px;
    color: #767676;
    word-break: keep-all;
  }
}

.btn-input-group {
  .el-input-group__append {
    font-weight: 400;
    @extend .bluish;
  }
}

.table-routing-test {
  .td-status {
    width: 90px;
  }

  .td-order {
    padding-top: 1rem;
    width: 20px;
    vertical-align: top;
    text-align: center;
  }

  .td-can-take-calls {
    padding-top: 1rem;
    width: 180px;
    vertical-align: top;
  }

  .el-collapse {
    border-color: #fff !important;

    .el-collapse-item__header {
      border-color: #fff !important;
    }

    .el-collapse-item__wrap {
      border-color: #fff !important;
    }
  }
}

.original-line-height {
  line-height: 1.2 !important;
}

.waveform-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  background: transparent;
}

#waveform {
  width: calc(100% - 110px);
  padding-left: 5px;
  padding-right: 5px;
}

.waveform-timeline {
  width: 80px;
  text-align: right;
}

.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #EFEFEF;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    background: #DDD;
  }
}

#table-accounts {
  thead {
    th {
      border-top: 0;
      border-bottom: 0;
    }
  }

  tbody {
    td {
      color: #545454;
      border-top: 1px solid #f0f0f0;
      border-color: rgba(120, 130, 140, 0.4);
    }
  }
}

#container-table-accounts, #container-table-presignups {
  min-height: calc(100vh - 200px);
  position: relative;
  overflow: auto;

  .message-centered {
    position: absolute;
    z-index: 110;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #d0d0d0;
    font-size: 26px;

    i {
      font-size: 60px;
      display: block;
      margin-bottom: 1rem;
    }
  }
}

#container-account-details {
  table {
    td {
      vertical-align: top;
    }
  }
}

.a-primary {
  color: #2196f3 !important;

  &:hover {
    opacity: 0.8;
  }
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-600 {
  font-weight: 600;
}

.stick-top-with-header {
  top: 120px;
  z-index: 560;

  &.top-70 {
    top: 70px
  }
}

.page-anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -120px;
}

.list-group-alo {

  .list-group-item {
    border: 0;
    border-left: 2px solid rgba(0, 0, 0, .1);
    padding: .5rem .5rem .5rem 1rem;
  }

  .list-group-item-action {
    &:hover, &:active {
      border-left: 2px solid #00BF50;
      background-color: #fefefe;
    }
  }
}

.input-suffix {
  height: 40px;
  vertical-align: middle;
  align-self: center;
  font-weight: bold;
  line-height: 40px;
  padding: 0 1rem 0 0;
  color: #DCDFE6;

  &.active {
    color: #00A344 !important;
  }
}

.form-aloware {
  color: #878787 !important;
  position: relative;

  h1, h2, h3, h4, h5, h6 {
    color: #656565 !important;
  }

  p {
    color: #656565;
    word-break: normal;
  }

  small {
    font-size: 14px;
    line-height: 1.6;
    display: inline-block;
  }

  .el-form-item {
    position: relative;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 2rem;

    &.no-border {
      border: 0;
      padding-bottom: 0;
    }
  }

  .el-form-item.shine:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 10px);

    &.no-border {
      height: calc(100% + 20px);
    }

    background-color: rgba(0, 191, 80, 0.1);
    animation: fade 2s linear;
    opacity: 0;
    border-radius: 4px;
  }

  @keyframes fade {
    0%, 100% {
      opacity: 0;
      display: none !important;
    }
    50% {
      opacity: 1;
      display: block !important;
    }
  }

  .switch-label {
    .el-switch__core {
      align-self: center;
      margin-right: .5rem;
    }

    label {
      margin: 2px 0 0 0 !important;
      font-weight: bold;
      line-height: 20px;
      vertical-align: middle;

      &.inactive {
        color: #c0c0c0 !important;
      }
    }

    .flex-basis-25 {
      flex-basis: 25%;
    }
  }

  hr {
    border-top: 1px solid #f2f2f2 !important;
  }

  .input-block {
    display: block;
    width: 100% !important;

    input {
      display: block;
      width: 100%;
    }
  }
}

.checkbox-label-description {
  height: inherit !important;
  display: block;
  margin-left: 0 !important;
  margin-bottom: 12px !important;

  .el-checkbox__input {
    line-height: 35px !important;
  }

  .el-checkbox__label {
    label {
      color: #232323;
      display: block;
      margin: 0;
      font-weight: bold;
      line-height: 1.1;
    }

    small {
      color: #999;
      font-weight: 300;
      line-height: 1;
    }
  }

  &.is-checked {
    .el-checkbox__label {
      label {
        color: $greenish !important;
      }
    }
  }
}

.checkbox-group {
  border: 1px solid $light-grayish-blue;
  border-radius: 4px;
  margin-bottom: 8px;

  &-area {
    padding: 10px;
  }

  &-item {
    display: flex !important;
    align-items: center !important;

    & > span {
      padding-left: 0 !important;
    }
  }
}

.textarea {
  &-container {
    position: relative;

    .el-textarea {
      textarea.el-textarea__inner {
        resize: none;
      }
    }

    &.with-label {
      .el-textarea {
        textarea.el-textarea__inner {
          padding-bottom: 36px;
        }
      }
    }
  }

  &-label {
    position: absolute;
    background-color: $white-80-opacity;
    padding: 0 5px;
    color: $dark-gray;
    font-weight: 400;
  }
}

.with-label .el-textarea__inner {
  padding-bottom: 20px;
}

.container-form-height-indicator {
  position: sticky;
  bottom: 70px;
  text-align: center;
  width: 100%;
  z-index: 1010;

  .btn-form-height-indicator {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fff;
    color: #00A344;
    border: 0;
    outline: none;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

    &:hover {
      background-color: #efefef;
      opacity: 0.8;
      cursor: pointer;
    }

    i {
      font-size: 20px;
      line-height: 40px;
      vertical-align: middle;
    }
  }
}

.el-input-copy {
  .el-input__inner {
    cursor: pointer;
  }
}

.list-ring-group {
  .list-ring-group-item {
    border: 1px solid #e0e0e0;
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    border-radius: 5px;
    background-color: #fdfdfd;

    .el-input__inner {
      //border: 0;
    }
  }
}

.line-greet-record {
  .card {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;

    .card-header {
      background-color: #fefefe;
    }

    .card-header {
      border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    }
  }
}

#account-settings-tab {
  .el-tabs__content {
    background: transparent !important;
    overflow: hidden !important;
  }

  &.el-tabs--border-card>.el-tabs__header .el-tabs__item {
    color: $blackish;
  }

  &.el-tabs--border-card>.el-tabs__header {
    background: transparent !important;
    border-bottom: 0px;
  }

  &.el-tabs--left .el-tabs__item.is-left {
    text-align: left !important;
    margin: 0px 0 10px -1px;
  }

  &.el-tabs--left .el-tabs__item.is-left.is-active {
    border-radius: 4px;
    background: var(--Light-Background, rgba(37, 110, 255, 0.12));
    border-color: transparent !important;
  }
}

.contacts-list {
  & .list-item {
    font-size: 14px;
  }
}

.h-24 {
  height: 24px;
}

.table-transparent {
  background-color: transparent;
  border: 0;

  tbody, thead, tr, td, th {
    background-color: transparent !important;
    border: 0 !important;
  }

  td, th {
    padding: 0 0 1rem 0 !important;
  }
}

.col-2-4 {
  width: 20%;
}

.summary-card-hover {
  &:hover {
    -webkit-box-shadow: 0 0 5px 2px #6ADA96;
    -moz-box-shadow: 0 0 5px 2px #6ADA96;
    box-shadow: 0 0 0px 1px #6ADA96;
    background: rgba(110, 218, 152, 0.08);
  }

  .countup-hover small.text-sm:not(.sms-text) {
    min-height: 36.8px;
    display: block;
  }

  .flex-equal-height {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .title,
  .metric-label,
  .metric-value,
  .metric-total-label,
  .metric-total-value {
    font-family: Roboto;
  }

  .title {
    color: #040404;
    font-size: 15px !important;
    font-weight: bold;
    letter-spacing: 0.38px;
  }

  .metric-label {
    color: #666666 !important;
    font-size: 14px !important;
    letter-spacing: 0.35px;
  }

  .metric-value {
    color: #040404;
    font-size: 26px !important;
    font-weight: bold;
    letter-spacing: 0.35px;
  }

  .metric-total-label {
    color: #666666;
    font-size: 10px !important;
    letter-spacing: 0.13px;
  }

  .metric-total-value {
    color: #666666;
    font-size: 20px !important;
    letter-spacing: 0.27px;
    line-height: 24px;
  }

  .countup-hover:not(.unclickable):hover {
    cursor: pointer;
  }

  .countup-hover:not(.unclickable):hover .metric-value,
  .countup-hover:hover .hover-value {
    color: #6ADA96 !important;
  }

  .countup-hover:not(.unclickable):hover .metric-label {
    color: #040404 !important;
  }

  .countup-hover.unclickable:hover {
    cursor: initial !important;
    pointer-events: none;
  }
}

.cursor-initial {
  cursor: initial;
}

.card-border-left {
  border-left: 1px solid rgba(120, 130, 140, 0.13) !important;
}

.summary-numbers-v-align-30 {
  transform: translateY(30%);
}

.summary-numbers-v-align {
  transform: translateY(80%);
}

.img-with-icon {
  margin-left: -8px;
  vertical-align: top;
  font-size: 13px;
  font-weight: 900;
}

.position-absolute-right {
  position: absolute;
  right: 0;
  z-index: 9;
}

.left-align-chart {
  .highcharts-legend {
    transform: translate(52%, 16%);
  }
}

.align-sub {
  vertical-align: sub !important;
}

.el-pagination {
  .el-pager {
    .number.active {
      color: #00A344;
    }
  }
}

.comm-log-options {
  border: 1px solid #D5D5D5;
  line-height: 24px;
  border-radius: 8px;
  background: #fff;
  font-weight: 900;
  color: #D5D5D5;
}

.comm-log-options:hover {
  cursor: pointer;
  border: 1px solid #000;
  color: #000;
}

/**
------------------------------------------------------------------------------------------------------------------------
Analytics Styling
------------------------------------------------------------------------------------------------------------------------
 */

.analytics {
  .donut-center-label {
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    z-index: 1000;

    h3 {
      font-size: 56px;
      color: #040404;
      margin: 0;
    }

    label {
      font-weight: 400;
      color: #323232
    }
  }
}

article {
  color: #323232;
  font-weight: 300;

  p {
    font-size: 16px;
    word-break: keep-all;
  }
}

@keyframes blink {
  50% {
    color: transparent;
  }
}

.loader__dot {
  animation: 1s blink infinite;
}

.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}

.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}

.text-dead {
  color: #898989;
  font-weight: 300;
}

#reports-activity-reporting-tab {
  .dropdown-toggle::after {
    content: none;
  }
}

.small-v-loading {
  .el-loading-spinner {
    top: unset !important;
    margin-top: unset !important;

    svg {
      height: 18px !important;
      width: 18px !important;
    }
  }
}

.medium-v-loading {
  height: 23px;
  top: -4px !important;
}

.table-aloware {
  th {
    border-top: 0;
  }
}

.sms-reminder-template-variables {
  font-size: .8rem;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    color: green !important;
  }
}

#container-dashboard,
.popover-metric {
  h1, h2, h3, h4, h5, h6, p, label, input, button, select, div {
    font-family: 'Roboto', sans-serif !important;
  }

  h1, h2, h3, h4, h5, h6, p, label {
    color: #323232;
  }

  label {
    font-weight: 400;
  }

  p, div {
    font-size: 14px;
    color: #323232;
    line-height: 1.4;
  }

  small {
    font-size: 13px;
    font-weight: 400;
  }

  .difference {
    display: inline-block;
    font-weight: 500;

    &.increase {
      color: #00A344;
    }

    &.decrease {
      color: #e74c3c;
    }

    &.equal {
      color: #999;
    }
  }
}

#container-dashboard {
  h1 {
    font-size: 1.8rem;
    font-weight: 500 !important;
  }

  .metric-widget {
    text-align: center;

    &:hover {
      background-color: #F2FBF6;
      border-color: #00BF4A;
      cursor: pointer;
    }

    p {
      margin: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
      line-height: 1;
      color: #323232;
      display: inline-block;
    }

    h1 {
      font-size: 50px;
    }

    h2 {
      font-size: 2rem;
      font-weight: 700;
    }

    .difference {
      display: inline-block;
      font-weight: 500;

      &.increase {
        color: #00A344;
      }

      &.decrease {
        color: #e74c3c;
      }

      &.equal {
        color: #999;
      }
    }
  }

  .container-outbound-connection-rate-widget {
    position: relative;

    .label-connection-rate {
      position: absolute;
      left: 50%;
      top :50%;
      transform: translate(-50%, -50%);
      z-index: 900;
      text-align: center;

      h1 {
        color: #00bf4a;
        font-size: 80px;
        font-weight: 400 !important;
        margin: 0;
      }

      h2 {
        color: #323232;
        font-size: 50px;
        font-weight: 500 !important;
        margin: 0;
      }

      p {
        font-weight: 300 !important;
        font-size: 20px;
      }
    }
  }
}

.container-controls {
  padding: 0.5rem 1rem;
  border-top: 2px solid #D2D2D2;
  background-color: #efefef;
}

.sms-reminders-wrapper {
  table {
    button {
      padding: 7px 10px;
      font-size: .8rem !important;
    }

    td:first-child {
      width: 70%;
    }

    td:nth-child(2), td:nth-child(3) {
      padding: .75rem .50rem;
    }
  }
}

.sold-report-container,
.messenger-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  iframe {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
}

.flashy-counts {
  -webkit-animation: counts-glow 1s ease-in-out infinite alternate;
  -moz-animation: counts-glow 1s ease-in-out infinite alternate;
  animation: counts-glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes counts-glow {
  from {
    box-shadow: 0 0 10px #FFFF00, 0 0 12px #FFFF00, 0 0 12px #dc3545, 0 0 12px #dc3545, 0 0 12px #dc3545, 0 0 12px #dc3545, 0 0 12px #dc3545;
  }

  to {
    box-shadow: 0 0 3px #fff, 0 0 3px #dc3545, 0 0 3px #dc3545, 0 0 3px #dc3545, 0 0 5px #dc3545, 0 0 3px #dc3545, 0 0 3px #dc3545;
  }
}

.md-form-group {
  .btn-edit {
    visibility: hidden;
  }

  &:hover {
    .btn-edit {
      visibility: visible;
    }
  }
}

.variable-copied-notify {
  bottom: 20px !important;
  top: auto !important;
}

.outbound-call-count-filter {
  .el-form-item__error {
    position: relative;
    text-align: center;
    float: right;
    width: 362px;
  }

  .inputs {
    width: 485px;
  }
}

.mention {
  background: rgba(0, 191, 80, .075);
  color: rgba(0, 191, 80, 1);
}

.support-tools {
  .el-tabs__nav {
    display: grid !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  &.el-tabs--border-card>.el-tabs__header .el-tabs__item {
    color: $blackish;
  }

  &.el-tabs--border-card>.el-tabs__header {
    background: transparent !important;
    border-bottom: 0px;
  }

  &.el-tabs--left .el-tabs__item.is-left {
    text-align: left !important;
    margin: 0px 0 10px -1px;
  }

  &.el-tabs--left .el-tabs__item.is-left.is-active {
    border-radius: 4px;
    background: var(--Light-Background, rgba(37, 110, 255, 0.12));
    border-color: transparent !important;
  }

  .el-tabs__content {
    position: static !important;
  }
}

.support-tools .tab-content {
  height: calc(100vh - (74px + 56px + 40px + 51.19px + 15px + 40px));
}

.contacts-tab .highlight,
.communications-tab .highlight {
  background: rgba(0, 191, 80, .1);
  color: rgba(0, 191, 80, 1);
}

.support-tools .search-resource-text {
  font-size: 26px;
  color: #d0d0d0;
}

.support-tools .search-resource-text i {
  font-size: 60px;
  margin-bottom: 1rem;
}

.min-height-screen {
  min-height: calc(100vh - 50px);
}

.container-listing {
  padding: 1.5rem;
  padding-bottom: 0;
  background-color: #fff;

  .listing-heading {
    color: #202125;
    align-self: center;
    margin: 0;
  }

  .container-header {
    margin-bottom: 1.5rem;
  }

  .container-listing-tools {
    padding: 0.75rem;
    border-top: 4px solid #e6e6e6;
    background-color: #f6f6f6;

    #search-input {
      height: 37px;
    }

    .el-button--small,
    .el-button--small.is-round {
      padding: 10.5px 15px;
      border-radius: 4px;
    }

    .el-button+.el-button {
      margin-left: 0px
    }
  }

  .table {
    h6 {
      color: #202125 !important;
    }

    th {
      border-top: 0;
    }

    tr:hover {
      td {
        background-color: #fafafa;
      }

      th {
        background-color: transparent !important;
      }
    }

    td {
      padding: 1.5rem 0.5rem
    }

    th {
      padding: 0.5rem
    }

    th:first-child {
      padding-left: 0;
    }

    &.table-sm {
      td {
        padding: 0.75rem 0.5rem
      }

      th {
        padding: 0.5rem
      }
    }
  }

  .container-footer {
    padding: 0.5rem 0;
    margin-bottom: 1rem;
  }

  .el-pagination {
    button, .number, .btn-quicknext, .btn-quickprev {
      background-color: transparent !important;
      border: 1px solid transparent;
      margin: 0 !important;
      padding: 0 !important;
    }

    .number:hover,
    .btn-quicknext:hover,
    .btn-quickprev:hover {
      color: #00BF50 !important;
      border: 1px solid #00BF50;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}

.dialog-blank {
  .el-dialog__header,
  .el-dialog__body {
    padding: 0 !important;
  }
}

.radio-btn-block {
  display: block;
  width: 100%;

  h6 {
    margin-bottom: 0.4rem !important;
  }

  .el-radio-button__inner {
    display: block;
    width: 100%;

    .fa-chevron-right {
      font-size: 20px;
      color: #e9e9e9
    }

    &.is-active {
      h6 {
        color: #fefefe
      }
    }
  }
}

.btn-dialog-close {
  padding-top: 0 !important;
  font-size: 20px !important;
  color: #dedede !important;;

  &:hover {
    color: #676767 !important;
  }
}

.radio-group-hover {
  .el-radio-button:hover {
    .el-radio-button__inner {
      border: 1px solid #00bf50;
      background-color: rgba(0,191,80, 0.1);
    }
  }
}

#form-item-sequence-types {
  max-height: 60vh;
  overflow-y: scroll;
  margin: 0 -1.5rem;
  padding: 1.5rem;
  box-shadow: inset 0px -11px 8px -10px rgba(0,0,0,0.1);
  z-index: 1100;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;

  .el-radio-button {
    .sequence-type-description, .sequence-type-description-danger {
      white-space: break-spaces;
    }
  }
}


.message {
  &.shine {
    animation: shine-fade 2s linear;
  }

  @keyframes shine-fade {
    0%, 100% {
      background-color: rgba(0, 191, 80, 0);
      display: none !important;
    }
    50% {
      background-color: rgba(0, 191, 80, 0.1);
      display: block !important;
    }
  }
}

.sequence-step-preview {
  border-top: 1px solid #f9f9f9;
  margin-top: 1rem;
}

.sequence-message {
  display: inline-block;
  position: relative;
  padding: 1.2rem;
  background: #f3f3f3;
  border-radius: 8px 8px 0 8px;
  max-width: 400px;
  text-align: left;

  .fa-caret-left {
    position: absolute;
    right: 0;
    bottom: -1.2rem;
    color: #f3f3f3;
    font-size: 40px;
  }
}

.w-80 {
  width: 80% !important;
}

/* integration settings */
.crm-integrations-unavailable {
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 24px;
  padding: 30px;
}

.integration-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.sequence-switch {
  padding: 0.5rem 0.7rem;
  border-radius: 4px;
  height: 40px;

  &.running {
    border: 1px solid #00bf50;
  }

  &.invalid {
    border: 1px solid #C4183C;
  }

  &.paused {
    border: 1px solid #f4b30d;
  }
}

.hover-lower-opacity:hover {
  color: #00bd50 !important;

  h1, h2, h3, h4, h5, h6, div, span, p, label, small {
    color: #00bd50 !important;
  }

  span, p, label, small {
    opacity: 0.8;
  }
}

.hover-lower-opacity-warning:hover {
  color: #ffb400 !important;
  cursor: pointer;

  h1, h2, h3, h4, h5, h6, div, span, p, label, small {
    color: #ffb400 !important;
  }

  span, p, label, small {
    opacity: 0.8;
  }
}

.hover-lower-opacity-primary:hover {
  color: #409eff !important;
  cursor: pointer;

  h1, h2, h3, h4, h5, h6, div, span, p, label, small {
    color: #409eff !important;
  }

  span, p, label, small {
    opacity: 0.8;
  }
}

.hover-lower-opacity-danger:hover {
  color: #f56c6c !important;
  cursor: pointer;

  h1, h2, h3, h4, h5, h6, div, span, p, label, small {
    color: #f56c6c !important;
  }

  span, p, label, small {
    opacity: 0.8;
  }
}

.button-select {
  .el-input__inner {
    border-radius: 20px;
  }

  .el-tag {
    border-radius: 12px !important;
    padding-left: .4rem;
    padding-right: .8rem;
  }

  .el-select__tags {
    padding-left: 0.2rem;
  }
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
}

span.nav-icon.messenger img {
  filter: invert(30%);
  height: 24px;
  width: 24px;
}

span.nav-icon.active.messenger img {
  filter: invert(1) contrast(500%);
}

.report-dispositions-table table.el-table__footer td {
  background-color: aliceblue;
}

.btn-contact-shortcut,
.input-contact-shortcut input {
  height: 43.99px;
}

#btn-mark-unanswered-read {
  border-left: 1px solid #DCDFE6
}

a:hover.is-underline {
  text-decoration: underline !important;
}

.force-full-height {
  & .sidebar-full-height {
    height: calc(100vh - 25px) !important;
  }
}

.disabled-wrapper {
  display: inline-block !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

#toolbox-scheduled-msg {
  .el-select {
    width: 150px;

    .el-input--small {
      font-size: 14px !important;
    }

    .el-input__inner {
      height: 37px !important;
    }
  }
}

#popover-scheduled-msg {
  & {
    max-height: 250px;
    overflow-y: auto;
  }
}

.messenger-info, .messenger-info a {
  font-size: .8rem;
}

.message-input {
  border: none;
  max-height: 15rem;
  height: 38px;
  resize: none;
  border-radius: 5px 5px 0 0;
  overflow-y: auto;
}

.message-input:disabled {
  cursor: not-allowed;
}

.message-actions {
  min-height: 1rem;
  border-top: 1px solid transparent;
}

.message-actions .action-item {
  font-size: 1rem;
}

.message-actions .action-item i {
  font-size: 1.2rem;
}

.attachment-preview {
  border: solid 1px #eaeaeac7;
  padding: 2px;
  margin-right: 1rem;
  border-radius: 5px;
  box-shadow: 1px 1px 1px #ddd;
  word-break: break-all;
  position: relative;
  flex-shrink: 0;
}

.attachment-preview img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.attachment-preview:hover .attachment-preview-close {
  visibility: visible;
}

.attachment-preview-close {
  position: absolute;
  top: -7px;
  right: -7px;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 4px !important;
  padding-bottom: 1px !important;
  visibility: hidden;
}

.schedule-dialog .el-dialog__body {
  padding: 10px 23px;
}

.display-table {
  display: table !important;
}

// Webhook integration response error container
.response-error-container {
    max-height: 500px;
    line-height: 2em;
}

.right-10-per {
    right: 10%;
}

.ss-messenger-frame {
  height: 47px;
  width: 146px;
  overflow: hidden;
  margin-left: 15px;
  margin-top: 0px;
  position:relative;
}

.ss-messenger-iframe-wrapper-click {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  cursor: pointer;
}

.search-toolbar {
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }

  @media (min-width: 576px) {
    width: 300px;
  }
}

.border-bottom-dashed {
  border-bottom: 1px dashed;
}

.contact-merge-confirm {
  .el-message-box__content {
   padding: 1.5rem 1.5rem;
  }
  .el-message-box__status {
    top: 0;
    transform: translateY(0);
    .el-icon-info {
      font-size: 18px !important;
    }
  }

  .el-message-box__message {
    p {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .el-message-box__btns {
    display: flex;
    // customize the confirm button of confirm dialog
    // I cant seems to easily override the confirm button and change it to
    // button--default, so this is a workaround
    button:nth-child(2) {
      text-overflow: ellipsis;
      overflow: hidden;
      color: #606266;
      background: #FFF;
      border: 1px solid #DCDFE6;

      &:hover {
        background-color: rgba(0, 191, 80, 0.1) !important;
        color: #00BF50 !important;
        border-color: rgba(0, 191, 80, 0.4) !important;
      }
    }
  }
}

.pointer-not-allowed {
  cursor: not-allowed !important;
}

.dropdown-toggle-caret-none {
  & .dropdown-toggle::after {
    display: none;
  }
}

#avatar-agent-statuses {
  .dropdown-item.btn-disabled {
    &:hover {
      color: #6c757d;
      background-color: transparent;
    }

    & .status-badge {
      opacity: 0.5;
    }
  }
}

.agent-status-filter {
  .el-select-dropdown__wrap {
    max-height: 450px !important;
  }
}

.scroll-y {
  overflow-y: auto !important;
}

.min-height-10-vh {
  min-height: 10vh !important;
}

.min-height-20-vh {
  min-height: 20vh !important;
}

.min-height-40-vh {
  min-height: 40vh !important;
}

.max-height-60-vh {
  max-height: 60vh !important;
}

.bg-status-offline {
  background: #828282;
}

.bg-status-online,
.bg-workflow-success {
  background: #27ae60;
}

.bg-status-busy,
.bg-workflow-error {
  background: #eb5757;
}

.bg-status-on-break {
  background: #f2994a;
}

.bg-status-on-call {
  background: #2f80ed;
}

.bg-status-wrap-up {
  background: #9b51e0;
}

.bg-status-ringing {
  background: #00c0cc;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.import-log-empty-text {
  line-height: 30px !important;

  .import-log-note {
    font-size: 14px;
    word-break: break-word;
  }
}

.el-tooltip__popper.is-power-dialer-start-button-tooltip {
  & {
    color: #606266;
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.10);
    line-height: 1.5;
    border: 1px solid #EBEEF5;
  }

  .popper__arrow {
    & {
      border-bottom-color: #EBEEF5 !important;
      filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03));
    }

    &::after {
      border-bottom-color: #FFFFFF !important
    }
  }
}

.el-tooltip__popper.is-upgrade-tooltip {
  & {
    color: #FFFFFF;
    background-color: #256EFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.10);
    line-height: 1.5;
    border-radius: 12px;
    border: 1px solid #256EFF;
  }

  .popper__arrow {
    & {
      border-top-color: #256EFF !important;
      border-bottom-color: #256EFF !important;
      filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03));
    }

    &::after {
      border-top-color: #256EFF !important;
      border-bottom-color: #256EFF !important;
    }
  }
}

.custom-confirmation-dialog-class {
  .el-message-box__title {
    margin-bottom: 10px;
    margin-right: 1em;
  }

  .el-message-box__btns {
    margin-top: 1em;
    display: flex;
  }
}

.min-w-0 {
  min-width: 0 !important;
}

.wrap-auto-margin {
  & > div,
  & > span {
    margin: 4px;
  }

  &.margin-x {
    & > div,
    & > span {
      margin: 0 4px;
    }
  }

  &.margin-y {
    & > div,
    & > span {
      margin: 4px 0;
    }
  }
 }

.min-width-210 {
  & {
    min-width: 210px !important;
  }
}

.min-width-240 {
  & {
    min-width: 240px !important;
  }
}

.alo-empty-text {
  line-height: 25px;
  .empty-text-subject {
    font-size: 18px;
  }

  .empty-text-info {
    font-size: 16px;
  }
}

.borderless-input {
  input {
    border: 0px !important;
  }
}

.integration-tabs {
  .el-tabs__item {
    font-weight: bold !important;
    font-size: 16px;
  }
}

.card-plan-usage {
  color: #121212;

  .header {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.customer-report-issue-dialog {
  background: none !important;
}

.audio-player {
  width: 300px;
  margin-top: 10px;
}

.integration-api-titles {
  font-size: 1.6rem;
}

.alo-button {
  border-radius: 8px !important;
  padding: 7px 12px !important;
}

.power-dialer-session-setting-form {
  border-radius: 8px !important;
  padding: 20px 27px;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0px !important;
  }
}

.dialer-session-setting-item {
  &:not(:last-child) {
    border-bottom: solid 1px #ebebeb;
  }

  &:hover {
    background: #ebebeb;
    .delete {
      display: block;
    }
  }

  .dialer-session-setting-edit {
    cursor: pointer;
  }

  .delete {
    cursor: pointer;
    display: none;
  }
}

.power-dialer-form-input {
  height: 22px;
  font-size: 16px;
  border:none;
  padding: 1px 7px;
  width: fit-content;

  &:focus {
    border: solid 1px #ebebeb;
    background: white;
    border-radius: 8px;
  }
}

#powerDialerSessionSettingForm {
  .title-label {
    font-size: 13px;
  }
}

.inbox-filter {
  @extend .alo-thin-scrollbar;
  .inbox-empty-image {
    width: 40% !important;
  }

  .create-settings-button {
    @extend .alo-blue-button;
  }

  .inbox-settings-table {
    border-radius: 6px;
    margin-top: 1em;
    min-height: 300px;
    max-height: 500px;
    overflow: auto;

    .el-table__empty-block {
      padding-top: 2em;
      min-height: 200px !important;
    }

    .inbox-table-row {
      .action-button {
        font-size: 12px !important;
        visibility: hidden;
      }

      .update-button {
        color: #256EFF;
      }

      .delete-button {
        margin-left: -3em;
      }

      &:hover {
        .action-button {
          visibility: unset;
        }
      }
    }
  }

  .settings-title {
    line-height: 31px;
    @extend .font-16-600;
  }
}

.inbox-filter-dialog {
  @extend .alo-thin-scrollbar;
  button {
    border-radius: 6px;
  }

  input {
    font-size: 14px;
  }

  .filter-name {
    input {
      font-size: 16px;
    }
  }

  .filter-name-text {
    font-size: 16px;
    color: #040404;
    font-weight: 600;
  }

  .filter-name-edit {
    font-size: 14px;
    i {
      color: #62666E;
    }
    &:hover {
      i {
        color: #0067F4;
      }
    }
  }

  .el-icon-close {
    visibility: hidden;
  }

  .el-dialog__body {
    padding: 2em 1em 0em 1em !important;
    max-height: 90vh;
    min-height: 80vh;

    .inbox-main-form {
      max-height: 80vh;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 10px;
    }
  }

  .inbox-filter-section-title {
    font-weight: 600;
    color: #040404;
  }

  .el-dialog {
    overflow: hidden;
    border-radius: 10px;
  }

  .filter-name {
    @extend .font-14-400;
  }

  .dialog-title {
    @extend .font-14-400;
    color: #95989E;
  }

  .input-pl-0 {
    input {
      padding-left: 0 !important;
    }
  }

  .override-switch {
    .el-form-item__label {
      width: 100%;
      line-height: 20px;
      margin-top: .5em;
    }
  }

  .handling-section-override {
    .el-form-item__label {
      line-height: 20px;
      margin-top: 15px;
    }
  }

  .el-form-item__label {
    font-size: 13px !important;
    text-align: left;
    word-break: break-word;
    color: #62666E !important;
  }

  .form-cancel-button {
    background: #95989E;
    color: #fff !important;

    &:hover {
      background-color: #909399 !important;
      color: #fff !important;
    }
  }

  .form-save-button {
    @extend .alo-blue-button;
  }

  .hidden-date-field {
    visibility: hidden;
    position:absolute;
    left: 0;
  }
}

/**
 * Font Manipulators
 */

.font-16-600 {
  font-size: 16px;
  font-weight: 600;
}

.font-14-400 {
  font-size: 14px;
  font-weight: 400;
}

/**
 * Scroll bar manipulators
 */
.alo-thin-scrollbar {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: initial;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d8d8d8;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/**
 * Button manipulators
 */
.alo-blue-button {
  background-color: #256EFF !important;
  color: #fff !important;

  &:hover {
    background-color: #145ca4 !important;
    color: #fff !important;
  }
}

/**
 * Positioning items
 */
.right-0 {
  right: 0 !important;
}

.width-fit-content {
  width: fit-content;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.store-icons {
  img {
    width: 133px;
    height: 40px;
  }

  .ios-container {
    margin-left: 2px;
  }
}

.agent-status-button {
  font-size: 14px !important;
  padding: 2px !important;
}

.agents-condensed-summary-report-table thead > tr > th,
.agents-condensed-summary-report-table tbody > tr > th,
.agents-condensed-summary-report-table tfoot > tr > th,
.agents-condensed-summary-report-table thead > tr > td,
.agents-condensed-summary-report-table tbody > tr > td,
.agents-condensed-summary-report-table tfoot > tr > td {
  padding: 1px !important;
}

.broadcast-schedule-input {
  input {
    height: 35px !important;
    border-radius: 6px;
  }
}

.voicemail-attention-box {
  width: 731px;
  height: 149px;
  margin-left: 18.5px;
  margin-top: 11px;

  .note {
    width: 478px;
    height: 16px;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.0025em;

    color: #000000;
  }

  .contain{
    display: flex;
    margin-bottom: 7px;
    align-items: center;
  }


  .voicemail-confirmation-attention {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    align-items: flex-start;
    padding: 3px 8px;

    background: #F2C94C;
    border-radius: 6px;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.0025em;

    /* Grey/100 */

    color: #040404;


    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px 3px 10px;
  }


  .icon {
    margin-left: 2.25px;
  }

  .message {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.0025em;

    color: #000000;

    border: 3px solid #F2C94C;
    box-sizing: border-box;
  }
}

.alo-custom-radio-buttons {
  .alo-radio-button-danger {
    .el-radio-button__inner {
      @extend .text-danger;
      @extend ._600;
    }

    &.is-active {
      .el-radio-button__inner {
        background-color: #EB5757 !important;
        border-color: #EB5757 !important;
        box-shadow: -1px 0 0 0 #EB5757 !important;
        color: #fff !important;
      }
    }
  }

  .alo-radio-button-warning {
    .el-radio-button__inner {
      color: #D29F02 !important;
      @extend ._600;
    }

    &.is-active {
      .el-radio-button__inner {
        background-color: #D29F02 !important;
        border-color: #D29F02 !important;
        box-shadow: -1px 0 0 0 #D29F02 !important;
        color: #fff !important;
      }
    }
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-title {
  font-size: 1.2rem;
}

#adminAccountFilters {
  position: absolute;
  z-index: 1;
}

#adminAccountFilters .account-filters {
  padding: 0px 25px !important;
  max-height: calc(88vh - 3.2rem);
  overflow: auto;
}

#adminAccountFilters .el-drawer__header {
  background: #F4F4F6;
  padding: 15px 19px;
}

#adminAccountFilters .el-drawer__header>:first-child {
  font-weight: 500;
  color: black !important;
  font-size: 18px;
}

#adminAccountFilters {
  @media only screen and (max-width: 1300px) {
    .el-drawer {
      width: 40% !important;
    }
  }

  @media only screen and (max-width: 1100px) {
    .el-drawer {
      width: 60% !important;
    }
  }

  @media only screen and (max-width: 900px) {
    .el-drawer {
      width: 70% !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .el-drawer {
      width: 100% !important;
    }
  }
}

.admin-accounts-page {
  .admin-buttons-container {
    .admin-buttons-inner {
      float: right;
    }
  }
  @media only screen and (max-width: 991px) {
    .admin-buttons-container {
      margin-top: 10px;

      .admin-buttons-inner {
        float: left;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .admin-action-button {
      margin-top: 10px;
    }

    .admin-buttons-inner {
      .filter-buttons {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

.admin-filter-button-container {
  position: absolute;
  width: 100%;
}

.admin-accounts-page {
  position: relative;
  overflow-x: hidden;
}

.alo-default-app-selector-dialog {
  .el-dialog {
    border-radius: 6px;
  }

  .alo-default-app-selector-body {
    padding: 1.25rem !important;
  }

  .alo-default-app-selector-title {
    font-size: 1.125rem !important;
  }
}

.default-app-selector {
  .el-button--mini {
    border-radius: 0.25rem !important;
  }

  .default-app-dropdown {
    .el-button-group {
      display: flex !important;

      .el-button:first-child {
        padding-left: 10px !important;
        padding-right: 14px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .el-button:last-child {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }
}

.alo-popover {
  &.el-popper[x-placement^=bottom] {
    margin-top: 5px
  }

  .popper__arrow {
    display: none !important;
  }
}

.default-app-button {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.default-app-selector {
  .default-app-button {
    border-radius: 0.25rem !important;
  }
}

.default-app-dropdown {
  margin-top: 0 !important;

  .label-text {
    font-weight: 400;
    color: #62666E;
  }

  .el-dropdown-menu {
    margin: 0 !important;
  }

  .popper__arrow {
    display: none !important;
  }
}

.lost-connection-dialog {
  & .el-dialog__body {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.dialog-business-hours {
  & {
    max-width: 678px;
  }

  &.el-message-box {
    padding-bottom: 0;
  }

  .el-message-box__header {
    border: none;
    padding: 20px 24px 0;
  }

  .el-message-box__content {
    padding: 28px 24px 0;
  }

  .el-message-box__btns {
    display: flex;
    text-align: center;
    padding: 28px 24px 20px;

    button {
      width: 100%;

      &:first-child {
        margin: 0 0.3rem 0 0;
      }

      &:nth-child(2) {
        margin: 0 0 0 0.3rem;
      }
    }
  }
}

.display-grid {
  display: grid !important;
}

$alert-grey-bg: #EBEBEB !default;
$alert-grey-text: #898989 !default;
$alert-grey-border:         darken($alert-grey-bg, 5%) !default;

.alert-grey {
  background-color: $alert-grey-bg;
  border-color: $alert-grey-border;
  color: $alert-grey-text;

  hr {
    border-top-color: darken($alert-grey-border, 5%);
  }
  .alert-link {
    color: darken($alert-grey-text, 10%);
  }
}

.sequence-holiday-banner .el-alert__description {
  margin: 0px 0 0 !important;
}

.info-alert-box .el-alert__description {
  margin: 0px 0 0 !important;
}

.alo-talk-info-container {
  margin-left: 6px;
  margin-right: 16px;

  .alo-talk-info-button {
    color: #256EFF !important;
    font-weight: 500;
    font-size: 14px;
    padding-left: 8px !important;
    padding-right: 8px !important;

    img {
      margin-top: -2px;
    }
  }
}

.border-blue {
  border-color: #256EFF !important;
}

.border-4 {
  border-width: 4px !important;
}

.status-background {
  background-color: #EBEBEB !important;
}

.nav > li > a .nav-icon i.fa-fighter-jet {
  font-size: 18px !important;
  line-height: 36px !important;
}

.form-capture-token-reset-pop-up {
  .el-message-box__header {
    border:none;
  }
  .el-message-box__message p {
    line-height: 22px;
    font-size: 14px;
  }
}

.integration-settings-drawer {
  .el-collapse-item__arrow {
    margin: 0 8px 0 2px;
  }
  .el-drawer__body {
    overflow-y: auto;
  }
  color: #010101;

  h3, h5, label {
    color: #010101;
  }
}

.flex-1 {
  flex: 1;
}

.flex-3 {
  flex: 3;
}

.webrtc-label-position-transfer {
  position: relative;
  left:220px;
  bottom:20px
}

.step-editor-email-buttons .btn.el-popover__reference {
  line-height: 1.75;
  margin-bottom: 0;
}

#el-dialog-compliance-wizard {
  .el-dialog__body {
    word-break: break-word !important;
  }
}

a.el-green-hover:hover {
  color: #00BF50 !important;
}

.half-opaq {
  opacity: 0.5 !important;
}

.line-height-normal {
  line-height: normal;
}

.circle-number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-color: #1f67ef;
  color: white;
  border-radius: 50%;
  font-size: 10px;
  margin-right: 5px;
}

.circle-number-title {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #1f67ef;
  color: white;
  border-radius: 50%;
  font-size: 13px;
}

.g-recaptcha {
  transform: scale(0.8);
  transform-origin: 0 0;
}

/* Default Styles */
.terms-conditions-dialog {
  & > .el-dialog {
    width: 70%;
    top: 4vh;
    margin-top: 0 !important;
  }
}

.terms-iframe {
  border: 0;
  width: 100%;
  height: 50vh; /* Default iframe height */
}

@media (max-width: 479px) {
  .terms-conditions-dialog {
    & > .el-dialog {
      width: 100%;
      top: 0vh;
      margin-top: 0 !important;
    }
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .terms-conditions-dialog {
    & > .el-dialog {
      width: 90%;
      top: 0vh;
      margin-top: 0 !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .terms-conditions-dialog {
    & > .el-dialog {
      width: 80%;
      top: 0vh;
      margin-top: 0 !important;
    }
  }
}

@media (min-width: 1024px) {
  .terms-conditions-dialog {
    & > .el-dialog {
      width: 80%;
      top: 4vh;
      margin-top: 0 !important;
    }
  }
}

@media (max-height: 479px) {
  .terms-iframe {
    height: 40vh;
  }
}

@media (min-height: 480px) and (max-height: 767px) {
  .terms-iframe {
    height: 44vh;
  }
}

@media (min-height: 768px) and (max-height: 1023px) {
  .terms-iframe {
    height: 56vh;
  }
}

@media (min-height: 1024px) {
  .terms-iframe {
    height: 58vh;
  }
}

.info-text {
  color: #000 !important;
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.beta-box {
  position: absolute;
  top: 7px;
  left: 7px;
  transform: translate(0, 0);
}

.beta-label {
  background: #ffa000;
}

@media (max-width: 1199px) {
  #page-title {
    display: none;
  }

  .referralhero {
    display: none !important;
  }
}

.trial-banner {
  &.button {
    height: 100%;
  }
  .activator {
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 34px;
      border-radius: 4px;
      border: none;
      background-color: rgba(37, 255, 72, 0.12) !important;
      cursor: pointer;

      &:hover {
        background-color: rgba(37, 255, 73, 0.15) !important;
      }
    }

    &-icon {
      flex-shrink: 0;
      color: #00A04F;
      width: 24px;
      height: 24px;
    }
  }
  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2247483000;/* Updated to avoid announce kit banner overlapping this video modal  */
    padding: 40px
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  .modal-content {
    position: relative;
    width: 80%;
    max-width: 800px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 20px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 10px 15px;
  }

  .modal-header h3 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 18px; /* Smaller font size */
    font-weight: 300; /* Lighter font weight */
    line-height: 1;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
  }

  .close-button:hover {
    color: #555; /* Slightly darker on hover for feedback */
  }

  .modal-body {
    padding: 0;
  }

  .modal-body iframe {
    width: 100%;
    height: 50vh;
    border: none;
  }

  .modal-notes {
    padding: 15px;
    font-size: 1em;
    color: #666;
    font-weight: normal;
    text-align: center;
  }

  .modal-link {
    text-align: center;
    color: var(--Primary-Blue, #256EFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.button-index {
  z-index: 1 !important;
  margin-right: 1rem;

  .q-btn__wrapper {
    margin-bottom: 3px;
  }

  .block {
    padding-right: 20px;
    padding-left: 20px;
    min-width: 200px;
  }

  & > a {
    color: #256EFF !important;
    font-size: 0.875rem;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.demo--button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;

  > img {
    margin-right: 0.5rem;
  }
}

.integrations.banner {
  padding: 20px 16px;
  background-color: $light-beige;

  .text {
    color: $full-black;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    .link, .link--bold {
      color: $royal-blue;
    }

    .link--bold {
      font-weight: 600;
    }

    a, div {
      display: inline;
    }
  }
}

.dialog-kyc {
  border-radius: 30px !important;
  background-color: white;
  color: black;
  width: 630px !important;

  &-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem !important;
    padding-top: 0 !important;
  }

  h6 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    color: black;
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
    padding-left: 3.4rem;
    padding-right: 3.4rem;
    padding-top: 10px;
    line-height: 1.25rem;
    letter-spacing: 0.01786em;
    color: black;
    word-break: initial;
  }

  .el-dialog__body {
    padding: 30px 20px 0px 20px !important;
  }
}

.signup {
  &--title {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &--subtitle {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  &-container {
    background: linear-gradient(to bottom right, #b3c9f7, #bcf7bd);
    backdrop-filter: blur(79.8780517578125px);
  }

  &-wrapper {
    padding: 30px;
    border-radius: 30px;
    background-color: #fff;
    backdrop-filter: blur(100px);
  }

  &-submit {
    &__button {
      background-color: #256EFF !important;
      color: #fff !important;
      border-radius: 30px !important;
      text-transform: none;

      &:hover {
        background-color: #145ca4 !important;
      }
    }
  }

  &-checkbox {
    &__link {
      color: $royal-blue;
      font-weight: 500;
      text-decoration: none;

      &:hover {
        color: $royal-blue;
        text-decoration: underline;
      }
    }

    &__container {
      &.is-checked {
        &.el-checkbox__input.is-checked + .el-checkbox__label {
          color: $dark-color !important;
        }
      }
    }
  }

  &-wrapper__success {
    display: flex;
    align-items: center;
    padding: 30px;
    border-radius: 30px;
    background-color: #fff;
    backdrop-filter: blur(100px);
    text-align: center;
    max-width: 600px;
    min-height: 300px;

    &__title {
      color: #646569;
      text-align: center;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
    }

    &__subtitle {
      color: #646569;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
    }
  }

  &-meeting-wrapper {
    border-radius: 30px;
    backdrop-filter: blur(100px);
    background-color: #fff;
    width: 800px;
    height: 800px;
  }

  @media (max-height: 767px) {
    &-meeting-wrapper {
      width: 720px;
      height: 670px;
    }
  }

  // responsive -meeting-wrapper
  @media (max-width: 767px) {
    &-meeting-wrapper {
      width: 480px;
      height: 500px;
    }
  }

  @media (max-width: 479px) {
    &-meeting-wrapper {
      width: 380px;
      height: 660px;
    }
  }

  @media (max-width: 400px) {
    &-meeting-wrapper {
      width: 360px;
      height: 660px;
    }
  }

  @media (max-width: 380px) {
    &-meeting-wrapper {
      width: 350px;
      height: 660px;
    }
  }

  @media (max-width: 370px) {
    &-meeting-wrapper {
      width: 340px;
      height: 660px;
    }
  }
}

.iti {
  width: 100% !important;
}

.iti__tel-input {
  width: 100% !important;

  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;

  &:focus {
    border-color: #00BF50 !important;
  }

  &.error {
    border-color: #EB5757 !important;
  }
}

.toast-kyc {
  border-radius: 10px !important;
  /*width: 80% !important;
  top: 20px !important;*/

  .el-dialog__header {
    padding: 0px !important;
  }
}

.export-summary-dropdown {
  z-index: 100;
}

.twilio-badge {
  color: #fff;
  background-color: #F22E46;
}

.telnyx-badge {
  color: #000;
  background-color: #00E3AA;
}

.commio-badge {
  color: #fff;
  background-color: #0277FF;
}

.hosted-on-badge {
  color: #fff;
  background-color: #9B51E0;
}

.custom-message-display {
    > span {
        &.suffix {
            &.negative--margin {
                margin-left: -3px !important;
            }
        }
    }
}
.input-field {
    &--icon {
        display: flex !important;
        align-items: center;
        height: 100%;
        padding-right: 6px;
        font-size: 20px;
        cursor: pointer;
    }
}

.ring-group {
    height: 300px;
    overflow-y: auto;
    padding-right: 16px;
    padding-left: 16px;

    &--title {
        font-size: 16px;
        font-weight: 600;
    }

    &--divider {
        border-top: 1px solid #ebebeb;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.user-to-teams {
    &--dialog {
        .el-dialog__body {
            padding: 0 25px 40px !important;
        }
    }

    .title {
        font-size: 16px;
        font-weight: 600;
    }

    &--label {
        font-family: Nunito;
        font-size: 18px;
        font-weight: 400;
    }

    .description {
        font-family: Nunito;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-align: center;
        padding-bottom: 12px;
    }

    &--divider {
        border-top: 1px solid #ebebeb;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .content {
        overflow-y: auto;
        height: 300px;
        padding-right: 16px;
        padding-left: 16px;
    }
}

.teams-tab {
    .title {
        font-size: 22px;
        font-weight: 600;
        text-align: left;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
    }

    .actions-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        background-color: #F6F6F6;
        border-top: 3px solid #E6E6E6;
        width: 100%;

        .text {
            font-family: Nunito;
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            min-width: 60px;
        }

        .w-60 {
            width: 60%;
        }
    }
}
.teams {
    .dialog--title {
        font-family: Nunito;
        font-size: 18px;
        font-weight: 600;
    }

    .el-dialog__body {
        padding: 18px 25px 30px !important;
    }
}

.has_conflict_color_outline {
  border-color: #FA003F;
  color: #FA003F;
}

.has_conflict_color_outline:hover {
  border-color: #FA003F;
  background-color: #FA003F;
  color: #FFF;
}

.has_conflict_color_background {
    background-color: #FA003F;
}

.conflicted_numbers_modal {
  color: #040404 !important;

  .el-table {
    color: #040404 !important;
  }

  .el-dialog__header {
    text-align: center;
    padding-top: 40px;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 8px;
    right: 8px;
    .el-dialog__close {
      color: #00BF4A;
    }
  }
  .el-dialog__body {
    padding: 10px 38px 0 38px !important
  }

  .conflicted_contacts_table tbody tr:last-child td {
    border-bottom: none;
  }
}

.ordered-user-team-selector {
    .layer-label {
        width: 76px;
        background-color: #ebebeb;
        border-radius: 4px;
        color: #000000;
        text-align: center;
        margin-right: 12px;
    }

    .select-label {
        width: 50px;
        margin-right: 6px;
        text-align: center;
        height: 100%;
        border-radius: 4px;
    }
}

.el-select.teams-select {
    .el-tag {
        color: #256EFF !important;
        background: #D3E2FF !important;
        border-color: #256EFF !important;
        border-radius: 2px !important;

    }
    .el-tag__close {
        background-color: #256EFF !important;
        &:hover {
            background-color: #3b79f5 !important;
        }
    }
}

.el-select-dropdown.teams-select-popper .el-select-dropdown__item.selected {
    border-left: 3px solid #256EFF !important;
    color: #256EFF !important;
}

.el-select-dropdown.teams-select-popper .el-select-dropdown__item:hover {
    background-color: #D3E2FF !important;
    color: #256EFF !important;
}

.el-select-dropdown.teams-select-popper .el-select-dropdown__item.selected .media .media-body label {
    color: #256EFF !important;
}

.aloai-fa-btn-custom {
  color: white;
  text-shadow:
      -1px -1px 0 #bababa,
      1px -1px 0 #bababa,
      -1px 1px 0 #bababa,
      1px 1px 0 #bababa;
}

.aloai-instructions .el-textarea__inner {
  resize: none;
}

.tags-deprecation-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #FFFFE7;
  border: #F2C94D 1px solid;
  border-radius: 5px;

  .left-content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .trial--text {
    margin: 0;
    font-size: 0.8rem;
    margin-right: 2rem;
  }

  @media only screen and (max-width: 1280px) {
    .trial-banner {
      flex-direction: column;
      align-items: flex-start;
    }

    .left-content {
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;
    }

    .left-content > .trial--text {
      flex: 1 0 100%;
    }
  }
}

.company-teams-list {
    .el-table__row {
        :hover {
            cursor: pointer;
            color: $greenish !important;
        }
    }

    .el-table--enable-row-transition .el-table__body td {
        transition: none !important;
    }
}

.ring-group-banner-msg {
  margin-top: 1rem !important;
}

.btn-dark-warning {
  background-color: #FD8709;
}

.wz-text-wrap {
  white-space: normal;
  word-wrap: break-word;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.wz-option-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wz-option-number {
  white-space: nowrap;
  margin-left: auto;
  text-align: right;
}

.wz-title-wrapper {
  display: flex;
  align-items: center;
}

.wz-icon-size {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.wz-title-content {
  display: flex;
  align-items: center;
}

.wz-title-text {
  font-weight: bold;
  font-size: 1.5rem;
}

.line-horizontal-buttons button {
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
}

.wz-pricing-table {
  width: 100%;
  border-collapse: collapse;
}

.wz-pricing-table th,
.wz-pricing-table td {
  border: 1px solid #ccc;
  padding: 10px;
}

.wz-pricing-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.wz-pricing-table td {
  background-color: #ffffff;
  color: #333;
  text-align: left;
}

.wz-pricing-table th,
.wz-pricing-table td {
  width: 33%;
}

.wz-pricing-table tbody tr:hover {
  background-color: #e9ecef;
}

.custom-red-radio .el-radio__input.is-checked + .el-radio__label {
  color: red !important;
}

.custom-red-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: red !important;
  background: red !important;
}

.custom-red-radio.is-bordered.is-checked {
  border-color: red !important;
}

.team-dialog {
    border-radius: 10px !important;
    & .el-dialog__body {
        font-size: 16px;
        font-weight: 400;
        word-break: keep-all;
    }
}

.aloai-promotion-dialog {
  border-radius: 10px !important;
  background: transparent !important;
  position: absolute !important;
  margin: auto !important;
  top: 100px;
  right: 5px;
  width: 400px !important;
}

.ai-info-box {
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  padding: 15px;
  font-family: Arial, sans-serif;
  background: linear-gradient(90deg, #9333ea, #6b46c1);
  color: white;
  word-break: break-word;

  .ai-info-box-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .ai-info-box-icon {
      font-size: 24px;
      margin-right: 10px;
    }

    .ai-info-box-title {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .ai-info-box-content {
    margin-bottom: 10px;

    strong {
      font-weight: bold;
    }
  }

  .ai-info-box-links {
    a {
      color: #ffe4e6;
      text-decoration: underline;
      display: inline-block;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/* Container for the entire effect */
.ai-effect-container {
  position: relative;
  border-radius: 0.5rem; /* rounded-lg */
  overflow: hidden;
}

/* Gradient background */
.ai-effect-gradient {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to bottom right, #60A5FA, #A855F7, #EC4899); /* from-blue-400 via-purple-500 to-pink-500 */
  opacity: 0.75;
}

/* Blur effect */
.ai-effect-blur {
  position: absolute;
  inset: 0;
  backdrop-filter: blur(4px); /* backdrop-blur-sm */
}

/* Content container */
.ai-effect-content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9); /* bg-white bg-opacity-90 */
  margin: 2px;
  border-radius: 0.3rem; /* rounded-lg */

  ul, ol {
    padding-inline-start: 16px;
  }

  h3 {
    font-size: 13px;
  }
}

/* Gradient text effect */
.ai-effect-gradient-text {
  cursor: pointer;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  color: transparent !important;
  background-image: linear-gradient(to right, #2563EB, #9333EA) !important; /* from-blue-600 to-purple-600 */

  & .el-radio__input.is-disabled+span.el-radio__label {
    color: transparent !important;
  }

  & .el-radio__input.is-checked + .el-radio__label:not(.custom-red-radio .el-radio__label) {
    color: transparent !important;
  }
}

.header-placeholder {
    visibility: hidden;
    width: 100%;
}
