.ui-switch{
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
  border-radius: 30px;
  background-color: $success;
  margin: 0;
  input{
    position: absolute;
    opacity: 0;
    &:checked{
      + i{
        &:before{
          top: 50%;
          bottom: 50%;
          left: 50%;
          right: 5px;
          border-width: 0;
          border-radius: 5px;
        }
        &:after{
          margin-left: $switch-width - $switch-height + 1;
        }
      }
    }
  }
  i{
    &:before{
      content: "";
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background-color: #fff;
      border: 1px solid #f0f0f0;
      border-radius: 30px;
      @include transition(all 0.2s);
    }
    &:after{
      content: "";
      position: absolute;
      background-color: #fff;
      width: $switch-height - 2;
      top: 1px;
      bottom: 1px;
      border-radius: 50%;
      @include box-shadow(1px 1px 3px rgba(0, 0, 0, 0.25));
      @include transition(margin 0.3s);
    }
  }
}

.ui-switch-md{
  width: $switch-md-width;
  height: $switch-md-height;
  input{
    &:checked{
      + i{
        &:after{
          margin-left: $switch-md-width - $switch-md-height + 1;
        }
      }
    }
  }
  i{
    &:after{
      width: $switch-md-height - 2;
    }
  }
}

.ui-switch-lg{
  width: $switch-lg-width;
  height: $switch-lg-height;
  input{
    &:checked{
      + i{
        &:after{
          margin-left: $switch-lg-width - $switch-lg-height + 1;
        }
      }
    }
  }
  i{
    &:after{
      width: $switch-lg-height - 2;
    }
  }
}
