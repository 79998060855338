.dark{
  .white,
  .box{
    background-color: lighten( $dark, $color-dk-percent );
  }
  &.app{
    background-color: $dark;
  }
}
.black{
  .white,
  .box{
    background-color: lighten( $black, $color-dk-percent );
  }
  &.app{
    background-color: $black;
  }
}
.grey{
  .white,
  .box{
    background-color: lighten( $grey, $color-dk-percent );;
  }
  &.app{
    background-color: $grey;
  }
}

.dark,
.black,
.grey{
  .form-control{
    background: transparent;
    background-color: rgba(255, 255, 255, 0.1);
    color: inherit;
    &:focus{
      border-color: rgba(255, 255, 255, 0.125);
    }
  }
  .input-group-btn{
    .btn{
      background-color: $greenish !important;
      color: inherit !important;
    }
  }
  .list-group{
    background-color: transparent;
  }
}
