// Toggles
//
// Used in conjunction with global variables to enable certain theme features.

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }
}

@mixin screen($size) {
  $screen-sm-min: 576px;
  $screen-md-min: 768px;
  $screen-lg-min: 992px;
  $screen-xl-min: 1200px;
  // Extra small devices
  @if ($size == "xs") {
    @media (max-width: ($screen-sm-min - 1px)) {
      @content;
    }
  }
  // Small devices
  @if ($size == "sm") {
    @media (min-width: $screen-sm-min) {
      @content;
    }
  }
  // Medium devices
  @if ($size == "md") {
    @media (min-width: $screen-md-min) {
      @content;
    }
  }
  // Large devices
  @if ($size == "lg") {
    @media (min-width: $screen-lg-min) {
      @content;
    }
  }
  // Extra large devices
  @if ($size == "xl") {
    @media (min-width: $screen-xl-min) {
      @content;
    }
  }
}
  
// Utilities
@import "mixins/breakpoints";
@import "mixins/hover";
@import "mixins/image";
@import "mixins/badge";
@import "mixins/resize";
@import "mixins/screen-reader";
@import "mixins/size";
@import "mixins/reset-text";
@import "mixins/text-emphasis";
@import "mixins/text-hide";
@import "mixins/text-truncate";
@import "mixins/transforms";
@import "mixins/visibility";

// Components
@import "mixins/alert";
@import "mixins/buttons";
@import "mixins/cards";
@import "mixins/pagination";
@import "mixins/lists";
@import "mixins/list-group";
@import "mixins/nav-divider";
@import "mixins/forms";
@import "mixins/table-row";

// Skins
@import "mixins/background-variant";
@import "mixins/border-radius";
@import "mixins/gradients";

// Layout
@import "mixins/clearfix";
// @import "mixins/navbar-align";
@import "mixins/grid-framework";
@import "mixins/grid";
@import "mixins/float";
