.dialer-icon {
  position: relative;
  left: -1px;
  top: -1px;
}

.webrtc-dialer {
  background: $whitish;
  width: 300px;
  max-height: 535px;
  position: fixed;
  z-index: 2001;
  bottom: 0px;
  right: 12px;

  & .el-input.is-disabled .el-input__inner {
    background-color: transparent !important;
    border-color: transparent !important;
    color: $greyish !important;
  }

  .no-select .el-input--mini .el-input__inner {
    @extend .no-select;
  }

  &.widget {
    width: 300px;
    right: auto;
    left: 0px;
  }

  & .webrtc-header {
    height: 100px;
    font-size: 13px;
    z-index: 2001;

    .header-right {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .contact-name {
      max-width: 80%;
    }

    #signal-strength {
      height: 20px;
      list-style: none;
      overflow: hidden;
      padding: 0;
      margin: 0;

      & li {
        display: inline-block;
        width: 5px;
        float: left;
        height: 100%;
        margin-right: 1px;
      }

      & li.pretty-strong {
        padding-top: 0px;
      }

      & li.strong {
        padding-top: 5px;
      }

      & li.weak {
        padding-top: 10px;
      }

      & li.very-weak {
        padding-top: 15px;
      }

      & li div {
        height: 100%;
        border: 1px solid white;
      }

      & li div.active {
        background: white;
      }
    }

    .phone-form {
      .el-form-item {
        .el-input__inner {
          padding-right: 0;
          padding-left: 0;
        }

        .el-form-item__error {
          top: 80%;
          display: none;
        }
      }
    }

    .el-form {
      width: 80%;
      float: left;

      .el-input__inner {
        width: 100%;
      }
    }

    & .phone-form input {
      color: $whitish;
      text-shadow: 0 0 0 $greyish;
      border: none;
      background: transparent;
      font-size: 24px;
      text-align: left;
      width: 100%;

      &.on-call {
        font-size: 15px !important;
        line-height: 15px;
      }

      &:focus {
        outline: none;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $whitish;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $whitish;
        opacity: 0.5;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $whitish;
        opacity: 0.5;
      }

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $whitish;
        opacity: 0.5;
      }

      &::-moz-placeholder { /* Firefox 19+ */
        color: $whitish;
        opacity: 0.5;
      }

      &:-moz-placeholder { /* Firefox 18- */
        color: $whitish;
        opacity: 0.5;
      }
    }

    & button {
      & i {
        font-size: 29px;
      }

      &:not([disabled]):not(.solid):focus,
      &:not([disabled]):not(.solid):hover,
      &:not([disabled]):not(.solid):active {
        background-color: transparent !important;
      }

      &.unhold:focus,
      &.unhold:hover,
      &.unhold:active {
        background-color: darken($greenish, 5%) !important;
      }
    }

    & .search-btn {
      & i {
        font-size: 20px;
      }
    }
  }

  & .communication-info {
    height: 392px;

    & .scrollable {
      height: 350px;
    }
  }

  & .call-communication-info {
    height: 304px;
    overflow-y: scroll;
  }

  & .dummy {
    width: 80px;
    height: 80px;
  }

  & .in {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  & .number-btn-wrapper {
    background: $whitish;
    border-right: 1px solid rgba(173, 210, 218, 0.30);
    border-bottom: 1px solid rgba(173, 210, 218, 0.30);
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  & .refresh-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2px;
    margin-right: 2px;
    padding: 0px;
    font-size: 18px !important;
    color: $dark-greenish;
  }

  & .number-text {
    font-family: $font-family-body;
    font-size: 24px;
    color: $new-blue;
  }

  & .number-text-sub {
    font-family: $font-family-body;
    font-size: 14px;
    color: $dark-greyish;
    text-align: center;
    display: block;
  }

  & .call-btn {
    background: $greenish;
    color: $whitish;
    padding: 1.5rem 1rem;

    i {
      font-size: 36px;
    }
  }

  & .hangup-btn {
    background: $danger;
    color: $whitish;
    padding: 1.5rem 1rem;

    i {
      font-size: 36px;
    }
  }

  & .minimize {
    z-index: 2002;
  }

  & .help {
    z-index: 2002;
  }

  & .settings {
    z-index: 2002;
  }

  & .note-menu .note-wrapper {
    height: 320px;
  }

  & .transfer-menu .transfer-wrapper {
    height: 320px;
  }

  & .copy-btn {
    color: $whitish !important;
    background: transparent !important;
    padding: 5px 0 !important;
    border: 0 !important;
    font-size: 13px !important;

    & i {
      font-size: inherit !important;
    }

    &:not([disabled]):focus,
    &:not([disabled]):hover,
    &:not([disabled]):active {
      color: rgba($whitish, 0.6) !important;
    }
  }

  /* dark skin theme for webrtc dialer */
  &.dark-skin-theme {
    background: $blackish;
    color: $whitish;

    & .webrtc-header {
      & input {
        color: $whitish;
        text-shadow: 0 0 0 $whitish;
      }
    }

    & .number-btn-wrapper {
      background: $blackish;
    }

    & .number-text {
      color: $whitish;
    }

    .number-text {
      color: $whitish;
    }

    /* for close button on dark skin theme */
    & .minimize {
      @extend .text-greenish;
    }
  }

  &.small {
    width: 270px;

    & .dummy {
      width: auto;
      height: 70px;
    }

    & .communication-info {
      height: 360px;

      & .scrollable {
        height: 312px;
      }
    }

    & .note-menu .note-wrapper {
      height: 280px;
    }

    & .transfer-menu .transfer-wrapper {
      height: 280px;
    }

    & .overview-boxes {
      height: 70px;
    }
  }
}

#dialer {
  z-index: 2000;
}

.button-custom-list {
  padding: 0;
  margin: 10px 0;
  list-style: none
}

.button-custom-list li {
  margin-bottom: 10px;
}

.button-custom-list li:last-child {
  margin-bottom: 0;
}
