.security-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &-wrapper {
    display: inline-block;
    margin: auto;
    min-width: 240px;
    text-align: center;
    @media only screen and (max-device-width: 736px) {
      max-width: 320px;
    }

    .security-code-field {
      width: 40px;
      display: inline-block;
      margin-right: 10px;
      float: left;
      @media only screen and (max-device-width: 736px) {
        float: none;
        margin-right: 4px;
      }

      .form-control {
        width: 44px;
        height: 44px;
        font-size: 30px;
        text-align: center;
        padding: 0;
        @media only screen and (max-device-width: 736px) {
          width: 42px;
          height: 42px;
          margin: 0;
        }
      }
    }
  }
}
