.el-table {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: $greyish !important;
}

.el-table thead {
  color: $dark-greyish !important;
  font-weight: bold !important;
}

.el-table .descending .sort-caret.descending {
  color: $dark-greyish !important;
  border-top-color: $greenish !important;
}

.el-table .ascending .sort-caret.ascending {
  color: $dark-greyish !important;
  border-bottom-color: $greenish !important;
}

.el-step__head.is-success {
  color: $greenish !important;
  border-color: $greenish !important;
}

.el-step__title.is-success {
  color: $greenish !important;
}

.el-step__description.is-success {
  color: $greenish !important;
}

.el-button {
  span > i.material-icons {
    line-height: 0;
  }

  &.el-button--text {
    & span > i.material-icons {
      line-height: normal;
    }
  }
}

.el-button--text {
  &:hover, &:focus {
    border-color: transparent !important;
    background-color: transparent !important;
  }
}

.el-button--success:not(.is-plain) {
  background-color: $greenish !important;
  border-color: $greenish !important;

  &:hover {
    background-color: darken($greenish, 4%) !important;
    color: $white !important;
  }

  &:focus {
    background-color: darken($greenish, 5%) !important;
    color: $white !important;
  }

  &:active {
    background-color: darken($greenish, 10%) !important;
  }

  &.is-disabled, &.is-disabled:active, &.is-disabled:focus, &.is-disabled:hover {
    background-color: rgba($dark-greenish, 0.2) !important;
    border-color: rgba($dark-greenish, 0.2) !important;
  }
}

.el-button--purple:not(.is-plain) {
  background-color: $purple !important;
  border-color: $purple !important;
  color: $white !important;

  &:hover {
    background-color: darken($purple, 4%) !important;
    color: $white !important;
  }

  &:focus {
    background-color: darken($purple, 5%) !important;
    color: $white !important;
  }

  &:active {
    background-color: darken($purple, 10%) !important;
  }

  &.is-disabled, &.is-disabled:active, &.is-disabled:focus, &.is-disabled:hover {
    background-color: rgba($purple, 0.2) !important;
    border-color: rgba($purple, 0.2) !important;
  }
}

.el-button-group .el-button--success:first-child {
  border-right-color: rgba(255, 255, 255, .5) !important;
}

.el-button-group .el-button--success:last-child {
  border-left-color: rgba(255, 255, 255, .5) !important;
}

.el-button--success.is-plain {
  border-color: $greenish !important;
  color: $greenish !important;

  border-color: lighten($greenish, 10%);

  &:hover {
    background-color: darken($greenish, 4%) !important;
    color: $white !important;
  }

  &:focus {
    background-color: darken($greenish, 5%) !important;
    color: $white !important;
  }

  &:active {
    background-color: darken($greenish, 10%) !important;
  }

  &.is-disabled, &.is-disabled:active, &.is-disabled:focus, &.is-disabled:hover {
    border-color: rgba($dark-greenish, 0.2) !important;
  }
}

.el-button--info {
  &:hover, &:focus {
    background: #a6a9ad !important;
    border-color: #a6a9ad !important;
    color: #fff !important;
  }
}

.el-button--warning {
  &:hover, &:focus {
    background: #ebb563 !important;
    border-color: #ebb563 !important;
    color: #fff !important;
  }
}

.el-button--danger {
  &:hover, &:focus {
    background: #f78989 !important;
    border-color: #f78989 !important;
    color: #fff !important;
  }
}

.el-step__line-inner {
  -webkit-transition: .05s ease-out !important;
  transition: .05s ease-out !important;
}

.el-form--label-top {
  & .el-form-item__label {
    padding: 0 !important;
    margin-bottom: 0px !important;
  }

  & .el-form-item__label {
    font-weight: bold !important;
  }

  & .el-form-item__label {
    line-height: 24px !important;
  }
}

.el-form--label-left {
  & .el-form-item__label {
    padding: 0 !important;
    margin-bottom: 0px !important;
  }

  & .el-form-item__label {
    font-weight: bold !important;
  }
}

.el-form-item.is-required .el-form-item__label:before {
  display: none !important;
}

.el-form--inline .el-form-item {
  margin-bottom: 0px !important;
}

.el-checkbox {
  margin-bottom: 0 !important;
}

.el-table .material-icons {
  word-break: normal !important;
}

.el-table__empty-text {
  font-size: 26px !important;
  font-weight: bold !important;
  color: rgb(96, 98, 102) !important;
}

.el-loading-mask {
  z-index: 999 !important;
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: $greenish !important;
  border-color: $greenish !important;
  -webkit-box-shadow: -1px 0 0 0 $greenish !important;
  box-shadow: -1px 0 0 0 $greenish !important;
  font-weight: 600 !important;
}

.el-button.text-md {
  font-size: 1.5rem !important;
}

.el-color-svpanel {
  width: 265px !important;
}

.el-pagination.is-background .el-pager li.active {
  background-color: $greenish !important;
}

.el-pagination.is-background .el-pager li.active:hover {
  color: $white !important;
}

.el-pagination.is-background .el-pager li:hover {
  color: $greenish !important;
}

.el-radio-button:focus:not(.is-focus):not(:active) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.bold-label .el-form-item__label {
  font-weight: 400 !important;
  color: $black !important;
}

.right-align {
  & .el-input__inner {
    text-align: right !important;
    font-size: 14px !important;
  }

  & .el-switch {
    float: right !important;
    margin-right: 10px !important;
  }

  & .popper__arrow {
    left: auto !important;
    right: 35px !important;
  }

  & .el-select__input {
    text-align: right !important;

    &:hover {
      cursor: pointer !important;
    }
  }
}

.no-bottom-margin {
  & .el-form-item__content, & .el-form-item__label {
    line-height: 25px !important;
  }

  & .el-input__inner {
    height: 25px !important;
  }

  & .el-input--mini .el-input__inner {
    height: 28px !important;
  }

  & .el-form-item {
    margin-bottom: 0px !important;
  }
}

.lean-bottom-margin {
  @extend .no-bottom-margin;

  .el-form-item {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
}

.fixed-line-height {
  & .el-form-item__content {
    line-height: 1em !important;
  }
}

.no-bottom-margin.compact {
  & .el-form-item__content, & .el-form-item__label {
    line-height: 25px !important;
  }

  & .el-input__inner {
    height: 26px !important;
    line-height: 26px;
  }

  & .el-form-item__label {
    margin-bottom: 0rem !important;
  }

  & .el-date-editor--daterange.el-input__inner {
    top: -2px !important;
    width: 195px !important;
  }
}

.no-bottom-margin .el-date-editor {
  & .el-range__icon, & .el-range-separator, & .el-range__close-icon {
    line-height: 32px !important;
  }

  &.el-input__inner {
    height: 40px !important;
  }
}

.no-bottom-margin.compact .el-date-editor {
  & .el-range__icon, & .el-range-separator, & .el-range__close-icon {
    line-height: 16px !important;
  }

  &.el-input__inner {
    height: 24px !important;
  }
}

.el-select-dropdown__item.selected {
  color: $greenish !important;
}

.el-radio-button {
  margin-bottom: 0 !important;
}

.el-radio-button.is-active {
  & .el-radio-button__inner:hover {
    color: $white !important;
  }
}

.wizard {
  & .el-form-item__content {
    line-height: 24px !important;
  }
}

.el-upload, .el-upload-dragger {
  width: 100% !important;
}

.sidebar-wrap {
  & .el-dropdown {
    width: 100% !important;
  }

  & .el-dropdown .el-button-group .el-button--primary:first-child {
    width: 70% !important;
  }
}

.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
  color: $greenish !important;
}

.dialog-padding {
  & .el-dialog__body {
    padding: 30px 20px 0px 20px !important;
  }
}

.el-loading-mask.is-fullscreen {
  z-index: 9999 !important;
}

.el-progress-bar__inner {
  transition: all .5s ease !important;
}

.auto > .el-dialog {
  min-width: auto;
  width: auto;
}

.el-radio-button__inner:hover {
  color: $greenish !important;
}

.el-table th.is-sortable > .cell {
  position: relative !important;
  top: 1px !important;
}

.el-radio-button__inner {
  font-weight: 400 !important;
}

.el-tabs__item:hover {
  color: $bluish !important;
}

.el-tabs__item.is-active {
  font-weight: 600 !important;
}

.el-tabs__active-bar {
  background-color: $bluish !important;
}

.el-radio__input.is-checked + .el-radio__label:not(.custom-red-radio .el-radio__label) {
  color: $dark-greenish !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: $dark-greenish !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: $dark-greenish !important;
  border-color: $dark-greenish !important;
}

.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: $greenish !important;
}

.el-textarea__inner:focus {
  border-color: $greenish !important;
}

.el-button {
  font-weight: 400 !important;
  font-size: 1rem !important;

  &.el-button--mini, &.el-button--small {
    font-size: 14px !important;
  }
}

.el-loading-spinner .path {
  stroke: $greenish !important;
}

.el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: 0 0 2px 2px $dark-greenish inset !important;
  box-shadow: 0 0 2px 2px $dark-greenish inset !important;
}

.el-switch.is-checked .el-switch__core {
  border-color: $dark-greenish;
  background-color: $dark-greenish;
  border: 1px solid #DCDFE6 !important;
}

.el-upload-dragger .el-upload__text em {
  color: $dark-greenish !important;
}

.el-upload-dragger:hover {
  border-color: $dark-greenish !important;
}

.el-radio__input.is-checked .el-radio__inner:not(.custom-red-radio .el-radio__inner){
  border-color: $dark-greenish !important;
  background: $dark-greenish !important;
}

.el-progress.is-success .el-progress-bar__inner {
  background-color: $greenish !important;
}

.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: $whitish !important;
  color: $greenish !important;
}

.el-breadcrumb__inner a:hover, .el-breadcrumb__inner:hover {
  color: $dark-greenish !important;
}

.el-alert__closebtn {
  top: calc(50% - 6px) !important;
}

.el-tabs__item {
  font-weight: 400 !important;
}

.el-table td, .el-table th {
  max-width: 0px;
}

.el-table--border::after, .el-table--group::after, .el-table::before {
  background-color: transparent !important;
}

.el-table__empty-block {
  min-height: 400px !important;

  &.no-min-height {
    min-height: 0 !important;
  }
}

.el-notification {
  width: 390px !important;
}

.el-notification.big {
  width: 475px !important;
}

.el-notification__content {
  text-align: left !important;
}

.el-popover {
  text-align: left !important;
  word-break: break-word !important;
  line-height: 1.75 !important;
}

.el-popover__title {
  font-weight: 600 !important;
}
.csat-table tr.el-table__row {
  cursor: pointer;
}

.el-button span {
  pointer-events: none;
}

.el-tooltip__popper {
  font-size: 14px !important;
  line-height: 18px;
}

.el-dialog__header {
  font-weight: bold !important;
  border-bottom: 0;

  h5 {
    color: #232323;
  }
}

.el-message-box__header {
  border-bottom: 1px solid #dedede;
}

.el-message-box__content {
  padding-top: 2rem;
}

.el-radio.is-bordered.is-checked:not(.custom-red-radio) {
  border-color: $dark-greenish !important;
}

.text-lg {
  & .el-radio.is-bordered {
    padding: 9px 20px 0 10px !important;
  }

  & .el-radio__label {
    font-size: 18px;
  }
}

.el-button {
  max-height: 44px;
}

.el-input-number__decrease:hover, .el-input-number__increase:hover {
  color: $dark-greenish !important;
}

.el-input-number__decrease:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled) {
  border-color: $dark-greenish !important;
}

.el-table__expand-icon > .el-icon {
  font-size: 16px;
}

.filter-select .el-input .el-select__caret {
  line-height: 16px !important;
}

.el-tabs__item {
  padding: 0px 30px !important;
}

.el-transfer__button {
  width: 40px;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.el-transfer-panel__item:hover {
  color: $dark-greenish !important;
}

.el-pagination > .el-pagination__sizes > .el-select > .el-input > .el-input__inner {
  @extend .no-select;
}

.el-notification__content {
  margin: 0 !important;
}

.el-notification__group {
  margin-right: 13px !important;
}

.el-popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: #e4e7ed !important;
}

.el-popper[x-placement^=top] .popper__arrow {
  border-top-color: #e4e7ed !important;
}

.dark-mode {
  &.el-popper {
    background: $blackish !important;
    border: 1px solid $blackish !important;
    color: $whitish !important;
    box-shadow: 0 2px 12px 0 rgba($blackish, .1) !important;
  }

  &.el-popper[x-placement^=top] .popper__arrow {
    border-top-color: $blackish !important;

    &:after {
      border-top-color: $blackish !important;
    }
  }

  & .el-select {
    background-color: lighten($blackish, 10%) !important;
    border: 1px solid lighten($blackish, 10%) !important;
    color: $whitish !important;

    & .el-input__inner {
      background-color: lighten($blackish, 10%) !important;
      border: 1px solid lighten($blackish, 10%) !important;
      color: $whitish !important;
    }
  }

  & .el-button--success:not(.is-plain).is-disabled {
    background-color: #00BF50 !important;
    border-color: #00BF50 !important;
  }
}

.el-date-editor .el-range-separator {
  width: 10% !important;
}

.el-tabs__item {
  @extend .no-select;
}

.el-table .cell {
  word-break: break-word !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: $whitish !important;
  background: $greenish !important;
}

.el-tabs__header {
  margin: 0px !important;
}

.el-tabs__content {
  background: $whitish !important;
  padding-top: 15px !important;
}

.big-screen {
  &:not(.force-full-height) .full-height > .el-tabs__content {
    height: 100%;
  }

  &.force-full-height .full-height > .el-tabs__content {
    height: calc(100vh - 25px);
  }

  & .el-tabs__content {
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0.15);
  }

  & .el-badge__content.is-fixed.is-dot {
    right: -5px !important;
    top: 5px !important;
  }
}

.embedded-screen {
  & .full-height > .el-tabs__content {
    height: calc(100vh - 390px);
  }

  & .sidebar-full-height {
    height: calc(100vh - 390px);
  }
}

.contact-details {
  & .el-collapse-item__arrow {
    position: relative;
    float: left;
    top: 18px;
    left: -11px;
    margin: 0 !important;
  }

  .el-collapse-item > div {
    display: table;
    width: 100%;
  }

  .el-collapse-item__header {
    display: table-cell !important;
    font-size: 14px !important;
    vertical-align: middle;
    padding-left: 25px;
    @extend ._600;
  }

  .el-collapse-item__content {
    padding-bottom: 10px;
    width: 362px;
  }
}

.call-communication-info {
  .el-collapse-item__content {
    padding-bottom: 5px;
  }
}

.el-message-box__title {
  @extend ._600;
}

.el-message-box__message p {
  line-height: 26px;
  font-size: 18px;
  @extend ._400;
}

.el-notification__title {
  font-size: 17px !important;
}

.el-notification__content {
  margin-top: 0.25em !important;
  font-size: 15px !important;
  line-height: 22px !important;
}

.lean {
  & .el-card__header {
    padding: 0.5rem 0.5rem !important;
  }

  & .el-card__body {
    padding: 0.5rem 0rem !important;
  }
}

.small-paddings {
  & .el-card__body, & .el-card__header {
    padding: 8px 10px !important;
  }
}

.el-card__body {
  word-break: break-word;
}

.el-card strong {
  font-size: 19px;
}

.el-button--light-blue-500 {
  background: $light-blue-500 !important;
  color: $dark-white !important;

  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]).active {
    box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
  }
}

.el-button--deep-orange-500 {
  background: $deep-orange-500 !important;
  color: $dark-white !important;

  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]).active {
    box-shadow: inset 0 -10rem 0px rgba(158, 158, 158, 0.2);
  }
}

.mega-dropdown-menu {
  & span.el-select__tags-text {
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    float: left;
  }
}

.el-message {
  font-size: 22px !important;
  font-weight: 500;

  & .el-message--content {
    font-size: 22px !important;
  }
}

.el-button span > i.material-icons {
  line-height: 0.75em;
  height: auto;
}

.el-select-dropdown__item {
  padding: 0 .75rem 0 .75rem !important;
  border-left: 3px solid transparent;
  white-space: normal !important;
  min-height: 34px !important;
  height: auto !important;
  font-size: 14px !important;

  .media {
    .media-body {
      padding: .5rem .5rem .5rem 0 !important;
      border-left: 3px solid transparent;
      font-weight: 400 !important;
      border-bottom: 1px solid #f9f9f9;
      line-height: 1.6;

      label {
        font-weight: bold;
        display: block;
        margin: 0
      }
    }
  }

  &.selected {
    border-left: 3px solid $greenish !important;

    .media {
      .media-body {
        color: #656565 !important;

        label {
          color: #00BF50 !important;
        }
      }
    }
  }

  &.hover {
    background-color: rgba(212, 237, 218, 0.3) !important;
    color: #00BF50 !important;
  }
}


.v-modal {
  background: rgba(0, 0, 0, 0.4) !important;
}

.el-radio {
  margin-right: 10px !important;
}

.el-radio-button--small .el-radio-button__inner {
  font-size: 14px !important;
}

.el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0 !important;
}

.el-input--small .el-input__inner {
  height: 34px !important;
  line-height: 32px !important;
}

.ivr-timeline {
  & .el-timeline-item__wrapper {
    padding-top: 1.5em;
    top: 2px !important;
  }

  & .el-timeline-item__dot {
    left: -15px;
    z-index: 100;
  }

  & .el-timeline-item__timestamp.is-top {
    display: none !important;
  }

  & .el-form-item__label {
    font-size: 16px !important;
  }

  & .form-hover-help {
    line-height: 1 !important;
  }
}

.user_timeline {
  & .el-timeline-item__wrapper {
    & .el-timeline-item__timestamp {
      font-weight: 400 !important;
      color: #0e1015;
    }
  }
}

.timeline-dot {
  width: 40px;
  height: 20px;
  position: relative;
  top: -2px;

  & .el-timeline-item__node--xlarge {
    left: 10px;
    width: 20px;
    height: 20px;
  }
}

.el-tabs__content {
  overflow: visible !important;
}

.el-menu.no-border {
  border: 0 !important;
}

.el-transfer-panel__item.el-checkbox {
  display: inherit;
}

.el-collapse.fullscreen {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  border: 0;

  .el-collapse-item {
    @extend .no-select;
  }

  .el-collapse-item:first-child {
    border-style: solid;
    border-color: #EBEEF5;
    border-width: 1px 1px 0 1px;
  }

  .el-collapse-item:not(:first-child) {
    border: 1px solid #EBEEF5;
  }

  .el-collapse-item:last-child {
    border-style: solid;
    border-color: #EBEEF5;
    border-width: 0 1px 1px 1px;
  }

  .el-collapse-item__header {
    padding-left: 1em;
    border-bottom: 0;
    @extend .no-select;
  }

  .el-collapse-item__content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 0 !important;

    & > div {
      padding: 0 1em 0 1em;
    }
  }

  .el-collapse-item__wrap {
    border-bottom: 0;
  }

  .el-collapse-item:not(.is-active) {
    .el-collapse-item__content {
      display: none;
    }
  }

  .el-collapse-item.is-active {
    display: flex;
    flex-direction: column;
    height: calc(100% - 199px);

    .el-collapse-item__header {
      border-bottom: 1px solid #EBEEF5;
    }

    .el-collapse-item__wrap {
      height: 100%;
    }
  }
}

.el-form-item__label {
  margin-bottom: 0;
}

.el-radio, .el-radio__input {
  white-space: inherit !important;
  word-break: break-word !important;
}

.el-table .cell {
  text-overflow: initial !important;
}

.el-button--mini, .el-button--small {
  font-size: 14px;
}

.el-collapse-item__header {
  @extend .no-select;
  font-size: 14px !important;
}

.el-collapse-item__content {
  font-size: 14px !important;
}

.border-0 {
  & .el-collapse-item__header {
    border: 0 !important;
  }

  & .el-collapse-item__wrap {
    border: 0 !important;
  }

  & .el-collapse-item__arrow {
    margin: 0 15px 0 auto !important;
  }
}

.el-tabs.no-header {
  & .el-tabs__header {
    display: none;
  }

  & .el-tabs__content {
    padding-top: 0px !important;
  }
}

.el-tabs.with-header {
  & .el-tabs__header {
    display: block;
  }
}

.el-divider__text.is-center {
  text-align: center;
  padding: .5rem !important;
  border: 1px solid rgba(120, 130, 140, 0.13) !important;
  @extend .text-xs;
  @extend .r-2x;
}

.nav-active-greenish .nav-link.active, .nav-active-greenish .nav > li.active > a {
  font-weight: 600 !important;
}

.item > .el-badge__content {
  font-size: 16px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  z-index: 1;
}

.el-button-group .el-button--info.is-plain:first-child {
  border-left-color: #d3d4d6 !important;
}

.el-button-group .el-button--info.is-plain:last-child {
  border-left-color: #d3d4d6 !important;
}

.el-icon-arrow-down:before {
  vertical-align: -1px;
}

.el-form-item__content {
  line-height: 32px !important;
}

.el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .1) !important;
}

.el-checkbox {
  white-space: normal !important;
}

.el-checkbox__input {
  display: block !important;
  float: left !important;
  line-height: 1.6em !important;
}

.el-checkbox__label {
  display: block !important;
  margin-left: 15px !important;
}

.el-divider.no-border {
  & .el-divider__text {
    border: none !important;
  }
}

.el-button--text.is-disabled {
  color: $grey-300 !important;
}

.el-transfer-panel__header {
  width: 100%;
  display: flex;
  align-items: center;

  & .el-checkbox {
    width: 100%;
  }
}

.el-transfer-panel__list {
  overflow-x: hidden !important;
}

.el-checkbox.is-bordered {
  display: inline-flex;
  align-items: center;

  .el-checkbox__input {
    line-height: 1 !important;
  }
}

.el-border-checkbox-danger {
  &.is-checked {
    color: #e44f37 !important;
    border: 1px solid #e44f37 !important;
    background-color: #faebee !important;

    .el-checkbox__inner {
      background-color: #e44f37 !important;
      border-color: #e44f37 !important;
    }

    .el-checkbox__label {
      color: #e44f37 !important;
    }
  }
}

.checkbox-md {
  .el-checkbox__label {
    font-size: 16px !important;
  }
}

.el-table.activity-reporting-table {
  & td, th {
    padding: 5px 0;
    vertical-align: top;
  }

  @extend .force-scrollbar-view;
}

.el-select {
  .el-tag {
    color: #67C23A !important;
    background: #f0f9eb !important;
    border-color: #89E45C !important;
    border-radius: 2px !important;
    font-size: 14px;
    padding-left: .75rem;
    padding-right: .75rem;
    box-shadow: none !important;
    font-weight: 600;
    word-wrap: break-word;
    max-width: 90%;
    white-space: initial;
    height: auto;

    .el-tag__close {
      background-color: #67C23A !important;
      color: #fff !important;

      &:hover {
        background-color: $dark-greenish !important;
      }
    }
  }
}

.webrtc-communication-tags {
  .el-select.tag-selector {
    max-width: 265px;
  }
}

.is-active.pause-deep-orange {
  .el-radio-button__inner {
    background-color: #ff5722 !important;
    border-color: #ff5722 !important;
    box-shadow: -1px 0 0 0 #ff5722 !important;
  }
}

.expand-view-btn {
  color: #6c757d !important;
  background-color: #fbfbfb !important;
  border: none;
}

.shrink-view-btn {
  color: #00BF50 !important;
  background-color: #fbfbfb !important;
  border: none;
}

.el-select {
  .el-input__inner {
    &:active,
    &:focus {
      border-color: $greenish !important;
    }
  }
}

.el-input.is-focus {
  .el-input__inner {
    border-color: $greenish !important;
  }
}

.el-tree-node.is-expanded {
  .el-tree-node__children {
    .el-tree-node {
      white-space: initial;
      .el-tree-node__content {
        height: auto;
      }
    }
  }
}

.el-button--deep-orange.is-plain:focus, .el-button--deep-orange.is-plain:hover {
  background: $white !important;
  border-color: $deep-orange !important;
  color: $deep-orange !important;
}

div.simpsocial-email {
  iframe {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
}

.el-alert .el-alert__description {
  font-size: 14px !important;
  font-weight: 600;
}

.kyc-review {
  overflow: auto;
  max-height: 80vh;

  .el-button-group {
    > .el-button:first-child {
      min-width: 150px;
    }
  }

  .status-KYC_STATUS_ONE {
    .el-button {
      background-color: white;
      color: #737373;
      border: 1px solid #737373;

      &:hover {
        background-color: rgb(175, 175, 175);
        color: white;
      }
    }
  }

  .status-KYC_STATUS_REJECTED {
    .el-button {
      background-color: white;
      color: #e22400;
      border: 1px solid #e22400;

      &:hover {
        background-color: #e22400;
        color: white;
      }
    }
  }

  .status-KYC_STATUS_PENDING {
    .el-button {
      background-color: white;
      color: #d58400;
      border: 1px solid #d58400;

      &:hover {
        background-color: #d58400;
        color: white;
      }
    }
  }

  .status-KYC_STATUS_APPROVED {
    .el-button {
      background-color: white;
      color: #2684ff;
      border: 1px solid #2684ff;

      &:hover {
        background-color: #2684ff;
        color: white;
      }
    }
  }

  .status-KYC_STATUS_FRAUD_SUSPECTED {
    .el-button {
      background-color: white;
      color: #e22400;
      border: 1px solid #e22400;

      &:hover {
        background-color: #e22400;
        color: white;
      }
    }
  }


  .status-STATUS_VERIFIED_FULL {
    .el-button {
      background-color: white;
      color: #91e641;
      border: 1px solid #91e641;

      &:hover {
        background-color: #669c35;
        color: white;
      }
    }
  }

  .status-badge {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
  }

  .status-badge-KYC_STATUS_ONE {
    background-color: #737373 !important;
    border: 1px solid #737373 !important;
  }

  .status-badge-KYC_STATUS_REJECTED {
    background-color: #e22400 !important;
    border: 1px solid #e22400 !important;
  }

  .status-badge-KYC_STATUS_PENDING {
    background-color: #d58400;
    border: 1px solid #d58400;
  }

  .status-badge-KYC_STATUS_APPROVED {
    background-color: #2684ff;
    border: 1px solid #2684ff;
  }

  .status-badge-KYC_STATUS_FRAUD_SUSPECTED {
    background-color: #e22400 !important;
    border: 1px solid #e22400 !important;
  }

  .status-badge-STATUS_VERIFIED_FULL {
    background-color: #91e641;
    border: 1px solid #91e641;
  }

  .status-display {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 35px;
    min-width: 400px;
    border-radius: 30px;
    padding-right: 120px;
    padding-left: 14px;
    font-size: 16px;
    position: relative;
    z-index: 1;
  }

  .status-display-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.0025em;
    text-align: left;
    margin-bottom: 0px;
    color: #040404;
  }

  .status-display__button {
    display: flex;
    background: #256EFF;
    color: white;
    border-radius: 30px;
    width: 64px;
    height: 35px !important;
    margin-left: -40px;
    align-items: center;
    padding-right: 75px;
    position: relative;
    z-index: 2;

    &.is-disabled {
      opacity: 100%;
    }

    &.el-button.is-loading {
      width: 100px !important;
    }
  }

  .status-display-KYC_STATUS_ONE {
    border: 1px solid #737373;
    color: #737373;
  }

  .status-display-KYC_STATUS_REJECTED {
    border: 1px solid #e22400;
    color: #e22400;
  }

  .status-display-KYC_STATUS_PENDING {
    border: 1px solid #d58400;
    color: #d58400;
  }

  .status-display-KYC_STATUS_APPROVED {
    border: 1px solid #2684ff;
    color: #2684ff;
  }

  .status-display-STATUS_VERIFIED_FULL {
    border: 1px solid #91e641;
    color: #91e641;
  }

  .status-display-KYC_STATUS_FRAUD_SUSPECTED {
    border: 1px solid #e22400;
    color: #e22400;
  }

  .details {
    .container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;

      > div {
        width: 32%;
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }

    .table {
      max-height: 30vh;
    }

    .account-log-table {
      max-height: 30vh;

      .el-table__header, .el-table__body {
        border: 1px solid #D8D8D8 !important;
      }

      .el-table__header {
          border-bottom: none !important;
      }

      .el-table__body {
          border-top: none !important;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }

    .col-span-2 {
      grid-column: span 2;
    }

    .col-span-3 {
      grid-column: span 3;
    }
  }

  .link {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #256EFF;
    cursor: pointer;
  }

  .kyc-full-risk-evaluation {
    display: flex;
    border: 1px solid #D8D8D8 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;

    .el-tree-node__label {
      font-size: 16px;
    }
  }

  .note {
    &__time {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.0025em;
      text-align: left;
      color: #040404;
      padding-left: 10px;
      margin-bottom: 0;
    }

    &__item {
      margin-top: 10px;
      border-radius: 30px;
      border: 1px solid var(--grey-70, #D8D8D8);
      padding: 12px 31px;
    }

    .action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;

      & > button {
        background-color: white;
        border: none;
        color: #256EFF;
        cursor: pointer;

        &:hover {
          background-color: white;
          color: #256effb1;
        }
      }
    }
  }

  .add-note {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 22px;
    margin-right: 12px;
    font-size: 16px;

    & > button {
      background-color: white;
      border: none;
      color: #256EFF;
      cursor: pointer !important;

      &:hover {
        background-color: white;
        color: #256effb1;
      }
    }
  }

  .el-table__empty-block {
    max-height: 210px !important;
    min-height: 210px !important;
    max-width: 1500px !important;
  }

  .min-w-60 {
    min-width: 60% !important;
  }
}

.account-registration {
  .el-button {
    background-color: #256EFF !important;
    color: #fff !important;
    border-radius: 30px !important;
    text-transform: none;

    &:hover {
      background-color: #3b79f5 !important;
      border-color: #3b79f5 !important;
      color: #FFFFFF !important;
    }
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266 !important;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0067F4 !important;
    border-color: #0067F4 !important;
  }
}

.aloai-agents {
  & .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .el-tabs__nav {
    display: flex;
    float: none;
  }

  & .el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1) !important;
  }

  & .el-radio.is-bordered.is-checked:not(.custom-red-radio) {
    border-color: #0067F4 !important;
  }

  & .el-radio__input.is-checked + .el-radio__label:not(.custom-red-radio .el-radio__label) {
    color: inherit !important;
  }

  & .el-tabs__header {
    position: sticky;
    top: 178px;
    z-index: 2;
    background: white;
  }

  & .el-tabs__content {
    position: static;
    padding: 0 !important;
    z-index: 1;
  }
}
