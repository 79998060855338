.avatar{
  position: relative;
  display: inline-block;
  width: 40px;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
  img{
    border-radius: 500px;
    width: 100%;
  }
  i{
    position: absolute;
    left: 0;
    top:  0;
    width:  10px;
    height: 10px;
    margin: 1px;
    border-width:  2px;
    border-style:  solid;
    border-radius: 100%;
    &.right{
      left: auto;
      right: 0;
    }
    &.bottom{
      left: auto;
      top: auto;
      bottom: 0;
      right: 0;
    }
    &.left{
      top: auto;
      bottom: 0;
    }
    &.on{
      background-color: $success;
    }
    &.off{
      background-color: $light;
    }
    &.away{
      background-color: $warning;
    }
    &.busy{
      background-color: $danger;
    }
  }
  &.w-32{
    i{
      margin: 0px;
    }
  }
  &.w-48{
    i{
      margin: 2px;
    }
  }
  &.w-56{
    i{
      margin: 3px;
    }
  }
  &.w-64{
    i{
      margin: 4px;
    }
  }
  &.w-96{
    i{
      margin: 9px;
    }
  }
  &.w-128{
    i{
      margin: 14px;
    }
  }
}
