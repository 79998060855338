.nav{
  border: inherit;
}

.nav-item{
  border: inherit;
}

.nav-link{
  border: inherit;
  position: relative;
}

.flex-row{
  .nav-link{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.nav-md{
  .nav-link{
    padding: 0.5rem 1rem;
  }
}

.nav-sm{
  .nav-link{
    padding: 0.25rem 0.75rem;
  }
}

.nav-xs{
  .nav-link{
    padding: 0.15rem 0.5rem;
    font-size: 90%;
  }
}

.nav-rounded{
  .nav-link{
    border-radius: 2rem;
  }
}

.nav-tabs{
  border-bottom-width: 0;
  position: relative;
  z-index: 1;
  .nav-link{
    background: transparent !important;
    color: inherit !important;
    &.active,
    &.active:hover,
    &.active:focus,
    &:hover,
    &:focus{
      border-color: $border-color;
      border-bottom-color: transparent;
    }
  }
}

.tab-content.tab-alt{
  .tab-pane{
    display:block;
    height: 0;
    overflow: hidden;
    &.active{
      height: auto;
      overflow: visible;
    }
  }
}

.nav-justified{
  .nav-item{
    display: table-cell;
    width: 1%;
    float: none !important;
    text-align: center;
  }
}

.nav-lists{
  .nav-item{
    border-bottom:1px solid $border-color;
    background-clip: padding-box;
  }
}

.nav-active-border{
  .nav-link{
    &:before{
      content: '';
      position: absolute;
      @include transition(all 0.2s ease-in-out);
      left: 50%;
      right: 50%;
      bottom: 0;
      border-bottom-width: 3px;
      border-bottom-style: solid;
      border-bottom-color: transparent;
    }
    &:hover:before,
    &:focus:before,
    &.active:before{
      left: 0%;
      right: 0%;
      border-bottom-color: inherit;
    }
  }
  &.top{
    .nav-link:before{
      bottom: auto;
      top: 0;
    }
  }
  &.left{
    .nav-link{
      float: none;
      &:before{
        border-bottom-width: 0;
        right: auto;
        border-left-width: 3px;
        border-left-style: solid;
        border-left-color: transparent;
        left: 0;
        top: 50%;
        bottom: 50%;
      }
      &:hover:before,
      &:focus:before,
      &.active:before{
        top: 0%;
        bottom: 0%;
        border-left-color: inherit;
      }
    }
    &.right{
      .nav-link{
        &:before{
          left: auto;
          right: 0;
        }
      }
    }
  }
}

.breadcrumb{
  background-color: $min-black;
  > li + li:before{
    content: '/ ';
  }
}
