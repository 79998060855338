@mixin md-label {
  @include transition(all 0.2s);
  font-size: 0.90em;
  font-weight: 600;
  position: absolute;
  z-index: 0;
  opacity: 0.8;
  display: inline-block;
  top: 0px;
  left: 0;
}

.md-form-group{
	padding: 18px 0 24px 0;
	position: relative;
}
.md-select {
  position: relative;
  z-index: 5;
  box-shadow: none;
  width: 100%;
  margin-top: 5px;
  font-weight: 800;
  &:focus,
  &.focus{
    ~ label{
      color: $primary;
      opacity: 1;
      top: 0 !important;
      font-size: 0.90em !important;
    }
  }
  .float-label &{
    ~ label{
      top: 20px;
      font-size: 1em;
    }
    &.ng-dirty,
    &.has-value{
      ~ label{
        top: 0;
        font-size: 0.90em;
      }
    }
  }
  ~ label{
    @include md-label;
  }
  &.disabled,
  &[disabled]{
    opacity: 0.5;
  }
}

.md-input{
	background: transparent;
	position: relative;
	z-index: 5;
	border: 0;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(160, 160, 160, 0.2);
    width: 100%;
    height: 34px;
    color: inherit;
    font-weight: 800;
    &:focus,
    &.focus{
    	border-color: $primary;
    	border-bottom-width: 2px;
    	padding-bottom: 1px;
    	~ label{
    		color: $primary;
    		opacity: 1;
            top: 0 !important;
            font-size: 0.90em !important;
    	}
    }
    .float-label &{
        ~ label{
            top: 20px;
            font-size: 1em;
        }
        &.ng-dirty,
        &.has-value{
            ~ label{
                top: 0;
                font-size: 0.90em;
            }
        }
    }
    ~ label{
      @include md-label;
    }
    &.disabled,
    &[disabled]{
        opacity: 0.5;
    }
}

.md-form-group label {
  @include md-label;
}

textarea.md-input{
    height: auto;
}

.md-input-white{
    &:focus,
    &.focus{
        border-color: #fff;
        ~ label{
            color: #fff;
        }
    }
}

.md-input-msg{
    position: absolute;
    bottom: 0;
    line-height: 24px;
    font-size: 0.90em;
}
.md-input-msg.right{
    right: 0;
}
